import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';

import {
  PlusIcon,
  EllipsisVerticalIcon,
  ArrowLeftIcon,
} from '@heroicons/react/20/solid';

import {
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon
} from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';

import Drawer from '../../../../components/common/Drawer';
import Modal from '../../../../components/common/Modal';
import ModalData from '../../../../components/common/ModalData';
import {
  useJobrequestQuery,
  useDeleteJobrequestMutation,
} from '../../../../features/manpower/jobrequestApiSlice';

import AddJobRequest from './AddJobRequest';
import DateToIndiaFormat from '../../../../components/common/DateToIndiaFormat';

import { useDepartmentQuery } from '../../../../features/department/departmentApiSlice';
import { useBranchQuery } from '../../../../features/branch/branchApiSlice';
import NoList from '../../../../components/common/NoList';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const MTJobRequest = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  // const [jobrequestId, setJobrequestId] = useState();
  const [openModalDataJr, setOpenModalDataJr] = useState();

  const [jobRequestID, setJobRequestID] = useState(0);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();
  const [deleteJobrequest] = useDeleteJobrequestMutation();
  const {
    data: jobrequest,
    error: jobrequestError,
    isLoading: jobrequestLoading,
    isFetching: jobrequestFetching,
    isSuccess: jobrequestSuccess,
  } = useJobrequestQuery({ refetchOnMountOrArgChange: true });

  console.log(jobrequest?.data)
  // Filter the array for objects where status is true
  const filteredJobRequests = jobrequest?.data.filter(job => job.status === true);

  console.log(filteredJobRequests);

  const {
    data: depts,
    error: deptsError,
    isLoading: deptsLoading,
    isFetching: deptsFetching,
    isSuccess: deptsSuccess,
  } = useDepartmentQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const people = [
    {
      city: 'Bangalore',
      area: 'Koramangala',
      publish: 'Published',
      p_date: '05/09/2020',
      deadline: 'DeadLine',
      d_date: '15/09/2020',
      jobassigned: 'Job Assigned',
      number: '10',
      id: 'MPR ID 02345',
      role: 'Senior Manager',
      department: 'Sales & Marketing',

      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
  ];

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    // setJobrequestId(id);
    setJobRequestID(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async (jobRequestID) => {
    try {
      const deleteJR = await deleteJobrequest(jobRequestID).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) { }
  };

  const openModalDataWindow = (dataValue) => {
    setOpenModalDataJr(dataValue);
    // setManpowerId(id);
    // setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const approvalStatus = (approvalid) => {
    if (approvalid == 0) {
      return (
        <div className="flex justify-between px-2 py-2 border-grey-600 border-b-21 rounded-lg">
          <div>
            {/* <a href="" className="">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </a> */}
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Waiting for Approval</dt>
                <dd className="text-md text-gray-500">
                  {'Waiting for Approval'}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 1) {
      return (
        <div className="flex justify-between px-2 py-2 border-screeningL3 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <CheckCircleIcon
                className="h-6 w-6 text-screeningL3"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Job Assigned</dt>
                <dd className="text-md text-gray-500">Approved</dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 2) {
      return (
        <div className="flex justify-between px-2 py-2 border-blue-600 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <HandThumbUpIcon
                className="h-6 w-6 text-blue-500"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Approved</dt>
                <dd className="text-md text-gray-500">{'Query'}</dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 3) {
      return (
        <div className="flex justify-between px-2 py-2 border-red-600 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Job Assigned</dt>
                <dd className="text-md text-gray-500">Rejected</dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
  };

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
      // ? `${filterdata[0]?.name} - ${filterdata[0]?.location.name}`
      // : '-';
    }
  };

  const getDepartmentName = (deptsId) => {
    if (deptsSuccess) {
      const deptsName = depts?.data.filter((dept) => {
        return dept.id === deptsId;
      });
      return deptsName[0]?.name;
    }
  };
  const noList = () => (
    <div className="flex h-[calc(100vh-275px)]">
      <div className="text-center bg-white w-1/4 m-auto p-10">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Job Request{' '}
        </h3>

        <div className="mt-6">
          <div>
            <Link
              type="button"
              to={''}
              className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              // onClick={() => setOpenDrawer(!openDrawer)}
              onClick={() =>
                openModalDataWindow({
                  mode: 'Add',
                  title: 'Create Jobrequest',
                })
              }
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Create Jobs
            </Link>
            {/* <Link
              type="button"
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex w-full justify-center rounded-3xl mt-4 text-sm font-medium  shadow-smfocus:outline-none "
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Back
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {jobrequestLoading ? 'Loading...' : ''}
      {jobrequestSuccess ? (
        <>
          {jobrequest.data.length > 0 ? (
            <div className="flex h-full flex-col">
              <div className="lg:pb-4">
                <div className="flex justify-between ">
                  <div className="flex"></div>
                  <div className="flex">
                    <Link
                      type="button"
                      to={''}
                      className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      // onClick={() => setOpenDrawer(!openDrawer)}
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Add',
                          title: 'Create Jobrequest',
                        })
                      }
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Create Jobs
                    </Link>
                  </div>
                </div>
              </div>
              <div className="">
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4"
                // className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6"
                >
                  {filteredJobRequests.map((jr) => (
                    <li
                      key={jr.id}
                      className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg"
                    >
                      <div className="flex flex-2 flex-col p-0">
                        <div className=" flex justify-between">
                          <div className="py-3">
                            {jr.manpower_requirement_id ? (
                              <span className="bg-blue-500 px-2 py-2 text-white font-semibold">
                                MPR ID {jr.manpower_requirement_id}
                              </span>
                            ) : (
                              <span className="bg-red-500 px-2 py-2 text-white font-semibold">
                                JD ID {jr.id}
                              </span>
                            )}
                          </div>
                          <div className="py-3">
                            <span className="bg-blue-400 px-2 py-2 text-white font-semibold rounded-3xl">
                              {jr.no_of_position}
                            </span>
                          </div>
                          <div>
                            <div className="flex pr-2 pt-4 self-baseline">
                              {jr.approval_status !== 1 ? (
                                <PencilIcon
                                  className="h-5 w-5 mr-2 text-green-700 cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() =>
                                    openModalDataWindow({
                                      mode: 'Edit',
                                      title: 'Edit Jobrequest',
                                      jr,
                                    })
                                  }
                                />
                              ) : (
                                <EyeIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() =>
                                    openModalDataWindow({
                                      mode: 'View',
                                      title: 'View Jobrequest',
                                      jr,
                                    })
                                  }
                                />
                              )}
                              <TrashIcon
                                className="h-5 w-5 mr-2 text-red-600 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => openModalWindow(jr.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="py-3 bg-blue-600 text-white">
                          {jr.title}
                        </div>
                        <div>
                          <dl className="flex flex-col text-left px-4 py-2">
                            <dt className="sr-only">Senior Manager</dt>
                            <dd className="text-lg text-blue-500 font-semibold">
                              {getDepartmentName(jr.department_id)}
                            </dd>
                            {/* <dt className="sr-only">Sales & Marketing</dt>
                          <dd className="">
                            <span className="rounded-ful text-sm font-medium text-gray-500">
                              Sales & Marketing
                            </span>
                          </dd> */}
                          </dl>
                        </div>

                        <div className="flex justify-between px-4 py-2 text-left">
                          <div>
                            <dl className="flex flex-grow flex-col justify-between">
                              <dt className="sr-only">City</dt>
                              <dd className="text-lg text-blue-500 font-semibold">
                                {getLocationName(jr.branch_id)}
                              </dd>
                              <dt className="sr-only">Area</dt>
                              <dd className="">
                                <span className="rounded-ful text-sm font-medium text-gray-500">
                                  {getBranchName(jr.branch_id)}
                                </span>
                              </dd>
                            </dl>
                          </div>
                          <div className="flex pr-2 self-baseline"></div>
                        </div>

                        <div className="flex justify-between border-b-2 border-gray-200 px-2 py-2">
                          <div>
                            <dl className="flex flex-grow flex-col justify-between">
                              <dt className="sr-only ">Publish</dt>
                              <dd className="text-sm text-gray-500">
                                Published
                              </dd>
                              <dt className="sr-only text-sm">Publish Date</dt>
                              <dd className="">
                                <span className="rounded-ful px-2 text-xs font-medium text-gray-500">
                                  {DateToIndiaFormat(jr.createdAt)}
                                </span>
                              </dd>
                            </dl>
                          </div>
                          <div className="flex pr-2">
                            <div>
                              <dl className="flex flex-grow flex-col justify-between">
                                <dt className="sr-only">DeadLine</dt>
                                <dd className="text-sm text-gray-500">
                                  Deadline
                                </dd>
                                <dt className="sr-only">Deadline Date</dt>
                                <dd className="">
                                  <span className="rounded-ful px-2 text-xs font-medium text-gray-500">
                                    {DateToIndiaFormat(jr.closing_date)}
                                  </span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {approvalStatus(jr.approval_status)}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            noList()
          )}
          {/* <pre>
            {JSON.stringify(
              JSON.parse(JSON.stringify(jobrequest?.data)),
              null,
              2
            )}
          </pre> */}
        </>
      ) : (
        ''
      )}
      {/* <Notification
          isShow={showNotification}
          onClose={() => closeNotificationMsg()}
        /> */}
      <Drawer
        isOpen={openDrawer}
        title={'Add Department'}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <h1>Hello</h1>
      </Drawer>

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(jobRequestID)}
      ></Modal>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={() => closeModalDataWindow()}
      >
        <AddJobRequest
          onModalDataClose={closeModalDataWindow}
          // onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataJr}
        />
      </ModalData>
    </>
  );
};

export default MTJobRequest;

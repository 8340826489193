import React from 'react';
import { useCountryQuery } from '../../features/country/countryApiSlice';
import { toast } from 'sonner';

export const useGetCountryName = (countryid) => {
  console.log('countryid == ', countryid);
  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery();

  if (countrySuccess) {
    const countryName = countryList?.data.filter((country) => {
      return country.id == countryid;
    });
    return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
  }
};

export const getStatusToToast = (approvalId, title) => {
  if (approvalId === 1) {
    toast.success(`${title} Approved`);
  } else if (approvalId === 2) {
    toast.info(`${title} Queried`);
  } else if (approvalId === 3) {
    toast.error(`${title} Rejected`);
  }
};

export const cleanObjectData = (data) => {
  const cleanedObject = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== 0) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return cleanedObject;
};

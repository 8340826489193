import { Fragment, useState } from 'react';
import { useFormik } from 'formik';

import { Tab } from '@headlessui/react';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import CandDetails from './interviewprocess/CandDetails';
import InterviewScore from './interviewprocess/tabs/InterviewScore';
import ScheduleInterview from './interviewprocess/tabs/ScheduleInterview';
import CandResume from './interviewprocess/tabs/CandResume';
import ScreeningScore from './interviewprocess/tabs/ScreeningScore'; //added by lakshmi


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const InterviewProcess = ({ toggleInterview }) => {
  const [showNotification, setShowNotification] = useState(false);

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const formik = useFormik({});

  const gotoInterviewList = (value, candid) => {
    toggleInterview(value);
  };
 

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoInterviewList('interviewlist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails />

        <div className="basis-5/6">
          <div className="flex flex-row  ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
                <div className=" border border-gray-100 ">
                  {/* <h1 className=" flex font-semibold px-0 text-xl mt-3 text-gray-700 border-b border-gray-200 pb-4 mx-5">
                    Screening Score
                  </h1> */}

                  <Tab.Group as="div" className="mt-4">
                    <Tab.List className="flex bg-white px-1 py-0 mx-5 my-0 gap-4">
                    <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-3 py-2 font-bold text-md rounded-t-lg mr-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm mr-3'
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Screening Scores
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-3 py-2 font-bold text-md rounded-t-lg mr-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm mr-3'
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Interview Scores
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-3 py-2 font-bold text-md rounded-t-lg ml-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Schedule Interview
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-3 py-2 font-bold text-md rounded-t-lg ml-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Resume
                          </a>
                        </span>
                      </Tab>
                    </Tab.List>
                    <Tab.Panels as={Fragment}>

                    <Tab.Panel className="space-y-4 py-0 mx-6 mb-6 rounded-r-lg">
                        <ScreeningScore />
                      </Tab.Panel>

                      <Tab.Panel className="space-y-4 py-0 mx-6 mb-6 rounded-r-lg">
                        <InterviewScore interviewData={[]} />
                      </Tab.Panel>

                      <Tab.Panel className="space-y-4 py-0 mx-6 mb-6 rounded-r-lg">
                        <ScheduleInterview
                          toggleInterview={() =>
                            gotoInterviewList('interviewlist')
                          }
                        />
                      </Tab.Panel>

                      <Tab.Panel className="space-y-4 py-0 mx-6 mb-6 rounded-r-lg">
                        <CandResume />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InterviewProcess;

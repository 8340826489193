import { Outlet } from 'react-router-dom';

import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const AuthLayout = () => {
  return (
    <>
      {/* <div> */}
      <Sidebar />
      <div className="flex flex-1 flex-col md:pl-64">
        <Header />
        <main className="sm:p-6 md:p-6 bg-bodybg" style={{ minHeight: '90vh' }}>
          <Outlet />
        </main>
      </div>
      {/* </div> */}
    </>
  );
};

export default AuthLayout;

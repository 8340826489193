import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';

import { PencilSquareIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import Notification from '../../../../../components/common/Notification';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';
import Wysiwyg from '../../../../../components/common/Wysiwyg';
// import Designation from "./Designation";

import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';

import { useSkillcaptureApprovalQuery } from '../../../../../features/hiringstages/skillcaptureApiSlice';
import { useSkillsQuery } from '../../../../../features/skillnew/skillnewApiSlice';
import { useSkillQuery } from '../../../../../features/skill/skillApiSlice';

import { useBranchQuery } from '../../../../../features/branch/branchApiSlice';
import { useCountryQuery } from '../../../../../features/country/countryApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';
import SCApprove from './SCApprove';

// import { useGetCountryName } from '../../../../../features/utils/Utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SkillCapture = () => {
  const {
    data: scs,
    error: scsError,
    isLoading: scsLoading,
    isFetching: scsFetching,
    isSuccess: scsSuccess,
  } = useSkillcaptureApprovalQuery({ refetchOnMountOrArgChange: true });

  const {
    data: sklTypes,
    error: sklTypesError,
    isLoading: sklTypesLoading,
    isFetching: sklTypesFetching,
    isSuccess: sklTypesSuccess,
  } = useSkillQuery({ refetchOnMountOrArgChange: true });

  const {
    data: skls,
    error: sklsError,
    isLoading: sklsLoading,
    isFetching: sklsFetching,
    isSuccess: sklsSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();

  const people = [
    {
      city: 'Bangalore',
      area: 'Koramangala',
      publish: 'Published',
      p_date: '05/09/2020',
      deadline: '31/03/2022',
      d_date: '15/09/2020',
      jobassigned: 'Job Assigned',
      number: '20',
      id: 'MPR ID 02345',
      role: 'Senior Manager',
      department: 'Sales & Marketing',
      designation: 'Fullstack Developer',
      branch: 'Chennai, India',
      signoff_name: 'Robin Williams',
      timeline_status: 'On Track',
      status: 'Completed',

      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
  ];

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const getSkillTypeName = (skillTypeId) => {
    if (sklTypesSuccess) {
      const skillTypeName = sklTypes?.data.filter((sklType) => {
        return sklType.id == skillTypeId;
      });
      return skillTypeName[0]?.name;
    }
  };

  const getSkillName = (skillId) => {
    if (sklsSuccess) {
      const skillName = skls?.data.filter((skl) => {
        return skl.id == skillId;
      });
      return skillName[0]?.name;
    }
  };

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
      // ? `${filterdata[0]?.name} - ${filterdata[0]?.location.name}`
      // : '-';
    }
  };

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };

  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'New'}
          </div>
        </div>
      );
    }
  };

  const setPriority = (priorityId) => {
    if (priorityId == 1) {
      return 'Low';
    } else if (priorityId == 2) {
      return 'Medium';
    } else if (priorityId == 3) {
      return 'High';
    } else {
      return '-';
    }
  };

  return (
    <>
      {scsLoading ? 'Loading...' : ''}
      {scsSuccess ? (
        <div className="">
          <ul
            role="list"
            className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3"
          >
            {scs?.data
              .filter((scf) => scf.approval_status != null)
              .map((sc) => (
                <li
                  key={sc.id}
                  className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg"
                >
                  <div className="flex flex-2 flex-col p-0">
                    <div className=" flex justify-between pt-5 px-5">
                      <div className="py-0 px-0">
                        <h2 className="py-0 text-gray-600 font-semibold text-lg">
                          {sc.job_request_title}
                        </h2>
                        <p className=" text-gray-600 font-light text-left">
                          {getLocationName(sc.branch_id)}
                          {', '}
                          {getCountryName(sc.country_id)}
                        </p>
                      </div>
                      <div className="py-0">
                        <p className=" px-0 pt-0 text-black font-semibold text-4xl">
                          {sc.no_of_position}
                        </p>
                      </div>
                      <div>
                        <span className="bg-sidebar rounded-3xl ">
                          <PencilSquareIcon
                            className="h-12 w-12 text-white bg-blue-600 rounded-full p-3"
                            aria-hidden="true"
                            // onClick={() => openModalWindow()}
                            onClick={() =>
                              openDrawerWindow('edit', {
                                id: sc.id,
                                title: sc.job_request_title,
                                jobrequestid: sc.job_request_id,
                                position: sc.no_of_position,
                                branch: getBranchName(sc.branch_id),
                                location: getLocationName(sc.branch_id),
                                requestedBy: getUsername(sc.createdBy),
                              })
                            }
                          />
                        </span>
                      </div>
                    </div>

                    {sklTypesLoading ? 'Loading Tabs...' : ''}
                    {sklTypesSuccess ? (
                      <Tab.Group as="div" className="mt-4 h-40">
                        {sc?.skill_list[0]?.skill_type_id ? (
                          <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 mx-5 my-3 rounded-md">
                            {sc.skill_list.map((sklList) => (
                              <Tab
                                // key={sklType.id}
                                className={({ selected }) =>
                                  classNames(
                                    selected
                                      ? 'bg-white text-black px-2 py-1 rounded-md font-semibold text-sm'
                                      : 'border-transparent text-gray-500 px-2 hover:border-gray-300 hover:text-gray-700'
                                  )
                                }
                              >
                                {getSkillTypeName(sklList.skill_type_id)}
                              </Tab>
                            ))}
                          </Tab.List>
                        ) : (
                          <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 mx-5 my-3 rounded-md">
                            No Skill Types Found
                          </Tab.List>
                        )}

                        <Tab.Panels as={Fragment}>
                          {sc.skill_list.map((sklList) => (
                            <Tab.Panel className="space-y-16 py-3">
                              <div className=" border-t py-5">
                                {sklList.skills?.map((skill) => (
                                  <span className="inline-flex mr-2 items-left rounded-full bg-blue-100 px-3 py-1.5 text-sm font-medium text-black mb-3">
                                    {getSkillName(skill.skill_id)}{' '}
                                    {skill.min ? `${skill.min} Years - ` : ' '}{' '}
                                    {skill.max ? `${skill.max} Years` : ' '}
                                  </span>
                                ))}
                              </div>
                            </Tab.Panel>
                          ))}
                        </Tab.Panels>
                      </Tab.Group>
                    ) : (
                      ''
                    )}
                    <div className="flex h-28 justify-between border-b border-t border-gray-200 px-5 py-5 mt-8">
                      <div>
                        <div className="text-md text-blue-500 font-semibold align-top">
                          Prepared By
                        </div>
                        <div className="text-md text-black">
                          {getUsername(sc.createdBy)}
                        </div>
                      </div>

                      <div>
                        <div className="text-md text-blue-500 font-semibold align-top">
                          Requested Date
                        </div>
                        <div className="text-md text-black">
                          {DateToIndiaFormat(sc.createdAt)}
                        </div>
                      </div>
                    </div>

                    <div className="my-5 sm:mt-6 flex flex-row justify-between items-center mx-5 py-2.5 rounded-lg">
                      <div className="">
                        <label htmlFor="">Priority: </label>
                        <span>{setPriority(sc.priority)}</span>
                      </div>
                      {/* <div className="">
                        <button
                          type="button"
                          className=" rounded-xl border bg-green-500 w-full py-2.5 px-3 text-sm font-medium text-white shadow-sm focus:outline-none "
                        >
                          Submit for Approval
                        </button>
                      </div> */}
                      <div>{displayStatus(sc.approval_status)}</div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ) : (
        ''
      )}

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />

      <Drawer
        isOpen={openDrawer}
        // title={'Approve Skill Capture'}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <SCApprove
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
          showNotification={() => showNotificationMsg()}
        />
      </Drawer>

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        <form className="flex flex-col justify-between">
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <div className="">
              <label
                htmlFor="job_title"
                className="block text-sm font-medium text-gray-700"
              >
                Job Tilte
              </label>
              <input
                type="text"
                name="job_title"
                id="job_title"
                autoComplete="cc-job_title"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.manpower_rqmt}
              />
              {/* {formik.touched.company_name && formik.errors.manpower_rqmt ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.manpower_rqmt}
                  </div>
                ) : null} */}
            </div>
            <div className="">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Department
              </label>
              <input
                type="text"
                name="department"
                id="department"
                autoComplete="cc-department"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.manpower_rqmt}
              />
              {/* {formik.touched.company_name && formik.errors.manpower_rqmt ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.manpower_rqmt}
                  </div>
                ) : null} */}
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <div className="">
              <label
                htmlFor="experience"
                className="block text-sm font-medium text-gray-700"
              >
                Experience
              </label>
              <input
                type="text"
                name="experience"
                id="experience"
                autoComplete="cc-experience"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.manpower_rqmt}
              />
              {/* {formik.touched.company_name && formik.errors.manpower_rqmt ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.manpower_rqmt}
                  </div>
                ) : null} */}
            </div>

            <div className="">
              <label
                htmlFor="position"
                className="block text-sm font-medium text-gray-700"
              >
                Position
              </label>
              <input
                type="text"
                name="position"
                id="position"
                autoComplete="cc-position"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.manpower_rqmt}
              />
              {/* {formik.touched.company_name && formik.errors.manpower_rqmt ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.manpower_rqmt}
                  </div>
                ) : null} */}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="bg-white  rounded-md shadow-3xl">
              <div className="flex justify-between items-center">
                <h2
                  id="account-details-heading"
                  className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
                >
                  Job Requirement
                </h2>
              </div>
              <Wysiwyg />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="bg-white  rounded-md shadow-3xl">
              <div className="flex justify-between items-center">
                <h2
                  id="account-details-heading"
                  className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
                >
                  Job Description
                </h2>
              </div>
              <Wysiwyg />
            </div>
          </div>

          <Tab.Group as="div" className="mt-4">
            <Tab.List className="flex justify-between bg-skillcaps px-2 py-3 mx-0 my-3 rounded-md">
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                      : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                        'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    // 'whitespace-nowrap   py-0  px-0'
                  )
                }
              >
                <span>
                  <a
                    href="#"
                    className="text-skillcapstext px-3 py-2 rounded-md text-md"
                    // className="bg-white text-black p-2 rounded-md font-bold text-md"
                  >
                    Basic Skill
                  </a>
                </span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    // 'whitespace-nowrap   py-0  px-0'
                  )
                }
              >
                <span>
                  <a
                    href="#"
                    className="text-skillcapstext px-3 py-2 rounded-md text-md"
                  >
                    Mandatory Skill
                  </a>
                </span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    // 'whitespace-nowrap   py-0  px-0'
                  )
                }
              >
                <span>
                  <a
                    href="#"
                    className="text-skillcapstext px-3 py-2 rounded-md text-md"
                  >
                    Preferred Skill
                  </a>
                </span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black p-2 rounded-md font-bold text-sm'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    // 'whitespace-nowrap   py-0  px-0'
                  )
                }
              >
                <span>
                  <a
                    href="#"
                    className="text-skillcapstext p-2 rounded-md text-md"
                  >
                    Soft Skill
                  </a>
                </span>
              </Tab>
            </Tab.List>
            <Tab.Panels as={Fragment}>
              <Tab.Panel className="space-y-16 py-3">
                <div className="flex flex-row">
                  <div className="basis-5/12 bg-gray-100 px-5 mr-10 border border-gray-300 py-3 rounded-lg overflow-auto h-48">
                    <div>
                      <p className="inline-block  mr-2  mt-2 rounded-full bg-white px-3 py-1.5  text-md font-medium text-black">
                        UI / UX
                      </p>
                    </div>
                    <div>
                      <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                        PHP
                      </p>
                    </div>
                    <div>
                      <p className="inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                        Java Script
                      </p>
                    </div>
                    <div>
                      <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                        JQuery
                      </p>
                    </div>
                    <div>
                      <p className="inline-block  mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                        JQuery
                      </p>
                    </div>
                    <div>
                      <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                        JQuery
                      </p>
                    </div>
                  </div>
                  <div className="basis-7/12  bg-white px-0 border border-gray-300 ">
                    <div className=" flex border-b border-gray-150 pb-2">
                      <div className="basis-8/12 pt-5 px-5">
                        <p className="text-md font-medium text-black">UI/UX</p>
                      </div>
                      <div className="basis-2/12">
                        <div className="flex">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <p className=" text-center text-sm">Min</p>
                              <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                20
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        1
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        2
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        3
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>

                      <div className="basis-2/12">
                        <div className="flex">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <p className=" text-center text-sm">Max</p>
                              <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                20
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        1
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        2
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        3
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>

                    <div className=" flex border-b border-gray-150 pb-2">
                      <div className="basis-8/12 pt-3 px-5">
                        <p className="text-md font-medium text-black">
                          Javascript
                        </p>
                      </div>
                      <div className="basis-2/12">
                        <div className="flex">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 mt-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                20
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        1
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        2
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        3
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>

                      <div className="basis-2/12">
                        <div className="flex">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 mt-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                20
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        1
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        2
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        3
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className="space-y-16 py-3">Content skill</Tab.Panel>
              <Tab.Panel className="space-y-16 py-3">Content Pref</Tab.Panel>
              <Tab.Panel className="space-y-16 py-3">Content Soft</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>

          {/* <div className="flex justify-between bg-skillcaps px-2 py-3 mx-0 my-3 rounded-md">
              <span>
                <a
                  href="#"
                  className="text-skillcapstext p-2 rounded-md text-md"
                >
                  Basic Skill
                </a>
              </span>
              <span>
                <a
                  href="#"
                  className=" text-skillcapstext p-2 rounded-md text-md"
                >
                  Mandatory Skill
                </a>
              </span>
              <span>
                <a
                  href="#"
                  className=" text-skillcapstext p-2 rounded-md text-md"
                >
                  Preferred Skill
                </a>
              </span>
              <span>
                <a
                  href="#"
                  className=" text-skillcapstext p-2 rounded-md text-md"
                >
                  Soft Skill
                </a>
              </span>
            </div> */}

          {/* <div className="flex flex-row">
              <div className="basis-5/12 bg-gray-100 px-5 mr-10 border border-gray-300 py-3 rounded-lg overflow-auto h-48">
                <div>
                  <p className="inline-block  mr-2  mt-2 rounded-full bg-white px-3 py-1.5  text-md font-medium text-black">
                    UI / UX
                  </p>
                </div>
                <div>
                  <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                    PHP
                  </p>
                </div>
                <div>
                  <p className="inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                    Java Script
                  </p>
                </div>
                <div>
                  <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                    JQuery
                  </p>
                </div>
                <div>
                  <p className="inline-block  mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                    JQuery
                  </p>
                </div>
                <div>
                  <p className=" inline-block mr-2 mt-2 rounded-full bg-white px-3 py-1.5 text-md font-medium text-black">
                    JQuery
                  </p>
                </div>
              </div>
              <div className="basis-7/12  bg-white px-0 border border-gray-300 ">
                <div className=" flex border-b border-gray-150 pb-2">
                  <div className="basis-8/12 pt-5 px-5">
                    <p className="text-md font-medium text-black">UI/UX</p>
                  </div>
                  <div className="basis-2/12">
                    <div className="flex">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <p className=" text-center text-sm">Min</p>
                          <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            20
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    1
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    2
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    3
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <div className="basis-2/12">
                    <div className="flex">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <p className=" text-center text-sm">Max</p>
                          <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            20
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    1
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    2
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    3
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <div className=" flex border-b border-gray-150 pb-2">
                  <div className="basis-8/12 pt-3 px-5">
                    <p className="text-md font-medium text-black">Javascript</p>
                  </div>
                  <div className="basis-2/12">
                    <div className="flex">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 mt-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            20
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    1
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    2
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    3
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <div className="basis-2/12">
                    <div className="flex">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-50 justify-center rounded-md border border-gray-300 bg-white px-2 py-0 mt-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            20
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    1
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    2
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    3
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          <div className="flex flex-row mt-5">
            <div className="basis-10/12 bg-gray-100  border py-0 mr-3 flex rounded-lg">
              <div className="basis-1/2 p-3 mr-3">
                <select
                  id="Priority"
                  name="Priority"
                  autoComplete="Priority"
                  className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                >
                  <option>Priority</option>
                  <option>Secondary</option>
                  <option>Prior</option>
                </select>
              </div>
              <div className="basis-1/2 py-3 mr-3">
                <button
                  type="button"
                  className=" rounded-xl border bg-green-500 w-full py-2.5 px-0 text-sm font-medium text-white shadow-sm focus:outline-none "
                >
                  Submit for Approval
                </button>
              </div>
            </div>

            <div className="basis-2/12  self-center ">
              <button
                type="button"
                className=" rounded-md bg-indigo-600 py-3 px-16  text-sm font-medium text-white shadow-sm focus:outline-none "
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SkillCapture;

import React, { Fragment, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  MagnifyingGlassIcon,
  PlusIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
} from '@heroicons/react/20/solid';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import { PencilSquareIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import AddSkillnew from '../../../../../features/skillnew/AddSkillnew';
import { toast } from 'sonner';
import {
  useAddSkillcaptureMutation,
  useUpdateSkillcaptureMutation,
} from '../../../../../features/hiringstages/skillcaptureApiSlice';
import { useSkillsQuery } from '../../../../../features/skillnew/skillnewApiSlice';
// import { useSkillQuery } from '../../../../../features/skill/skillApiSlice';
import { useSkillQuery } from '../../../../../features/skill/skillApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AddSkillCapture({ data, showNotification, onModalDataClose }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSkillBlock, setShowSkillBlock] = useState(false);
  const [addSkillcapture] = useAddSkillcaptureMutation();
  const [updateSkillcapture, { isLoading }] = useUpdateSkillcaptureMutation();
  const {
    data: sklTypes,
    error,
    isLoading: sklTLoading,
    isFetching,
    isSuccess,
  } = useSkillQuery({ refetchOnMountOrArgChange: true });

  const {
    data: skls,
    error: sklsError,
    isLoading: sklsLoading,
    isFetching: sklsFetching,
    isSuccess: sklsSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  const initialValues = {
    job_request_id: data.job_request_id,
    priority: data.priority || 0,
    due_date: data.duedate || '02/07/2023',
    timeline_status: 0,
  };
  const validationSchema = yup.object({
    // name: yup.string().required('Please enter  name'),
    // description: yup.string().required('Please enter description'),
  });
  const onSubmit = async (validationSchema) => {
    const newArray = {
      ...validationSchema,
      skill_list: selectedValues,
    };

    try {
      if (data.skilllist[0].skill_type_id != null) {
        const accountData = await updateSkillcapture(newArray).unwrap();
        toast.success('Skill capture saved');
      } else {
        const accountData = await addSkillcapture(newArray).unwrap();
        toast.success('Skill capture created');
      }
      onModalDataClose();
      showNotification();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // enableReinitialize: true,
  });

  const [selectData, setSelectData] = useState([]);

  const [selectedValues, setSelectedValues] = useState(
    data.skilllist[0].skill_type_id != null ? data.skilllist : []
  );

  const selectCheckboxChange = (event, tabID) => {
    const { id, checked } = event.target;

    setSelectData((prevValues) => {
      // Find existing object in selectedValues array with matching tabId
      const existingObjIndex = prevValues.findIndex(
        (obj) => obj.skill_type_id === tabID
      );

      if (existingObjIndex !== -1) {
        // Update existing object's array with selected values
        const updatedObj = { ...prevValues[existingObjIndex] };
        updatedObj.skills = checked
          ? [...updatedObj.skills, { skill_id: id, weightage: 'general' }]
          : updatedObj.skills.filter((value) => value.skill_id !== id);

        if (updatedObj.skills.length > 0) {
          return [
            ...prevValues.slice(0, existingObjIndex),
            updatedObj,
            ...prevValues.slice(existingObjIndex + 1),
          ];
        } else {
          return [...prevValues.slice(0, existingObjIndex)];
        }
      } else {
        // Add a new object to the selectedValues array
        return [
          ...prevValues,
          {
            skill_type_id: tabID,
            skills: checked ? [{ skill_id: id, weightage: 'general' }] : [],
          },
        ];
      }
    });

    handleCheckboxChange(event, tabID);
  };

  const isselectChecked = (sklId, sklTypeId) => {
    const existingObjIndex = selectData?.findIndex(
      (obj) => obj.skill_type_id === sklTypeId
    );

    const skillObj = selectData[existingObjIndex];
    const rowIndex = skillObj?.skills?.findIndex((row) => {
      return row.skill_id == sklId;
    });

    if (rowIndex != -1 && rowIndex != undefined) {
      return true;
    } else {
      return false;
    }
  };

  // const savetoArray = (e, rowId, tabID) => {};

  const savetoArray = (e, rowId, tabID) => {
    const { name, value } = e.target;

    setSelectedValues((prevValues) => {
      // Find existing object in selectedValues array with matching tabId
      const existingObjIndex = prevValues.findIndex(
        (obj) => obj.skill_type_id === tabID
      );

      if (existingObjIndex !== -1) {
        const updatedObj = { ...prevValues[existingObjIndex] };

        const rowIndex = updatedObj.skills.findIndex((row) => {
          return row.skill_id == rowId;
        });
        if (rowIndex !== -1) {
          updatedObj.skills[rowIndex] = {
            ...updatedObj.skills[rowIndex],
            [name]: value,
          };
        } else {
          updatedObj.skills = [
            ...updatedObj.skills,
            { skill_id: rowId, [name]: value },
          ];
        }
        return [
          ...prevValues.slice(0, existingObjIndex),
          updatedObj,
          ...prevValues.slice(existingObjIndex + 1),
        ];
      } else {
        // Add a new object to the selectedValues array
        return [
          ...prevValues,
          {
            skill_type_id: tabID,
            skills: [{ skill_id: rowId, [name]: value }],
          },
        ];
      }
    });
  };

  const handleCheckboxChange = (event, tabID, level) => {
    const { id, checked } = event.target;

    setSelectedValues((prevValues) => {
      // Find existing object in selectedValues array with matching tabId
      const existingObjIndex = prevValues.findIndex(
        (obj) => obj.skill_type_id === tabID
      );

      if (existingObjIndex !== -1) {
        // Update existing object's array with selected values
        const updatedObj = { ...prevValues[existingObjIndex] };

        if (level) {
          updatedObj.skills = checked
            ? [
                ...updatedObj.skills,
                { skill_id: id, weightage: 'general', min: 0, max: 0 },
              ]
            : updatedObj.skills.filter((value) => value.skill_id !== id);
        } else {
          updatedObj.skills = checked
            ? [...updatedObj.skills, { skill_id: id, weightage: 'general' }]
            : updatedObj.skills.filter((value) => value.skill_id !== id);
        }

        // Update the selectedValues array with the updated object
        if (updatedObj.skills.length > 0) {
          return [
            ...prevValues.slice(0, existingObjIndex),
            updatedObj,
            ...prevValues.slice(existingObjIndex + 1),
          ];
        } else {
          return [...prevValues.slice(0, existingObjIndex)];
        }
      } else {
        // Add a new object to the selectedValues array
        if (level) {
          return [
            ...prevValues,
            {
              skill_type_id: tabID,
              skills: checked
                ? [{ skill_id: id, weightage: 'general', min: 0, max: 0 }]
                : [],
            },
          ];
        } else {
          return [
            ...prevValues,
            {
              skill_type_id: tabID,
              skills: checked ? [{ skill_id: id, weightage: 'general' }] : [],
            },
          ];
        }
      }
    });
  };

  const isCurrentChecked = (sklId, sklTypeId) => {
    const existingObjIndex = selectedValues.findIndex(
      (obj) => obj.skill_type_id === sklTypeId
    );

    const skillObj = selectedValues[existingObjIndex];
    const rowIndex = skillObj?.skills?.findIndex((row) => {
      return row.skill_id == sklId;
    });

    if (rowIndex != -1 && rowIndex != undefined) {
      return true;
    } else {
      return false;
    }
  };

  const getCurrentOptionValue = (sklId, sklTypeId, optionName) => {
    const existingObjIndex = selectedValues.findIndex(
      (obj) => obj.skill_type_id === sklTypeId
    );

    const skillObj = selectedValues[existingObjIndex];
    const rowIndex = skillObj?.skills?.findIndex((row) => {
      return row.skill_id == sklId;
    });

    if (existingObjIndex != -1 && rowIndex != -1) {
      return skillObj.skills[rowIndex][optionName];
    }
  };

  const changeMinMax = (e, sklTypeId, sklId) => {
    const { name, value } = e.target;

    const newSkillType = sklTypeId; // The skill_type_id you want to update
    const newSkillId = sklId; // The skill_id you want to update
    // const newWeightage = value; // The new value for weightage

    const updatedSelectedValues = selectedValues.map((skillType) => {
      if (skillType.skill_type_id === newSkillType) {
        // If the skill_type_id matches the one you want to update
        const updatedSkills = skillType.skills.map((skill) => {
          if (skill.skill_id === newSkillId) {
            // If the skill_id matches the one you want to update
            return { ...skill, [name]: value };
          }
          return skill;
        });
        return { ...skillType, skills: updatedSkills };
      }
      return skillType;
    });

    setSelectedValues(updatedSelectedValues);
  };

  const changeWeightage = (e, sklTypeId, sklId) => {
    const { name, value } = e.target;

    const newSkillType = sklTypeId; // The skill_type_id you want to update
    const newSkillId = sklId; // The skill_id you want to update
    const newWeightage = value; // The new value for weightage

    const updatedSelectedValues = selectedValues.map((skillType) => {
      if (skillType.skill_type_id === newSkillType) {
        // If the skill_type_id matches the one you want to update
        const updatedSkills = skillType.skills.map((skill) => {
          if (skill.skill_id === newSkillId) {
            // If the skill_id matches the one you want to update
            return { ...skill, weightage: newWeightage };
          }
          return skill;
        });
        return { ...skillType, skills: updatedSkills };
      }
      return skillType;
    });

    setSelectedValues(updatedSelectedValues);
  };

  const getSkillName = (skillId) => {
    if (sklsSuccess) {
      const skillName = skls?.data.filter((skl) => {
        return skl.id == skillId;
      });
      return skillName[0]?.name;
    }
  };

  return (
    <>
      <form
        className="flex flex-col justify-between"
        onSubmit={formik.handleSubmit}
      >
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Job Tilte
            </label>
            <h2 className="mt-2 font-bold">{data.title}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Branch
            </label>
            <h2 className="mt-2 font-bold">{data.branch}</h2>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Location
            </label>
            <h2 className="mt-2 font-bold">{data.location}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Positions
            </label>
            <h2 className="mt-2 font-bold">{data.positions}</h2>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <Tab.Group as="div" className="mt-4 overflow-auto">
            <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 my-3 rounded-md">
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-5 py-1 rounded-md font-semibold text-sm'
                      : 'border-transparent text-gray-500 px-5 hover:border-gray-300 hover:text-gray-700  text-sm'
                  )
                }
              >
                Job Requirement
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-5 py-1 rounded-md font-semibold text-sm'
                      : 'border-transparent text-gray-500 px-2 hover:border-gray-300 hover:text-gray-700  text-sm'
                  )
                }
              >
                Job Description
              </Tab>
            </Tab.List>

            <Tab.Panels as={Fragment}>
              <Tab.Panel className="space-y-16 overflow-auto">
                <ReactQuill
                  theme="snow"
                  readOnly
                  value={data.job_requirement}
                />
              </Tab.Panel>
              <Tab.Panel className="space-y-16 overflow-auto">
                <ReactQuill
                  theme="snow"
                  readOnly
                  value={data.job_description}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        {/* <h1>selectData</h1>
        <h1 className="font-bold text-lg">{JSON.stringify(selectData)}</h1>
        <h1>selectedValues</h1>
        <h1 className="font-bold text-lg">{JSON.stringify(selectedValues)}</h1> */}
        {/* {sklTypesLoading ? 'Loading Tabs...' : ''}
        {sklTypesSuccess ? ( */}
        <div className="flex mt-4">
          <Link
            type="button"
            // to={'/app/skill'}
            className="flex   justify-center rounded-3xl bord1er border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
            onClick={() => setShowSkillBlock(!showSkillBlock)}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
            Add Skill
          </Link>
        </div>

        {showSkillBlock ? (
          <div>
            <AddSkillnew data={null} />
          </div>
        ) : (
          ' '
        )}
        <Tab.Group as="div" className="" onChange={() => setSearchTerm('')}>
          <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 my-3 rounded-md">
            {sklTypes?.data.map((sklType) => (
              <Tab
                key={sklType.id}
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-white text-black px-5 py-1 rounded-md font-semibold text-sm'
                      : 'border-transparent text-gray-500 px-5 hover:border-gray-300 hover:text-gray-700 text-sm'
                  )
                }
              >
                {sklType.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels as={Fragment}>
            {sklTypes?.data.map((sklType) => (
              <Tab.Panel key={sklType.id} className="space-y-16 ">
                <div className="flex flex-row">
                  <div className=" basis-2/5 mr-5">
                    {/* <h3 className="mb-5 text-lg font-medium text-gray-900">
                      Choose technology:
                    </h3> */}
                    <div className="min-w-0 flex-1 mr-5 mb-4">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="search"
                          name="search"
                          id={`search-${sklType.id}`}
                          className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Search by Skill"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                    <ul className="w-full h-80  overflow-auto">
                      {skls?.data
                        .filter((sklf) => sklf.skill_type_id == sklType.id)
                        .filter((sklfr) =>
                          searchTerm.toLowerCase() === ''
                            ? sklfr
                            : sklfr.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                        )
                        .map((skl) => (
                          <li className="mb-2 mr-2" key={`left${skl.id}`}>
                            <div className="flex mr-4 justify-end">
                              {/* <span>Level - {skl.id}</span> */}
                              <input
                                checked={isCurrentChecked(skl.id, sklType.id)}
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxChange(e, sklType.id, skl.level)
                                }
                                id={skl.id}
                                value=""
                                className="hidden peer"
                                required=""
                              />
                              <label
                                for={skl.id}
                                className="inline-flex items-center justify-between w-full py-2 px-5 text-gray-600 bg-white border-2  rounded-full cursor-pointer  peer-checked:bg-blue-600  peer-checked:text-white peer-checked:border-blue-600"
                              >
                                <div className="block">
                                  <div className="w-full text-md font-semibold">
                                    {skl.name}
                                  </div>
                                </div>
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className=" border-t py-2 basis-3/5 h-96 overflow-auto">
                    {selectedValues
                      .filter((sklf) => sklf.skill_type_id == sklType.id)
                      .map((sklf) =>
                        sklf.skills.map((skl) => (
                          <div
                            key={skl.skill_id}
                            className="flex border  py-2 justify-between flex-row items-center"
                          >
                            <span className="w-1/2 inline-flex mr-2 items-left rounded-full px-3 py-1.5 text-md font-medium text-black">
                              {getSkillName(skl.skill_id)}
                            </span>
                            <select
                              name=""
                              id=""
                              value={skl.weightage}
                              onChange={(e) =>
                                changeWeightage(e, sklType.id, skl.skill_id)
                              }
                              className="bg-inputbg border border-inputBorder mt-1 block w-52 mr-3 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                            >
                              <option value={'general'}>general</option>
                              <option value={'prefered'}>prefered</option>
                              <option value={'mandatory'}>mandatory</option>
                            </select>

                            {'min' in skl && 'max' in skl ? (
                              <>
                                <div className=" w-1/2 flex mr-4">
                                  <div className="flex justify-start items-center">
                                    <label
                                      htmlFor="min"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Min
                                    </label>
                                    <select
                                      id="min"
                                      name="min"
                                      autoComplete="min"
                                      // value={getCurrentOptionValue(
                                      //   skl.id,
                                      //   sklType.id,
                                      //   'min'
                                      // )}
                                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                      // onChange={(e) =>
                                      //   savetoArray(e, skl.id, sklType.id)
                                      // }
                                      // onBlur={}
                                      // value={}
                                      value={skl.min}
                                      onChange={(e) =>
                                        changeMinMax(
                                          e,
                                          sklType.id,
                                          skl.skill_id
                                        )
                                      }
                                    >
                                      <option value="-1">
                                        -- Select Min --
                                      </option>
                                      {Array.from({ length: 21 }).map(
                                        (_, index) => (
                                          <option key={index} value={index}>
                                            {index}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="flex justify-start items-center ml-2">
                                    <label
                                      htmlFor="max"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Max
                                    </label>
                                    <select
                                      id="max"
                                      name="max"
                                      autoComplete="max"
                                      // value={getCurrentOptionValue(
                                      //   skl.id,
                                      //   sklType.id,
                                      //   'max'
                                      // )}
                                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                      // onChange={(e) =>
                                      //   savetoArray(e, skl.id, sklType.id)
                                      // }
                                      // onBlur={formik.handleBlur}
                                      // value={formik.values.max}
                                      value={skl.max}
                                      onChange={(e) =>
                                        changeMinMax(
                                          e,
                                          sklType.id,
                                          skl.skill_id
                                        )
                                      }
                                    >
                                      <option value="-1">
                                        -- Select Max --
                                      </option>
                                      {Array.from({ length: 21 }).map(
                                        (_, index) => (
                                          <option key={index} value={index}>
                                            {index}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        ))
                      )}
                    {/* )} */}
                  </div>
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
        {/* ) : (
          ''
        )} */}

        <div className="flex flex-row mt-5">
          <div className="basis-10/12 bg-gray-100  border py-0 mr-3 flex rounded-lg">
            <div className="basis-1/2 p-3 mr-3">
              <select
                id="priority"
                name="priority"
                autoComplete="priority"
                className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
              >
                <option>-- Select Priority --</option>
                <option value={'3'}>High</option>
                <option value={'2'}>Medium</option>
                <option value={'1'}>Low</option>
              </select>
              {formik.touched.priority && formik.errors.priority ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.priority}
                </div>
              ) : null}
            </div>
            <div className="basis-1/2 py-3 mr-3">
              <button
                type="submit"
                className=" rounded-xl border bg-green-500 w-full py-2.5 px-0 text-sm font-medium text-white shadow-sm focus:outline-none "
              >
                Submit for Approval
              </button>
            </div>
          </div>

          {/* <div className="basis-2/12  self-center ">
            <button
              type="button"
              className=" rounded-md bg-indigo-600 py-3 px-16  text-sm font-medium text-white shadow-sm focus:outline-none "
            >
              Save
            </button>
          </div> */}
        </div>
      </form>
    </>
  );
}

export default AddSkillCapture;

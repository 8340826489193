import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useGetSkills from '../../../../../../../hooks/useGetSkills';
// import useCandidate from '../../../../../../../hooks/useCandidate';

import { useSelector } from 'react-redux';
import { useGetInterviewCandDetailsQuery } from '../../../../../../../features/hiringstages/interviewApiSlice';

export const AddKeySkill = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
  updateSkillsArray,
}) => {
  const { skills, loadingSkills, successSkills } = useGetSkills();
  const [skillsList, setSkillsList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const candiid = useSelector((state) => state.candidateinterview) || [];
  const {
    data: canddetails,
    isLoading: canddetailsLoading,
    isSuccess: canddetailsSuccess,
  } = useGetInterviewCandDetailsQuery(candiid, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const existingSkills = data.data !== null ? data.data : [];

    setSelectedIds((prevSelectedIds) => [
      ...prevSelectedIds,
      ...existingSkills,
    ]);

    const skillsArray = skills?.data.map((skill) => {
      return {
        id: skill.id,
        name: skill.name,
        isChecked: existingSkills.includes(skill.id) ? true : false,
      };
    });

    setSkillsList(skillsArray);
  }, [skills, canddetails]);

  const handleCheckboxChange = (id) => {
    // const isSelected = selectedIds.includes(id);
    // if (isSelected) {
    //   setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    // } else {
    //   setSelectedIds([...selectedIds, id]);
    // }
    updateSkillsArray(id);
    const updatedSkills = skillsList.map((skill) => {
      if (skill.id == id) {
        return { ...skill, isChecked: !skill.isChecked };
      }
      return skill;
    });
    setSkillsList(updatedSkills);
  };

  const submitform = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* onSubmit={(e) => submitform(e)} */}
      <form className="flex flex-col" onSubmit={submitform}>
        <div className="pb-3">
          <label
            // htmlFor={skill.name}
            className=" text-md font-semibold text-gray-700 "
          >
            Key Skill
          </label>
        </div>
        <div className="grid grid-cols-5 gap-4">
          {skillsList?.map((skill) => (
            <div className="flex" key={skill.id}>
              <div className="flex h-6 items-center ">
                <input
                  id={skill.name}
                  name={skill.name}
                  type="checkbox"
                  className="h-4 w-4 rounded-md  border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value={skill.id}
                  // checked={existingSkills.includes(skill.id)}
                  checked={skill.isChecked}
                  onChange={() => handleCheckboxChange(skill.id)}
                />
              </div>
              <div className="ml-3 text-sm leading-6 truncate">
                <label
                  // htmlFor={skill.name}
                  className="font-medium text-gray-900"
                >
                  {skill.name}
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <div className="mr-3">
            <button
              type="button"
              onClick={() => onModalDataClose()}
              className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4  ml-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
            >
              Cancel
            </button>
          </div>
          {/* <div>
            <button
              type="button"
              onClick={sendToParent}
              className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 mt-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Save
            </button>
          </div> */}
        </div>
      </form>
    </>
  );
};

import { apiSlice } from '../../app/api/apiSlice';

export const forgotPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    forgotPasswordApi: builder.mutation({
      query: (credentials) => ({
        url: '/users/forgotpassword',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useForgotPasswordApiMutation } = forgotPasswordApiSlice;

import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import {
  useAcceptInterviewMutation,
  useInterviewerCandListQuery,
  useRejectInterviewMutation,
} from '../../features/hiringstages/interviewApiSlice';
import { format } from 'date-fns';

import InterviewerCandList from './InterviewerCandList';

export default function InterviewRequest({ }) {
  const {
    data: interviewerCandList,
    error: interviewerCandListError,
    isLoading: interviewerCandListLoading,
  } = useInterviewerCandListQuery({ refetchOnMountOrArgChange: true });

  const interviewerCandListData = interviewerCandList?.data;
  console.log(interviewerCandListData)
  return (
    <div className="">
      <ul
        role="list"
        className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3"
      >
        <InterviewerCandList data={interviewerCandListData} />
      </ul>
    </div>
  );
}

// export default InterviewRequest;

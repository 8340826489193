import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import {
  ArchiveBoxIcon,
  PencilSquareIcon,
  CheckCircleIcon,
  HandThumbUpIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';

import Notification from '../../../../../components/common/Notification';
import { useHandoverQuery } from '../../../../../features/hiringstages/handoverApiSlice';
import GetLocationName from '../../../../../components/common/GetLocationName';
import GetCountry from '../../../../../components/common/GetCountry';
import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';
import HandoverCandidateList from './HandoverCandidateList';
import { getStatusCode } from '../../../UserWSUtils';

export default function Handover({ toggleHandover }) {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(false);

  const {
    data: handoverList,
    isLoading: handoverListLoading,
    isFetching: handoverListFetching,
    isSuccess: handoverListSuccess,
  } = useHandoverQuery({ refetchOnMountOrArgChange: true });

  const handoverListObj = handoverList?.data;

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const gotoHandoverDetails = (value, obj) => {
    toggleHandover(value, obj);
  };

  if (handoverListLoading || handoverListFetching) {
    return 'Loading ...';
  }

  if (handoverListSuccess) {
    return (
      <>
        {handoverListObj?.length > 0 ? (
          <>
            {handoverListObj?.map((list) => (
              <>
                {/* <pre>{JSON.stringify(list, undefined, 2)}</pre> */}
                <div className=" pt-0 sm:pt-0" key={list.job_request_id}>
                  <Disclosure as="div" className="pt-3">
                    {({ open }) => (
                      <>
                        {}
                        <dt>
                          <Disclosure.Button
                            className="flex w-full text-left text-gray-900 bg-white items-center shadow-lg rounded-lg "
                            onClick={() => console.log('DD click', open)}
                          >
                            <span className="text-base font-semibold leading-7 w-full">
                              <div className="mt-0  pb-0 sm:pb-0">
                                <div className="relative">
                                  <div className="absolute inset-0 h-1/2 " />
                                  <div className="relative  px-6 lg:px-0">
                                    <div className="">
                                      <dl className="rounded-t-lg bg-white  sm:grid sm:grid-cols-10 px-3">
                                        <div className="flex  border-b border-gray-100 py-3 text-center self-center sm:border-0 sm:border-r col-span-2">
                                          <span className="self-center mr-3">
                                            {open ? (
                                              <ChevronUpIcon
                                                className="ml-1 mr-2 h-7 w-7 text-white bg-purple-800 rounded-full "
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <ChevronDownIcon
                                                className="ml-1 mr-2 h-7 w-7 text-white bg-purple-800 rounded-full "
                                                aria-hidden="true"
                                              />
                                            )}
                                          </span>

                                          <div className="">
                                            {' '}
                                            <p className="order-1 mt-2 text-md font-medium leading-6 text-gray-500">
                                              {list.job_request_title}
                                            </p>
                                            <p className="order-2 text-md font-semibold tracking-tight text-indigo-600">
                                              {/* <GetBranchName
                                            branchId={list.branch_id}
                                          />
                                          ,{' '} */}
                                              <GetLocationName
                                                branchId={list.branch_id}
                                              />
                                              ,{' '}
                                              <GetCountry
                                                countryid={list.country_id}
                                              />
                                            </p>
                                          </div>
                                        </div>
                                        <div className="flex flex-col border-b border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l sm:border-r">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6  text-indigo-600">
                                            Position
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                            {list.no_of_position}
                                          </dd>
                                        </div>
                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6 text-indigo-600">
                                            Department
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                            {list.department_id}
                                          </dd>
                                        </div>

                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            Emp Type
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                            {list.employment_type}
                                          </dd>
                                        </div>

                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            Salary
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                            {list.salary_budget}
                                          </dd>
                                        </div>
                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            Sourced
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                            {list.sourced}
                                          </dd>
                                        </div>

                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            Timeline Status
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500 flex justify-center">
                                            {getStatusCode(list.Taskstatus)}
                                          </dd>
                                        </div>
                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            Signoff Status
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500 flex justify-center">
                                            {getStatusCode(list.SignoffStatus)}
                                          </dd>
                                        </div>

                                        <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                          <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                            RQ Date
                                          </dt>
                                          <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                            {DateToIndiaFormat(
                                              list.closing_date
                                            )}
                                          </dd>
                                        </div>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </Disclosure.Button>
                        </dt>
                        {/* <OfferCandidateList
                        toggleOfferList={gotoOfferList}
                        offerListId={list.job_request_id}
                      /> */}
                        {/* <OfferCandidateList
                        toggleOfferList={gotoOfferList}
                        offerListId={list.job_request_id}
                      /> */}
                        {/* <Disclosure.Panel as="dd" className="mt-0 pr-0">
                        <div className="text-base leading-7 text-gray-600">
                          Helo
                        </div>
                      </Disclosure.Panel> */}
                        {/* <p>{list.job_request_id}</p> */}

                        <HandoverCandidateList
                          toggleHandover={gotoHandoverDetails}
                          JobRequestId={list.job_request_id}
                        />
                      </>
                    )}
                  </Disclosure>
                </div>
              </>
            ))}
          </>
        ) : (
          'No data found'
        )}
      </>
    );
  }
}

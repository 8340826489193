import React, { useEffect, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Editor = ({ valueTxt, setValueTxt }) => {
  const [value, setValue] = useState(valueTxt);

  useEffect(() => {
    setValueTxt(value);
  }, [value]);

  return <ReactQuill theme="snow" value={value} onChange={setValue} />;
};

export default Editor;

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    email: '',
    employeeType: '',
    token: null,
    message: null,
    roleId: null,
    id: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, message } = action.payload;

      state.user = user.first_name;
      state.email = user.email;
      state.token = token;
      state.message = message;
      state.roleId = user.role_id;
      state.id = user.id;
      state.employeeType = user.employee_type;
    },

    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.message = null;
      state.roleId = null;
      state.id = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserRole = (state) => state.auth.roleId;
export const selectCurrentUserID = (state) => state.auth.id;
export const selectCurrentemail = (state) => state.auth.email;
export const selectCurrentEmployeeType = (state) => state.auth.employeeType;

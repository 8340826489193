import { React, useState, useEffect, useCallback } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import 'rc-tooltip/assets/bootstrap.css';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useInterviewerCandListQuery } from '../../features/hiringstages/interviewApiSlice';
const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
function MyCalendar() {
  const navigate = useNavigate();
  const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' });
  const [allEvents, setAllEvents] = useState();
  const {
    data: interviewerCandList,
    error: interviewerCandListError,
    isLoading: interviewerCandListLoading,
    isSuccess: interviewerCandListSuccess,
  } = useInterviewerCandListQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (interviewerCandListSuccess) {
      const allEvents = interviewerCandList?.data
        .filter((item) => item.interview_date !== null)
        .map((item) => {
          const startDate = new Date(
            `${item.interview_date} ${item.interview_time_start}`
          );
          const endDate = new Date(
            `${item.interview_date} ${item.interview_time_end}`
          );
          return {
            title: `Interview - ${item.fullName}`,
            start: startDate,
            end: endDate,
            candid: item.id,
          };
        });
      setAllEvents(allEvents);
    }
  }, [interviewerCandListSuccess]);

  function handleAddEvent() {
    for (let i = 0; i < allEvents.length; i++) {
      const d1 = new Date(allEvents[i].start);
      const d2 = new Date(newEvent.start);
      const d3 = new Date(allEvents[i].end);
      const d4 = new Date(newEvent.end);

      if ((d1 <= d2 && d2 <= d3) || (d1 <= d4 && d4 <= d3)) {
        alert('CLASH');
        break;
      }
    }
    setAllEvents([...allEvents, newEvent]);
  }
  const handleSelectEvent = useCallback(
    (event) => navigate(`/app/interviewrequest/${event.candid}`),
    []
  );

  return (
    <>
      <div className="bg-white px-5 py-5 ">
        <Calendar
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800, margin: '50px' }}
          onSelectEvent={handleSelectEvent}
        />
      </div>
    </>
  );
}

export default MyCalendar;

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';

import {
  MagnifyingGlassIcon,
  PlusIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
} from '@heroicons/react/20/solid';

import { UserIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Modal from '../../components/common/Modal';
import Notification from '../../components/common/Notification';
import {
  useTeamsQuery,
  useDeleteEmployeeMutation,
} from '../../features/teams/teamsApiSlice';
import { toast } from 'sonner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Team = () => {
  const {
    data: users,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [empId, setEmpId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const onModalSubmit = async () => {
    try {
      const deleteEmp = await deleteEmployee(empId).unwrap();
      closeModalWindow();
      // showNotificationMsg();
      toast.error('Teams Deleted');
    } catch (error) {}
  };

  const openModalWindow = (id) => {
    setEmpId(id);
    setOpenModal(!openModal);
  };

  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const noList = () => (
    <div className="text-center bg-white w-1/4 m-auto p-10">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vector-effect="non-scaling-stroke"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Team </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new Team.
      </p>
      <div className="mt-6">
        {/* <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          New Project
        </button> */}
        <div>
          <Link
            type="button"
            to={'/app/team/addemployee'}
            className="flex   justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
            Add Team
          </Link>
        </div>
      </div>
    </div>
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState();

  useEffect(() => {
    setSearchResults(users?.data);
  }, [users]);

  if (isLoading) <h2>Loading...</h2>;

  if (isSuccess) {
    return (
      <>
        <Notification isShow={showNotification} />

        {searchResults?.length > 0 ? (
          <>
            <div className="flex justify-between ">
              <div className="flex">
                {/* <form className=" flex space-x-4" action="#"> */}
                <div className="min-w-0 flex-1">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="search"
                      name="search"
                      id="search"
                      className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Search by Name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                {/* </form> */}
              </div>
              <div className="flex">
                <Link
                  type="button"
                  to={'/app/team/addemployee'}
                  className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                >
                  <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Add
                </Link>
              </div>
            </div>
            <div className="mt-6">
              <ul
                role="list"
                className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6"
              >
                {searchResults
                  .filter((item) =>
                    searchTerm.toLowerCase() === ''
                      ? item
                      : item.first_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                  )
                  .map((user, i) => (
                    <li
                      key={user.email}
                      className="relative col-span-1 flex justify-center items-center flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg"
                    >
                      <div className="flex flex-1 flex-col p-4">
                        <div className="ring-offset-2 w-1 h-1 ring rounded-full absolute top-5 left-5 ring-lime-500">
                          {' '}
                        </div>

                        <Menu
                          as="div"
                          className="flex-shrink-0 pr-2 absolute top-2 right-0"
                        >
                          <Menu.Button className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-7 top-3 z-10 mx-3 mt-1 w-28 text-left origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/app/team/editemployee/${user.id}`}
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                      )}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                      )}
                                      onClick={() => openModalWindow(user.id)}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>

                        <div className="flex items-center justify-center w-28 h-28 overflow-hidden bg-gradient-to-r from-useIconleft to-userIconRight rounded-full">
                          <UserIcon className="w-20 h-20 self-center stroke-white" />
                        </div>

                        <h3 className="mt-3 text-sm font-bold text-gray-900">
                          {user.first_name}
                        </h3>
                        <dl className="flex flex-grow flex-col justify-between">
                          <dt className="sr-only">Title</dt>
                          {/* <dd className="text-sm text-gray-500">{person.title}</dd> */}
                          <dt className="sr-only">Role</dt>
                          <dd className="">
                            <span className="rounded-ful px-2 py-1 text-xs font-medium text-gray-500">
                              {user.role_id}
                            </span>
                          </dd>
                        </dl>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        ) : isSuccess ? (
          noList()
        ) : (
          ''
        )}
        <Notification
          isShow={showNotification}
          onClose={() => closeNotificationMsg()}
        />
        <Modal
          isModalOpen={openModal}
          onClose={() => closeModalWindow()}
          onSubmit={() => onModalSubmit()}
        ></Modal>
      </>
    );
  }
};

export default Team;

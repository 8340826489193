import React from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import { selectCurrentEmail } from '../../features/auth/register/registerSlice';

const ContactDetails = () => {
  const registeredEmail = useSelector(selectCurrentEmail);
  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-start py-12 sm:px-6 lg:px-8 h-screen">
        <div className="my-8 sm:mx-auto sm:w-full ">
          <div className="bg-white py-8 px-10 shadow sm:rounded-lg sm:px-10 h-screen">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" width="240" />
            </div>

            <div>
              <div className="text-center">
                {/* <CheckCircleIcon className="bg-white" /> */}

                <h5 className="mt-6 text-2xl font-semibold text-center tracking-tight text-gray-900 underline">
                  Contact Details
                </h5>

                <div className=" flex justify-center mt-3">
                  <h5 className="text-md font-semibold  tracking-tight text-gray-900">
                    Company Name :
                  </h5>
                  <h3 className="text-md font-regular  tracking-tight text-gray-700 pl-4">
                    {' '}
                    Skillstrove
                  </h3>
                </div>

                <div className=" flex justify-center mt-3">
                  <h5 className=" text-md font-semibold  tracking-tight text-gray-900">
                    Full Address :
                  </h5>
                  <h3 className="text-md font-regular  tracking-tight text-gray-700 pl-4">
                    23, 2nd venue India
                  </h3>
                </div>

                <div className="flex justify-center mt-3">
                  <h5 className=" text-md font-semibold  tracking-tight text-gray-900">
                    E-Mail :
                  </h5>
                  <h3 className="text-md font-regular  tracking-tight text-gray-700 pl-4">
                    company@email.com
                  </h3>
                </div>

                <div className="flex justify-center mt-3">
                  <h5 className=" text-md font-semibold  tracking-tight text-gray-900">
                    Mobile :
                  </h5>
                  <h3 className="text-md font-regular  tracking-tight text-gray-700 pl-4">
                    +91 86575 34563
                  </h3>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-center" aria-hidden="true">
                  <div className="w-48 border-t border-gray-300 md:divide-y-8 flex justify-center text-center" />
                </div>
              </div>
              <div>
                <p className="text-center text-xs font-normal text-verify pt-6">
                  Clickhere
                  <Link
                    to={'/login'}
                    className="text-clickhere text-md font-semibold"
                  >
                    {' '}
                    Login
                  </Link>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;

import { apiSlice } from '../../app/api/apiSlice';

export const teamsApiSlice = apiSlice.injectEndpoints({
  // tagTypes: ['Team', 'TeamsTask'],
  tagTypes: ['Team', 'TeamsTask', 'TeamsSignoff'],
  endpoints: (builder) => ({
    teams: builder.query({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
      providesTags: ['Team'],
      keepUnusedDataFor: 5,
    }),
    teamsTask: builder.query({
      query: () => ({
        url: '/users/task',
        method: 'GET',
      }),
      providesTags: ['TeamsTask'],
      keepUnusedDataFor: 5,
    }),
    teamsSignoff: builder.query({
      query: () => ({
        url: '/users/signoff',
        method: 'GET',
      }),
      providesTags: ['TeamsSignoff'],
      keepUnusedDataFor: 5,
    }),
    addEmployee: builder.mutation({
      query: (data) => ({
        url: '/users',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Team', 'TeamsTask', 'TeamsSignoff'],
    }),

    updateEmployee: builder.mutation({
      query: (data) => ({
        url: '/users',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Team', 'TeamsTask', 'TeamsSignoff'],
    }),

    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Team', 'TeamsTask', 'TeamsSignoff'],
    }),

    uploaddata: builder.mutation({
      query: (data) => ({
        url: '/users/upload',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Team', 'TeamsTask', 'TeamsSignoff'],
    }),
    // code for tist
    teamsSignoffAdmin: builder.query({
      query: () => ({
        url: '/users/signoffAdmin',
        method: 'GET',
      }),
      providesTags: ['TeamsSignoffAdmin'],
      keepUnusedDataFor: 5,
    }),

    teamTaskWfAdmin: builder.query({
      query: () => ({
        url: '/users/taskWfAdmin',
        method: 'GET',
      }),
      providesTags: ['TaskWfAdmin'],
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useTeamsQuery,
  useTeamsTaskQuery,
  useTeamsSignoffQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useUploaddataMutation,
  useTeamsSignoffAdminQuery,
  useTeamTaskWfAdminQuery
} = teamsApiSlice;

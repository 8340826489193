import { apiSlice } from '../../app/api/apiSlice';

export const sourcingApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    'Sourcing',
    'candidatelist',
    'SourcingApproval',
    'SourcingApproval',
  ],
  endpoints: (builder) => ({
    sourcing: builder.query({
      query: () => ({
        url: '/sourcing',
        method: 'GET',
      }),
      providesTags: ['Sourcing'],
      keepUnusedDataFor: 5,
    }),
    sourcingApproval: builder.query({
      query: () => ({
        url: '/sourcing/approval',
        method: 'GET',
      }),
      providesTags: ['SourcingApproval'],
      keepUnusedDataFor: 5,
    }),

    candlist: builder.query({
      query: (id) => ({
        url: `/sourcing/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelist'],
      keepUnusedDataFor: 5,
    }),
    getCandDetails: builder.query({
      query: (id) => ({
        url: `/sourcing/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelist'],
      keepUnusedDataFor: 5,
    }),
    addCandidate: builder.mutation({
      query: (data) => ({
        url: '/sourcing/candidate',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),
    updateCandidate: builder.mutation({
      query: (data) => ({
        url: '/sourcing/candidate',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),
    pushtoScreening: builder.mutation({
      query: (data) => ({
        url: `/sourcing/candidate/${data.id}`,
        method: 'PUT',
        body: { ...data.body },
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),
    deleteEmployment: builder.mutation({
      query: (id) => ({
        url: `/sourcing/candidate/employment/${id}`, ///sourcing/candidate/employment/1
        method: 'DELETE',
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),

    deleteEducation: builder.mutation({
      query: (id) => ({
        url: `/sourcing/candidate/education/${id}`, ///sourcing/candidate/education/1
        method: 'DELETE',
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),

    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/sourcing/candidate/project/${id}`, ///sourcing/candidate/project/1
        method: 'DELETE',
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),
    //code for tist
    deleteCandidate: builder.mutation({
      query: (id) => ({
        url: `/sourcing/candidateDetails/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sourcing', 'candidatelist', 'SourcingApproval'],
    }),
    //end here

  }),
});

export const {
  useSourcingQuery,
  useSourcingApprovalQuery,
  useCandlistQuery,
  useGetCandDetailsQuery,
  useAddCandidateMutation,
  useUpdateCandidateMutation,
  usePushtoScreeningMutation,
  useDeleteEmploymentMutation,
  useDeleteEducationMutation,
  useDeleteProjectMutation,
  useDeleteCandidateMutation, 
  // useApproveSkillcaptureMutation,
  // useDeleteDepartmentMutation,
} = sourcingApiSlice;

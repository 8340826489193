import React, { useState } from 'react';

import { useCountryQuery } from '../country/countryApiSlice';
import { useLocationQuery } from '../location/locationApiSlice';

const SelectCountryListByLocation = ({
  setCountryId,
  setLocationId,
  isDaysFilter,
}) => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState(0);

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery();

  const {
    data: locations,
    error: locationError,
    isLoading: locationIsLoading,
    isFetching: locationIsFetching,
    isSuccess: locationIsSuccess,
  } = useLocationQuery();

  const uniqueCountryIds = [
    ...new Set(locations?.data?.map((location) => location.country_id)),
  ];
  const filteredCountries = countryList?.data?.filter((country) =>
    uniqueCountryIds.some((cityId) => cityId === country.id)
  );

  const reset = () => {
    setSelectedCountryId(0);
    setSelectedLocationId(0);
    setCountryId(0);
    setLocationId(0);
  };

  return (
    <div className="text-white flex space-x-2">
      <select
        name="countrylist"
        id="countrylist"
        className=" bg-[#39216B] text-[13px] text-white text-xs w-20"
        value={selectedCountryId}
        onChange={(e) => {
          setSelectedCountryId(e.target.value);
          setSelectedLocationId(0);
          setCountryId(e.target.value);
          setLocationId(0);
        }}
      >
        <option value="0">Country</option>
        {filteredCountries?.map((country) => (
          <option key={country.id} value={country.id}>
            {country.country_name}
          </option>
        ))}
      </select>
      <select
        name="locationlist"
        id="locationlist"
        className=" bg-[#39216B] text-[13px] text-white text-xs w-20"
        onChange={(e) => {
          setSelectedLocationId(e.target.value);
          setLocationId(e.target.value);
        }}
        value={selectedLocationId}
      >
        <option value="0">Location</option>
        {locations?.data
          .filter((location) => location.country_id == selectedCountryId)
          .map((location) => (
            <option id={location.id} value={location.id}>
              {location?.name}
            </option>
          ))}
      </select>

      {isDaysFilter && (
        <select
          name=""
          id=""
          className=" bg-[#39216B] text-[13px] text-white text-xs w-20"
        >
          <option value="30">30 Days</option>
          <option value="60">60 Days</option>
        </select>
      )}
      <button
        onClick={reset}
        className="text-xs border border-1 px-3 py-1 rounded-xl"
      >
        Reset
      </button>
    </div>
  );
};

export default SelectCountryListByLocation;

import { apiSlice } from '../../app/api/apiSlice';

export const workflowApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Workflow', 'Workflow2'],
  endpoints: (builder) => ({
    hiringStages: builder.query({
      query: () => ({
        url: '/master/hiringstages',
        method: 'GET',
      }),
      providesTags: ['Workflow'],
      keepUnusedDataFor: 5,
    }),

    workflow: builder.query({
      query: () => ({
        url: '/workflow',
        method: 'GET',
      }),
      providesTags: ['Workflow2'],
      keepUnusedDataFor: 5,
    }),

    workflowtask: builder.query({
      query: () => ({
        url: '/workflow/task',
        method: 'GET',
      }),
      providesTags: ['Workflow2'],
      keepUnusedDataFor: 5,
    }),

    workflowsignoff: builder.query({
      query: () => ({
        url: '/workflow/signoff',
        method: 'GET',
      }),
      providesTags: ['Workflow2'],
      keepUnusedDataFor: 5,
    }),

    addWorkflow: builder.mutation({
      query: (data) => ({
        url: '/workflow/',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Workflow2'],
    }),

    updateTask: builder.mutation({
      query: (data) => ({
        url: '/workflow/task',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Workflow2'],
    }),

    updateSignoff: builder.mutation({
      query: (data) => ({
        url: '/workflow/signoff',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Workflow2'],
    }),

    updatetaskStatus: builder.mutation({
      query: (data) => ({
        url: '/workflow/task/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Workflow2'],
    }),

    updateSignoffStatus: builder.mutation({
      query: (data) => ({
        url: '/workflow/signoff/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Workflow2'],
    }),

    deleteWorkflow: builder.mutation({
      query: (id) => ({
        url: `/workflow/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Workflow', 'Workflow2'],
    }),
  }),
});

export const {
  useHiringStagesQuery,
  useWorkflowQuery,
  useWorkflowtaskQuery,
  useWorkflowsignoffQuery,
  useAddWorkflowMutation,
  useUpdateTaskMutation,
  useUpdateSignoffMutation,
  useUpdatetaskStatusMutation,
  useUpdateSignoffStatusMutation,
  useDeleteWorkflowMutation,
} = workflowApiSlice;

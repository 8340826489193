import React, { useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import ModalData from '../../../../../../components/common/ModalData';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import useCandidate from '../../../../../../hooks/useCandidate';
import { AddEducations } from '../modal/AddEducations';
import Modal from '../../../../../../components/common/Modal';
import Notification from '../../../../../../components/common/Notification';
import { useDeleteEducationMutation } from '../../../../../../features/hiringstages/sourcingApiSlice';

const CandEducation = ({ gotoAddSourcing, showNotificationMsg }) => {
  const initialArr =
    useSelector((state) => state.candidatedetails.educations) || [];
  const candiid = useSelector((state) => state.candidatedetails?.id) || [];
  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);

  const [deleteEducation, { isCandLoading }] = useDeleteEducationMutation();

  const [educations, setEducations] = useState(initialArr);
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [deptId, setDeptId] = useState(null);

  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
    showNotificationMsg();
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    try {
      const deleteAdminMytask = await deleteEducation(deptId).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  return (
    <>
      <div className="flex justify-between px-6 pt-2 items-center">
        <h1 className="text-2xl font-bold">Educational List</h1>
        <Link
          type="button"
          to={''}
          className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
          onClick={() =>
            openModalDataWindow({
              mode: 'Add',
              title: 'Create Employment',
            })
          }
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
          Create Educational
        </Link>
      </div>
      {canddetails?.data.educations.length > 0 ? (
        <>
          {canddetails?.data.educations.map((education) => (
            <div className="border-t border-gray-200" key={education.id}>
              <div className="flex justify-between px-6 py-5">
                <div className="">
                  <h1 className="font-semibold text-2xl text-blue-700">
                    {education.education}
                  </h1>
                  <h4 className="font-semibold text-xl text-red-500">
                    {education.course} {education.speciallization}
                  </h4>
                  <h6 className="font-medium text-md text-gray-600 pb-2">
                    {education.university}
                  </h6>
                  <h6 className="font-medium text-md text-gray-600 pt-1 pb-3">
                    {education.courseType} - {education.passingOutYear} -{' '}
                    {education.gradingSystem
                      ? `${education.gradingSystem}%`
                      : ''}
                  </h6>
                  <div>
                    <h2 className=" font-bold">Achievements</h2>
                    {education.achievement}
                  </div>
                </div>
                <div className="flex items-start ">
                  <div className="flex items-center">
                    <PencilSquareIcon
                      className="-ml-1 mr-2 h-8 w-8 cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Edit',
                          obj: education,
                        })
                      }
                    />
                    <TrashIcon
                      className="-ml-1 mr-5 h-8 w-8 cursor-pointer text-red-600"
                      aria-hidden="true"
                      onClick={() => openModalWindow(education.id)}
                      // onClick={() => removeTechSkill(techskill.skill_id)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="mx-6">No Educationals Found</div>
      )}
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        <AddEducations
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          setShowNotification={() => showNotificationMsg()}
          data={openModalDataValues}
        />
      </ModalData>

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        modal
      </Modal>
    </>
  );
};

export default CandEducation;

import React,{useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import { useJobrequestChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';

const BubbleChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setselectedLocationId] = useState(0);
  const [selectedDeptId, setSelectedDeptId] = useState(0);
  
  const [
    jobRequestChart,
    { data: jobRequest, isLoading: jobRequestLoading },
  ] = useJobrequestChartMutation();

  
  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId, selectedDeptId]);

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setselectedLocationId(id);
  };

  const setDepartmentId = (id) => {
    setSelectedDeptId(id);
  }

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
      department_id: parseInt(selectedDeptId),
    };
    const dataObj = cleanObjectData(data);
    jobRequestChart(dataObj);
  };

  const chartData = jobRequest?.data ||[];

  const chartName = 'Job Request'

  console.log(chartData);

  // Convert data to the format required by ApexCharts
  const categoriesX = [...new Set(chartData.map(item => item.location_name))];
  console.log(categoriesX);
  const categoriesY = [...new Set(chartData.map(item => item.name))];
  console.log(categoriesY);

  const seriesData = chartData.map(item => ({
    x: categoriesX.indexOf(item.location_name),
    y: categoriesY.indexOf(item.name),
    z: Number(item.value)
  }));

  const options = {
    chart: {
      height: 350,
      type: 'bubble',
      fontFamily: 'Inter var', // Set font family for all text elements
      foreColor: '#000000', // Set color for all text elements to black
      toolbar: {
        show: false, // Hide the toolbar (menu bar)
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      // categories: categoriesX,
      labels: {
        formatter: (value) => categoriesX[value], // Adjust index for display
        show: true,
      },
      min: -0.5, // Add extra space on the left side
      max: categoriesX.length - 0.5, // Add extra space on the right side
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        height: 1,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => categoriesY[value], // Adjust index for display
      },
      min: -0.5, // Add extra space on the bottom side
      max: categoriesY.length - 0.5, // Add extra space on the top side
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#f3f4f6',
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: false, // Show horizontal grid lines
        },
      },
    },
    plotOptions: {
      bubble: {
        minBubbleRadius: 5, // Minimum bubble size
        maxBubbleRadius: 50  // Maximum bubble size
      }
    }
  };

  const series = [{
    name: 'Data',
    data: seriesData
  }];

  return (
    <div>
      <div className="flex justify-between p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            setDepartmentId={setDepartmentId}
            isDaysFilter={true}
            showCountry={true}
            showDepartment={true}
            showLocation={true}
          />
        </div>
      </div>
      <ReactApexChart
        type="bubble"
        series={series}
        options={options}
        width={567}
        height={380}
      />
    </div>
  );
};

export default BubbleChart;




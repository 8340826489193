import React, { useState, useRef } from 'react';
import {
  PlusIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';

import axios from 'axios';
import { useSelector, useDiszpatch } from 'react-redux';
import { useFormik } from 'formik';

import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  PhoneIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowUpIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import ModalData from '../../../../../../../components/common/ModalData';
import Modal from '../../../../../../../components/common/Modal';
import { selectCandidateInterview } from '../../../../../../../features/hiringstages/candidateInterviewSlice';
import {
  useCandOfferDetailsQuery,
  useOfferDocumentMutation,
} from '../../../../../../../features/hiringstages/offerApiSlice';

const people = [
  {
    name: 'Comments',
    title: 'Strong Hire',
    percentage: '70%',
  },
  // More people...
];
const Documents = () => {
  const [fileNameTxt, setFileNameTxt] = useState('');
  const [openModalData, setOpenModalData] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [selectedfile, SetSelectedFile] = useState([]);
  const [docFilePath, setDocFilePath] = useState('');
  const [docFileUrl, setDocFileUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const fileInputRef = useRef(null);

  const candId = useSelector(selectCandidateInterview);
  const {
    data: canddetails,
    isLoading: canddetailsLoading,
    isSuccess,
  } = useCandOfferDetailsQuery(candId, { refetchOnMountOrArgChange: true });

  const documentsData = canddetails?.data?.documents;

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const [offerDocument, { isLoading }] = useOfferDocumentMutation();

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const openModalWindow = (id) => {
    // setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const [selectedFile, setSelectedFile] = useState([]);

  const token = useSelector((data) => data.auth.token);

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    setSelectedFile(e.target.files[0]);

    if (e.target.files[0] != '') {
      var formData = new FormData();
      formData.append('file', e.target.files[0]);

      var options = formData;
      try {
        axios
          .post('https://api.skillstrove.com/users/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setDocFilePath(response.data.data.filePath);
            setDocFileUrl(response.data.data.url);
          })

          .catch((error) => {});
      } catch (error) {}
    }
  };

  const formik = useFormik({
    // initialValues,
    // validationSchema,
    // enableReinitialize: true,
    // onSubmit,
  });

  async function submitDocument(e) {
    e.preventDefault();
    const data = {
      profile_id: candId,
      title: fileNameTxt,
      document_path: docFilePath,
    };

    try {
      const documentapicall = await offerDocument(data).unwrap();
      alert('Document added successfully');
      setFileNameTxt('');
      SetSelectedFile([]);
    } catch (error) {}
  }

  return (
    <>
      {/* Content Soft */}
      <div className="basis-5/6">
        <div className="flex flex-row">
          <div className="w-full ">
            <div className="bg-white rounded-lg">
              <div className="">
                <div className="">
                  <div className="bg-gray-200  py-5 rounded-lg  px-5">
                    {' '}
                    <div className="bg-white rounded-lg px-5 py-5">
                      <div className="flex justify-between">
                        <h1 className="text-xl font-bold text-center self-center">
                          Documents from Candidate
                        </h1>
                        <div className="flex">
                          {' '}
                          {/* <div className="kb-file-upload mb-0 self-center">
                            <div className="file-upload-box flex whitespace-nowrap !rounded-xl px-3 bg-sidebar ">
                              <input
                                type="file"
                                id="fileupload"
                                className="file-upload-input"
                                accept=".doc,.docx,.pdf"
                                onChange={handleFileChange}
                              />
                              <span className="flex">
                                <DocumentArrowUpIcon
                                  className="h-7 w-7 text-white mr-2"
                                  aria-hidden="true"
                                />
                                <span className=" text-white self-center pr-3">
                                  Upload
                                </span>
                              </span>
                            </div>
                          </div> */}
                          <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                            <a
                              // href={'#'}
                              // target="_blank"
                              // title="Edit"
                              type="file"
                              className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                              onClick={() => openModalDataWindow({})}
                            >
                              {' '}
                              <DocumentArrowUpIcon
                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                aria-hidden="true"
                              />{' '}
                              Upload Document
                            </a>
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 flow-root">
                        <div className="">
                          <div className="inline-block min-w-full py-2 align-middle">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                    >
                                      File Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-bold text-gray-900"
                                    >
                                      Attachments
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left flex justify-end mr-6 text-sm font-bold text-gray-900"
                                    >
                                      {/* Action */}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {documentsData.map((document) => (
                                    <tr key={document.id}>
                                      <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {/* {person.name} */}
                                        {document.title}
                                      </td>
                                      <td className="whitespace-nowrap  text-sm text-gray-500">
                                        {/* {person.title} */}
                                        <span className=" flex whitespace-nowrap py-2 text-sm text-gray-500">
                                          <a
                                            href={document.document_path}
                                            target="_blank"
                                            title="Edit"
                                            className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                          >
                                            {' '}
                                            <ArrowDownTrayIcon
                                              className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                              aria-hidden="true"
                                            />{' '}
                                            Download
                                          </a>
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap flex justify-end mr-6  text-sm text-gray-500">
                                        {/* {person.email} */}
                                        {/* <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                          <a
                                            className="bg-red-500 px-4 py-2 items-center flex rounded-3xl text-white"
                                            onClick={() => openModalWindow({})}
                                          >
                                            {' '}
                                            <XMarkIcon
                                              className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                              aria-hidden="true"
                                            />{' '}
                                            Remove
                                          </a>
                                        </span> */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        // data={openModalDataValues}
      >
        {/* <EditJD /> */}
        {/* <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        /> */}

        {/* Modal window */}
        {/* <CandCalling dataval={openModalDataValues} /> */}
        <form onSubmit={submitDocument}>
          {/* <div>
            <span>
              File name : <strong> resume.pdf</strong>
            </span>
            <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
              <a
                href={'#'}
               
                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
              >
                <DocumentArrowUpIcon
                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                  aria-hidden="true"
                />
                Upload
              </a>
            </span>
          </div> */}

          <div className="grid grid-cols-4">
            <div className="mt-3 ">
              <label
                htmlFor="file_name"
                className="block text-sm  font-medium text-gray-900 mr-4"
              >
                File name
              </label>
            </div>
            <div>
              <input
                type="text"
                name="file_name"
                id="file_name"
                value={fileNameTxt}
                onChange={(e) => setFileNameTxt(e.target.value)}
                // onBlur={formik.handleBlur}
                // value={formik.values.file_name}
                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              />
              {/* {formik.touched.file_name &&
              formik.errors.file_name ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.file_name}
                </div>
              ) : null} */}
            </div>
          </div>
          {/* <div className="grid grid-cols-4 mt-2">
            <div className="mt-3 ">
              <label
                htmlFor="field_name"
                className="block text-sm  font-medium text-gray-900 mr-4"
              >
                Field name
              </label>
            </div>
            <div>
              <input
                type="text"
                name="field_name"
                id="field_name"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.field_name}
                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              />
             
            </div>
          </div> */}
          {/* <div>
            <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-0">
              <a
                href={'#'}
                
                type="file"
                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
              >
                <DocumentArrowUpIcon
                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                  aria-hidden="true"
                />
                Upload
              </a>
            </span>
          </div> */}

          <div className="flex justify-between mt-5 ">
            <div className="kb-file-upload mb-0 mt-0 w-1/4">
              {' '}
              <div className="file-upload-box whitespace-nowrap !rounded-xl px-3 bg-violet-900 ">
                <input
                  type="file"
                  id="fileupload"
                  className="file-upload-input"
                  accept=".doc,.docx,.pdf"
                  onChange={handleFileChange}
                />
                <span className="flex">
                  <ArrowUpOnSquareIcon
                    className="h-8 w-8 text-white mr-4"
                    aria-hidden="true"
                  />
                  <span className=" text-white self-center">Upload</span>
                </span>
              </div>{' '}
              <p>{selectedFile.name} </p>
            </div>
            <div>
              <button
                type="submit"
                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8  mr-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
              >
                Submit
              </button>
            </div>
          </div>
          {/* 
          <div className="kb-file-upload mb-0 mt-5">
            <div>
              <p>{selectedFile.name} </p> <button type="submit">Submit</button>
            </div>
          </div> */}
        </form>
        {/* Modal window */}
      </ModalData>

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
    </>
  );
};

export default Documents;

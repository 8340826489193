import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import Piechart from './dashboard/pieChart_apex';
import HorizontalGrpBarChart from './dashboard/horizontalGroupChart_apex';
import ManpowerBarChart from './dashboard/ManpowerBarChart';

import StackedBarChart from './dashboard/stackedBarChart_apex';
// import JobRequestBubbleChart from './dashboard/bubbleChart_apex';
import VerticalColumnChart from './dashboard/jobRequestStatusVertical_apex';
import StackedBarChartBoxDesign from './dashboard/stackedBarChartDesign2';
import StackedVerticalColumnChart from './dashboard/stackedVerticalBarChart';
import StackedBarChartCurveDesign from './dashboard/stackedBarChartCurveDesign';
import RangeBarChart from './dashboard/rangeBarChart';
import HorizontalBarChart from './dashboard/horizontalBarChart';
import BubbleChartForStatus from './dashboard/bubbleChart_apex';
// import BubbleChart from './dashboard/bubbleChart_react_chart';
import JobDescriptionPieChart from './dashboard/JobDescriptionPiechart';

import { useManpowerChartMutation } from '../features/dashboard/dashboardApiSlice';
import bubbleChart_chatJs from './dashboard/bubbleChart_chatJs';
// import BubbleChart from './dashboard/BubbleChart';

import BubbleChartForOne from './dashboard/bubbleChart_one';
import BubbleChartForTwo from './dashboard/bubbleChart_two';
import BubbleChartForThree from './dashboard/bubbleChart_three';

import ManpowerPiechart from './dashboard/ManpowerPieChart_apex';
import { useJobrequestChartMutation } from '../features/dashboard/dashboardApiSlice';
import JobRequestStatusReport from './dashboard/bubbuleChart_rechart';
import JobRequestStackedbarChart from './dashboard/JobRequestStackedbarChart';
import JobRequestStatusColumnChart from './dashboard/JobRequestStatusVerticalBarchart';
// import JobrequestStatusStackedChart from './dashboard/JobrequestStatusStackedChart';
import JobDescriptionColumnChart from './dashboard/JobDescriptionVerticalCloumnChart';
import JobDescStatusBarChart from './dashboard/JobDescriptionStatusBarChart';
import SkillCaptureStatusChart from './dashboard/SkillCaptureStackedChart';
import SourcingStatusColumnChart from './dashboard/SourcingStatusColumnChart';
import ScreeningStatusStackedChart from './dashboard/ScreeningStatusStackedChart';
import JobRequestStatusStackedChart from './dashboard/JobrequestStatusStackedChart';
// import JobRequestBubbleChart from './dashboard/JobRequestBubbleChart';
// import ApexChart, { BubbleChart } from './dashboard/bubbleChart_googleCharts';
import BubbleChart from './dashboard/bubbleChart_googleCharts';



const Dashboard = () => {
  // const {
  //   data: manpowerChart,
  //   isLoading: manpowerLooading,
  //   isFetching: manpoerFetching,
  //   isSuccess: manpowerSuccess,
  // } = useManpowerChartMutation();

  const [
    manpowerChart,
    {
      data: manpower,
      isLoading: manpowerLoading,
      isFetching: manpowerFetching,
      isSuccess: manpowerSuccess,
    },
  ] = useManpowerChartMutation();

  const [jobrequestChart,{data: jobrequest, isLoading: jobrequestLoading, isFetching: jobrequestFetching, isSuccess: jobrequestSuccess}] = useJobrequestChartMutation();

  useEffect(() => {
    manpowerChart();
    jobrequestChart();
  }, []);

  const manPower = [
    { name: 'India', value: 150 },
    { name: 'USA', value: 120 },
    { name: 'UK', value: 100 },
    { name: 'Germany', value: 110 },
    { name: 'Russia', value: 50 },
  ];

  const jobDescription = [
    { name: 'India', value: 150 },
    { name: 'USA', value: 120 },
    { name: 'UK', value: 100 },
    { name: 'Germany', value: 110 },
    { name: 'Russia', value: 50 },
  ];

  const JobRequest = [
    { name: 'Admin', value1: 10, value2: 40, value3: 20 },
    { name: 'Sales', value1: 50, value2: 10, value3: 8 },
    { name: 'Manager', value1: 50, value2: 25, value3: 0 },
    { name: 'HR', value1: 65, value2: 10, value3: 6 },
    { name: 'Java', value1: 20, value2: 25, value3: 14 },
    { name: 'Finance', value1: 38, value2: 14, value3: 9 },
  ];
  const JobRequestSeries = ['Approved', 'Waiting', 'Rejected'];

  const jobRequestBubble = [
    {
      city: 'Chennai',
      jobRole: {
        Finance: 2,
        Purchase: 0,
        HR: 5,
        Manager: 0,
        Sales: 1,
        Admin: 0,
      },
    },
    {
      city: 'Bangalore',
      jobRole: {
        Finance: 0,
        Purchase: 6,
        HR: 0,
        Manager: 1,
        Sales: 5,
        Admin: 0,
      },
    },
    {
      city: 'Pune',
      jobRole: {
        Finance: 0,
        Purchase: 2,
        HR: 0,
        Manager: 7,
        Sales: 0,
        Admin: 5,
      },
    },
    {
      city: 'Delhi',
      jobRole: {
        Finance: 2,
        Purchase: 0,
        HR: 1,
        Manager: 0,
        Sales: 5,
        Admin: 0,
      },
    },
    {
      city: 'Mumbai',
      jobRole: {
        Finance: 0,
        Purchase: 3,
        HR: 0,
        Manager: 4,
        Sales: 0,
        Admin: 0,
      },
    },
    {
      city: 'Kochi',
      jobRole: {
        Finance: 1,
        Purchase: 0,
        HR: 4,
        Manager: 0,
        Sales: 0,
        Admin: 2,
      },
    },
  ];
  //Data for Interview - bubble chart

  // const interview = [
  //   {city: 'Screening', jobRole: {Steve: 3, Franklin: 0, Robert: 0, Jackob: 0, John: 0, Williams: 0}},
  //   {city: 'L1', jobRole: {Steve: 0, Franklin: 2, Robert: 0, Jackob: 0, John: 0, Williams: 0}},
  //   {city: 'L2', jobRole: {Steve: 0, Franklin: 0, Robert: 5, Jackob: 0, John: 0, Williams: 0}},
  //   {city: 'L3', jobRole: {Steve: 0, Franklin: 0, Robert: 0, Jackob: 4, John: 0, Williams: 0}},
  //   {city: 'L4', jobRole: {Steve: 0, Franklin: 0, Robert: 0, Jackob: 0, John: 2, Williams: 0}},
  //   {city: 'Offer', jobRole: {Steve: 0, Franklin: 0, Robert: 0, Jackob: 0, John: 0, Williams: 7}},
  // ]
  const interview = [
    { name: 'Screening', value: 'Steve' },
    { name: 'L1', value: 'Franklin' },
    { name: 'L2', value: '' },
    { name: 'L3', value: 'Steve' },
    { name: 'L4', value: 'Steve' },
    { name: 'Offer', value: 'Steve' },
  ];

  //Data for job Request Status - stacked bar chart
  const JobRequestStatus = [
    { name: 'Admin', value1: 40, value2: 26 },
    { name: 'Sales', value1: 35, value2: 22 },
    { name: 'Manager', value1: 30, value2: 25 },
    { name: 'HR', value1: 45, value2: 14 },
    { name: 'Java', value1: 38, value2: 27 },
    { name: 'Finance', value1: 50, value2: 15 },
  ];
  const JobRqstStatusSeries = ['Hand Over', 'Over Due'];

  //Data for Job Request status - vertical bar chart or column chart
  const JobRqstStatus = [
    { name: 'USA', value: 20 },
    { name: 'India', value: 13 },
    { name: 'Russia', value: 15 },
    { name: 'Singapore', value: 17 },
    { name: 'France', value: 25 },
    { name: 'Dubai', value: 30 },
    { name: 'Canada', value: 27 },
  ];

  //Data for Job Descriptons - vertical bar chart or column chart
  const jobDescriptionCC = [
    { name: 'Bangalore', value: 40 },
    { name: 'Pune', value: 20 },
    { name: 'Chennai', value: 25 },
    { name: 'Mumbai', value: 30 },
    { name: 'Cochin', value: 45 },
    { name: 'Delhi', value: 65 },
    { name: 'Goa', value: 50 },
  ];

  //Data for Job Descriptions and Skill capture, Screening - Stacked bar chart for box chart
  const jobDescriptionStacked = [
    { name: 'UI/UX Designer', value1: 45, value2: 23, value3: 13 },
    { name: 'Senior Manager', value1: 70, value2: 15, value3: 10 },
    { name: 'Accpuntant', value1: 45, value2: 40, value3: 12 },
    { name: 'Java Developer', value1: 30, value2: 35, value3: 15 },
    { name: 'Software Engineer', value1: 40, value2: 25, value3: 65 },
    { name: 'Anroid Developer', value1: 75, value2: 40, value3: 20 },
  ];
  const jobDescriptionStackedSeries = ['On Track', 'Over Due', 'At Risk'];

  //Data for Sourcing - stacked vertical bar chart
  const sourcingStacked = [
    { name: 'UI/UX Designer', value1: 45, value2: 23, value3: 13 },
    { name: 'Senior Manager', value1: 70, value2: 15, value3: 10 },
    { name: 'Accpuntant', value1: 45, value2: 40, value3: 12 },
    { name: 'Java Developer', value1: 30, value2: 35, value3: 15 },
    { name: 'Software Engineer', value1: 40, value2: 25, value3: 65 },
    { name: 'Anroid Developer', value1: 75, value2: 40, value3: 20 },
  ];
  const sourcingStackedSeries = ['Total Postions', 'Sourced', 'Screening'];

  //Data for job Request Status, Screening Quality - stacked bar chart
  const sourcingQualityReport = [
    { name: 'John', value1: 10, value2: 2 },
    { name: 'Perter', value1: 2, value2: 1 },
    { name: 'Robin', value1: 7, value2: 3 },
    { name: 'Williams', value1: 8, value2: 2 },
    { name: 'Smith', value1: 4, value2: 4 },
    { name: 'John Doe', value1: 6, value2: 0 },
    { name: 'Martin', value1: 15, value2: 0 },
  ];
  const sourcingQualityReportSeries = ['Source', 'Joining'];
  //for Screening Quality-series
  const screeningQualitySeries = ['Screened', 'Final Interview Clearence'];

  //Data for Interviewer Inaction Report - Range bar chart
  const interviwerInactionData = [
    {
      name: 'Scheduled Acceptance',
      data: [
        {
          name: 'John',
          value: [
            new Date('2023-05-19').getTime(),
            new Date('2023-05-24').getTime(),
          ],
        },
        {
          name: 'Ragul',
          value: [
            new Date('2023-05-20').getTime(),
            new Date('2023-05-25').getTime(),
          ],
        },
        {
          name: 'Rose',
          value: [
            new Date('2023-05-21').getTime(),
            new Date('2023-05-27').getTime(),
          ],
        },
        {
          name: 'Jackob',
          value: [
            new Date('2023-05-21').getTime(),
            new Date('2023-05-27').getTime(),
          ],
        },
      ],
    },
    {
      name: 'Feedback Pending',
      data: [
        {
          name: 'Joe',
          value: [
            new Date('2023-05-18').getTime(),
            new Date('2023-05-25').getTime(),
          ],
        },
        {
          name: 'Dev',
          value: [
            new Date('2023-05-19').getTime(),
            new Date('2023-05-23').getTime(),
          ],
        },
      ],
    },
  ];
  // const interviwerInactionData = [
  //     {name:'john', value: [
  //               new Date('2023-05-20').getTime(),
  //               new Date('2023-05-25').getTime()
  //             ],status:'Scheduled Acceptance'},
  //     {name:'joe', value: [
  //               new Date('2023-05-20').getTime(),
  //               new Date('2023-05-25').getTime()
  //             ],status:'Feedback Pending'},
  //             {name:'Deo', value: [
  //               new Date('2023-05-20').getTime(),
  //               new Date('2023-05-25').getTime()
  //             ],status:'Feedback Pending'},
  //             {name:'Dev', value: [
  //               new Date('2023-05-20').getTime(),
  //               new Date('2023-05-25').getTime()
  //             ],status:'Scheduled Acceptance'},
  // ]

  //Data for Interview - grouped bar chart
  const interviewGrouped = [
    { name: 'UI/UX Designer', value1: 45, value2: 23 },
    { name: 'Senior Manager', value1: 70, value2: 15 },
    { name: 'Accpuntant', value1: 45, value2: 40 },
    { name: 'Java Developer', value1: 30, value2: 35 },
    { name: 'Software Engineer', value1: 40, value2: 25 },
    { name: 'Anroid Developer', value1: 75, value2: 40 },
  ];
  const interviewGroupedSeries = ['Hire', 'No Hire'];

  //Data for Offer Dropped Out Report - Horizontal group chart
  const offerDroppedOutData = [
    { name: 'UI/UX Designer', value1: 45, value2: 23 },
    { name: 'Senior Manager', value1: 70, value2: 15 },
    { name: 'Accpuntant', value1: 45, value2: 40 },
    { name: 'Java Developer', value1: 30, value2: 35 },
    { name: 'Software Engineer', value1: 40, value2: 25 },
    { name: 'Anroid Developer', value1: 75, value2: 40 },
  ];
  const offerDroppedOutDataSeries = ['Accepted', 'Declined'];

  //Data for Manpower - Horizontal group chart
  const manpowerData = [
    { name: 'Electronic City', value1: 100, value2: 58 },
    { name: 'Global Village', value1: 75, value2: 40 },
    { name: 'Manyata Embassy', value1: 35, value2: 30 },
    { name: 'Ecospace', value1: 62, value2: 58 },
    { name: 'Embassy Tech Park', value1: 80, value2: 42 },
    { name: 'International Tech Park', value1: 65, value2: 20 },
    { name: 'Prestige Tech Park', value1: 75, value2: 41 },
    { name: 'Brigade Tech Park', value1: 35, value2: 20 },
  ];
  const manPowerDataSeries = ['Closed', 'Openings'];
  //Data for Joining - Horizontal Group chart
  const joiningData = [
    { name: 'UI/UX Designer', value1: 45, value2: 23, value3: 13 },
    { name: 'Senior Manager', value1: 70, value2: 15, value3: 10 },
    { name: 'Accpuntant', value1: 45, value2: 40, value3: 12 },
    { name: 'Java Developer', value1: 30, value2: 35, value3: 15 },
    { name: 'Software Engineer', value1: 40, value2: 25, value3: 65 },
    { name: 'Anroid Developer', value1: 75, value2: 40, value3: 20 },
  ];
  const joiningDataSeries = ['On Track', 'Over Due', 'At Risk'];

  //Data for Team Performance Report - Horizontal Group chart
  const teamPerformanceData = [
    { name: 'Ram Kumar', value1: 45, value2: 23 },
    { name: 'Ragul', value1: 70, value2: 15 },
    { name: 'Rajesh', value1: 45, value2: 40 },
    { name: 'Remi', value1: 30, value2: 35 },
    { name: 'Rohit', value1: 40, value2: 25 },
    { name: 'Rupa', value1: 75, value2: 40 },
  ];
  const teamPerformanceSeries = ['Joined', 'Sourced'];

  //Data for Ram Kumar- Java Developer - Horizontal chart
  const javaDeveloperData = [
    { name: 'Joining', value: 6, status: 'Over Due' },
    { name: 'Offer', value: 5, status: 'On Track' },
    { name: 'Level 4', value: 4, status: 'Over Due' },
    { name: 'Level 3', value: 1, status: 'On Track' },
    { name: 'Level 2', value: 6, status: 'On Track' },
    { name: 'Level 1', value: 2, status: 'On Track' },
    { name: 'Screening', value: 7, status: 'On Track' },
  ];

  //Data for Recruitment process report - delay report - bubble chart
  const recruitmentProcessDelayData = [
    {
      city: 'Manager',
      jobRole: {
        Singapore: 2,
        Canada: 0,
        Germany: 5,
        India: 0,
        USA: 1,
        Australia: 0,
      },
    },
    {
      city: 'Java',
      jobRole: {
        Singapore: 0,
        Canada: 6,
        Germany: 0,
        India: 1,
        USA: 5,
        Australia: 0,
      },
    },
    {
      city: 'Testing',
      jobRole: {
        Singapore: 0,
        Canada: 2,
        Germany: 0,
        India: 7,
        USA: 0,
        Australia: 5,
      },
    },
    {
      city: 'React',
      jobRole: {
        Singapore: 2,
        Canada: 0,
        Germany: 1,
        India: 0,
        USA: 5,
        Australia: 0,
      },
    },
    {
      city: 'FullStackDev',
      jobRole: {
        Singapore: 0,
        Canada: 3,
        Germany: 0,
        India: 4,
        USA: 0,
        Australia: 0,
      },
    },
    {
      city: 'UI/UX',
      jobRole: {
        Singapore: 1,
        Canada: 0,
        Germany: 4,
        India: 0,
        USA: 0,
        Australia: 2,
      },
    },
  ];

  return (
    <>
      {/* <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1> */}
      <div className="flex justify-evenly items-center">
        <div className="border border-[#6b7280] bg-white">
          {manpowerLoading ? (
            'Loading ...'
          ) : (
            <Piechart chartData={manpower?.data} chartName="Man Power" />
          )}
        </div>
        <div className="border border-[#6b7280] bg-white">
          {/* <HorizontalGrpBarChart
            chartData={manpowerData}
            series={manPowerDataSeries}
            chartName="Man Power"
          /> */}

          <ManpowerBarChart />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white ">
          {/* <JobRequestBubbleChart/> */}
         <BubbleChart/>
          {/* <BubbleChart chartData={jobRequestBubble} chartName="Job Request" />
          
          */}

          {/* <bubbleChart_chatJs
            chartData={jobRequestBubble}
            chartName="Job Request"
          /> */}

          {/* <BubbleChart chartData={jobRequestBubble} chartName="Job Request" /> */}

          {/* <BubbleChartForOne
            // chartData={jobRequestBubble}
            // chartName="Job Request"

            chartData={jobRequestBubble}
            series={JobRequestSeries}
            chartName="Job Request"
          /> */}
          {/* <BubbleChart/> */}
        </div>
        <div className="border border-[#6b7280] bg-white">
          {/* <StackedBarChart
            chartData={JobRequest}
            series={JobRequestSeries}
            chartName="Job Request"
          /> */}
          <JobRequestStackedbarChart/>
        </div>
      </div>
      {/* <div> */}
        {/* <JobRequestBubbleChart/> */}
        {/* <BubbleChart1/> */}
      {/* </div> */}
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white">
          {/* <VerticalColumnChart
            chartData={JobRqstStatus}
            chartName="Job Request Status"
          /> */}
          <JobRequestStatusColumnChart/>
        </div>
        <div className="border border-[#6b7280] bg-white">
          <JobRequestStatusStackedChart/>
          {/* <StackedBarChart
            chartData={JobRequestStatus}
            series={JobRqstStatusSeries}
            chartName="Job Request Status"
          /> */}
          {/* <JobrequestStatusStackedChart/> */}
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white ">
          {/* <Piechart chartData={jobDescription} chartName="Job Description" /> */}
          <JobDescriptionPieChart/>
        </div>
        <div className="border border-[#6b7280] bg-white">
          {/* <VerticalColumnChart
            chartData={jobDescriptionCC}
            chartName="Job Description"
          /> */}
          <JobDescriptionColumnChart/>
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white">
          {/* <StackedBarChartBoxDesign
            chartData={jobDescriptionStacked}
            series={jobDescriptionStackedSeries}
            chartName="Job Description"
          /> */}
          <JobDescStatusBarChart/>
        </div>
        <div className="border border-[#6b7280] bg-white">
          {/* <StackedBarChartBoxDesign
            chartData={jobDescriptionStacked}
            series={jobDescriptionStackedSeries}
            chartName="Skill Capture"
          /> */}
          <SkillCaptureStatusChart/>
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white">
          {/* <StackedVerticalColumnChart
            chartData={sourcingStacked}
            series={sourcingStackedSeries}
            chartName="Sourcing"
          /> */}
          <SourcingStatusColumnChart/>
        </div>
        <div className="border bg-[#39216B]">
          <StackedBarChartCurveDesign
            chartData={sourcingQualityReport}
            series={sourcingQualityReportSeries}
            chartName="Sourcing Quality Report"
          />
         
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border border-[#6b7280] bg-white">
          {/* <StackedBarChartBoxDesign
            chartData={jobDescriptionStacked}
            series={jobDescriptionStackedSeries}
            chartName="Screening"
          /> */}
           <ScreeningStatusStackedChart/>
        </div>
        <div className="border bg-[#39216B]">
          <StackedBarChartCurveDesign
            chartData={sourcingQualityReport}
            series={screeningQualitySeries}
            chartName="Screening Quality"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          {/* <BubbleChart chartData={jobRequestBubble} chartName="Interview" /> */}

          <BubbleChartForTwo
            chartData={jobRequestBubble}
            chartName="Interview"
          />
        </div>
        <div className="border bg-[#39216B]">
          <RangeBarChart
            chartData={interviwerInactionData}
            chartName="Interviwer Inaction Report"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          <HorizontalGrpBarChart
            chartData={interviewGrouped}
            series={interviewGroupedSeries}
            chartName="Interview"
          />
        </div>
        <div className="border bg-[#39216B]">
          <HorizontalGrpBarChart
            chartData={offerDroppedOutData}
            series={offerDroppedOutDataSeries}
            chartName="Offer Dropped Out Report"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          {/* <HorizontalGrpBarChart chartData={interviewGrouped} series={interviewGroupedSeries} chartName='Interview' /> */}

          <BubbleChartForThree
            chartData={interviewGrouped}
            series={interviewGroupedSeries}
            chartName="Interview"
          />
        </div>
        <div className="border bg-[#39216B]">
          <HorizontalGrpBarChart
            chartData={joiningData}
            series={joiningDataSeries}
            chartName="Joining"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          <HorizontalGrpBarChart
            chartData={teamPerformanceData}
            series={teamPerformanceSeries}
            chartName="Team Performance Report"
          />
        </div>
        <div className="border bg-[#39216B]">
          <HorizontalBarChart
            chartData={javaDeveloperData}
            chartName="Ram Kumar - Java Developer"
          />
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="Recruitment Process Report - Delay Report"
          /> */}
        </div>
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="Delay Report - Postition Wise"
          /> */}
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="No.of Positions - Deppartment Wise"
          /> */}
        </div>
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="No.of Candidates Delayed"
          /> */}
        </div>
      </div>
      <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="Recrurter Wise - Candidates Delay"
          /> */}
        </div>
        <div className="border bg-[#39216B]">
          {/* <BubbleChart
            chartData={recruitmentProcessDelayData}
            chartName="Candidate Wise - No.of Days Delayed"
          /> */}
        </div>
      </div>
      {/* <div className="flex justify-evenly items-center mt-10">
        <div className="border bg-[#39216B]">
          <JobRequestStackedbarChart/>
        </div>
      </div>  */}
    </>
  );
};

export default Dashboard;

import { Fragment, useEffect, useState } from 'react';

import { Tab } from '@headlessui/react';

import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  PhoneIcon,
  EnvelopeIcon,
  DocumentArrowUpIcon,
} from '@heroicons/react/20/solid';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';

import useGetSkills from '../../../../../hooks/useGetSkills';

import Notification from '../../../../../components/common/Notification';

import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';
// import { AddEmployment, AddNewCandidate } from './AddEmployment';
import ModalData from '../../../../../components/common/ModalData';

// import Slider, { Range } from 'rc-slider';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useSelector } from 'react-redux';
import {
  useGetScreenCandDetailsQuery,
  useUpdateScreenCandidateMutation,
} from '../../../../../features/hiringstages/screeningApiSlice';
import useGetSkillcapturebyid from '../../../../../hooks/useSkillcapturebyid';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import CandidateExpectation from './CandidateExpectation';
import ScreeningFeedback from './ScreeningFeedback';
import CandResume from './CandResume';
import { useUpdateCandScreeningScoreMutation } from '../../../../../features/hiringstages/screeningApiSlice'; //added by lakshmi

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ViewCandidate = ({ toggleScreening }) => {
  const [scoreTable, setScoreTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [updatedCandScreeningScore] = useUpdateCandScreeningScoreMutation();
  const navigate = useNavigate();
  // const tooltipValues = [3, 7]; // Values where you want tooltips to appear

  const formatTooltip = (value) => {
    // Customize the content to your needs
    return `${value} %`;
  };

  // const [updateExperience]= useUpdateCandidateExpMutation();

  const [updateScreenCandidate, { isLoading: isCandScreenloadingData }] =
    useUpdateScreenCandidateMutation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [mandatory, setmandatory] = useState(0);
  const [preferred, setpreferred] = useState(0);
  const [general, setgeneral] = useState(0);
  const [screeningScore, setScreeningScore] = useState(0); //added by lakshmi

  const selectedCand = useSelector((data) => data.candidate);

  const { skills, loadingSkills, successSkills } = useGetSkills();

  const {
    data: canddetails,
    error: canddetailsErr,
    isLoading: canddetailsLoading,
    isFetching: canddetailsFetching,
    isSuccess: canddetailsSuccess,
  } = useGetScreenCandDetailsQuery(selectedCand, {
    refetchOnMountOrArgChange: true,
  });
  console.log('canddetails', canddetails);
  const { skillcapturebyid, loadingSkillcapturebyid, successSkillcapturebyid } =
    useGetSkillcapturebyid(canddetails?.data?.job_request_id);

  const jobrequestSkillArray = skillcapturebyid?.data[0]?.skill_list || [];
  console.log('jobrequestSkillArray', jobrequestSkillArray);

  const showNotificationMsg = () => {
    // setShowNotification(!showNotification);
    setShowNotification(true);

    // setTimeout(() => {
    //   setShowNotification(false);
    // }, 3000);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getSkillName = (skillId) => {
    if (successSkills) {
      const skillName = skills?.data.filter((skl) => {
        return skl.id == skillId;
      });
      return skillName[0]?.name;
    }
  };

  const gotoScreeningList = (value) => {
    console.log(value)
    toggleScreening(value);
  };

  const output = [];

  const canddetailsSkill = canddetails?.data.technical_skills;
  console.log('canddetailsSkill', canddetailsSkill);

  useEffect(() => {
    const updatedOutput = [];

    jobrequestSkillArray.forEach((jobSkillType) => {
      jobSkillType.skills.forEach((jobSkill) => {
        const candidateSkill = canddetailsSkill?.find((candidate) => candidate.skill_id === jobSkill.skill_id);

        if (candidateSkill) {
          const tabName = jobSkill.weightage;

          let tab = updatedOutput.find((item) => item.tabName === tabName);
          if (!tab) {
            tab = { tabName, skills: [] };
            updatedOutput.push(tab);
          }

          const skillInfo = {
            skill_id: jobSkill.skill_id,
            experience: candidateSkill.experience,
            rating: candidateSkill.rating ? candidateSkill.rating : 0,
            exp_experience_min: jobSkill.min !== undefined ? jobSkill.min : 0,
            exp_experience_max: jobSkill.max !== undefined ? jobSkill.max : 0,
          };

          tab.skills.push(skillInfo);
        }
      });
    });

    setScoreTable(updatedOutput);
  }, [canddetailsSkill, canddetailsSuccess, jobrequestSkillArray]);


  console.log('scoretable', scoreTable);

  const updateRating = (newRating, tabName, skillId) => {
    console.log("Updating rating for", { newRating, tabName, skillId });
    const updatedData = scoreTable.map((tab) => {
      // Find the correct tab to update by matching `tabName`
      if (tab.tabName === tabName) {
        // For the matching tab, update only the skill that matches `skillId`
        const updatedSkills = tab.skills.map((skill) => {
          // Find the correct skill to update by matching `skillId`
          if (skill.skill_id === skillId) {
            // Return the updated skill object with the new rating
            return { ...skill, rating: newRating };
          }
          // Return the skill unchanged if it's not the one being updated
          return skill;
        });
        // Return the updated tab with the updated skills
        return { ...tab, skills: updatedSkills };
      }
      // Return the tab unchanged if it's not the one being updated
      return tab;
    });

    // Update the state with the new data, ensuring immutability
    setScoreTable([...updatedData]);
  };


  const handleRatingChange = (value, tabName, skillId) => {
    const newRating = parseInt(value);
    updateRating(newRating, tabName, skillId);
  };

  const getSkillsScore = (skillArray, tabName) => {
    const sumOfRatings = skillArray.reduce(
      (total, skill) => total + skill.rating,
      0
    );
    const averageRating = sumOfRatings / skillArray.length;

    if (tabName === 'mandatory') {
      return `${(averageRating * 10 * 0.7).toFixed(1)} %`;
    } else if (tabName === 'prefered') {
      return `${(averageRating * 10 * 0.2).toFixed(1)} %`;
    } else if (tabName === 'general') {
      return `${(averageRating * 10 * 0.1).toFixed(1)} %`;
    }

    // return `${averageRating * 10} %`;
  };

  const getAllSkillsScore = () => {
    const totalRatings = scoreTable.reduce((total, tab) => {
      const tabTotal = tab.skills.reduce(
        (tabSum, skill) => tabSum + skill.rating,
        0
      );
      return total + tabTotal;
    }, 0);

    const totalSkills = scoreTable.reduce(
      (total, tab) => total + tab.skills.length,
      0
    );

    const averageRating = totalRatings / totalSkills;
    console.log(averageRating);
    // setScreeningScore(averageRating);
    // console.log(screeningScore);
    return averageRating * 10;
  };

  // Use useEffect to calculate and set screeningScore when scoreTable changes
  useEffect(() => {
    const score = getAllSkillsScore();
    setScreeningScore(score);
  }, [scoreTable]);

  console.log('screeningScore', screeningScore);

  const updateSkillsRating = async () => {
    const flattenedData = scoreTable.reduce((result, tab) => {
      const skills = tab.skills.map((skill) => ({
        skill_id: skill.skill_id,
        experience: skill.experience,
        rating: skill.rating,
      }));
      return result.concat(skills);
    }, []);
    
    console.log('flattenedData', flattenedData)
    let values = {
      profile_id: canddetails?.data.id,
      technical_skills: flattenedData,
    };
    console.log('values', values);
    let dataScore = {
      profile_id: canddetails?.data.id,
      account_id: canddetails?.data.account_id,
      screening_score: screeningScore,
    };
    console.log('dataScore', dataScore);
    try {
      const updateCandidateData = await updateScreenCandidate(values).unwrap();
      const result = await updatedCandScreeningScore(dataScore);
      console.log(updateCandidateData);
      if (result?.data?.message) {
        console.log(result.data.message);
      } else if (result?.error) {
        console.error('Update failed:', result.error);
      }
      showNotificationMsg();
    } catch (error) {
      console.error('Error during update skills:', error);
      alert('An unexpected error occured');
    }
  };

  const handleExpChange = (value, tabName, skillId) => {
    const newExperience = value;
    console.log('newExperience', newExperience);
    updateExperience(newExperience, tabName, skillId);
  }

  const updateExperience = (newExperience, tabName, skillId) => {
    console.log('newExperience', newExperience);
    console.log('skillId', skillId);
    console.log('tabName', tabName);
    const updatedData = scoreTable.map((tab) => {
      if (tab.tabName === tabName) {
        const updatedSkills = tab.skills.map((skill) => {
          if (skill.skill_id === skillId) {
            return { ...skill, experience: newExperience };
          }
          return skill;
        });
        return { ...tab, skills: updatedSkills };
      }
      return tab;
    });
    //update the state with the new data
    setScoreTable(updatedData);
  }

  if (canddetailsSuccess) {
    return (
      <>
        <div className="flex justify-end items-center mb-4">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoScreeningList('candidatelist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>

        <div className="flex flex-row mt-0 ">
          <div className="basis-80 ">
            <div className="bg-white rounded-md shadow-3xl border border-gray-100">
              <div className=" border-b border-gray-300 pb-4">
                <div className="min-w-0 flex-1 px-4 pt-4 ">
                  <h1 className="  flex font-semibold  text-xl mt-3 text-gray-700">
                    {canddetails?.data.fullName}
                  </h1>
                  <p className="flex  font-semibold py-1  text-sm text-gray-400">
                    {canddetails?.data.current_designation} -{' '}
                    {canddetails?.data.current_company}
                  </p>
                </div>
              </div>

              <h2 className=" text-lg font-semibold p-4">Contact Details</h2>
              <div className="  border-b border-gray-300 mb-4 pl-4">
                <span className="flex items-center">
                  <DevicePhoneMobileIcon
                    className="h-6 w-6 text-sidebar"
                    aria-hidden="true"
                  />

                  <div className="mt-0">
                    <p className=" ml-0 px-5 py-1.5 text-black font-semibold  placeholder:text-black text-sm sm:leading-6 focus:outline-none">
                      {canddetails?.data.mobile}
                    </p>
                  </div>
                </span>

                <span className="flex items-center mt-2">
                  <PhoneIcon
                    className="h-6 w-6 text-sidebar"
                    aria-hidden="true"
                  />

                  <div className="mt-0">
                    <p className="px-5 py-1.5 text-black font-semibold  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                      {canddetails?.data.work_mobile}
                    </p>
                  </div>
                </span>

                <span className="flex items-center mb-4 mt-2">
                  <EnvelopeIcon
                    className="h-6 w-6 text-sidebar mr-2"
                    aria-hidden="true"
                  />
                  <div>
                    <div className="mt-0">
                      <p className=" px-3 py-1.5 text-black font-semibold  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                        {canddetails?.data.email}
                      </p>
                    </div>
                  </div>
                </span>
                {/* <CardsUser /> */}
              </div>

              <div className=" border-b border-gray-300 mb-4">
                <h2 className=" text-lg font-semibold px-4">Address</h2>

                <div>
                  <div className="mt-2 mx-4 mb-4">
                    <p className=" px-0 py-1.5 text-black font-semibold break-words  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                      {canddetails?.data.address}
                    </p>
                  </div>
                </div>
              </div>

              <div className="  mb-4">
                {canddetails?.data.resume ? (
                  <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500 ml-7">
                    <a
                      href={canddetails?.data.resume}
                      target="_blank"
                      title="Edit"
                      className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                    >
                      {' '}
                      <DocumentArrowUpIcon
                        className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                        aria-hidden="true"
                      // onClick={() => openDrawerWindow()}
                      />{' '}
                      Download Resume
                    </a>
                  </span>
                ) : (
                  <span className="text-center flex whitespace-nowrap px-2 py-2 text-md text-gray-500 justify-center font-bold ">
                    Resume not available
                  </span>
                )}
              </div>
            </div>

            <CandidateExpectation
              setShowNotification={() => showNotificationMsg()}
            />
          </div>

          <div className="basis-5/6  gap-4 ">
            <div className="bg-white shadow-lg gap-4 ml-5 rounded-lg">
              <Tab.Group as="div" className="mt-4">
                <Tab.List className="flex justify-start bg-skillcaps px-2 py-1 mx-5 my-3 rounded-md space-x-10">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                          : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        // 'whitespace-nowrap   py-0  px-0'
                      )
                    }
                  >
                    <span>
                      <a
                        className="text-skillcapstext px-3 py-2 rounded-md text-md uppercase"
                      // className="bg-white text-black p-2 rounded-md font-bold text-md"
                      >
                        Screening Score
                      </a>
                    </span>
                  </Tab>

                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                          : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        // 'whitespace-nowrap   py-0  px-0'
                      )
                    }
                  >
                    <span>
                      <a
                        className="text-skillcapstext px-3 py-2 rounded-md text-md uppercase"
                      // className="bg-white text-black p-2 rounded-md font-bold text-md"
                      >
                        Resume
                      </a>
                    </span>
                  </Tab>
                </Tab.List>
                <Tab.Panels as={Fragment}>
                  <Tab.Panel className="space-y-16 py-3">
                    <div className=" border border-gray-100 ">
                      <h1 className="    flex font-semibold px-0 text-xl mt-3 text-gray-700 border-b border-gray-200 pb-4 mx-5">
                        Screening Score
                      </h1>
                      <Tab.Group as="div" className="mt-4">
                        <Tab.List className="flex justify-start bg-skillcaps px-2 py-1 mx-5 my-3 rounded-md space-x-10">
                          {scoreTable?.map((tab) => (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                                    : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                  // 'whitespace-nowrap   py-0  px-0'
                                )
                              }
                            >
                              <span>
                                <a
                                  className="text-skillcapstext px-3 py-2 rounded-md text-md uppercase"
                                // className="bg-white text-black p-2 rounded-md font-bold text-md"
                                >
                                  {tab.tabName} Skills
                                </a>
                              </span>
                            </Tab>
                          ))}
                        </Tab.List>
                        <Tab.Panels as={Fragment}>
                          {scoreTable?.map((tab) => (
                            <Tab.Panel className="space-y-16 py-3">
                              <div className="px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flow-root">
                                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-0 align-middle sm:px-6 lg:px-8">
                                      <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="py-3.5 w-[20px] pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                            >
                                              Title
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5  w-[70px] text-left text-sm font-semibold text-gray-900"
                                            >
                                              Expected Exp
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5 w-[20px] text-left text-sm font-semibold text-gray-900"
                                            >
                                              Actual Exp
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5 w-[300px] text-left text-sm font-semibold text-gray-900"
                                            >
                                              Rating
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5 w-[50px] text-left text-sm font-semibold text-gray-900"
                                            >
                                              Percentage
                                            </th>
                                            {/* <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                >
                                  <span className="sr-only">Edit</span>
                                </th> */}
                                          </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                          {tab.skills.map((skill) => (
                                            <tr key={skill.skill_id}>
                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                {getSkillName(skill.skill_id)}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {/* 
                                              {skill.min && skill.max ? (
                                                  <>
                                                    {skill.min} - {skill.max}{' '}
                                                    Years
                                                  </>
                                                ) : (
                                                  'N/A'
                                                )}
                                              */}
                                                <>
                                                  {skill.exp_experience_min === 0 && skill.exp_experience_max === 0
                                                    ? 'N/A'
                                                    : `${skill.exp_experience_min} - ${skill.exp_experience_max} Yrs`
                                                  }
                                                  {/* {skill.exp_experience_min} - { skill.exp_experience_max}{' '}
                                                Yrs */}
                                                </>
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <div className=" w-1/4 flex mr-4">
                                                  {/* {skill.experience} Year(s) */}
                                                  <input type='string' placeholder={skill.experience} className='w-20 justify-center' onChange={(e) => handleExpChange(parseInt(e.target.value), tab.tabName, skill.skill_id) || 0} />
                                                  <span className="ml-2">Yrs</span>
                                                </div>
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {skill.rating}
                                                <div className="mt-0">
                                                  <Slider
                                                    // range
                                                    // className="cursor-pointer "
                                                    marks={{
                                                      0: 0,
                                                      10: 10,
                                                    }}
                                                    min={0}
                                                    step={1}
                                                    max={10}
                                                    allowCross={false}
                                                    tipFormatter={formatTooltip}
                                                    // pushable={true}
                                                    // defaultValue={[0]}
                                                    // value={[0, 10]}
                                                    onChange={(e) =>
                                                      handleRatingChange(
                                                        e,
                                                        tab.tabName,
                                                        skill.skill_id
                                                      )
                                                    }
                                                    value={skill.rating}
                                                  />
                                                </div>
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {skill.rating * 10}%{' '}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Panel>
                          ))}
                        </Tab.Panels>
                      </Tab.Group>
                      <div className="border-t border-b border-gray-200 pb-4 ">
                        <div className="-mx-4 mt-2 sm:-mx-0">
                          <table className="min-w-full ">
                            <thead>
                              <tr>
                                {scoreTable?.map((tab) => (
                                  <>
                                    {/* <pre>
                                      {JSON.stringify(tab, undefined, 2)}
                                    </pre> */}
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-center text-md font-semibold text-gray-900 sm:pl-0 capitalize"
                                    >
                                      {tab.tabName} Skill Score
                                    </th>
                                  </>
                                ))}
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-center text-md font-semibold text-gray-900"
                                >
                                  Overall Score
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-center text-lg font-bold text-gray-900"
                                >
                                  Result
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr className="">
                                {scoreTable?.map((tab) => (
                                  <td className="w-full text-center max-w-0 py-0 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                    <p className="text-xl text-purple-800 font-bold">
                                      {getSkillsScore(tab.skills, tab.tabName)}
                                    </p>
                                  </td>
                                ))}

                                <td className="px-3 text-center py-0 text-sm text-gray-500">
                                  <p className="text-xl text-purple-800 font-bold">
                                    {getAllSkillsScore().toFixed(0)} %
                                  </p>
                                </td>
                                <td className="px-3 text-center py-0 text-sm text-gray-500">
                                  <p className="text-xl text-purple-800 font-bold">
                                    {getAllSkillsScore() < 50 ? (
                                      <span className="text-red-600">
                                        Failed
                                      </span>
                                    ) : (
                                      <span className="text-green-600">
                                        Success
                                      </span>
                                    )}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>{' '}
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 my-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          onClick={() => updateSkillsRating()}
                          disabled={isCandScreenloadingData}
                        // {isCandloadingData ? 'disabled' : ''}
                        >
                          {isCandScreenloadingData ? (
                            <>
                              {' '}
                              <svg
                                role="status"
                                className="inline mr-3 w-4 h-4 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"
                                />
                              </svg>{' '}
                              Saving...{' '}
                            </>
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                    <ScreeningFeedback
                      setShowNotification={() => showNotificationMsg()}
                      score={getAllSkillsScore().toFixed(0)}
                    />
                  </Tab.Panel>

                  <Tab.Panel className="space-y-16 py-3">
                    <CandResume />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>

            {/* <div className="flex justify-end mt-3">
              <button
                type="button"
                className="inline-flex rounded-3xl border border-gray-400 py-3 px-6 text-sm font-medium shadow-sm focus:outline-none "
                // onClick={() => gotoScreening('screening')}
                onClick={() => gotoScreeningList('candidatelist')}
              >
                Cancel
              </button>
            </div> */}
          </div>
        </div>
        <Notification
          isShow={showNotification}
          // onClose={() => closeNotificationMsg()}
          onClose={() => setShowNotification(false)}
        />

      </>
    );
  }
};

import React, { useEffect, useState } from 'react';
import Wysiwyg from '../../../../../components/common/Wysiwyg';
import {
  useAddJobdescMutation,
  useUpdateJobdescMutation,
} from '../../../../../features/hiringstages/jobdescApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Editor from '../../../../../components/common/Editor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { toast } from 'sonner';

const YEARMIN = 0;
const YEARMAX = 40;

const SALARYMIN = 50000;
const SALARYMAX = 4000000;

let rupee = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

const EditJD = ({ data, showNotification, onModalDataClose }) => {

  console.log('data',data);
  const JDMode = data.mode;
  console.log('JDMode',JDMode);

  const [addJobdesc, { isLoading }] = useAddJobdescMutation();
  const [updateJobdesc, { isLoading: updateLoading }] =
    useUpdateJobdescMutation();

  // const [yearvalues, setYearvalues] = useState([YEARMIN, YEARMAX]);
  const [yearvalues, setYearvalues] = useState(
    data.required_experience
      ? JSON.parse(data.required_experience)
      : [YEARMIN, YEARMAX]
  );
  const [salaryvalues, setSalaryvalues] = useState(
    data.salary_budget ? JSON.parse(data.salary_budget) : [SALARYMIN, SALARYMAX]
  );

  const [jobReqTxt, setJobReqTxt] = useState(data.job_requirement);
  const [jobDescTxt, setJobDescTxt] = useState(data.job_description);

  const initialValues = {
    // job_requirement: jobReqTxt,
    // job_description: jobDescTxt,
    job_request_id: data.job_request_id,
    priority: data.priority || 0,
    employment_type: data.employment_type || 'Full Time',
    id: data.id || null,
  };
  const validationSchema = yup.object({
    // name: yup.string().required('Please enter  name'),
    // description: yup.string().required('Please enter description'),
  });
  const onSubmit = async (validationSchema) => {
    const newArray = {
      ...validationSchema,
      required_experience: JSON.stringify(yearvalues),
      salary_budget: JSON.stringify(salaryvalues),
      job_requirement: jobReqTxt,
      job_description: jobDescTxt,
    };

    try {
      if (data.id) {
        const accountData = await updateJobdesc(newArray).unwrap();
        toast.success('Job description saved');
      } else {
        const accountData = await addJobdesc(newArray).unwrap();
        toast.success('Job description added');
      }
      onModalDataClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // enableReinitialize: true,
  });

  const handleChangeEditor = (content, delta, source, editor) => {
    const text = editor.getText(content);
    const words = text.split(/\s+/).filter(Boolean); // Split by whitespace and remove empty strings

    if (words.length <= 1000) {
      setJobReqTxt(content);
    } else {
      alert('You have reached the 1000 word limit!');
      // Optionally, you can trim the content to 1000 words here
      // and use setEditorHtml to update the editor content
    }
  };

  const handleChangeDesc = (content, delta, source, editor) => {
    const text = editor.getText(content);
    const words = text.split(/\s+/).filter(Boolean); // Split by whitespace and remove empty strings

    if (words.length <= 1000) {
      setJobDescTxt(content);
    } else {
      alert('You have reached the 1000 word limit!');
      // Optionally, you can trim the content to 1000 words here
      // and use setEditorHtml to update the editor content
    }
  };
  return (
    <>
      { JDMode === 'edit' ? (
        <form
        className="flex flex-col justify-between"
        onSubmit={formik.handleSubmit}
      >
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Job Title
            </label>
            <h2 className="mt-2 font-bold">{data.title}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Branch
            </label>
            <h2 className="mt-2 font-bold">{data.branch}</h2>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Location
            </label>
            <h2 className="mt-2 font-bold">{data.location}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Positions #
            </label>
            <h2 className="mt-2 font-bold">{data.positions}</h2>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="employment_type"
              className="block text-sm font-medium text-gray-700"
            >
              Employment Type
            </label>
            <select
              id="employment_type"
              name="employment_type"
              autoComplete="employment_type"
              className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employment_type}
            >
              <option>-- Select Employment Type</option>
              <option value={'Full Time'}>Full Time</option>
              <option value={'Part Time'}>Part Time</option>
            </select>

            {formik.touched.employment_type && formik.errors.employment_type ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.employment_type}
              </div>
            ) : null}
          </div>
          <div className="">
            <div className="flex justify-between">
              <label
                htmlFor="required_experience"
                className="block text-sm font-medium text-gray-700"
              >
                Required Experience
              </label>
              <div>
                {yearvalues[0]} - {yearvalues[1]} <b>Years</b>
              </div>
            </div>
            <div className="mt-3 px-6">
              <Slider
                range
                min={YEARMIN}
                max={YEARMAX}
                step={1}
                allowCross={false}
                // defaultValue={[6, 10]}
                value={[yearvalues[0], yearvalues[1]]}
                onChange={setYearvalues}
                // value={10}
              />
            </div>

            {/* <input
              type="text"
              name="required_experience"
              id="required_experience"
              autoComplete="cc-required_experience"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              placeholder="2 - 4 Years"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.required_experience}
            />
            {formik.touched.required_experience &&
            formik.errors.required_experience ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.required_experience}
              </div>
            ) : null} */}
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <div className="flex justify-between">
              <label
                htmlFor="salary_budget"
                className="block text-sm font-medium text-gray-700"
              >
                Salary
              </label>
              <div>
                <b>INR</b>{' '}
                {salaryvalues[0].toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                })}{' '}
                -{' '}
                {salaryvalues[1].toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                })}{' '}
              </div>
            </div>
            <div className="mt-3 px-4">
              <Slider
                range
                min={SALARYMIN}
                max={SALARYMAX}
                step={50000}
                allowCross={false}
                // defaultValue={[6, 10]}
                value={[salaryvalues[0], salaryvalues[1]]}
                onChange={setSalaryvalues}
                // value={10}
              />
            </div>
            {/* <input
              type="text"
              name="salary_budget"
              id="salary_budget"
              autoComplete="salary_budget"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              placeholder="INR 25000 - 40000 "
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.salary_budget}
            />
            {formik.touched.salary_budget && formik.errors.salary_budget ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.salary_budget}
              </div>
            ) : null} */}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div className="bg-white  rounded-md shadow-3xl">
            <div className="flex justify-between items-center">
              <h2
                id="account-details-heading"
                className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
              >
                Job Requirement
              </h2>
            </div>
            <ReactQuill
              theme="snow"
              value={jobReqTxt}
              // onChange={setJobReqTxt}
              // onChange={(value) => handleChange(value)}
              onChange={handleChangeEditor}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="bg-white  rounded-md shadow-3xl">
            <div className="flex justify-between items-center">
              <h2
                id="account-details-heading"
                className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
              >
                Job Description
              </h2>
            </div>
            {/* <Wysiwyg /> */}
            <ReactQuill
              theme="snow"
              value={jobDescTxt}
              onChange={handleChangeDesc}
            />
          </div>
        </div>

        <div className="flex flex-row mt-5">
          <div className="basis-10/12 bg-gray-100  border py-0 mr-3 flex rounded-lg">
            <div className="basis-1/2 p-3 mr-3">
              <select
                id="priority"
                name="priority"
                autoComplete="priority"
                className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
              >
                <option>-- Select Priority --</option>
                <option value={'3'}>High</option>
                <option value={'2'}>Medium</option>
                <option value={'1'}>Low</option>
              </select>
              {formik.touched.priority && formik.errors.priority ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.priority}
                </div>
              ) : null}
            </div>
            <div className="basis-1/2 py-3 mr-3">
              <button
                type="submit"
                className=" rounded-xl border bg-green-500 w-full py-2.5 px-0 text-sm font-medium text-white shadow-sm focus:outline-none "
              >
                Submit for Approval
              </button>
            </div>
          </div>

          {/* <div className="basis-2/12  self-center ">
            <button
              type="button"
              className=" rounded-md bg-indigo-600 py-3 px-16  text-sm font-medium text-white shadow-sm focus:outline-none "
            >
              Save
            </button>
          </div> */}
        </div>
      </form>
      ) : (
        <form
        className="flex flex-col justify-between"
        onSubmit={formik.handleSubmit}
      >
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Job Title
            </label>
            <h2 className="mt-2 font-bold">{data.title}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Branch
            </label>
            <h2 className="mt-2 font-bold">{data.branch}</h2>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Location
            </label>
            <h2 className="mt-2 font-bold">{data.location}</h2>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch"
              className="block text-sm font-medium text-gray-700"
            >
              Positions #
            </label>
            <h2 className="mt-2 font-bold">{data.positions}</h2>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="employment_type"
              className="block text-sm font-medium text-gray-700"
            >
              Employment Type
            </label>
            <select
              id="employment_type"
              name="employment_type"
              autoComplete="employment_type"
              className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm font-bold"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employment_type}
              disabled={true}
            >
              <option>-- Select Employment Type</option>
              <option value={'Full Time'}>Full Time</option>
              <option value={'Part Time'}>Part Time</option>
            </select>

            {formik.touched.employment_type && formik.errors.employment_type ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.employment_type}
              </div>
            ) : null}
          </div>
          <div className="col-span-4 sm:col-span-1">
          <label
                htmlFor="required_experience"
                className="block text-sm font-medium text-gray-700"
              >
                Required Experience
              </label>
              <h2 className='mt-2 font-bold'>
                {yearvalues[0]} - {yearvalues[1]} Years
              </h2>
          </div>
          
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            {/* <div className="flex justify-between"> */}
              <label
                htmlFor="salary_budget"
                className="block text-sm font-medium text-gray-700"
              >
                Salary
              </label>
              {/* <div> */}
              <h2 className='mt-2 font-bold'>
                INR{' '}
                {salaryvalues[0].toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                })}{' '}
                -{' '}
                {salaryvalues[1].toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                })}{' '}
                </h2>
              
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div className="bg-white  rounded-md shadow-3xl">
            <div className="flex justify-between items-center">
              <h2
                id="account-details-heading"
                className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
              >
                Job Requirement
              </h2>
            </div>
            <h2 className='mt-2 font-bold'><div dangerouslySetInnerHTML={{ __html: data.job_requirement }} /></h2>
            
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="bg-white  rounded-md shadow-3xl">
            <div className="flex justify-between items-center">
              <h2
                id="account-details-heading"
                className=" mb-1 flex text-sm mt-5  font-medium leading-6 text-gray-900"
              >
                Job Description
              </h2>
            </div>
            
            <h2 className='mt-2 font-bold'>
            <div dangerouslySetInnerHTML={{ __html: data.job_description }} />
            </h2>
          </div>
        </div>
        <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => onModalDataClose()}
            >
              Cancel
            </button>
            
          </div>
      </form>
      )}  
    </>
  );
};

export default EditJD;

import React from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';

import { useSelector } from 'react-redux';

export const AddProject = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
}) => {
  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();
  const profileId = useSelector((state) => state.candidatedetails.id);

  const initialValues = {
    id: data.obj?.id || '',
    title: data.obj?.title || '',
    tag: data.obj?.tag || '',
    client: data.obj?.client || '',
    project_status: data.obj?.project_status || '',
    worked_from: data.obj?.worked_from || '',
    worked_to: data.obj?.worked_to || '',
    description: data.obj?.description || '',
  };
  const validationSchema = yup.object({
    title: yup.string().required('Please enter title'),
    client: yup.string().required('Please enter clientname'),
    worked_from: yup.string().required('Please enter From year'),
    worked_to: yup.string().required('Please enter To year'),
    description: yup.string().required('Please enter Description'),
  });
  const onSubmitform = async (validationSchema) => {
    let values = {
      profile_id: profileId,
      projects: [],
    };
    values.projects.push(validationSchema);

    try {
      const updateCandidateData = await updateCandidate(values).unwrap();

      setShowNotification(true);
      onModalDataClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: onSubmitform,
  });

  return (
    <>
      <form className="flex flex-col" onSubmit={formik.handleSubmit}>
        <div className="flex flex-row">
          <div className="basis-3/4 mt-5 ">
            <label
              htmlFor="title"
              className="block text-md font-medium text-gray-700"
            >
              Project Title*
            </label>
            <input
              type="text"
              name="title"
              id="title"
              // autoComplete="cc-title"
              className="bg-inputbg border w-full border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.title}
              </div>
            ) : null}
          </div>
        </div>

        {/* <div className="flex flex-row">
          <div className="basis-3/4 mt-5 ">
            <label
              htmlFor="Employment_Education"
              className="block text-md font-medium text-gray-700"
            >
              Tag this project with your Employment/Education*
            </label>
            <input
              type="text"
              name="Employment_Education"
              id="Employment_Education"
              autoComplete="cc-Employment_Education"
              className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            />
          </div>
        </div> */}

        <div className="flex flex-row">
          <div className="basis-3/4 mt-5 ">
            <label
              htmlFor="client"
              className="block text-md font-medium text-gray-700"
            >
              Client Name
            </label>
            <input
              type="text"
              name="client"
              id="client"
              // autoComplete="cc-client"
              className="bg-inputbg border w-full border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.client}
            />
            {formik.touched.client && formik.errors.client ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.client}
              </div>
            ) : null}
          </div>
        </div>

        {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-2 ">
            <label
              htmlFor="project_status"
              className="block text-md font-medium text-gray-700 pb-2"
            >
              Project Status*
            </label>
            <div className="flex h-6 items-center">
              <select
                id="project_status"
                name="project_status"
                className="bg-inputbg w-full border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.project_status}
              >
                <option val="-1">-- Select location --</option>
                <option value="inprogress">In Progress</option>
                <option value="completed">Completed</option>
              </select>

              {formik.touched.project_status && formik.errors.project_status ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.project_status}
                </div>
              ) : null}
            </div>
          </div>
        </div> */}

        {/* <div className="mt-5 sm:mt-6 ">
          <label
            htmlFor="project_duration"
            className="block text-md font-medium text-gray-700"
          >
            Project Duration*
          </label>
          <p className="text-sm">From</p>
          <input
            type="month"
            name="project_duration"
            id="project_duration"
            autoComplete="cc-project_duration"
            className="bg-inputbg border w-52 border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.project_duration}
          />
          {formik.touched.project_duration && formik.errors.project_duration ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.project_duration}
            </div>
          ) : null}
        </div> */}

        <div className="mt-5 sm:mt-6 ">
          <label
            htmlFor="project_duration"
            className="block text-md font-medium text-gray-700"
          >
            Project Duration*
          </label>
          <div className="flex mt-1 ">
            <div className="mr-3">
              <label
                htmlFor="From"
                className="block text-sm font-medium text-gray-700"
              >
                From
              </label>
              <input
                type="date"
                name="worked_from"
                id="worked_from"
                autoComplete="cc-worked_from"
                className="bg-inputbg border w-52 border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.worked_from}
              />
              {formik.touched.worked_from && formik.errors.worked_from ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.worked_from}
                </div>
              ) : null}
            </div>{' '}
            <div className="">
              <label
                htmlFor="to"
                className="block text-sm font-medium text-gray-700"
              >
                To
              </label>
              <input
                type="date"
                name="worked_to"
                id="worked_to"
                autoComplete="cc-worked_to"
                className="bg-inputbg border w-52 border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.worked_to}
              />
              {formik.touched.worked_to && formik.errors.worked_to ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.worked_to}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-span-2 mt-6 border-b-2 border-gray-200 pb-8">
          <label
            htmlFor="description"
            className="block text-md font-medium text-gray-700"
          >
            Detail of Project*
          </label>
          <textarea
            rows={5}
            name="description"
            id="description"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            // placeholder="detail_of_project"
            // defaultValue={''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.description}
            </div>
          ) : null}
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
              // className="inline-flex  justify-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

import { apiSlice } from '../../app/api/apiSlice';

export const screeningApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Screening', 'candidatelistScreening', 'ScreeningApproval'],
  endpoints: (builder) => ({
    screening: builder.query({
      query: () => ({
        url: '/screening',
        method: 'GET',
      }),
      providesTags: ['Screening'],
      keepUnusedDataFor: 5,
    }),
    screeningApproval: builder.query({
      query: () => ({
        url: '/screening/approval',
        method: 'GET',
      }),
      providesTags: ['ScreeningApproval'],
      keepUnusedDataFor: 5,
    }),

    candScreenAlllist: builder.query({
      query: (id) => ({
        url: `/screening/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistScreening'],
      keepUnusedDataFor: 5,
    }),
    getScreenCandDetails: builder.query({
      query: (id) => ({
        url: `/screening/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistScreening'],
      keepUnusedDataFor: 5,
    }),

    updateScreenCandidate: builder.mutation({
      query: (data) => ({
        url: '/screening/candidate',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Screening',
        'candidatelistScreening',
        'ScreeningApproval',
      ],
    }),
    pushtoInterview: builder.mutation({
      query: (data) => ({
        url: `/screening/${data.id}`,
        method: 'PUT',
        body: { ...data.body },
      }),
      invalidatesTags: [
        'Screening',
        'candidatelistScreening',
        'ScreeningApproval',
      ],
    }),
     //added by tist
     updateCandScreeningScore: builder.mutation({
      query: (data)=> ({
        url:'screening/candidates/screening_score',
        method: 'PUT',
        body: {...data},
      })
    }),
    getCandScreeningScore: builder.query({
      query: (id) => ({
        url:`screening/candidates/screening_score/${id}`,
        method: 'GET',
      }) 
    }),
    //end here
  }),
});

export const {
  useScreeningQuery,
  useScreeningApprovalQuery,
  useCandScreenAlllistQuery,
  useGetScreenCandDetailsQuery,
  useUpdateScreenCandidateMutation,
  usePushtoInterviewMutation,
  useUpdateCandScreeningScoreMutation,
  useGetCandScreeningScoreQuery,
} = screeningApiSlice;

import { apiSlice } from '../../app/api/apiSlice';

export const skillcaptureApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Skillcapture'],
  endpoints: (builder) => ({
    skillcapture: builder.query({
      query: () => ({
        url: '/skill/skillcapture',
        method: 'GET',
      }),
      providesTags: ['Skillcapture'],
      keepUnusedDataFor: 5,
    }),
    skillcaptureApproval: builder.query({
      query: () => ({
        url: '/skill/skillcapture/approval',
        method: 'GET',
      }),
      providesTags: ['SkillcaptureApproval'],
      keepUnusedDataFor: 5,
    }),

    skillcapturebyid: builder.query({
      query: (id) => ({
        url: `/skill/skillcapture/${id}`,
        method: 'GET',
      }),
      providesTags: ['Skillcaptureid'],
      keepUnusedDataFor: 5,
    }),

    addSkillcapture: builder.mutation({
      query: (data) => ({
        url: '/skill/skillcapture',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        'Skillcapture',
        'Skillcaptureid',
        'SkillcaptureApproval',
      ],
    }),
    updateSkillcapture: builder.mutation({
      query: (data) => ({
        url: '/skill/skillcapture',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Skillcapture',
        'Skillcaptureid',
        'SkillcaptureApproval',
      ],
    }),
    approveSkillcapture: builder.mutation({
      query: (data) => ({
        url: '/skill/skillcapture/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Skillcapture',
        'Skillcaptureid',
        'SkillcaptureApproval',
      ],
    }),
  }),
});

export const {
  useSkillcaptureQuery,
  useSkillcaptureApprovalQuery,
  useSkillcapturebyidQuery,
  useAddSkillcaptureMutation,
  useUpdateSkillcaptureMutation,
  useApproveSkillcaptureMutation,
  // useDeleteDepartmentMutation,
} = skillcaptureApiSlice;

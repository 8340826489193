import { apiSlice } from '../../app/api/apiSlice';

export const manpowerApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Manpower', 'ManpowerApproval'],
  endpoints: (builder) => ({
    manpower: builder.query({
      query: () => ({
        url: '/workflow/manpower',
        method: 'GET',
      }),
      providesTags: ['Manpower'],
      keepUnusedDataFor: 5,
    }),
    manpowerApproval: builder.query({
      query: () => ({
        url: '/workflow/manpower/approval',
        method: 'GET',
      }),
      providesTags: ['ManpowerApproval'],
      keepUnusedDataFor: 5,
    }),
    addManpower: builder.mutation({
      query: (data) => ({
        url: '/workflow/manpower',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Manpower', 'ManpowerApproval'],
    }),

    updateManpower: builder.mutation({
      query: (data) => ({
        url: '/workflow/manpower',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Manpower', 'ManpowerApproval'],
    }),

    updateManpowerApproval: builder.mutation({
      query: (data) => ({
        url: '/workflow/manpower/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Manpower', 'ManpowerApproval'],
    }),

    deleteManpower: builder.mutation({
      query: (id) => ({
        url: `/workflow/manpower/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Manpower', 'ManpowerApproval'],
    }),
  }),
});

export const {
  useManpowerQuery,
  useManpowerApprovalQuery,
  useAddManpowerMutation,
  useUpdateManpowerMutation,
  useUpdateManpowerApprovalMutation,
  useDeleteManpowerMutation,
} = manpowerApiSlice;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useDesignationQuery } from '.././../features/designation/designationApiSlice';
import { toast } from 'sonner';

import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { useLocationQuery } from '../../features/location/locationApiSlice';
import {
  useBranchQuery,
  useDeleteBranchMutation,
} from '../../features/branch/branchApiSlice';

import {
  useHiringStagesQuery,
  useAddWorkflowMutation,
} from '../../features/workflow/workflowApiSlice';

const CreateWorkflow = () => {
  const navigate = useNavigate();

  const [isEdit, setEdit] = useState(false);
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [isHr, setIsHr] = useState(0);

  const [addWorkflow, { isLoading: loadWF }] = useAddWorkflowMutation();

  function handleAddTodo() {
    if (newTodo.trim()) {
      const newId = todos.length ? todos[todos.length - 1].sno + 1 : 1;
      setTodos([...todos, { sno: newId, title: newTodo, is_hr: isHr }]);
      setNewTodo('');
      setIsHr(0);
    }
  }

  function handleDeleteTodo(sno) {
    setTodos(todos.filter((todo) => todo.sno !== sno));
  }

  const { data: branchs } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: desigs,
    error: desigsError,
    isLoading: desigsIsLoading,
    isFetching: desigsIsFetching,
    isSuccess: desigsIsSuccess,
  } = useDesignationQuery({ refetchOnMountOrArgChange: true });

  const { data: locations } = useLocationQuery({
    refetchOnMountOrArgChange: true,
  });

  const {
    data: depts,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useHiringStagesQuery({ refetchOnMountOrArgChange: true });

  let initialValues = {
    title: '',
    designation_id: 0,
    branch_id: 0,
    hiring_stages: [
      {
        hiring_stage_id: 1,
      },
      {
        hiring_stage_id: 2,
      },
      {
        hiring_stage_id: 3,
      },
      {
        hiring_stage_id: 4,
      },
      {
        hiring_stage_id: 5,
        interviewList: todos || [],
      },
      {
        hiring_stage_id: 6,
      },
      {
        hiring_stage_id: 7,
      },
      {
        hiring_stage_id: 8,
      },
    ],
  };
  const validationSchema = yup.object({
    title: yup.string().required('Please enter Workflow title'),
    branch_id: yup.string().required('Please enter Location'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      if (isEdit) {
        // const accountData = await updateDepartment(validationSchema).unwrap();
      } else {
        initialValues = {
          title: formik.values.title,
          designation_id: formik.values.designation_id,
          branch_id: formik.values.branch_id,
          hiring_stages: [
            {
              hiring_stage_id: 1,
            },
            {
              hiring_stage_id: 2,
            },
            {
              hiring_stage_id: 3,
            },
            {
              hiring_stage_id: 4,
            },
            {
              hiring_stage_id: 5,
              interviewList: todos || [],
            },
            {
              hiring_stage_id: 6,
            },
            {
              hiring_stage_id: 7,
            },
            {
              hiring_stage_id: 8,
            },
          ],
        };
        const accountData = await addWorkflow(initialValues).unwrap();
        toast.success('Workflow created successfully');
      }

      navigate(-1);

      // onClose();
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <section aria-labelledby="personal-details-heading">
        <form onSubmit={formik.handleSubmit}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div className="flex justify-between items-center">
                <h2
                  id="personal-details-heading"
                  className=" flex text-lg font-bold leading-6 text-gray-900"
                >
                  Create Workflow
                </h2>

                <div className="flex">
                  <Link
                    type="button"
                    to={'..'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
                  >
                    <ArrowLeftIcon
                      className="-ml-1 mr-2 h-5 w-5 text"
                      aria-hidden="true"
                    />
                    Back
                  </Link>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-6">
                <div className="">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Workflow Title *
                  </label>

                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="title"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.title}
                    </div>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="branch_id"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Branch *
                  </label>
                  {/* <select className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm">
                    <option value="">-- Select Location --</option>
                    {locations && locations.data && locations.data.map((locationItem) => (
                      <option key={locationItem.id} value={locationItem.id}>
                        {locationItem.name}
                      </option>
                    ))}
                  </select> */}
                  <select
                    name="branch_id"
                    id="branch_id"
                    autoComplete="branch_id"
                    placeholder="Chennai, India"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branch_id}
                  >
                    <option value="-1">-- Select Branch --</option>
                    {branchs?.data.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>

                  {formik.touched.branch_id && formik.errors.branch_id ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.branch_id}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-row items-baseline mt-5">
                <div className="basis-1/3 border border-spacing-2 rounded-md mr-10 py-4 px-3">
                  <div className="flex justify-between">
                    <div className="self-center ">
                      <h2 className="font-bold ">Workflow</h2>
                    </div>
                  </div>
                  <div className=" mt-2 border-t-2">
                    <ul className="">
                      <li className="p-3 text-blue-500">
                        <a href="#">Job Description</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Skill Capture</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Sourcing</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Screening</a>
                      </li>
                      <li className="p-3 text-white bg-sidebar">
                        <a href="#">Interview</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Offer</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Joining</a>
                      </li>
                      <li className="p-3 text-blue-500">
                        <a href="#">Handover</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="basis-2/4 border border-spacing-2 rounded-md  py-2 px-3">
                  <div className="flex justify-between">
                    <div className="self-center">
                      <h2 className="font-bold">Interviews</h2>
                    </div>
                    <div className=" ">
                      {/* <button
                      type="button"
                      className="flex justify-center rounded-xl border border-transparent bg-violet-900 py-1 px-1 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() => setOpenDrawer(!openDrawer)}
                      // onClick={() => openDrawerWindow()}
                    >
                      <PlusIcon
                        className=" h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </button> */}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <input
                      type="text"
                      value={newTodo}
                      onChange={(e) => setNewTodo(e.target.value)}
                      placeholder="Add Interview"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    />
                    <div className="flex justify-start space-x-2">
                      <input
                        type="checkbox"
                        name="isinterview"
                        id="isinterview"
                        checked={isHr}
                        onChange={(e) => setIsHr(e.target.checked)}
                      />
                      <label htmlFor="isinterview">Is this HR interview </label>
                    </div>
                    {/* <button onClick={handleAddTodo}>Add</button> */}
                    <button
                      type="button"
                      className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={handleAddTodo}
                    // onClick={() => setOpenDrawer(!openDrawer)}
                    // onClick={() => openDrawerWindow()}
                    >
                      <PlusIcon
                        className=" h-5 w-5 text-white"
                        aria-hidden="true"
                      />{' '}
                      Add Interview
                    </button>
                  </div>
                  {todos.map((todo) => {
                    const listStyle = `bg-screeningL${todo.sno} px-2 py-1.5 text-black  text-sm font-bold`;
                    return (
                      <div
                        className="flex justify-start mt-2 border-t-2 pt-3"
                        key={todo.sno}
                      >
                        <div>
                          <span className={listStyle}>L{todo.sno}</span>
                        </div>
                        <div className="flex">
                          <h2 className="pl-3 mr-3 font-bold">
                            {todo.title}
                            {todo.is_hr !== 0 && (
                              <span className=" italic text-xs text-red-500">
                                {' - (HR Round)'}
                              </span>
                            )}
                          </h2>
                          <button
                            className="flex justify-center rounded-xl border border-transparent bg-red-900 py-1 px-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none "
                            onClick={() => handleDeleteTodo(todo.sno)}
                          >
                            <XMarkIcon
                              className=" h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Cancel
                </button>

                <button
                  disabled={!(formik.dirty && formik.isValid)}
                  type="submit"
                  className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default CreateWorkflow;

import React from 'react';
import {
  PhoneIcon,
  EnvelopeIcon,
  DocumentArrowUpIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/20/solid';
import { useGetInterviewCandDetailsQuery } from '../../../../../../features/hiringstages/interviewApiSlice';
import { selectCandidateInterview } from '../../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';

function CandDetails() {
  const candId = useSelector(selectCandidateInterview);
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useGetInterviewCandDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="basis-80 ">
      <div className="bg-white rounded-md shadow-3xl border border-gray-100">
        <div className=" border-b border-gray-300 pb-4">
          <div className="min-w-0 flex-1 px-4 pt-4 ">
            <h1 className="  flex font-semibold  text-xl mt-3 text-gray-700">
              {canddetails?.data.fullName}
            </h1>
            <p className="flex  font-semibold py-1  text-sm text-gray-400">
              {canddetails?.data.current_designation} -{' '}
              {canddetails?.data.current_company}
            </p>
          </div>
        </div>

        <h2 className=" text-lg font-semibold p-4">Contact Details</h2>
        <div className="  border-b border-gray-300 mb-4 pl-4">
          <span className="flex items-center">
            <DevicePhoneMobileIcon
              className="h-6 w-6 text-sidebar"
              aria-hidden="true"
            />

            <div className="mt-0">
              <p className=" ml-0 px-5 py-1.5 text-black font-semibold  placeholder:text-black text-sm sm:leading-6 focus:outline-none">
                {canddetails?.data.mobile}
              </p>
            </div>
          </span>

          <span className="flex items-center mt-2">
            <PhoneIcon className="h-6 w-6 text-sidebar" aria-hidden="true" />

            <div className="mt-0">
              <p className="px-5 py-1.5 text-black font-semibold  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                {canddetails?.data.work_mobile}
              </p>
            </div>
          </span>

          <span className="flex items-center mb-4 mt-2">
            <EnvelopeIcon
              className="h-6 w-6 text-sidebar mr-2"
              aria-hidden="true"
            />
            <div>
              <div className="mt-0">
                <p className=" px-3 py-1.5 text-black font-semibold  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                  {canddetails?.data.email}
                </p>
              </div>
            </div>
          </span>
        </div>

        <div className=" border-b border-gray-300 mb-4">
          <h2 className=" text-lg font-semibold px-4">Address</h2>

          <div>
            <div className="mt-2 mx-4 mb-4">
              <p className=" px-0 py-1.5 text-black font-semibold break-words  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                {canddetails?.data.address}
              </p>
            </div>
          </div>
        </div>

        <div className="  mb-4">
          {canddetails?.data.resume ? (
            <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500 ml-7">
              <a
                href={canddetails?.data.resume}
                target="_blank"
                title="Edit"
                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
              >
                {' '}
                <DocumentArrowUpIcon
                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                  aria-hidden="true"
                />{' '}
                Download Resume
              </a>
            </span>
          ) : (
            <span className="text-center flex whitespace-nowrap px-2 py-2 text-md text-gray-500 justify-center font-bold ">
              Resume not available
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default CandDetails;

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const HorizontalBarChart = ({ chartData, chartName }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  //Define options for countries, branches
  const countryOptions = ['India', 'USA', 'UK'];
  const branchOptions = ['Chennai', 'Bangalore', 'Pune'];

  // Extracting unique statuses for series
  const statuses = [...new Set(chartData.map((item) => item.status))];

  // Sorting data based on the original order of names
  // const sortedData = chartData.sort((a, b) => a.value - b.value);
  // console.log(sortedData)

  // Extracting y-axis labels and x-axis values
  // const labels = chartData.map(item => item.name);
  // console.log(labels)
  // const values = chartData.map(item => item.value);

  // Generating series data based on statuses
  const series = statuses.map((status) => ({
    name: status,
    data: chartData.map((item) => (item.status === status ? item.value : 0)),
  }));

  // Options for the chart
  const options = {
    chart: {
      type: 'bar',
      height: 400,

      fontFamily: 'Poppins',
      foreColor: '#FFFFFF',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        barWidth: '50%',
        // borderRadius: 10, //All rounded corners to the bars
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
      axisBorder: {
        show: true,
        color: '#6234C1',
        height: 2,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      categories: chartData ? chartData.map((item) => item.value) : [],
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
      axisBorder: {
        show: true,
        color: '#6234C1',
        width: 2,
      },
    },
    grid: {
      show: true,
      borderColor: '#42297D',
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: false, // Show horizontal grid lines
        },
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      show: true,
      labels: {
        colors: '#FFFFFF',
      },
    },
    colors: ['#53BA9E', '#FFC10B'],
  };

  return (
    <div>
      <div className="flex justify-between p-5">
        <div>
          <h1 className="text-white text-[16px] font-poppins">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          <select
            className=" bg-[#39216B] text-[13px] text-white "
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>

          {/* Dropdown for branch */}
          <select
            className="ml-1  text-[13px] bg-[#39216B] text-white "
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={380}
        width={570}
      />
    </div>
  );
};

export default HorizontalBarChart;

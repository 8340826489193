import React from 'react';

import { json, Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import { selectCandidateInterview } from '../../features/hiringstages/candidateInterviewSlice';
import { useInterviewerCandDetailsQuery } from '../../features/hiringstages/interviewApiSlice';
import { useSelector } from 'react-redux';
// import { json } from 'stream/consumers';

import { selectCurrentUserID } from '../../features/auth/authSlice';

function InterviewScore() {
  // const candId = useSelector(selectCandidateInterview);
  const { id } = useParams();
  const candId = id
  const UserID = useSelector(selectCurrentUserID);
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useInterviewerCandDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });
  // console.log(candId)
  const interviewData = canddetails?.data?.interview;
  const techSkills = canddetails?.data?.technical_skills;

  let reversedInterviewData = interviewData?.slice().reverse();
  console.log(UserID, reversedInterviewData)

  function calculateWeightedAverage(skills) {
    // const skills = [category];
    // console.log(skills)
    if (skills.length > 0) {
      const totalRating = skills.reduce((acc, skill) => acc + skill.rating, 0);
      const averageRating = totalRating / skills.length;
      // console.log(averageRating)
      return averageRating;
    }
    else
      return 0
  }

  function totalPer(technicalSkillsArray, isHr) {
    // const technicalSkillsArray = JSON.parse(technicalSkillsArrayString);
    console.log('technical_skills is not an array:', isHr, technicalSkillsArray);
    if (!Array.isArray(technicalSkillsArray)) {
      return 0; // Return a default value or handle the error as needed
    }
    // // Flatten the skills array to make it easier to process
    // const allSkills = technicalSkillsArray.flatMap(
    //   (category) => category.skills
    // );
    // console.log(allSkills)
    // const mandatoryAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'mandatory')
    // );
    // const preferredAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'prefered')
    // );
    // const generalAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'general')
    // );


    // Extract each category's skills
    const otherSkillsCategory = technicalSkillsArray.find(category => category.name === 'Other Skills');
    if (isHr != 1) {
      const mandatorySkillsCategory = technicalSkillsArray.find(category => category.name === 'mandatory');
      const preferredSkillsCategory = technicalSkillsArray.find(category => category.name === 'prefered');
      const generalSkillsCategory = technicalSkillsArray.find(category => category.name === 'general');

      const OtherSkills = otherSkillsCategory ? otherSkillsCategory.skills : [];
      const mandatorySkills = mandatorySkillsCategory ? mandatorySkillsCategory.skills : [];
      const preferredSkills = preferredSkillsCategory ? preferredSkillsCategory.skills : [];
      const generalSkills = generalSkillsCategory ? generalSkillsCategory.skills : [];

      const combinedSkills = [...mandatorySkills, ...OtherSkills];
      // console.log(combinedSkills,OtherSkills,mandatorySkills, preferredSkills, generalSkills)

      // Calculate the average rating for each category
      const mandatoryAverage = calculateWeightedAverage(combinedSkills);
      const preferredAverage = calculateWeightedAverage(preferredSkills);
      const generalAverage = calculateWeightedAverage(generalSkills);


      // console.log(mandatoryAverage, preferredAverage, generalAverage)
      const mandatoryWeight = 0.7;
      const preferredWeight = 0.2;

      const generalWeight = 0.1;
      // const otherWeight = 0.1;

      const totalWeightedAverage =
        mandatoryAverage * mandatoryWeight +
        preferredAverage * preferredWeight +
        generalAverage * generalWeight
      // console.log(totalWeightedAverage)
      return totalWeightedAverage;
    }
    else {
      const OtherSkills = otherSkillsCategory ? otherSkillsCategory.skills : [];
      const otherAverage = calculateWeightedAverage(OtherSkills);
      // console.log(otherAverage)
      return otherAverage;
    }

  }

  return (
    <>
      {reversedInterviewData?.length > 0 ? (
        <>
          <div className=" ">
            <div className="">
              <div className="">
                <div className="">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 bg-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-300">

                      <tbody className="divide-y divide-gray-200">

                        {reversedInterviewData.length > 0 ? (
                          <>
                            {/* Table Header */}

                            <tbody>
                              {/* Additional Rows (like Comments) if needed */}
                              <tr className="border-b border-gray-300">
                                <th
                                  scope="col"
                                  className="py-3.5 w-[20px] pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Factors
                                </th>
                                {reversedInterviewData?.map((list) => (
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                                  >
                                    {list?.interview_stage?.sno} -{' '}
                                    {list?.interview_stage?.title}
                                    <p className=" text-sm font-semibold text-gray-900">
                                      {list?.interview_mode}
                                    </p>
                                    <p className="text-blue-500">
                                      {list?.interviewer_name}
                                    </p>
                                  </th>
                                ))}
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                  Comments
                                </td>
                                {reversedInterviewData?.map((list, index) => (
                                  <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {list.interview_recommendation_comments != null ? (
                                      <div className="text-center">
                                        <span className="inline-flex bg-green-800 justify-center py-2 px-4 text-white rounded-lg text-sm font-medium shadow-sm">
                                          {list.interview_recommendation_comments}
                                        </span>
                                        <Link
                                          type="button"
                                          to={'/app/settings'}
                                          className="flex mt-2 text-blue-500 underline justify-center rounded-3xl text-sm font-medium shadow-sm focus:outline-none"
                                        >
                                          Comments
                                        </Link>
                                      </div>
                                    ) : (
                                      <span className="text-center">N/A</span>
                                    )}
                                  </td>
                                ))}
                              </tr>
                              {/* Iterate over skill categories 'mandatory', 'prefered', 'general' */}
                              {['mandatory', 'prefered', 'general', 'Other Skills'].map((skillCategory, categoryIndex) => (
                                <tr key={categoryIndex}>
                                  {/* Skill Category Name */}
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {skillCategory.charAt(0).toUpperCase() + skillCategory.slice(1)}
                                  </td>

                                  {/* Display average rating for each stage */}
                                  {reversedInterviewData?.map((interview, index) => {
                                    const techSkills = interview.technical_skills?.find(tech => tech.name === skillCategory);
                                    const averageRating = techSkills ? calculateWeightedAverage(techSkills.skills) : 'N/A';

                                    return (
                                      <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="text-center">
                                          {averageRating !== 'N/A' ? (
                                            <span className=" justify-center py-2 px-4 text-black rounded-lg text-sm font-medium shadow-sm">
                                              {averageRating.toFixed(1)}
                                            </span>
                                          ) : (
                                            <span className="text-center">N/A</span>
                                          )}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              ))}

                              <tr>
                                <td className="whitespace-nowrap px-3 py-4 text-gray-500 text-lg text-gray-800 font-semibold capitalize">
                                  Total
                                </td>
                                {reversedInterviewData?.map((list, i) => {
                                  // Parse the technical_skills JSON string
                                  let technicalSkillsArray;
                                  try {
                                    technicalSkillsArray = list.technical_skills
                                  } catch (error) {
                                    console.error(
                                      'Error parsing technical_skills:',
                                      error
                                    );
                                    technicalSkillsArray = [];
                                  }
                                  console.log(list?.interview_stage?.is_hr, technicalSkillsArray)
                                  // Assuming totalPer is a function that processes the technical skills array
                                  return (
                                    <td
                                      key={i}
                                      className="whitespace-nowrap px-3 py-4 text-gray-500 text-center text-lg text-gray-800 font-semibold capitalize"
                                    >
                                      {(totalPer(technicalSkillsArray, list?.interview_stage?.is_hr) * 10).toFixed(1)}%
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan={reversedInterviewData.length + 1}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                            >
                              No interview data available
                            </td>
                          </tr>
                        )}



                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </>
      ) : (
        <div>
          <h1> No interview</h1>
        </div>
      )}
    </>
  );
}

export default InterviewScore;

import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';

import { Dialog, Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { useCandScreenAlllistQuery } from '../../../../../features/hiringstages/screeningApiSlice';
import { setCandId } from '../../../../../features/hiringstages/candidateSlice';
import { useDispatch } from 'react-redux';

import GetCountry from '../../../../../components/common/GetCountry';
import GetLocationName from '../../../../../components/common/GetLocationName';

import ModalData from '../../../../../components/common/ModalData';
// import { AddPushToScreening } from './AddPushToScreening';
// import CandFilter from './CandFilter';
import { stringify } from 'uuid';
import { selectCurrentUserID } from '../../../../../features/auth/authSlice';

const CandidateList = ({ toggleScreening }) => {
  const selectedScreening = useSelector((state) => state.screening);
  const dispatch = useDispatch();
  const selectCurrentUserId = useSelector(selectCurrentUserID);

  const {
    data: candidateList,
    error: candidateListError,
    isLoading: candidateListLoading,
    isFetching: candidateListFetching,
    isSuccess: candidateListSuccess,
  } = useCandScreenAlllistQuery(selectedScreening?.job_request_id, {
    refetchOnMountOrArgChange: true,
  });

  const gotoScreeningList = (value, candid) => {
    if (candid?.id > 0) {
      dispatch(setCandId(candid.id));
    } else {
      dispatch(setCandId(0));
    }
    toggleScreening(value);
  };

  return (
    <>
      <div className=" mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 items-center justify-end">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoScreeningList('screeninglist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>

        <div className="w-full">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Candidate Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Exp
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Current Salary
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Expectation CTC
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Current company
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Notice Period (in Days)
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Current designation
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Action
                </th>
              </tr>
            </thead>
            {candidateList?.data?.length > 0 ? (
              <tbody className="divide-y divide-gray-200 bg-white">
                {candidateList?.data
                  .map(
                    (item) => item
                    // searchTerm.toLowerCase() === ''
                    //   ? item
                    //   : item.fullName
                    //       .toLowerCase()
                    //       .includes(searchTerm.toLowerCase())
                  )
                  .map((cand) => (
                    <tr key={cand.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-10">
                        {cand.fullName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {cand.experience || 0}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cand.current_salary || '-'}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cand.salary_expectation || '-'}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {cand.current_company || '-'}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {cand.notice_period || '-'}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {cand.current_designation || '-'}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {cand.screening_approval_status == 1 ? (
                          <div className="flex justify-center">
                            <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                              {'Pushed to Interview'}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {/* <div>
                          {selectCurrentUserId} ==
                          {candidateList?.signoffUsers[0]?.user_id}
                        </div> */}
                        <div className=" self-center">
                          {/* {cand.screening_approval_status != 1 ? ( */}
                          <PencilIcon
                            className="ml-1 mr-2 h-5 w-5 text-purple-500 cursor-pointer"
                            aria-hidden="true"
                            onClick={() =>
                              gotoScreeningList('viewCandidate', cand)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>No Data Found</tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default CandidateList;

import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/images/logo.jpg';
import Signupbg from '../../assets/images/signupbg.jpg';
import { Form, Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  MagnifyingGlassIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid';
import {} from '@heroicons/react/24/outline';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setRegister } from '../../features/auth/register/registerSlice';
import { useRegisterApiMutation } from '../../features/auth/register/registerApiSlice';

const Register = () => {
  const notificationMethods = [
    { id: 'agency', title: 'Staffing Agency' },
    { id: 'corporate', title: "Corporate HR's" },
  ];
  const lowercaseRegex = /(?=.*[a-z])/;

  const uppercaseRegex = /(?=.*[A-Z])/;

  const numericRegex = /(?=.*[0-9])/;

  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [registerApi, { isLoading }] = useRegisterApiMutation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      company_name: '',
      email: '',
      password: '',
      full_name: '',
      mobile: '',
      dialCode: '+91',
      account_type_id: 1,
    },

    validationSchema: yup.object({
      company_name: yup.string().required('Please enter the company name'),
      full_name: yup.string().required('Please enter the full name'),
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('Please enter your email'),
      password: yup
        .string()
        .required('Please enter the password')
        .matches(lowercaseRegex, 'One lowercase required!')
        .matches(uppercaseRegex, 'One uppercase required!')
        .matches(numericRegex, 'One number required!')
        .min(8, 'Minimum 8 characters required!'),

      mobile: yup
        .string()
        .required('Please enter a phone number')
        .max(10, 'Maximum 10 numbers only'),
    }),

    onSubmit: async (validationSchema) => {
      try {
        const registerData = await registerApi(validationSchema).unwrap();
        dispatch(
          setRegister({
            ...registerData,
            email: formik.values.email,
            fullname: formik.values.full_name,
          })
        );
        navigate('/accountverify');
      } catch (error) {
        dispatch(
          setRegister({
            ...formik.values.email,
            ...formik.values.full_name,
          })
        );
        if (!error) {
          setErrMsg('No Server Response');
        } else if (error.status === 300) {
          setErrMsg(error.data.message);
        } else if (error.status === 500) {
          setErrMsg(`${error.data.message} - Phone number Exist`);
        } else if (error.status === 404) {
          setErrMsg(error.data.message);
        } else {
          setErrMsg(`Register Failed - ${error.status}: ${error.data.message}`);
        }
      }
    },
  });

  useEffect(() => {
    setErrMsg('');
    return () => {};
  }, [formik.values.email, formik.values.mobile]);

  return (
    <>
      <div className="flex min-h-full h-screen">
        <div className="flex flex-1 flex-col w-1/2 justify-center bg-gradient-to-r from-cyan-500 to-blue-500 ">
          {' '}
          {/* max-h-full 2xl:h-screen */}
          <div className="mx-auto my-10 lg:w-3/5 2xl:w-3/6 lg:w-100 bg-white p-8 rounded-xl ">
            <h2 className="mb-3 text-3xl text-center font-bold tracking-tight text-gray-900">
              Are you a recruiter or a hiring manager?
            </h2>

            <h5 className="mb-3 text-center font-bold tracking-tight text-gray-900">
              Create an Account
            </h5>
            <div className="mt-2 text-sm text-red-600">{errMsg}</div>
            <form
              action="#"
              method="POST"
              className="xl:space-y-4 2xl:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <fieldset className="mt-4 flex justify-center">
                  <legend className="sr-only">Hiring method</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {notificationMethods.map((notificationMethod) => (
                      <div
                        key={notificationMethod.id}
                        className="flex items-center"
                      >
                        <input
                          id={notificationMethod.id}
                          name="notification-method"
                          type="radio"
                          defaultChecked={notificationMethod.id === 'corporate'}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 "
                        />
                        <label
                          htmlFor={notificationMethod.id}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {notificationMethod.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="space-y-1">
                <label htmlFor="company_name" className="sr-only">
                  Company Name
                </label>
                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  className="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 placeholder-gray-600 shadow-sm focus:outline-none focus:ring-purplecolor-100 focus:ring-1 sm:text-sm"
                  placeholder="Company Name *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_name}
                />
                {formik.touched.company_name && formik.errors.company_name ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.company_name}
                  </div>
                ) : null}
              </div>

              <div className="space-y-1">
                <label htmlFor="full_name" className="sr-only">
                  Full Name
                </label>
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  className="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 placeholder-gray-600 shadow-sm focus:outline-none focus:ring-purplecolor-100 focus:ring-1 sm:text-sm"
                  placeholder="Full Name *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.full_name}
                />
                {formik.touched.full_name && formik.errors.full_name ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.full_name}
                  </div>
                ) : null}
              </div>

              <div className="space-y-1">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 placeholder-gray-600 shadow-sm focus:outline-none focus:ring-purplecolor-100 focus:ring-1 sm:text-sm"
                  placeholder="Working Email Id *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className="space-y-1 relative">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  // type="password"
                  type={showPassword ? 'type' : 'password'}
                  name="password"
                  id="password"
                  maxLength={12}
                  className="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 placeholder-gray-600 shadow-sm focus:outline-none focus:ring-purplecolor-100 focus:ring-1 sm:text-sm"
                  placeholder="Password *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.values.password && (
                  <div
                    className=" cursor-pointer absolute top-1 right-0  items-center pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeIcon
                        className="h-5 w-5 text-clickhere"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                )}
                {formik.touched.password && formik.errors.password ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <div>
                <label htmlFor="mobile" className="sr-only">
                  Phone Number
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute pl-3 pt-1.5">
                    <span className="text-gray-500  sm:text-sm ">+91</span>
                  </div>

                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    placeholder="Phone Number *"
                    className="block w-full appearance-none rounded-md border border-gray-400 pl-16  px-3 py-2 placeholder-gray-600 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.mobile}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="space-y-1">
                <p className="font-medium text-black mt-4 text-xs">
                  By signing up for our service, you agree to our{' '}
                  <Link
                    to={'/termscondition'}
                    className="font-medium text-indigo-600 mt-4 text-xs"
                  >
                    Terms & Conditions
                  </Link>{' '}
                  and acknowledge that you have read our{' '}
                  <Link
                    to={'/privacypolicy'}
                    className="font-medium text-indigo-600 text-xs"
                  >
                    Privacy Policy.
                  </Link>
                </p>
              </div>

              <div>
                {isLoading ? (
                  <button
                    type="submit"
                    disabled
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm focus:outline-none "
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={!(formik.dirty && formik.isValid)}
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none "
                  >
                    Sign Up
                  </button>
                )}
              </div>
            </form>
            <p className="mt-3 text-sm font-medium text-black text-center">
              Does your company alreay use Recruitee?{' '}
              <a
                href="/login"
                className="font-medium text-indigo-600 mt-4 text-sm"
              >
                Log in
              </a>
            </p>
          </div>
        </div>

        <div className="flex flex-1 flex-col w-1/2 justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="flex justify-center">
            <img className="" src={Logo} alt="Rsaas" />
          </div>
          <div className="mt-12 flex justify-center">
            <img className="w-auto" src={Signupbg} alt="Rsaas" />
          </div>
          <p className="mt-12 text-md text-black font-semibold text-center">
            Skillstrove offers a configurable recruitment management system for
            small and medium enterprises. Our recruiting software helps you
            streamline your end to end hiring process, right from requisition to
            offer.{' '}
          </p>
        </div>
      </div>
    </>
  );
};

export default Register;

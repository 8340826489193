import React, { useState, useRef, useEffect } from 'react';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  useTeamsQuery,
  useTeamsSignoffAdminQuery,
  useTeamsTaskQuery,
} from '../././../features/teams/teamsApiSlice';
import Notification from '../../components/common/Notification';
import {
  useHiringStagesQuery,
  useWorkflowQuery,
  useUpdateTaskMutation,
} from '../../features/workflow/workflowApiSlice';

// import uuid from 'uuid/v4';
import { v4 as uuid } from 'uuid';

import styled from 'styled-components';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  const found = destClone.some((el) => el.userid == item.userid);
  if (!found) {
    destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  }

  return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const Content = styled.div`
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  padding: 0.5rem;
  margin: 10px;
  align-items: flex-start;
  align-text: center;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

const Handle = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  color: #000;
`;

const List = styled.div`
  border: 1px
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
  background: #fff;
  padding: 0.5rem 0.5rem 0;
  border-radius: 3px;
  flex: 0 0 150px;
`;

const Kiosk = styled(List)`
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // width: 200px;
`;

const Container = styled(List)`
  margin: 0 0.5rem 0.5rem;
  display: flex;
  overflow-x: auto;
  border: 2px dashed #d6d6d6;
  background: #f7f7f7;
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const AssignWorkflowTask = () => {
  let { id } = useParams();
  const { data: usersSignoff, isLoading: userSignoffLoading } =
  useTeamsSignoffAdminQuery({ refetchOnMountOrArgChange: true });
  console.log(usersSignoff)
  // const getSignoffUSer = usersSignoff?.data?.map((user) => {
  //   let numberArray = user.privileges.split(',');
  //   if (numberArray.indexOf('4') !== -1) {
  //     return user;
  //   }
  // });
  const getSignoffUSer = usersSignoff?.data
  ?.filter((user) => {
    let numberArray = user.privileges.split(',');
    return numberArray.indexOf('5') !== -1;
  });

  console.log(getSignoffUSer)
  const inputElement = useRef();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [approvedBy, setApprovedBy] = useState(0);
  const [updateTask] = useUpdateTaskMutation();
  const { data: users, isLoading: userLoading } = useTeamsQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: usersTask, isLoading: userTaskLoading } = useTeamsTaskQuery({
    refetchOnMountOrArgChange: true,
  });

  // console.log(usersTask)
  const getUSerTask = usersTask?.data
  ?.filter((user) => {
    let numberArray = user.privileges.split(',');
    return numberArray.indexOf('3') !== -1;
  });
  // console.log(getUSerTask)

  const { data: hiringStage, isLoading: hiringLoading } = useHiringStagesQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: workflow, isLoading: wfLoading } = useWorkflowQuery({
    refetchOnMountOrArgChange: true,
  });

  const [inputFields, setInputFields] = useState({});
  const [data, setData] = useState({});

  const workflowStates = [
    'Job Desc',
    'Skill Capture',
    'Sourcing',
    'Screening',
    'Interview',
    'Offer',
    'Joining',
    'Handover',
  ];

  const workflowIDValue = workflow?.data?.filter((wf) => wf.id == id);

  const hiringStageArray = workflowIDValue[0]?.hiringStageDetails;

  const WFSTAGEARRAY = hiringStageArray.flatMap((item) => {
    if (item.hiringStageId === 5 && item.interviewDetails) {
      return item.interviewDetails.map((interview) => ({
        id: item.id,
        hiringStageId: item.hiringStageId,
        interviewId: interview.id,
        hiringStageTitle: `Interview - ${interview.title}`,
      }));
    } else {
      return {
        id: item.id,
        hiringStageId: item.hiringStageId,
        hiringStageTitle: item.hiringStageTitle,
      };
    }
  });

  const handleFormChange = (index, event) => {
    const { name, value } = event.target;

    const limit = 2;
    // setNum(event.target.value.slice(0, limit));
    if (isNaN(name)) {
      inputFields[name] = value.slice(0, limit);
      setInputFields({ ...inputFields });
    }
  };

  const ITEMS = getUSerTask?.map((user) => ({
    id: `${user.id}`,
    content: `${user.first_name} ${user.last_name}`,
    userid: `${user.id}`,
  }));

  const [state, setState] = useState({});

  useEffect(() => {
    const a = WFSTAGEARRAY.length;
    const boxes = {};
    for (let i = 1; i <= a; i++) {
      boxes[`box${i}`] = [];
    }

    setState(boxes);
  }, []);

  const handleDelete = (e, itemId, droppableBox) => {
    e.preventDefault();
    const newItems = state[droppableBox].filter((item) => item.id !== itemId);

    setState((prevState) => ({ ...prevState, [droppableBox]: newItems }));
    // setState({ ...state, newItems });
  };
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        setState((prevState) => ({
          ...prevState,
          [destination.droppableId]: reorder(
            state[source.droppableId],
            source.index,
            destination.index
          ),
        }));
        break;
      case 'ITEMS':
        setState((prevState) => ({
          ...prevState,
          [destination.droppableId]: copy(
            ITEMS,
            prevState[destination.droppableId],
            source,
            destination
          ),
        }));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          ...move(
            prevState[source.droppableId],
            prevState[destination.droppableId],
            source,
            destination
          ),
        }));
        break;
    }
  };

  const addList = () => {
    setState((prevState) => ({ ...prevState, [uuid()]: [] }));
  };

  const submitAssign = async () => {
    var array3 = [];

    WFSTAGEARRAY.forEach((stage, i) => {
      var taskKey = 'task' + stage.hiringStageId;

      var stageData = {
        id: stage.id,
        hiring_Stage_id: stage.hiringStageId,
        task_duration: parseInt(inputFields[`task${i}`]),
        taskUsers: state[`box${i + 1}`]?.map((val) => parseInt(val.userid)),
        interview_details: [],
      };

      if (stage.hiringStageId === 5) {
        var interviewId = stage.interviewId;
        if (inputFields.hasOwnProperty(`task${i}`)) {
          var taskDuration = parseInt(inputFields[`task${i}`]);
          stageData.task_duration = taskDuration;

          var existingStage = array3.find((item) => item.hiring_Stage_id === 5);
          if (existingStage) {
            existingStage.interview_details.push({
              id: interviewId,
              task_duration: taskDuration,
              taskUsers: state[`box${i + 1}`]?.map((val) =>
                parseInt(val.userid)
              ),
            });
          } else {
            stageData.interview_details.push({
              id: interviewId,
              task_duration: taskDuration,
              taskUsers: state[`box${i + 1}`]?.map((val) =>
                parseInt(val.userid)
              ),
            });
            array3.push(stageData);
          }
        }
      } else {
        array3.push(stageData);
      }
    });

    const serverData = {
      workflow_id: parseInt(id),
      approved_by: parseInt(approvedBy),
      hiring_stages: array3,
    };

    try {
      const accountData = await updateTask(serverData).unwrap();
      toast.success('Assigned successfully');
      // setShowNotification(!showNotification);
      // to={'/app/adminworkspace'}
      navigate(-1);
    } catch (error) { }
  };

  return (
    <>
      {/* <pre>
        {JSON.stringify(JSON.parse(JSON.stringify(workflowIDValue)), null, 2)}
      </pre> */}
      <Notification isShow={showNotification} />
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm font-bold leading-4 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
          {workflowIDValue[0]
            ? `${workflowIDValue[0]?.title} - Workflow`
            : 'Workflow'}
        </p>
        <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
          <Link
            type="button"
            to={'/app/adminworkspace'}
            className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {userLoading ? (
          ' Loading..'
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="relative">
              <div className="bg-white p-4 rounded-md shadow-3xl w-3/12 fixed">
                {/* <p className="text-sm font-bold leading-4 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
          Workflow dfdfd
        </p> */}
                <Droppable
                  droppableId="ITEMS"
                  isDropDisabled={true}
                // direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <Kiosk
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {ITEMS?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                {item.content}
                              </Item>
                              {snapshot.isDragging && (
                                <Clone>{item.content}</Clone>
                              )}
                            </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                    </Kiosk>
                  )}
                </Droppable>
              </div>
            </div>
            <div className="col-span-2 border overflow-y-auto">
              <Content>
                {/* <div className='flex justify-between px-3 shadow-xl py-5'>
                      <div className='self-center'>
                        <p className="block text-sm font-medium text-gray-700 self-center ">Job Recuirement</p>
                      </div>
                      <div className='flex'>
                    <label htmlFor="task" className="block text-sm font-medium text-gray-700 self-center pr-3">
                      Task Duration
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="task"
                        id="task"
                        autoComplete="task"
                        className="block w-40 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                      />
                    </div>
                      </div>

                    </div> */}
                {Object.keys(state).map((list, i) => {
                  return (
                    <div className="bg-white p-1 mb-4">
                      <div>
                        <div className=" py-5">
                          <div className="flex justify-between px-3 ">
                            <div className="self-center">
                              <p className="block text-sm font-medium text-gray-700 self-center ">
                                {/* {!hiringLoading
                                  ? hiringStage.data[i].name
                                  : 'Loading'} */}

                                {WFSTAGEARRAY[i].hiringStageTitle}
                              </p>
                            </div>
                            <div className="flex">
                              <label
                                htmlFor="task"
                                className="block text-sm font-medium text-gray-700 self-center pr-3"
                              >
                                Task Duration
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number"
                                  name={`task${i}`}
                                  id={`task${i}`}
                                  ref={inputElement}
                                  autoComplete="task"
                                  value={inputFields[`task${i}`]}
                                  onKeyDown={(e) => {
                                    if (
                                      e.code === 'NumpadSubtract' ||
                                      e.code === 'Minus' ||
                                      e.code === 'KeyE' ||
                                      e.code === 'Digit0' ||
                                      e.code === 'Numpad0'
                                      // e.target.value > 5
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(event) =>
                                    handleFormChange(i, event)
                                  }
                                  // maxLength={2}
                                  // max={2}
                                  className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none block w-16 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Droppable
                        key={list}
                        droppableId={list}
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <Container
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            class
                          >
                            {/* <div>
                        <p className='text-md font-medium text-gray-700 px-3 flex'>Drag and Drop user here</p>
                      </div> */}

                            {state[list].length ? (
                              state[list].map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Item
                                      // style={{ display: 'flex' }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      isDragging={snapshot.isDragging}
                                    // style={provided.draggableProps.style}
                                    >
                                      {/* <Handle {...provided.dragHandleProps} >
                                          <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
                                    />
                                  </svg>
                                        </Handle> */}

                                      <div className="flex justify-start overflow-x-auto ">
                                        {/* <div className="bg-white py-3 px-4 my-3 flex border border-gray-300 rounded-2xl mx-0 shadow-md mr-3 "> */}
                                        <div className="bg-white py-0 px-0 my-0 flex  mx-0 mr-0 ">
                                          <h5 className="text-sm font-medium tracking-tight text-blue-500 truncate whitespace-nowrap overflow-hidden w-28">
                                            {item.content}
                                          </h5>
                                          <button
                                            onClick={(e) =>
                                              handleDelete(e, item.id, list)
                                            }
                                          >
                                            {/* <XMarkIcon
                                            className=" ml-2 mr-0 h-6 w-6 text-red-500 current-stroke stroke-5 md:stroke-4 "
                                            aria-hidden="true"
                                          /> */}

                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="2"
                                              stroke="red"
                                              className="w-6 h-6 ml-2"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </Item>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <Notice>Drag and Drop user here</Notice>
                            )}
                            {/* !provided.placeholder && (
                                  <Notice>Drop items here</Notice>
                                )} */}
                            {provided.placeholder}
                          </Container>
                        )}
                      </Droppable>
                    </div>
                  );
                })}
              </Content>
            </div>
          </DragDropContext>
        )}
      </div>

      <div className="flex justify-end mt-4 bg-white p-4 rounded-md">
        <div className="flex mr-4 ">
          <label
            htmlFor="approved_by"
            className="block text-sm font-medium text-gray-700 w-full self-center"
          >
            Approved by
          </label>
          <select
            id="approved_by"
            name="approved_by"
            autoComplete="approved_by"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3 w-full focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.approved_by}
            onChange={(e) => setApprovedBy(e.target.value)}
            value={approvedBy}
          >
            <option value={0}>-- Select Approved By--</option>
            {getSignoffUSer?.map((sfUser) => (
              <option key={sfUser.id} value={sfUser.id}>
                {`${sfUser.first_name} ${sfUser.last_name}`}
              </option>
            ))}
          </select>
          {/* {formik.touched.approved_by && formik.errors.approved_by ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.approved_by}
            </div>
          ) : null} */}
        </div>

        <button
          // disabled={!(formik.dirty && formik.isValid)}
          // disabled={Object.keys(inputFields).length !== WFSTAGEARRAY.length}
          onClick={() => submitAssign()}
          type="submit"
          className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default AssignWorkflowTask;

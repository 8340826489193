// import React from 'react';
// import Chart from 'react-apexcharts';

// class BubbleChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.chartDetails = {
//       options: {
//         series: [{
//             name: 'Bubble1',
//             data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
//               min: 10,
//               max: 60
//             })
//           },
//           {
//             name: 'Bubble2',
//             data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
//               min: 10,
//               max: 60
//             })
//           },
//           {
//             name: 'Bubble3',
//             data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
//               min: 10,
//               max: 60
//             })
//           },
//           {
//             name: 'Bubble4',
//             data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
//               min: 10,
//               max: 60
//             })
//           }
//         ],
//         chart: {
//           height: 350,
//           type: 'bubble',
//         },
//         dataLabels: {
//           enabled: false
//         },
//         fill: {
//           opacity: 0.8
//         },
//         title: {
//           text: 'Simple Bubble Chart'
//         },
//         xaxis: {
//           tickAmount: 12,
//           type: 'category',
//         },
//         yaxis: {
//           max: 70
//         }
//       }
//     };
//   }

//   generateData = (baseval, count, yrange) => {
//     var i = 0;
//     var series = [];
//     while (i < count) {
//       var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
//       var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
//       var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

//       series.push([x, y, z]);
//       baseval += 86400000;
//       i++;
//     }
//     return series;
//   };

//   render() {
//     return (
//       <div id="chart">
//         <Chart options={this.chartDetails.options} series={this.chartDetails.options.series} type="bubble" height={350} />
//       </div>
//     );
//   }
// }

// export default BubbleChart;

// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const BubbleChart = () => {
//     const jobRequest = [
//         {city:'Chennai',
//             jobRole: {
//                 Finance: 2,
//                 Purchase: 0,
//                 HR: 5,
//                 Manager: 0,
//                 Sales: 1,
//                 Admin: 0
//             }
//         },
//         {city:'Bangalore',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 6,
//                 HR: 0,
//                 Manager: 1,
//                 Sales: 5,
//                 Admin: 0
//             }
//         },
//         {city:'Pune',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 2,
//                 HR: 0,
//                 Manager: 7,
//                 Sales: 0,
//                 Admin: 5
//             }
//         },
//         {city:'Delhi',
//             jobRole: {
//                 Finance: 2,
//                 Purchase: 0,
//                 HR: 1,
//                 Manager: 0,
//                 Sales: 5,
//                 Admin: 0
//             }
//         },
//         {city:'Mumbai',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 3,
//                 HR: 0,
//                 Manager: 4,
//                 Sales: 0,
//                 Admin: 0
//             }
//         },
//         {city:'Kochi',
//             jobRole: {
//                 Finance: 1,
//                 Purchase: 0,
//                 HR: 4,
//                 Manager: 0,
//                 Sales: 0,
//                 Admin: 2
//             }
//         },
//     ]

//     //format jobrequest data into series format
//     const series = Object.keys(jobRequest[0].jobRole).map(role => ({
//         name: role,
//         data: jobRequest.map(item => ({
//             x: item.city,
//             y: role,
//             z: item.jobRole[role]
//         }))
//     }));

//     const options = {
//         chart: {
//             height: 350,
//             type: 'bubble',
//             toolbar: {
//                 show: false
//             }
//         },
//         dataLabels: {
//             enabled: false
//         },
//         fill: {
//             opacity: 0.8,
//             colors: ['#FF6347', '#36A2EB', '#FFD700', '#FFA500']
//         },
//         title: {
//             text: 'Bubble Chart Example',
//             align: 'left'
//         },
//         xaxis: {
//             type: 'category'
//         },
//         yaxis: {
//             type: 'category'
//         },
//         plotOptions: {
//             bubble: {
//                 hover: {
//                     sizeOffset: 0
//                 }
//             }
//         }
//     };

// //     const chartAreaBackground = {
// //       id:'chartAreaBackground',
// //       beforeDatasetsDraw(chart,args,plugins){
// //           const {ctx, chartArea: {top,bottom,left,right,width,height}} = chart;
// //           ctx.save();
// //           ctx.fillStyle = 'indigo';
// //           ctx.fillRect(left,top,width,height);
// //           ctx.restore();
// //       }
// //   }

// // plugins={[chartAreaBackground]}

//     return (
//         <div>
//             <ReactApexChart options={options} series={series} type="bubble" height={350} />
//         </div>
//     );
// };

// export default BubbleChart;

// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const BubbleChart = () => {
//     const jobRequest = [
//         {city:'Chennai',
//             jobRole: {
//                 Finance: 2,
//                 Purchase: 0,
//                 HR: 5,
//                 Manager: 0,
//                 Sales: 1,
//                 Admin: 0
//             }
//         },
//         {city:'Bangalore',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 6,
//                 HR: 0,
//                 Manager: 1,
//                 Sales: 5,
//                 Admin: 0
//             }
//         },
//         {city:'Pune',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 2,
//                 HR: 0,
//                 Manager: 7,
//                 Sales: 0,
//                 Admin: 5
//             }
//         },
//         {city:'Delhi',
//             jobRole: {
//                 Finance: 2,
//                 Purchase: 0,
//                 HR: 1,
//                 Manager: 0,
//                 Sales: 5,
//                 Admin: 0
//             }
//         },
//         {city:'Mumbai',
//             jobRole: {
//                 Finance: 0,
//                 Purchase: 3,
//                 HR: 0,
//                 Manager: 4,
//                 Sales: 0,
//                 Admin: 0
//             }
//         },
//         {city:'Kochi',
//             jobRole: {
//                 Finance: 1,
//                 Purchase: 0,
//                 HR: 4,
//                 Manager: 0,
//                 Sales: 0,
//                 Admin: 2
//             }
//         },
//     ];

//     // Format jobRequest data into series format
//     const series = Object.keys(jobRequest[0].jobRole).map(role => ({
//         name: role,
//         data: jobRequest.map(item => ({
//             x: item.city,
//             y: role,
//             z: item.jobRole[role]
//         }))
//     }));

//     const options = {
//         chart: {
//             height: 350,
//             type: 'bubble',
//             toolbar: {
//                 show: false
//             },
//             fontFamily:'Poppins',//set font family for all text elements
//             foreColor: '#FFFFFF', //set color for all text elements to white
//         },
//         dataLabels: {
//             enabled: false
//         },
//         fill: {
//             opacity: 0.8,
//             colors: ['#FF6347', '#36A2EB', '#FFD700', '#FFA500']
//         },
//         title: {
//             text: 'Bubble Chart Example',
//             align: 'left'
//         },
//         xaxis: {
//             type: 'category',
//             gridLines: {
//                 show: true,
//                 borderColor: '#FFFFFF',
//                 strokeDashArray: 4,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         yaxis: {
//             type: 'category',
//             gridLines: {
//                 show: true,
//                 borderColor: '#FFFFFF',
//                 strokeDashArray: 4,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         plotOptions: {
//             bubble: {
//                 hover: {
//                     sizeOffset: 0
//                 }
//             }
//         },
//         grid: {
//             show: false, // Hide the main grid lines
//         },
//     };

//     return (
//         <div>
//             <ReactApexChart options={options} series={series} type="bubble" height={350} />
//         </div>
//     );
// };

// export default BubbleChart;

// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const BubbleChart = () => {
//     const jobRequest = [
//         {city:'Chennai',jobRole: { Finance: 2,Purchase: 0,HR: 5,Manager: 0,Sales: 1,Admin: 0 }},
//         {city:'Bangalore',jobRole: { Finance: 0,Purchase: 6,HR: 0,Manager: 1,Sales: 5,Admin: 0 }},
//         {city:'Pune',jobRole: { Finance: 0,Purchase: 2,HR: 0,Manager: 7,Sales: 0,Admin: 5 }},
//         {city:'Delhi',jobRole: { Finance: 2,Purchase: 0,HR: 1,Manager: 0,Sales: 5,Admin: 0 }},
//         {city:'Mumbai',jobRole: { Finance: 0,Purchase: 3,HR: 0,Manager: 4,Sales: 0,Admin: 0 }},
//         {city:'Kochi',jobRole: { Finance: 1,Purchase: 0,HR: 4,Manager: 0,Sales: 0,Admin: 2 }},
//     ];

//     //Extract unique city names for x-axis categories
//     const cities = jobRequest.map(item => item.city);
//     //Extract unique job roles for y-axis categories
//     const jobRoles = Object.keys(jobRequest[0].jobRole);

//     // Format jobRequest data into series format
//     const series = Object.keys(jobRequest[0].jobRole).map(role => ({
//         name: role,
//         data: jobRequest.map(item => ({
//             x: item.city,
//             y: role,
//             z: item.jobRole[role]
//         }))
//     }));

//     // Log the series object to the console
// console.log('Formatted series data:', series);

//     //prepare data for bubble chart
//     // const bubbleData = [];
//     // jobRequest.forEach(entry => {
//     //     Object.entries(entry.jobRole).forEach(([jobRole, value]) => {
//     //         bubbleData.push({x:entry.city, y:jobRole})
//     //     })
//     // })

//     const options = {
//         chart: {
//             height: 350,
//             type: 'bubble',
//             toolbar: {
//                 show: false
//             },
//             fontFamily:'Poppins',//set font family for all text elements
//             foreColor: '#FF6347', //set color for all text elements to white
//         },
//         dataLabels: {
//             enabled: false
//         },
//         fill: {
//             opacity: 0.8,
//             colors: ['#FF6347', '#36A2EB', '#FFD700', '#FFA500']
//         },
//         title: {
//             text: 'Job Request',
//             align: 'left'
//         },
//         xaxis: {
//             type: 'category',
//             categories: ['Chennai','Bangalore','Pune',''],
//             gridLines: {
//                 show: true,
//                 borderColor: '#FFFFFF',
//                 strokeDashArray: 4,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         yaxis: {
//             type: 'category',
//             categories: jobRoles,
//             gridLines: {
//                 show: true,
//                 borderColor: '#FFFFFF',
//                 strokeDashArray: 4,
//                 offsetX: 0,
//                 offsetY: 0
//             },
//         },
//         plotOptions: {
//             bubble: {
//                 hover: {
//                     sizeOffset: 0
//                 }
//             }
//         },
//         grid: {
//             show: false, // Hide the main grid lines
//         },
//         tooltip: {
//             x: {
//                 show: true,
//                 formatter: function (val) {
//                     return val;
//                 }
//             },
//             y: {
//                 show: true,
//                 formatter: function (val) {
//                     return val;
//                 }
//             },
//             z: {
//                 title: 'Requests'
//             }
//         }
//     };

//     return (
//         <div>
//             <ReactApexChart options={options} series={series} type="bubble" height={350} />
//         </div>
//     );
// };

// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const BubbleChart = () => {
//     const jobRequest = [
//         {city: 'Chennai', jobRole: 'Finance', value: 2},
//         {city: 'Chennai', jobRole: 'Purchase', value: 0},
//         {city: 'Bangalore', jobRole: 'Finance', value: 3},
//         {city: 'Bangalore', jobRole: 'Purchase', value: 4},
//         {city: 'Pune', jobRole: 'Finance', value: 3},
//     ];

//     // Extract unique city names and job roles for axis categories
//     const cities = [...new Set(jobRequest.map(item => item.city))];
//     const jobRoles = [...new Set(jobRequest.map(item => item.jobRole))];

//     // Transform jobRequest data into series format for ApexCharts
//     const series = jobRoles.map((role, roleIndex) => ({
//         name: role,
//         data: jobRequest
//             .filter(item => item.jobRole === role)
//             .map((item) => ({
//                 x: cities.indexOf(item.city) + 1,
//                 y: roleIndex + 1,
//                 z: item.value
//             }))
//     }));

//     const options = {
//         chart: {
//             height: 350,
//             type: 'bubble',
//             toolbar: {
//                 show: false
//             },
//             fontFamily: 'Poppins',
//             foreColor: '#FFFFFF',
//             // background: '#2E2E3A'
//         },
//         dataLabels: {
//             enabled: false
//         },
//         fill: {
//             opacity: 0.8,
//             colors: ['#FF6347', '#36A2EB', '#FFD700', '#FFA500', '#8A2BE2', '#00FA9A']
//         },
//         title: {
//             text: 'Job Request',
//             align: 'left',
//             style: {
//                 color: '#FFFFFF'
//             }
//         },
//         xaxis: {
//             type: 'category',
//             categories: cities,
//             labels: {
//                 formatter: (value) => cities[value - 1]
//             },
//             title: {
//                 text: 'City',
//                 style: {
//                     color: '#FFFFFF'
//                 }
//             },
//             axisBorder: {
//                 show: true,
//                 color: '#FFFFFF',
//             },
//             axisTicks: {
//                 show: true,
//                 color: '#FFFFFF',
//             },
//             tickAmount: cities.length - 1,
//         },
//         yaxis: {
//             type: 'category',
//             categories: jobRoles,
//             labels: {
//                 formatter: (value) => jobRoles[value - 1]
//             },
//             title: {
//                 text: 'Department',
//                 style: {
//                     color: '#FFFFFF'
//                 }
//             },
//             axisBorder: {
//                 show: true,
//                 color: '#FFFFFF',
//             },
//             axisTicks: {
//                 show: true,
//                 color: '#FFFFFF',
//             },
//             tickAmount: jobRoles.length - 1,
//         },
//         plotOptions: {
//             bubble: {
//                 minBubbleRadius: 5,
//                 maxBubbleRadius: 20
//             }
//         },
//         // tooltip: {
//         //     custom: function({ series, seriesIndex, dataPointIndex, w }) {
//         //         const data = w.config.series[seriesIndex].data[dataPointIndex];
//         //         return `<div>
//         //             <strong>City:</strong> ${cities[data.x - 1]}<br>
//         //             <strong>Department:</strong> ${jobRoles[data.y - 1]}<br>
//         //             <strong>Requests:</strong> ${data.z}
//         //         </div>`;
//         //     }
//         // },
//         grid: {
//             borderColor: '#555',
//             strokeDashArray: 4,
//         }
//     };

//     return (
//         <div>
//             <ReactApexChart options={options} series={series} type="bubble" height={350} width={380} />
//         </div>
//     );
// };

// export default BubbleChart;

// import React from 'react';
// import {
//   XYPlot,
//   XAxis,
//   YAxis,
//   VerticalGridLines,
//   HorizontalGridLines,
//   MarkSeries,
//   Hint
// } from 'react-vis';
// import 'react-vis/dist/style.css';

// const BubbleChart = () => {
//   const jobRequest = [
//     {city: 'Chennai', jobRole: 'Finance', value: 2},
//     {city: 'Chennai', jobRole: 'Purchase', value: 0},
//     {city: 'Bangalore', jobRole: 'Finance', value: 3},
//     {city: 'Bangalore', jobRole: 'Purchase', value: 4},
//     {city: 'Pune', jobRole: 'Finance', value: 3},

//   ];

//   const cities = [...new Set(jobRequest.map(item => item.city))];
//   const jobRoles = [...new Set(jobRequest.map(item => item.jobRole))];

//   const data = jobRequest.map(item => ({
//     x: cities.indexOf(item.city),
//     y: jobRoles.indexOf(item.jobRole),
//     size: item.value,
//     city: item.city,
//     jobRole: item.jobRole,
//     value: item.value
//   }));

//   const [hintValue, setHintValue] = React.useState(null);

//   return (
//     <>
//     <XYPlot
//       xDomain={[0, cities.length - 1]}
//       yDomain={[0, jobRoles.length - 1]}
//       width={380}
//       height={400}
//     >
//       <VerticalGridLines />
//       <HorizontalGridLines />
//       <XAxis
//         tickFormat={v => cities[v]}
//         title="City"
//       />
//       <YAxis
//         tickFormat={v => jobRoles[v]}
//         title="Department"
//       />
//       <MarkSeries
//         className="mark-series-example"
//         sizeRange={[5, 20]}
//         data={data}
//         onValueMouseOver={v => setHintValue(v)}
//         onValueMouseOut={() => setHintValue(null)}
//         color="#FF6347"
//       />
//       {hintValue && (
//         <Hint value={hintValue}>
//           <div style={{ background: 'black', color: 'white', padding: '5px' }}>
//             <p><strong>City:</strong> {hintValue.city}</p>
//             <p><strong>Department:</strong> {hintValue.jobRole}</p>
//             <p><strong>Requests:</strong> {hintValue.value}</p>
//           </div>
//         </Hint>
//       )}
//     </XYPlot>
//     </>
//   );
// };

// export default BubbleChart;

// import React from 'react';
// import {
//   XYPlot,
//   XAxis,
//   YAxis,
//   VerticalGridLines,
//   HorizontalGridLines,
//   MarkSeries,
//   Hint
// } from 'react-vis';
// import 'react-vis/dist/style.css';

// const BubbleChart = () => {
//   const jobRequest = [
//     {city: 'Chennai', jobRole: 'Finance', value: 2},
//     {city: 'Chennai', jobRole: 'Purchase', value: 0},
//     {city: 'Bangalore', jobRole: 'Finance', value: 3},
//     {city: 'Bangalore', jobRole: 'Purchase', value: 4},
//     {city: 'Pune', jobRole: 'Finance', value: 3},
//   ];

//   const cities = [...new Set(jobRequest.map(item => item.city))];
//   const jobRoles = [...new Set(jobRequest.map(item => item.jobRole))];

//   const data = jobRequest.map(item => ({
//     x: cities.indexOf(item.city) + 1,
//     y: jobRoles.indexOf(item.jobRole) + 1,
//     size: item.value,
//     city: item.city,
//     jobRole: item.jobRole,
//     value: item.value
//   }));

//   const [hintValue, setHintValue] = React.useState(null);

//   return (
//     <XYPlot
//       xDomain={[0, cities.length ]} // Adjust domain to center ticks between grid lines
//       yDomain={[0, jobRoles.length ]} // Adjust domain to center ticks between grid lines
//       width={600}
//       height={400}
//     >
//       <VerticalGridLines />
//       <HorizontalGridLines />
//       <XAxis
//         tickValues={Array.from({ length: cities.length }, (_, i) => i + 1)} // Generate tick values starting from 1
//         tickFormat={v => cities[v - 1]} // Map tick values to city names
//         title="City"
//       />
//       <YAxis
//         tickValues={Array.from({ length: jobRoles.length }, (_, i) => i + 1)} // Generate tick values starting from 1
//         tickFormat={v => jobRoles[v - 1]} // Map tick values to job roles
//         title="Department"
//       />
//       <MarkSeries
//         className="mark-series-example"
//         sizeRange={[5, 20]}
//         data={data}
//         onValueMouseOver={v => setHintValue(v)}
//         onValueMouseOut={() => setHintValue(null)}
//         color="#FF6347"
//       />
//       {hintValue && (
//         <Hint value={hintValue}>
//           <div style={{ background: 'black', color: 'white', padding: '5px' }}>
//             <p><strong>City:</strong> {hintValue.city}</p>
//             <p><strong>Department:</strong> {hintValue.jobRole}</p>
//             <p><strong>Requests:</strong> {hintValue.value}</p>
//           </div>
//         </Hint>
//       )}
//     </XYPlot>
//   );
// };

// export default BubbleChart;

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BubbleChartForTwo = () => {
  const joining = [
    { name: 'UI/UX Designer', value1: 0, value2: 10, value3: 0 },
    { name: 'Senior Manager', value1: 0, value2: 0, value3: 10 },
    { name: 'Accpuntant', value1: 0, value2: 20, value3: 0 },
    { name: 'Java Developer', value1: 30, value2: 0, value3: 0 },
    { name: 'Software Engineer', value1: 0, value2: 0, value3: 65 },
    { name: 'Anroid Developer', value1: 0, value2: 40, value3: 0 },
  ];

  const joiningDataSeries = ['On Track', 'Over Due', 'At Risk'];

  const seriesData = joiningDataSeries.map((seriesName, index) => {
    return {
      name: seriesName,
      data: joining.map((job) => {
        return {
          x: job.name,
          y: job[`value${index + 1}`],
          z: job[`value${index + 1}`], // Using the same value for the bubble size
        };
      }),
    };
  });

  const optionsd = {
    chart: {
      height: 350,
      type: 'bubble',
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      title: {
        text: 'Value',
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.8,
    },
    title: {
      text: 'Bubble Chart for Joining Data',
      align: 'left',
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
  };

  const options = {
    chart: {
      type: 'bubble',
      height: 350,
      stacked: true,
      fontFamily: 'Poppins', //set font family for all text elements
      foreColor: '#FFFFFF', //set color for all text element to white
      toolbar: {
        show: false, //Hide the toolbar (menu bar)
      },
    },
    plotOptions: {
      bubble: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        bubbleHeight: '40%',
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: true, // Error seems to be here
      // formatter: function (val, opts) {
      //     return opts.w.config.series[opts.seriesIndex]; //Display value
      // },
      style: {
        fontSize: '15px',
        fontFamily: 'Poppins',
        colors: ['#FFFFFF'], //color of the data labels
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },

    title: {
      text: 'Job Request Status',
      style: {
        color: '#FFFFFF',
        fontSize: '15px',
        fontFamily: 'Poppins',
        fontWeight: 500,
      },
    },
    xaxis: {
      type: 'category',
      // categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        show: false, //Hide the x-axis labels
      },
      axisBorder: {
        show: true,
        color: '#6234C1', //color of x-axis line
        height: 2,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#6234C1', //color of x-axis line
        width: 2,
      },
    },
    grid: {
      show: true,
      borderColor: '#42297D',
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ['#53BA9E', '#FFC10B', '#FA2C50'],
  };

  return (
    <div style={{ width: '567px' }}>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="bubble"
        height={450}
      />
    </div>
  );
};

export default BubbleChartForTwo;

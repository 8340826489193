// created by lakshmi for job request status - vertical bar chart
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useJobrequestByStatusChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';

const JobRequestStatusColumnChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  //   const [selectedDepartment, setSelectedDepartment] = useState(0);
  //   const [selectedLast30Days, setSelectedLast30Days] = useState(0);

  //   // Define options for countries, branches, departments, and last 30 days
  //   const countryOptions = ['India', 'USA', 'UK'];
  //   const branchOptions = ['Chennai', 'Bangalore', 'Pune'];
  //   const departmentOptions = ['Department1', 'Department2', 'Department3'];
  //   const last30DaysOptions = ['Last 30 days', 'Last month', 'Last Year'];

  const [
    jobRequestStatusChart,
    { data: jobRequestStatus, isLoading: jobRequestStatusLoading },
  ] = useJobrequestByStatusChartMutation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId]);

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setSelectedLocationId(id);
  };

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
    };
    const dataObj = cleanObjectData(data);
    jobRequestStatusChart(dataObj);
  };
  const chartData = jobRequestStatus?.data;

  const chartName = 'Job Request Status';

  const series = [
    {
      name: chartName,
      data: chartData ? chartData.map((item) => item.value) : [],
    },
  ];
  //Find the maximum value in the chart data to set the y-axis max
  const maxValue = Math.max(
    ...(chartData ? chartData.map((item) => item.value) : [0])
  );
  const extraPt = 5; //Adjust this value to control how much xtra we want on the y-axis

  const options = {
    chart: {
      type: 'bar',
      height: 380,
      fontFamily: 'Inter var',
      foreColor: '#000',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30px', //Adjust column width to control spacing
        endingShape: 'flat', //control the shape of the bar
        barHeight: '100%',
        distributed: true, // Distribute the bars evenly
        dataLabels: {
          enabled: true,
          position: 'center',
          style: {
            fontSize: '15px',
            fontFamily: 'Inter var',
            colors: ['#fff'],
          },
        },
      },
    },
    
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        show: true, //Hide the x-axis labels
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        height: 1,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      min: 0, // Ensure the y-axis starts  from 0
      //   max: maxValue + extraPt, //set the maximum value of the y-axis
      max: Math.ceil((maxValue + extraPt) / 20) * 20, //set the maximum value of the y-axis
      tickAmount: Math.ceil((maxValue + extraPt) / 20), // Adjust the number of ticks
      labels: {
        show: true, //Hide the x-axis labels
        formatter: function (val) {
          return val.toFixed(0); // Ensure labels are whole numbers
        },
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#f3f4f6',
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
  };
  return (
    <>
      <div className="flex justify-between  p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          {/* <select
            className=" bg-[#39216B] text-white text-[13px]"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select> */}

          {/* Dropdown for branch */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select> */}

          {/* Dropdown for department */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Department</option>
            {departmentOptions.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select> */}

          {/* Dropdown for last 30 days */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedLast30Days}
            onChange={(e) => setSelectedLast30Days(e.target.value)}
          >
            <option value="">Last 30 Days</option>
            {last30DaysOptions.map((days, index) => (
              <option key={index} value={days}>
                {days}
              </option>
            ))}
          </select> */}
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            isDaysFilter={true}
            showCountry={true}
            showDepartment={true}
            showLocation={true}
          />
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={380}
        width={570}
      />
    </>
  );
};

export default JobRequestStatusColumnChart;

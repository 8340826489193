import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  levelOne: 0,
  levelTwo: 0,
};

const adminWorkspaceSlice = createSlice({
  name: 'adminworkspace',
  initialState: initialState,
  reducers: {
    updateLevelOne: (state, action) => {
      state.levelOne = action.payload;
    },
    updateLevelTwo: (state, action) => {
      state.levelTwo = action.payload;
    },
  },
});

export const { updateLevelOne, updateLevelTwo } = adminWorkspaceSlice.actions; // updateLevelThree
export default adminWorkspaceSlice.reducer;
export const levelOne = (state) => state.adminWorkspace.levelOne;
export const levelTwo = (state) => state.adminWorkspace.levelTwo;

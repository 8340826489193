import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';


// Core viewer
// import { Viewer } from '@react-pdf-viewer/core';

// // // Plugins
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// // // Import styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function CandResume({ resume }) {
  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  console.log('resume = ', resume);
  const docs = [
    { uri: resume}
  ];
  return resume != null ? (
    // <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
    //   <div
    //     style={{
    //       border: '1px solid rgba(0, 0, 0, 0.3)',
    //       height: '750px',
    //     }}
    //   >
    //     <Viewer fileUrl={resume} />
    //   </div>
    // </Worker>
    // <DocViewer
    //   documents={[{ url: resume }]}
    //   pluginRenderers={DocViewerRenderers}
    // />
    <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        config={{
          header: {
            disableHeader: false,
            disableFileName: false,
            retainURLParams: false
          }
        }}
        style={{ height: 500 }}
      />

    // <iframe src={resume} />

  ) : (
    // <Viewer
    //   fileUrl={resume}
    //   plugins={[
    //     // Register plugins
    //     defaultLayoutPluginInstance,
    //   ]}
    // />
    'No resume found'
  );
}

export default CandResume;
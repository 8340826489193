import React, { useState } from 'react';
import {
  // useUpdateCandidateMutation,
  useGetScreenCandDetailsQuery,
  usePushtoInterviewMutation,
} from '../../../../../features/hiringstages/screeningApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

const ScreeningFeedback = ({ setShowNotification, score }) => {
  const [scoreTable, setScoreTable] = useState([]);
  const [pushtoInterview, { isLoading: isPushtoInterview }] =
    usePushtoInterviewMutation();
  const selectedCand = useSelector((data) => data.candidate);

  const {
    data: canddetails,
    error: canddetailsErr,
    isLoading: canddetailsLoading,
    isFetching: canddetailsFetching,
    isSuccess: canddetailsSuccess,
    // data,
  } = useGetScreenCandDetailsQuery(selectedCand, {
    refetchOnMountOrArgChange: true,
  });

  const [isRecommendChecked, setIsRecommendChecked] = useState(
    canddetails?.data.screening_feedback || false
  );

  const initialValues = {
    screening_feedback: canddetails?.data.screening_feedback || '',
    screening_recommendation: isRecommendChecked ? 1 : 0,
    screening_recommendation_comments:
      canddetails?.data.screening_recommendation_comments || '',
    screening_approval_status: 1,
  };

  const validationSchema = yup.object({
    // screening_feedback: yup.string().required('Please enter feedback'),
    // screening_recommendation_comments: yup
    //   .string()
    //   .when('screening_recommendation', {
    //     is: 1,
    //     then: yup.string().required('Please enter comments'),
    //   }),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const updateCandidateData = await pushtoInterview({
        body: validationSchema,
        id: selectedCand,
      }).unwrap();

      setShowNotification(true);
      // onModalDataClose();
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* ... your other form elements ... */}

      <div className="bg-white shadow-lg gap-4 ml-5 rounded-lg mt-5">
        <div className=" border border-gray-100 ">
          <div className="">
            {score >= 70 && (
              <div className="flex justify-end mr-5 my-5">
                <div className="">
                  <button
                    type="submit"
                    className="rounded-xl border bg-green-500 w-full py-2 px-10 text-sm font-medium text-white shadow-sm focus:outline-none"
                    disabled={isPushtoInterview}
                  >
                    {isPushtoInterview ? (
                      <>
                        <svg
                          role="status"
                          className="inline mr-3 w-4 h-4 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {/* ... your loading SVG ... */}
                        </svg>
                        Saving...
                      </>
                    ) : (
                      'Push to Interview'
                    )}
                  </button>
                </div>
              </div>
            )}

            {score >= 50 && score < 70 && (
              <div>
                <div className="mx-5">
                  <h1 className="  font-semibold px-0 text-xl mt-3 pt-3 text-gray-700 pb-4">
                    Screening Feedback
                  </h1>
                  <textarea
                    rows={5}
                    name="screening_feedback"
                    id="screening_feedback"
                    className=" w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.screening_feedback}
                  />
                  {formik.touched.screening_feedback &&
                  formik.errors.screening_feedback ? (
                    <div className="mt-2 text-sm w-full text-red-600">
                      {formik.errors.screening_feedback}
                    </div>
                  ) : null}
                </div>

                <div className="flex h-6 items-center pl-5 py-4">
                  <input
                    id="screening_recommendation"
                    aria-describedby="screening_recommendation"
                    name="screening_recommendation"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                    checked={isRecommendChecked}
                    onChange={() => setIsRecommendChecked(!isRecommendChecked)}
                  />
                  <div className="ml-3 text-md leading-6">
                    <label
                      htmlFor="screening_recommendation"
                      className="font-semibold cursor-pointer"
                    >
                      Recommend for next level
                    </label>
                  </div>
                </div>

                {isRecommendChecked && (
                  <div className="mx-5">
                    <h1 className="  font-semibold px-0 text-xl mt-3 text-gray-700 pb-4">
                      Need Explanation for Recommendation
                    </h1>
                    <textarea
                      rows={5}
                      name="screening_recommendation_comments"
                      id="screening_recommendation_comments"
                      className=" w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.screening_recommendation_comments}
                    />
                    {formik.touched.screening_recommendation_comments &&
                    formik.errors.screening_recommendation_comments ? (
                      <div className="mt-2 text-sm w-full text-red-600">
                        {formik.errors.screening_recommendation_comments}
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="flex justify-end mr-5 my-5">
                  <div className="">
                    <button
                      type="submit"
                      className="rounded-xl border bg-green-500 w-full py-2 px-10 text-sm font-medium text-white shadow-sm focus:outline-none"
                      disabled={isPushtoInterview}
                    >
                      {isPushtoInterview ? (
                        <>
                          <svg
                            role="status"
                            className="inline mr-3 w-4 h-4 text-white animate-spin"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {/* ... your loading SVG ... */}
                          </svg>
                          Saving...
                        </>
                      ) : (
                        'Push to Interview'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {score < 50 && (
              <div className="flex justify-end mr-5 my-5">
                <div className="">
                  <button
                    type="button"
                    className="rounded-xl border bg-red-500 w-full py-2 px-10 text-sm font-medium text-white shadow-sm focus:outline-none"
                    // onClick={onClose}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ScreeningFeedback;

import { apiSlice } from "../../app/api/apiSlice";

export const signoffworkflowApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["SignOffWorkflow"],
  endpoints: (builder) => ({
    workflow: builder.query({
      query: () => ({
        url: "/master/signoffworkflow",
        method: "GET",
      }),
      providesTags: ["SignOffWorkflow"],
      // keepUnusedDataFor: 5,
    }),
    addSignOffWorkflow: builder.mutation({
      query: (data) => ({
        url: "/master/signoffworkflow",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["SignOffWorkflow"],
    }),
    updateSignOffWorkflow: builder.mutation({
      query: (data) => ({
        url: "/master/signoffworkflow",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["SignOffWorkflow"],
    }),
    deleteSignoffWorkflow: builder.mutation({
      query: (id) => ({
        url: `/master/signoffworkflow/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SignOffWorkflow"],
    }),
  }),
});

export const {
  useSignOffWorkflowQuery,
  useAddSignOffWorkflowMutation,
  useUpdateSignOffWorkflowMutation,
  useDeleteSignOffWorkflowMutation,
} = signoffworkflowApiSlice;

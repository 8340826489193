import React from 'react';
import { Link } from 'react-router-dom';

const Settings = () => {
  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-5">
      <div>
        <h1 className="text-lg font-medium leading-6 text-gray-900">General</h1>
        <div className="flex flex-col space-y-3 mt-4">
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/account'}
          >
            Account
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/personal'}
          >
            Personal
          </Link>
        </div>
      </div>

      <div>
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          Master Data
        </h1>
        <div className="flex flex-col space-y-3 mt-4">
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/department'}
          >
            Department
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/designation'}
          >
            Designation
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/branch'}
          >
            Branch
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/location'}
          >
            Location
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/skilltype'}
          >
            Skilltype
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/skill'}
          >
            Skill
          </Link>
        </div>
      </div>

      <div>
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          Privilege settings
        </h1>
        <div className="flex flex-col space-y-3 mt-4">
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/userprivilege'}
          >
            User - Workspace Privilege
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/adminprivilege'}
          >
            Admin - Workspace Privilege
          </Link>
        </div>
      </div>

      <div>
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          Email Template
        </h1>
        <div className="flex flex-col space-y-3 mt-4">
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/emailtemplate'}
          >
            Email Template List
          </Link>
          {/* <Link className="text-clickhere font-medium" to={''}>
            Add Template
          </Link> */}
        </div>
      </div>

      <div>
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          Recruitment Workflow
        </h1>
        <div className="flex flex-col space-y-3 mt-4">
          <Link className="text-clickhere font-medium" to={''}>
            Workflow
          </Link>
          <Link className="text-clickhere font-medium" to={''}>
            Workflow Signoffs
          </Link>
          <Link
            className="text-clickhere font-medium"
            to={'/app/settings/offertemplate'}
          >
            Offer Template
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Settings;

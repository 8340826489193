import { apiSlice } from '../../app/api/apiSlice';

export const countryApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Country'],
  endpoints: (builder) => ({
    country: builder.query({
      query: () => ({
        url: '/master/country',
        method: 'GET',
      }),
      providesTags: ['Country'],
    }),
  }),
});

export const { useCountryQuery } = countryApiSlice;

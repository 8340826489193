import { apiSlice } from '../../app/api/apiSlice';

export const userWSPApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['UserMaster', 'Getuserchecklist'],
  endpoints: (builder) => ({
    userWSP: builder.query({
      query: () => ({
        url: '/master/workspaceprivileges',
        method: 'GET',
      }),
      providesTags: ['UserMaster'],
      keepUnusedDataFor: 5,
    }),
    getuserWSP: builder.query({
      query: (id) => ({
        url: `/settings/userworkspaceprivileges?user_id=${id}`,
        method: 'GET',
      }),
      providesTags: ['Getuserchecklist'],
      keepUnusedDataFor: 5,
    }),
    addUserpriv: builder.mutation({
      query: (data) => ({
        url: '/settings/userworkspaceprivileges',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Getuserchecklist'],
    }),
  }),
});

export const { useUserWSPQuery, useGetuserWSPQuery, useAddUserprivMutation } =
  userWSPApiSlice;

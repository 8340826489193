import React from 'react';
import { useState, Fragment } from 'react';

import { Disclosure } from '@headlessui/react';
import {
  PlusIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import { useCandScreenAlllistQuery } from '../../../../../features/hiringstages/screeningApiSlice';
import { Popover, Transition } from '@headlessui/react';
// import { DocumentIcon } from '@heroicons/react/outline';

const transactions = [
  {
    name: 'Robin Williams',
    exp: '4 Years',
    salary_exp: '10 L',
    notice_prd: '1 Month',
    current_company: 'HCL',
    current_pos: '3D Animator',
  },
  {
    name: 'Theodore',
    exp: '6 Years',
    salary_exp: '12 L',
    notice_prd: '2 Months',
    current_company: 'Capgemini',
    current_pos: '3D Modeler',
  },
  {
    name: 'Dwayne',
    exp: '8 Years',
    salary_exp: '16 L',
    notice_prd: '3 Months',
    current_company: 'TCS',
    current_pos: 'Lead Designer',
  },
  // More transactions...
];

const CandidateList = ({ screeningId }) => {
  const {
    data: candidateList,
    error: candidateListError,
    isLoading: candidateListLoading,
    isFetching: candidateListFetching,
    isSuccess: candidateListSuccess,
  } = useCandScreenAlllistQuery(screeningId, {
    refetchOnMountOrArgChange: true,
  });

  const getRatingPercentage = (techskillsArray) => {
    if (techskillsArray != null) {
      if (techskillsArray.length > 0) {
        const totalRating = techskillsArray.reduce(
          (acc, current) => acc + current.rating,
          0
        );
        const averageRating = totalRating / techskillsArray.length;
        return averageRating * 10;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  if (candidateListLoading) {
    return <div>Loading...</div>;
  }

  if (candidateListSuccess) {
    return (
      <Disclosure.Panel as="dd" className="mt-0 pr-0">
        <p className="text-base leading-7 text-gray-600">
          {/* <h3>Bottom</h3> */}
          <div className="flex">
            <div className=" gap-4 basis-full">
              <div className="bg-white shadow-3xl gap-4 rounded-md ">
                <div className="bg-white rounded-md shadow-3xl border border-gray-100">
                  {/* <Screening /> */}

                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center"></div>

                    <div className="mt-0 mb-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-100 text-left text-md font-bold text-gray-900">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Experience(Years)
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Current Salary
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Notice Period(In Days)
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Current Company
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Current Position
                                  </th>

                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-center"
                                  >
                                    Feedback
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Score
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              {candidateList?.data?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {candidateList?.data?.map((candidate) => (
                                    <tr
                                      key={candidate.id}
                                      className="text-left"
                                    >
                                      <td className="whitespace-nowrap py-2  pr-3 text-sm text-gray-500 sm:pl-5">
                                        {candidate.fullName}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                        {candidate.experience || '-'}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                        {candidate.current_salary || '-'}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {candidate.notice_period || '-'}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {candidate.current_company || '-'}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {candidate.current_designation || '-'}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">
                                        {/* <a
                                        href={candidate.resume}
                                        target="_blank"
                                        title="Edit"
                                      >
                                        <ChatBubbleBottomCenterTextIcon
                                          className=" mt-1 h-10 w-10 text-blue-500 "
                                          aria-hidden="true"
                                          onClick={() => openDrawerWindow()}
                                        />
                                      </a> */}
                                        {(candidate.screening_feedback != '' &&
                                          candidate.screening_feedback !=
                                            null) ||
                                        (candidate.screening_recommendation_comments !=
                                          '' &&
                                          candidate.screening_recommendation_comments !=
                                            null) ? (
                                          <Popover className="relative ml-2">
                                            {({ open }) => (
                                              <>
                                                <Popover.Button>
                                                  <ChatBubbleBottomCenterTextIcon
                                                    className="h-10 w-10 text text-blue-600"
                                                    aria-hidden="true"
                                                  />
                                                </Popover.Button>
                                                <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-200"
                                                  enterFrom="opacity-0 translate-y-1"
                                                  enterTo="opacity-100 translate-y-0"
                                                  leave="transition ease-in duration-150"
                                                  leaveFrom="opacity-100 translate-y-0"
                                                  leaveTo="opacity-0 translate-y-1"
                                                >
                                                  <Popover.Panel className="absolute left-1 mt-3 w-screen max-w-sm -translate-x-3/4 transform px-4 sm:px-0 ">
                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-10">
                                                      <div className="relative bg-white text-left p-4">
                                                        {'Comments'}
                                                        {/* Feedback */}
                                                      </div>
                                                      <div className="bg-gray-50 px-4 py-3 font-normal text-left">
                                                        {candidate.screening_feedback !=
                                                          '' &&
                                                        candidate.screening_feedback !=
                                                          null ? (
                                                          <div>
                                                            <h2 className=" font-bold mb-1">
                                                              Feedback
                                                            </h2>
                                                            <p>
                                                              {
                                                                candidate.screening_feedback
                                                              }
                                                            </p>
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>

                                                      <div>
                                                        <div className="bg-gray-50 px-4 py-3 font-normal text-left">
                                                          {candidate.screening_recommendation_comments !=
                                                            '' &&
                                                          candidate.screening_recommendation_comments !=
                                                            null ? (
                                                            <div>
                                                              <h2 className=" font-bold mb-1">
                                                                Recommendation
                                                              </h2>
                                                              <p>
                                                                {
                                                                  candidate.screening_recommendation_comments
                                                                }
                                                              </p>
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Popover.Panel>
                                                </Transition>
                                              </>
                                            )}
                                          </Popover>
                                        ) : (
                                          <div className="text-center flex justify-center">
                                            <ChatBubbleBottomCenterTextIcon
                                              className="h-10 w-10 text text-gray-300"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td className=" whitespace-nowrap px-5 py-2 text-sm text-gray-500">
                                        <div className="flex items-center mt-1">
                                          <div>
                                            {/* {getSkillPercentage(
                                            candidate.technical_skills
                                          )} */}
                                          </div>

                                          <div className="w-full ">
                                            <div className=" text-gray-700 text-center pb-1">
                                              <span className="text-sm font-semibold">
                                                {getRatingPercentage(
                                                  candidate.technical_skills
                                                ).toFixed(0) + '%'}
                                              </span>
                                            </div>
                                            <div className="w-full h-2 bg-gray-300 rounded-lg">
                                              <div
                                                style={{
                                                  width: `${getRatingPercentage(
                                                    candidate.technical_skills
                                                  )}%`,
                                                }}
                                                className={`h-2 bg-violet-600 rounded-lg`}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                        {/* {candidate.current_designation || '-'} */}
                                        {candidate.screening_approval_status ==
                                        1 ? (
                                          <div className="flex justify-center">
                                            <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                                              {'Pushed to Interview'}
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <td className="p-3">No Data Found</td>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p>
      </Disclosure.Panel>
    );
  }
};

export default CandidateList;

import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import './quill.css';

function Wysiwyg({ data, getEditorData }) {
  const { quill, quillRef } = useQuill();
  const [value, setValue] = useState();

  React.useEffect(() => {
    if (quill) {
      if (data) {
        quill.clipboard.dangerouslyPasteHTML(data);
      }
      quill.on('text-change', () => {
        setValue(quillRef.current.firstChild.innerHTML);
        getEditorData(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill, data]);

  return (
    <div>
      <div>
        <div style={{ background: '#fff' }} ref={quillRef} />
      </div>
    </div>
  );
}
export default Wysiwyg;

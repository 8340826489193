import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';

import { ArrowLeftIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useAddSkillMutation,
  useUpdateSkillMutation,
} from '../features/skill/skillApiSlice';
import Skiltype from '../pages/settings/masterdata/Skilltype';
import { toast } from 'sonner';

const AddSkilltype = ({ data, isEdit, onClose }) => {
  const [addSkill] = useAddSkillMutation();
  const [updateSkill] = useUpdateSkillMutation();

  const [showNotification, setShowNotification] = useState(false);
  const initialValues = {
    // id:data?.id || "",
    // name: "",
    // description: "",
    id: data?.id || '',
    name: data?.name || '',
    description: data?.desc || '',
  };
  const validationSchema = yup.object({
    name: yup.string().required('Please enter  name'),
    description: yup.string().required('Please enter description'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      const addSkillData = await addSkill(validationSchema).unwrap();

      if (isEdit) {
        const addSkillData = await updateSkill(validationSchema).unwrap();
        toast.success('Skilltype updated');
      } else {
        const addSkillData = await addSkill(validationSchema).unwrap();
        toast.success('skilltype added');
        formik.values.name = '';
        formik.values.description = '';
      }
      onClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <section aria-labelledby="payment-details-heading">
        <form action="#" method="POST" onSubmit={formik.handleSubmit}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div className="flex justify-between items-center">
                <h2
                  id="payment-details-heading"
                  className="text-lg font-bold leading-6 text-gray-900"
                >
                  Add Skilltype
                </h2>
                <div className="flex">
                  <Link
                    type="button"
                    to={'/app/skilltype'}
                    className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
                  >
                    <ArrowLeftIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Back
                  </Link>
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-sm font-bold uppercase text-purplecolor-300">
                  Skill Information
                </h3>
                {/* <div className="mt-3 grid xl:grid-cols-4 sm:grid-cols-3 gap-6 border-b-2 border-gray-200 pb-6"> */}
                <div className="mt-3 grid xl:grid-cols-4 sm:grid-cols-3 gap-6 pb-6">
                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="first-name"
                      autoComplete="cc-given-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      name="description"
                      id="last-name"
                      autoComplete="cc-family-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-t-gray-300 bg-white px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm focus:outline-none "
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddSkilltype;

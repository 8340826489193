import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';

import { useSelector } from 'react-redux';
import useGetSkills from '../../../../../../hooks/useGetSkills';
import useCandidate from '../../../../../../hooks/useCandidate';

export const AddKeySkill = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
}) => {
  const { skills, loadingSkills, successSkills } = useGetSkills();
  const [skillsList, setSkillsList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  // Getting Candidate Id from Redux and existing skills from API
  const candiid = useSelector((state) => state.candidatedetails?.id) || [];
  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);
  // const existingSkills = canddetails?.data.key_skills?.map((cand) => cand);

  useEffect(() => {
    const existingSkills =
      canddetails?.data.key_skills !== null ? canddetails?.data.key_skills : [];

    setSelectedIds((prevSelectedIds) => [
      ...prevSelectedIds,
      ...existingSkills,
    ]);

    const skillsArray = skills?.data.map((skill) => {
      return {
        id: skill.id,
        name: skill.name,
        isChecked: existingSkills.includes(skill.id) ? true : false,
      };
    });

    setSkillsList(skillsArray);
  }, [skills, canddetails]);

  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();
  const profileId = useSelector((state) => state.candidatedetails.id);

  // Handle Checkbox Change
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the selectedIds array
    const isSelected = selectedIds.includes(id);
    // If it's selected, remove it; otherwise, add it
    if (isSelected) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }

    const updatedSkills = skillsList.map((skill) => {
      if (skill.id == id) {
        return { ...skill, isChecked: !skill.isChecked };
      }
      return skill;
    });
    setSkillsList(updatedSkills);
  };

  // Form Submit
  const submitform = async (e) => {
    e.preventDefault(e);
    let values = {
      profile_id: profileId,
      key_skills: selectedIds,
    };
    try {
      const updateCandidateData = await updateCandidate(values).unwrap();

      setShowNotification(true);
      onModalDataClose();
    } catch (error) {}
  };

  return (
    <>
      <form className="flex flex-col" onSubmit={(e) => submitform(e)}>
        <div className="pb-3">
          <label
            // htmlFor={skill.name}
            className=" text-md font-semibold text-gray-700 "
          >
            Key Skill
          </label>
        </div>
        <div className="grid grid-cols-5 gap-4">
          {skillsList?.map((skill) => (
            <div className="flex" key={skill.id}>
              <div className="flex h-6 items-center ">
                <input
                  id={skill.name}
                  name={skill.name}
                  type="checkbox"
                  className="h-4 w-4 rounded-md  border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value={skill.id}
                  // checked={existingSkills.includes(skill.id)}
                  checked={skill.isChecked}
                  onChange={() => handleCheckboxChange(skill.id)}
                />
              </div>
              <div className="ml-3 text-sm leading-6 truncate">
                <label
                  // htmlFor={skill.name}
                  className="font-medium text-gray-900"
                >
                  {skill.name}
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-4">
          <div className="mr-3">
            <button
              type="button"
              onClick={() => onModalDataClose()}
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4  ml-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8  mr-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
              // className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 mt-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

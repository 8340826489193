import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSourcingStatusChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';

const SourcingStatusColumnChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  //   const [selectedDepartment, setSelectedDepartment] = useState('');
  //   const [selectedLast30Days, setSelectedLast30Days] = useState('');

  //   // Define options for countries, branches, departments, and last 30 days
  //   const countryOptions = ['India', 'USA', 'UK'];
  //   const branchOptions = ['Chennai', 'Bangalore', 'Pune'];
  //   const departmentOptions = ['Department1', 'Department2', 'Department3'];
  //   const last30DaysOptions = ['Last 30 days', 'Last month', 'Last Year'];

  // Function to wrap long labels
  const wrapLabel = (label) => {
    const maxWidth = 15; // Maximum width of each line
    const words = label.split(' ');
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      if (currentLine.length + words[i].length + 1 <= maxWidth) {
        currentLine += ' ' + words[i];
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);

    return lines.join('\n');
  };

  //   const getSeriesData = () => {
  //     return series.map((series, index) => ({
  //       name: series,
  //       data: chartData.map((item) => item[`value${index + 1}`] || 0),
  //     }));
  //   };

  const [
    sourcingStatusChart,
    { data: sourcingStatus, isLoading: sourcingStatusLoading },
  ] = useSourcingStatusChartMutation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId]);

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
    };
    const dataObj = cleanObjectData(data);
    sourcingStatusChart(dataObj);
  };

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setSelectedLocationId(id);
  };

  const chartData = sourcingStatus?.data;
  const chartName = 'Sourcing';

  const series = ['Total positions', 'Sourced', 'Screened'];

  const getSeriesData = () => {
    // return series.map((series, index) => ({
    //   name: series,
    //   data: chartData.map((item) => item[`value${index + 1}`] || 0),
    // }));
    return series.map((series, index) => {
      if (index === 0) {
        return {
          name: series,
          data: chartData?.map((item) => item['no_of_position'] || 0),
        };
      }
      if (index === 1) {
        return {
          name: series,
          data: chartData?.map((item) => item['total_sourced'] || 0),
        };
      }
      if (index === 2) {
        return {
          name: series,
          data: chartData?.map((item) => item['total_screened'] || 0),
        };
      }
    });
  };

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      fontFamily: 'Inter var', //set font family for all text elements
      foreColor: '#000', //set color for all text element to white
      toolbar: {
        show: false, //Hide the toolbar (menu bar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'center', // Position data labels at the center of each bar
          total: {
            enabled: false,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        columnWidth: '30px', //Adjust column width to control spacing
        // endingShape: 'flat', //control the shape of the bar
        // barHeight: '100%',
        // distributed: true, // Distribute the bars evenly
      },
    },
    dataLabels: {
      enabled: true, // Error seems to be here
      // formatter: function (val, opts) {
      //     return opts.w.config.series[opts.seriesIndex]; //Display value
      // },
      style: {
        fontSize: '15px',
        fontFamily: 'Inter var',
        colors: ['#fff'], //color of the data labels
        fontWeight:''
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        show: true, //Hide the x-axis labels
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        height: 1,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#f3f4f6',
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ['#53BA9E', '#FFC10B', '#FA2C50'],
  };

  return (
    <div>
      <div className="flex justify-between  p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          {/* <select
            className=" bg-[#39216B] text-white text-[13px]"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select> */}

          {/* Dropdown for branch */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select> */}

          {/* Dropdown for department */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Department</option>
            {departmentOptions.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select> */}

          {/* Dropdown for last 30 days */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedLast30Days}
            onChange={(e) => setSelectedLast30Days(e.target.value)}
          >
            <option value="">Last 30 Days</option>
            {last30DaysOptions.map((days, index) => (
              <option key={index} value={days}>
                {days}
              </option>
            ))}
          </select> */}
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            isDaysFilter={true}
          />
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={getSeriesData()}
        type="bar"
        height={380}
        width={567}
      />
    </div>
  );
};

export default SourcingStatusColumnChart;

import React, { useEffect, useState, Fragment } from 'react';
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import {
  useBranchQuery,
  useDeleteBranchMutation,
} from '../../../features/branch/branchApiSlice';
import { Link, useNavigate } from 'react-router-dom';

import Drawer from '../../../components/common/Drawer';
import Modal from '../../../components/common/Modal';
import Notification from '../../../components/common/Notification';
import AddBranch from '../../../features/branch/AddBranch';
import { toast } from 'sonner';

// import { useFormik } from "formik";

const Branch = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [deleteBranch] = useDeleteBranchMutation();

  const {
    data: branchs,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    return () => {};
  }, []);

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async (deptId) => {
    try {
      const deleteLoc = await deleteBranch(deptId).unwrap();
      closeModalWindow();
      toast.error('Branch deleted');
    } catch (error) {}
  };
  // const formik = useFormik();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    return () => {};
  }, []);

  const noList = () => (
    <div className="flex h-[calc(100vh-114px)]">
      <div className="text-center bg-white w-1/4 m-auto p-10">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Branch </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new Branch.
        </p>
        <div className="mt-6">
          {/* <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          New Project
        </button> */}
          <div>
            <Link
              type="button"
              // to={'/app/branch'}
              className="flex   justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              // onClick={() => openDrawerWindow()}
              onClick={() => openDrawerWindow()}
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Add Branch
            </Link>
            <Link
              type="button"
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex w-full justify-center rounded-3xl mt-4 text-sm font-medium  shadow-smfocus:outline-none "
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {branchs?.data.length > 0 ? (
        <div className="md:flex md:items-baseline md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                Branch
              </h2>
              <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
                <Link
                  type="button"
                  to={'..'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
                >
                  <ArrowLeftIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Back
                </Link>
                {/* <Link
                  type="button"
                  to={'/app/settings'}
                  className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
                >
                  <ArrowLeftIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Back
                </Link> */}
                <button
                  type="button"
                  className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                  onClick={() => openDrawerWindow()}
                >
                  <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Add
                </button>
              </div>
            </div>

            {isLoading && <h2>Loading...</h2>}
            {/* {isFetching && <h2>Fetching...</h2>} */}
            {error && <h2>{JSON.stringify(error)}</h2>}
            {isSuccess && (
              <div>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                >
                  {branchs?.data.map((branch) => (
                    <li
                      key={branch.id}
                      className="col-span-1 flex rounded-md shadow-sm relative  "
                    >
                      <div className="flex flex-1 justify-between truncate rounded-xl border border-gray-200  hover:border-blue-600 bg-white">
                        <div className="flex-1 truncate p-4 text-sm relative">
                          <div className="flex flex-1 justify-between">
                            <a
                              // href={project.href}
                              className="  text-gray-900 font-bold text-md hover:text-gray-600"
                            >
                              {branch.name}
                            </a>
                            <div className="flex">
                              <PencilIcon
                                className="h-5 w-5 mr-2 text-green-700 cursor-pointer"
                                aria-hidden="true"
                                onClick={() =>
                                  openDrawerWindow('edit', {
                                    name: branch.name,
                                    desc: branch.description,
                                    id: branch.id,
                                    email: branch.email,
                                    mobile: branch.mobile,
                                    location_id: branch.location_id,
                                  })
                                }
                              />
                              <TrashIcon
                                className="h-5 w-5 text-red-600 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => openModalWindow(branch.id)}
                              />
                            </div>
                          </div>
                          {/* <div className="py-4"> */}
                          {/* <p className="text-gray-500 py-1">
                              {branch.location_id}
                            </p> */}
                          {/* <p className="text-gray-500 py-1">{branch.email}</p>
                          <p className="text-gray-500 py-1">{branch.mobile}</p> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : isSuccess ? (
        // noList()
        <div className="flex h-[calc(100vh-114px)]">
          <div className="text-center bg-white w-1/4 m-auto p-10">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vector-effect="non-scaling-stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Branch{' '}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new Branch.
            </p>
            <div className="mt-6">
              {/* <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          New Project
        </button> */}
              <div>
                <Link
                  type="button"
                  // to={'/app/branch'}
                  className="flex   justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                  // onClick={() => openDrawerWindow()}
                  onClick={() => openDrawerWindow()}
                >
                  <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Add Branch
                </Link>
                <Link
                  type="button"
                  to={'..'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex w-full justify-center rounded-3xl mt-4 text-sm font-medium  shadow-smfocus:outline-none "
                >
                  <ArrowLeftIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2>Loading...</h2>
      )}
      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
      <Drawer
        isOpen={openDrawer}
        title={isEdit ? 'Edit Branch' : 'Add Branch'}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        <AddBranch
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(deptId)}
      ></Modal>
    </>
  );
};

export default Branch;

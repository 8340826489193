import React from 'react';
import { useTeamsSignoffQuery } from '../../../../../../../features/teams/teamsApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useOfferletterSubmitMutation } from '../../../../../../../features/hiringstages/offerApiSlice';

const OfferLetter = ({ canddetails }) => {
  const [offerletterSubmit, { isLoading: offerLetterLoading }] =
    useOfferletterSubmitMutation();
  const {
    data: usersSignoff,
    isLoading: userSignoffLoading,
    isSuccess: userSignoffSuccess,
  } = useTeamsSignoffQuery({ refetchOnMountOrArgChange: true });

  const canddetailsObj = canddetails?.data;
  const usersSignoffObj = usersSignoff?.data;

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    offer_letter_template: canddetailsObj?.offer_letter_template || '',
    offer_letter: 'Helo',
    offer_sign_required: 2,
    offer_sign_user_id: canddetailsObj?.offer_sign_user_id || '',
  };

  const validationSchema = yup.object({
    offer_letter_template: yup
      .string()
      .required('Please select offer template'),
    offer_sign_user_id: yup.string().required('Please select signoff user'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const rrrr = await offerletterSubmit(validationSchema).unwrap();
      alert('Data Add Successfully');
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const offerStatus = (statusVal) => {
    if (statusVal === 1) {
      return (
        <div className="bg-green-700 px-6 py-2 text-white block w-full text-center">
          Offer Accepted{' '}
        </div>
      );
    } else if (statusVal === 2) {
      return (
        <div className="bg-red-600 px-3 py-2 text-white block w-full text-center">
          Offer Rejected{' '}
        </div>
      );
    } else if (statusVal === 3) {
      return (
        <div className="bg-blue-600 px-3 py-2 text-white block w-full text-center">
          Offer Query
        </div>
      );
    }
  };

  return (
    <form className=" bg-white pb-4 rounded-lg" onSubmit={formik.handleSubmit}>
      {offerStatus(canddetailsObj?.offer_approval_status)}
      <div className="rounded-md shadow-3xl mx-0">
        {' '}
        <div className="flex flex-row mt-0">
          <div className="gap-4 rounded-lg mr-0">
            {' '}
            <div className=" bg-gray-200 pb-4 rounded-lg">
              <div className="pt-3 px-2">
                <select
                  name="offer_letter_template"
                  id="offer_letter_template"
                  // autoComplete="offer_letter_template"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.offer_letter_template}
                  className="bg-white border border-inputBorder mt-1 ml-2 block w-64 rounded-md py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                >
                  <option value={''}>Choose Offer Template</option>
                  <option value={1}> Offer Template 1</option>
                  <option value={2}>Offer Template 2</option>
                </select>

                {formik.touched.offer_letter_template &&
                formik.errors.offer_letter_template ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.offer_letter_template}
                  </div>
                ) : null}
              </div>
              <div className="bg-white rounded-md py-4 px-4 mx-4 my-4">
                <span className="flex">
                  Dear
                  <p className="text-sidebar pl-1">[Candidate Name]</p>
                </span>
                <p className="py-2">
                  Wipro is delighted to offer you the full-time position of
                  <e className="text-sidebar pl-1">[Designation]</e>
                  with an anticipated start date of
                  <e className="text-sidebar pl-1">[Joining date],</e>
                  contingent upon [background check, drug screening, etc.].{' '}
                </p>

                <p>
                  As the
                  <e className="text-sidebar pl-1">[Designation]</e>, you will
                  be responsible for [brief mention of job responsibilities and
                  expectations].{' '}
                </p>

                <p className="py-2">
                  You will report directly to [manager/supervisor name and
                  title] at [workplace location]. Working hours are from [hours
                  of day, days of week].
                </p>

                <p>
                  The starting salary for this position is{' '}
                  <e className="text-sidebar pl-1">[Salary]</e>
                  per month. Payment is on a monthly basis by [direct deposit,
                  check, etc.]. In addition, you will be eli gible to receive
                  [discuss additional compensation potential].
                </p>

                <p className="py-2">
                  {' '}
                  Wipro offers a comprehensive benefits program, which includes
                  [medical insurance, 401(k), paid time off, etc.].{' '}
                </p>

                <p>
                  Your employment with Wipro will be on an at-will basis, which
                  means you and the company are free to terminate employment at
                  any time, with or without cause or advance notice. This letter
                  is not a contract indicating employment terms or duration.
                </p>

                <p className="py-2">
                  Please confirm your acceptance of this offer by signing and
                  returning this letter by [offer expiration date].
                </p>

                <p className="text-md font-semibold">Sincerely,</p>
                <p className="text-sidebar mt-4">[Signature]</p>
              </div>
              {/* <div className="flex h-6 items-center pl-5 py-4">
                <input
                  id="add_digital_signature"
                  aria-describedby="add_digital_signature"
                  name="add_digital_signature"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                  checked={isRecommendChecked}
                  onChange={() =>
                    setIsRecommendChecked(!isRecommendChecked)
                  }
                />
                <div className="ml-3 text-md leading-6">
                  <label
                    htmlFor="add_digital_signature"
                    className="font-semibold cursor-pointer"
                  >
                    Add Digital Signature
                  </label>
                </div>
              </div> */}

              <div className="flex justify-between">
                <div>
                  <div className="pt-3 px-2">
                    <select
                      name="offer_sign_user_id"
                      id="offer_sign_user_id"
                      className="bg-white border border-inputBorder mt-1 ml-2 block w-64 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.offer_sign_user_id}
                    >
                      <option value="">Choose Authorized Person</option>
                      {usersSignoffObj?.map((SFUser) => (
                        <option key={SFUser?.id} value={SFUser?.id}>
                          {SFUser?.first_name} {SFUser?.last_name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.offer_sign_user_id &&
                    formik.errors.offer_sign_user_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.offer_sign_user_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex self-center">
                  <div className=" ">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-3xl border border-transparent bg-green-900 py-3 px-8  mr-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OfferLetter;

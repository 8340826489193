import { Fragment, useState } from 'react';
import { useFormik } from 'formik';

import { Link } from 'react-router-dom';
import axios from 'axios';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowUpIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  PlusIcon,
  PaperClipIcon,
  CheckIcon,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';

import { useSelector } from 'react-redux';

import CandDetails from './CandDetails';

import {
  useCandHandoverDetailsQuery,
  useHandoverNegotationMutation,
} from '../../../../../features/hiringstages/handoverApiSlice';
import ModalData from '../../../../../components/common/ModalData';
import Modal from '../../../../../components/common/Modal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const HandoverDetails = ({ toggleHandover }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedfile, SetSelectedFile] = useState([]);
  const [docFilePath, setDocFilePath] = useState('');
  const [docFileUrl, setDocFileUrl] = useState('');
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const candId = useSelector(selectCandidateInterview);

  const openModalWindow = (id) => {
    // setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useCandHandoverDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  const [handoverNegotation, { isLoading: handoverNegotationLoading }] =
    useHandoverNegotationMutation();

  const canddetailsObj = canddetails?.data;

  // const people = [
  //   {
  //     name: 'Comments',
  //     title: 'Strong Hire',
  //     percentage: '70%',
  //   },
  //   // More people...
  // ];

  const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
      role: 'Member',
    },
    // More people...
  ];

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(true);
  };

  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const gotoHandoverList = (value, candid) => {
    toggleHandover(value);
  };

  const [selectedFile, setSelectedFile] = useState([]);
  const token = useSelector((data) => data.auth.token);

  const handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    setSelectedFile(e.target.files[0]);

    if (e.target.files[0] != '') {
      var formData = new FormData();
      formData.append('file', e.target.files[0]);

      var options = formData;
      try {
        axios
          .post('https://api.skillstrove.com/users/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setDocFilePath(response.data.data.filePath);
            setDocFileUrl(response.data.data.url);
            // formik.values.resume = response.data.filePath;
          })

          .catch((error) => {});
      } catch (error) {}
    }
  };

  const formik = useFormik({});

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoHandoverList('handoverlist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails canddetails={canddetails} />

        <div className="basis-5/6">
          <div className="flex flex-row  ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
                <div className="">
                  <div className="">
                    <div className=" rounded-md shadow-3xl mx-0">
                      {' '}
                      <div className="bg-white  py-5 rounded-lg shadow-lg  px-10">
                        <div className="flex justify-between">
                          <h1 className="text-xl font-bold text-center self-center">
                            Documents from Candidate
                          </h1>
                          {/* <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                            <a
                              href={'#'}
                              target="_blank"
                              title="Edit"
                              className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                            >
                              {' '}
                              <DocumentArrowUpIcon
                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                aria-hidden="true"
                              />{' '}
                              Upload Document
                            </a>
                          </span> */}

                          <div className="flex">
                            {' '}
                            <div className="kb-file-upload mb-0 self-center">
                              <div className="file-upload-box flex whitespace-nowrap !rounded-xl px-3 bg-sidebar ">
                                <input
                                  type="file"
                                  id="fileupload"
                                  className="file-upload-input"
                                  accept=".doc,.docx,.pdf"
                                  onChange={handleFileChange}
                                  multiple
                                />
                                <span className="flex">
                                  <DocumentArrowUpIcon
                                    className="h-7 w-7 text-white mr-2"
                                    aria-hidden="true"
                                  />
                                  <span className=" text-white self-center pr-3">
                                    Upload
                                  </span>
                                </span>
                              </div>
                            </div>
                            <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                              <a
                                // href={'#'}
                                // target="_blank"
                                // title="Edit"
                                type="file"
                                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                onClick={() => openModalDataWindow({})}
                              >
                                {' '}
                                <DocumentArrowUpIcon
                                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                  aria-hidden="true"
                                />{' '}
                                Upload Document
                              </a>
                            </span>
                          </div>
                        </div>

                        <div className="mt-2 flow-root">
                          <div className="">
                            <div className="inline-block min-w-full py-2 align-middle">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                      >
                                        File Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        Attachments
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left flex justify-end mr-6 text-sm font-bold text-gray-900"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {people.map((person) => (
                                      <tr key={person.email}>
                                        <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {/* {person.name} */}
                                          Address Proof (Aadhar Card)
                                        </td>
                                        <td className="whitespace-nowrap  text-sm text-gray-500">
                                          {/* {person.title} */}
                                          <span className=" flex whitespace-nowrap py-2 text-sm text-gray-500">
                                            <a
                                              href={'#'}
                                              target="_blank"
                                              title="Edit"
                                              className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                            >
                                              {' '}
                                              <ArrowDownTrayIcon
                                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                aria-hidden="true"
                                              />{' '}
                                              Download
                                            </a>
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap flex justify-end mr-6  text-sm text-gray-500">
                                          {/* {person.email} */}
                                          <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            <a
                                              // href={'#'}
                                              // target="_blank"
                                              // title="Edit"
                                              className="bg-red-500 px-4 py-2 items-center flex rounded-3xl text-white"
                                              onClick={() => openModalWindow()}
                                            >
                                              {' '}
                                              <XMarkIcon
                                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                aria-hidden="true"
                                              />{' '}
                                              Remove
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between mt-3">
                          <h1 className="text-xl font-bold text-center self-center">
                            Onboarding Progress
                          </h1>

                          <span className="flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                            <Link
                              type="button"
                              to={''}
                              className="flex mr-2 justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                              onClick={() => openModalDataWindow({})}
                            >
                              <PlusIcon
                                className="-ml-1 mr-2 h-5 w-5 text"
                                aria-hidden="true"
                              />
                              Add
                            </Link>
                          </span>
                        </div>

                        <div className="mt-2 flow-root">
                          <div className="">
                            <div className="inline-block min-w-full py-2 align-middle">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                      >
                                        Process
                                      </th>

                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left flex justify-end mr-10 text-sm font-bold text-gray-900"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {people.map((person) => (
                                      <tr key={person.email}>
                                        <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {/* {person.name} */}
                                          Welcome Email
                                        </td>

                                        <td className="whitespace-nowrap flex justify-end mr-6 text-sm text-gray-500">
                                          {/* {person.email} */}
                                          <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            <a
                                              // href={'#'}
                                              // target="_blank"
                                              // title="Edit"
                                              className="bg-red-500 px-4 py-2 items-center flex rounded-3xl text-white"
                                              onClick={() =>
                                                openModalWindow({})
                                              }
                                            >
                                              {' '}
                                              <XMarkIcon
                                                className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                                aria-hidden="true"
                                              />{' '}
                                              Remove
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" mt-0 grid grid-cols-1 gap-6 px-0">
                          <div className="flex flex-row mt-5 justify-between">
                            <div className="basis-7/12 bg-gray-100  border py-0 mr-10 flex rounded-lg">
                              <div className="basis-1/2 p-3 mr-3">
                                <select
                                  id="priority"
                                  name="priority"
                                  autoComplete="priority"
                                  className="bg-white border border-inputBorder mt-0 w-full rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                  // onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  // value={formik.values.priority}
                                >
                                  <option>-- Select Priority --</option>
                                  <option value={'3'}>High</option>
                                  <option value={'2'}>Medium</option>
                                  <option value={'1'}>Low</option>
                                </select>
                                {/* {formik.touched.priority && formik.errors.priority ? ( */}
                                <div className="mt-2 text-sm text-red-600">
                                  {/* {formik.errors.priority} */}
                                </div>
                                {/* ) : null} */}
                              </div>
                              <div className="basis-1/2 py-3 mr-3">
                                <button
                                  type="button"
                                  className=" rounded-xl border bg-green-500 w-full py-2.5 px-0 text-sm font-medium text-white shadow-sm focus:outline-none "
                                >
                                  Handover
                                </button>
                              </div>
                            </div>

                            <div className=" self-center ">
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
                                // className=" rounded-md bg-indigo-600 py-3 px-16  text-sm font-medium text-white shadow-sm focus:outline-none "
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        // data={openModalDataValues}
      >
        {/* <EditJD /> */}
        {/* <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        /> */}

        {/* Modal window */}
        {/* <CandCalling dataval={openModalDataValues} /> */}
        <form>
          {/* <div>
            <span>
              File name : <strong> resume.pdf</strong>
            </span>
            <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
              <a
                href={'#'}
                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
              >
                <DocumentArrowUpIcon
                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                  aria-hidden="true"
                />
                Upload
              </a>
            </span>
          </div> */}

          <div className="grid grid-cols-4">
            <div className="mt-3 ">
              <label
                htmlFor="file_name"
                className="block text-sm  font-medium text-gray-900 mr-4"
              >
                File name
              </label>
            </div>
            <div>
              <input
                type="text"
                name="file_name"
                id="file_name"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.file_name}
                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              />
              {/* {formik.touched.file_name &&
              formik.errors.file_name ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.file_name}
                </div>
              ) : null} */}
            </div>
          </div>
          <div className="grid grid-cols-4 mt-2">
            <div className="mt-3 ">
              <label
                htmlFor="field_name"
                className="block text-sm  font-medium text-gray-900 mr-4"
              >
                Field name
              </label>
            </div>
            <div>
              <input
                type="text"
                name="field_name"
                id="field_name"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.field_name}
                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              />
              {/* {formik.touched.field_name &&
              formik.errors.field_name ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.field_name}
                </div>
              ) : null} */}
            </div>
          </div>
          {/* <div>
            <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-0">
              <a
                href={'#'}
                
                type="file"
                className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
              >
                <DocumentArrowUpIcon
                  className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                  aria-hidden="true"
                />
                Upload
              </a>
            </span>
          </div> */}

          <div className="kb-file-upload mb-0 w-1/4 mt-5">
            <div className="file-upload-box flex whitespace-nowrap !rounded-xl px-3 bg-sidebar ">
              <input
                type="file"
                id="fileupload"
                className="file-upload-input"
                accept=".doc,.docx,.pdf"
                onChange={handleFileChange}
                multiple
              />
              <span className="flex">
                <ArrowUpOnSquareIcon
                  className="h-8 w-8 text-white mr-4"
                  aria-hidden="true"
                />
                <span className=" text-white self-center">Upload</span>
              </span>
            </div>
          </div>
        </form>
        {/* Modal window */}
      </ModalData>

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
    </>
  );
};
export default HandoverDetails;

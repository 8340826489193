import React, { useEffect, useState } from 'react';

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { XCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import ModalData from '../../../../../../components/common/ModalData';
import Modal from '../../../../../../components/common/Modal';
import { useSelector } from 'react-redux';

import { AddTechnicalSkill } from '../modal/AddTechnicalSkill';

import useCandidate from '../../../../../../hooks/useCandidate';
import useGetSkills from '../../../../../../hooks/useGetSkills';

import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';

const CandTechskills = ({ showNotificationMsg }) => {
  const [idToDelete, setIdToDelete] = useState(null);

  const profileId = useSelector((state) => state.candidatedetails?.id);
  const { skills, loadingSkills, successSkills } = useGetSkills();
  const candiid = useSelector((state) => state.candidatedetails?.id) || [];
  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);

  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();

  const [techskills, setTechskills] = useState();

  useEffect(() => {
    const keySkillArray =
      canddetails?.data.technical_skills !== null
        ? canddetails?.data.technical_skills
        : [];
    // console.log("keySkillArray : ", keySkillArray)
    setTechskills(keySkillArray);
  }, [canddetails]);
  let techSkillsArrayString = JSON.stringify(techskills);
  // Parse the JSON string to convert it to an array of objects
  let parsedTechskills;
  

  try {
    parsedTechskills = JSON.parse(techSkillsArrayString);

    if (!Array.isArray(parsedTechskills)) {
      throw new Error('Parsed value is not an array');
    }
  } catch (error) {
    console.error('Error parsing techskills:', error);
    parsedTechskills = [];
  }

  console.log('parsedTechskills --> ', parsedTechskills);

  const [openModal, setOpenModal] = useState(false);

  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };

  const onModalDataSubmit = (e) => {
    e.preventDefault();
    showNotificationMsg();
  };

  const getSkillName = (skillid) => {
    const skillName = skills?.data.find((element) => element.id == skillid);
    return skillName?.name;
  };

  const openModalWindow = (id) => {
    setIdToDelete(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    const removeTechSkill = techskills.filter(
      (techskill) => techskill.skill_id !== idToDelete
    );

    setTechskills(removeTechSkill);

    let values = {
      profile_id: profileId,
      technical_skills: removeTechSkill,
    };

    try {
      const updateCandidateData = await updateCandidate(values).unwrap();
      showNotificationMsg();
      closeModalWindow();
    } catch (error) { }
  };

  return (
    <>
      <div className="flex justify-between px-6 pt-2 items-center">
        <h1 className="text-2xl font-bold">Relevant skills List</h1>
        <Link
          type="button"
          to={''}
          className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
          onClick={() =>
            openModalDataWindow({
              mode: 'Add',
              title: 'Add Relevant skills',
            })
          }
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
          Add Relevant skills
        </Link>
      </div>
      <div className="px-4 sm:px-0 lg:px-5">
        {/* <div className="flex justify-end mr-0 mt-5">
          <PencilSquareIcon
            className="-ml-1 mr-0 h-8 w-8 cursor-pointer"
            aria-hidden="true"
          />
        </div> */}

        <div className="mt-2 flow-root">
          {/* <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
          <div className="">
            {canddetailsLoading ? ( // if the data is loading, display a loading message
              <div className="text-purplecolor font-bold mt-5">Loading...</div>
            ) : (
              ''
            )}
            {canddetailsSuccess ? (
              <div className="inline-block min-w-full py-2 align-middle sm:px-1 lg:px-1s">
                {techskills?.length > 0 ? (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-0"
                        >
                          Skills
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                        >
                          Experience
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className=" divide-gray-200">
                      {Array.isArray(parsedTechskills) && parsedTechskills.length > 0 ? (
                        parsedTechskills.map((techskill) => (
                          <tr key={techskill.skill_id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {getSkillName(techskill.skill_id)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {techskill.experience} Years
                            </td>
                            <td className="whitespace-nowrap px-3 py-4">
                              <div className="flex justify-end mr-0">
                                <TrashIcon
                                  className="-ml-1 mr-5 h-8 w-8 cursor-pointer text-red-600"
                                  aria-hidden="true"
                                  onClick={() => openModalWindow(techskill.skill_id)}
                                />
                                {/* Uncomment and adjust the code below if you need the PencilSquareIcon */}
                                {/* <PencilSquareIcon
            className="-ml-1 mr-0 h-8 w-8 cursor-pointer text-purple-500"
            aria-hidden="true"
            onClick={() =>
              openModalDataWindow({
                mode: 'Edit',
                title: 'Edit Technical Skill',
              })
            }
          /> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center py-4 text-sm text-gray-500">
                            No technical skills available.
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                ) : (
                  'No Skills, Add Skill'
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        <AddTechnicalSkill
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          setShowNotification={() => showNotificationMsg()}
          data={openModalDataValues}
        />
      </ModalData>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        modal
      </Modal>
    </>
  );
};

export default CandTechskills;

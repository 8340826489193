import { apiSlice } from '../../app/api/apiSlice';

export const locationApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Location'],
  endpoints: (builder) => ({
    location: builder.query({
      query: () => ({
        url: '/master/location',
        method: 'GET',
      }),
      providesTags: ['Location'],
      keepUnusedDataFor: 5,
    }),
    addLocation: builder.mutation({
      query: (data) => ({
        url: '/master/location',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Location'],
    }),
    updateLocation: builder.mutation({
      query: (data) => ({
        url: '/master/location',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Location'],
    }),
    deleteLocation: builder.mutation({
      query: (id) => ({
        url: `/master/location/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Location'],
    }),
  }),
});

export const {
  useLocationQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationApiSlice;

import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useManpowerQuery } from '../../../../features/manpower/manpowerApiSlice';
import { useWorkflowQuery } from '../../../../features/workflow/workflowApiSlice';
import { useCountryQuery } from '../../../../features/country/countryApiSlice';
import { useBranchQuery } from '../../../../features/branch/branchApiSlice';
import { useDepartmentQuery } from '../../../../features/department/departmentApiSlice';
import { useTeamsSignoffQuery } from '../../../../features/teams/teamsApiSlice';
import {
  useAddJobrequestMutation,
  useJobrequestQuery,
  useUpdateJobrequestMutation,
} from '../../../../features/manpower/jobrequestApiSlice';
import DateToIndiaFormat from '../../../../components/common/DateToIndiaFormat';
import GetCountry from '../../../../components/common/GetCountry';
import GetBranchName from '../../../../components/common/GetBranchName';
import { toast } from 'sonner';

const AddJobRequest = ({ data, onModalDataClose }) => {
  console.log('data ', data);
  const JRMode = data.mode

  const [totalPosition, setTotalPosition] = useState(0);
  const [usedPosition, setUsedPosition] = useState(0);

  const [addJobrequest, { isLoading }] = useAddJobrequestMutation();
  const [updateJobrequest] = useUpdateJobrequestMutation();
  const [wfNoOfPositions, setWfNoOfPositions] = useState(0);

  const {
    data: jobrequest,
    error: jobrequestError,
    isLoading: jobrequestLoading,
    isFetching: jobrequestFetching,
    isSuccess: jobrequestSuccess,
  } = useJobrequestQuery({ refetchOnMountOrArgChange: true });

  console.log('jobrequest ', jobrequest?.data);

  const totalManpowerPositionUsed = (manpowerID) => {
    const totalPosition = jobrequest?.data
      .filter((item) => {
        console.log(
          'totalPosition ?? ',
          typeof manpowerID,
          typeof item.manpower_requirement_id
        );
        return item.manpower_requirement_id === parseInt(manpowerID);
      })
      .reduce((total, item) => total + item.no_of_position, 0);

    console.log('totalPosition ', totalPosition);
    return totalPosition;
  };
  // const totalPositionsForWorkflow2 = data
  // .filter(item => item.workflow_id === 2)
  // .reduce((total, item) => total + item.no_of_position, 0);

  const { data: usersSignoff, isLoading: userSignoffLoading } =
    useTeamsSignoffQuery({ refetchOnMountOrArgChange: true });

  const getSignoffUSer = usersSignoff?.data?.map((user) => {
    let numberArray = user.privileges.split(',');
    if (numberArray.indexOf('4') !== -1) {
      return user;
    }
  });
  const {
    data: manpowerlist,
    error: manpowerError,
    isLoading: manpowerLoading,
    isFetching: manpowerFetching,
    isSuccess: manpowerSuccess,
  } = useManpowerQuery({ refetchOnMountOrArgChange: true });

  const {
    data: wf,
    error: wfError,
    isLoading: wfLoading,
    isFetching: wfFetching,
    isSuccess: wfSuccess,
  } = useWorkflowQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: depts,
    error: deptsError,
    isLoading: deptsLoading,
    isFetching: deptsFetching,
    isSuccess: deptsSuccess,
  } = useDepartmentQuery({ refetchOnMountOrArgChange: true });

  console.log(depts)

  const initialValues =
    (data.mode == 'Edit' || data.mode == 'View')
      ? {
        id: data.jr.id,
        title: data.jr.title,
        manpower_requirement_id: data.jr.manpower_requirement_id,
        workflow_id: data.jr.workflow_id,
        country_id: data.jr.country_id,
        branch_id: data.jr.branch_id,
        department_id: data.jr.department_id,
        no_of_position: data.jr.no_of_position,
        budget: data.jr.budget,
        closing_date: data.jr.closing_date,
        approved_by: data.jr.approved_by,
      }
      : {
        manpower_requirement_id: 0,
        title: '',
        workflow_id: 0,
        country_id: 0,
        branch_id: 0,
        department_id: 0,
        no_of_position: 0,
        budget: '',
        closing_date: '',
        approved_by: 0,
      };
  const validationSchema = yup.object({
    title: yup.string().required('Please enter Job title'),
    no_of_position: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Number must be non-negative')
      .required('No. of Position is required'),
    budget: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Number must be non-negative')
      .required('Budget is required'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      if (data.mode == 'Edit') {
        const accountData = await updateJobrequest(validationSchema).unwrap();
        toast.success('Jobrequest saved');
      } else {
        const accountData = await addJobrequest(validationSchema).unwrap();
        toast.success('Jobrequest created');
      }
      onModalDataClose();
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  // Extract unique country IDs from the cities array
  const uniqueCountryIds = [
    ...new Set(branchs?.data?.map((branch) => branch.location.country_id)),
  ];
  const filteredCountries = countryList?.data?.filter((country) =>
    uniqueCountryIds.some((cityId) => cityId === country.id)
  );

  let filterBranchValues = [];
  const [filteredBranch, setFilteredBranch] = useState([]);
  const handleCountryChange = (event) => {
    const country = event.target.value;
    formik.setFieldValue('country_id', country);
    formik.setFieldValue('branch_id', ''); // Reset state when country changes

    filterBranchValues = branchs?.data?.filter(
      (branch) => branch.location.country_id == country
    );
    setFilteredBranch(filterBranchValues);
  };

  useEffect(() => {
    if (formik.values.country_id !== '') {
      // formik.setFieldValue('country_id', country);
      // formik.setFieldValue('branch_id', ''); // Reset state when country changes

      filterBranchValues = branchs?.data?.filter(
        (branch) => branch.location.country_id == formik.values.country_id
      );
      setFilteredBranch(filterBranchValues);
    }
  }, []);

  const [selectedMPID, setselectedMPID] = useState(0);

  useEffect(() => {
    if (formik.values.manpower_requirement_id != 0) {
      setselectedMPID(formik.values.manpower_requirement_id);
    }
  }, []);

  const selectManpowerFilter = (event) => {
    const manpowerID = event.target.value;
    const totalUsedPos = totalManpowerPositionUsed(manpowerID);

    formik.setFieldValue('manpower_requirement_id', manpowerID);
    setselectedMPID(manpowerID);
    const selectedMPData = manpowerlist?.data?.filter(
      (manpower) => manpower.id == manpowerID
    );

    console.log(
      'selectedMPData[0].no_of_position ',
      selectedMPData[0].no_of_position,
      totalUsedPos
    );
    setTotalPosition(selectedMPData[0].no_of_position);
    setUsedPosition(totalUsedPos);

    if (manpowerID == 0) {
      formik.setFieldValue('no_of_position', '');
      formik.setFieldValue('closing_date', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('branch_id', '');
      formik.setFieldValue('department_id', '');
      // formik.setFieldValue('budget', '');
    } else {
      formik.setFieldValue('no_of_position', data.no_of_position);
      formik.setFieldValue('closing_date', selectedMPData[0].closing_date);
      formik.setFieldValue('country_id', selectedMPData[0].country_id);
      formik.setFieldValue('branch_id', selectedMPData[0].branch_id);
      formik.setFieldValue('department_id', selectedMPData[0].department_id);
      // formik.setFieldValue('budget', selectedMPData[0].budget);
    }
  };
  return (
    <>
      {JRMode === "View" ? (
        <form
          className="flex flex-col justify-between"
          onSubmit={formik.handleSubmit}
        >
          <h1 className="text-lg text-black font-bold">{data.title}</h1>
          <div className="mt-5 col-span-4 sm:col-span-1">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-900"
            >
              Job Title
            </label>

            {formik.values.title}

          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense ">

            <label
              htmlFor="department_id"
              className="block text-sm font-medium text-gray-700"
            >
              Department
            </label>
            {deptsLoading ? 'Loading Department ...' : ' '}
            {deptsSuccess ? (
              <select
                id="department_id"
                name="department_id"
                autoComplete="department_id"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                value={formik.values.department_id}
                disabled={true}
              >
                {depts.data.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </select>
            ) : (
              ''
            )}

          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="manpower_requirement_id"
                className="block text-sm font-medium text-gray-700"
              >
                Manpower
              </label>
              {manpowerLoading ? 'Loading Manpowerlist ...' : ' '}
              {manpowerSuccess ? (
                <select
                  id="manpower_requirement_id"
                  name="manpower_requirement_id"
                  autoComplete="manpower_requirement_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  value={formik.values.manpower_requirement_id}
                  disabled={true}
                >
                  <option value="">-- Select Manpower --</option>

                  {manpowerlist.data
                    .filter(function (mpList) {
                      return mpList.approval_status == 1; // Filter only approved one's
                    })
                    .map((mpList) => (
                      <option key={mpList.id} value={mpList.id}>
                        {mpList.manpower_agency}
                      </option>
                    ))}
                </select>
              ) : (
                ''
              )}
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="workflow_id"
                className="block text-sm font-medium text-gray-700"
              >
                Workflow
              </label>
              {wfLoading ? 'Loading Workflow ...' : ' '}
              {wfSuccess ? (
                <select
                  id="workflow_id"
                  name="workflow_id"
                  autoComplete="workflow_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  disabled={true}
                  value={formik.values.workflow_id}
                >
                  <option value="">-- No Data  --</option>
                  {wf.data.map((wfItem) => (
                    <option key={wfItem.id} value={wfItem.id}>
                      {/* {' '}
                    {wfItem.branch} - {wfItem.designation}{' '} */}
                      {wfItem.title}
                    </option>
                  ))}
                </select>
              ) : (
                ''
              )}
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="approved_by"
                className="block text-sm font-medium text-gray-700"
              >
                Approved by
              </label>
              <select
                id="approved_by"
                name="approved_by"
                autoComplete="approved_by"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                disabled={true}
                value={formik.values.approved_by}
              >
                <option value={0}>-- Select ApprovedBy--</option>
                {getSignoffUSer?.map((sfUser) => (
                  <option key={sfUser.id} value={sfUser.id}>
                    {`${sfUser.first_name} ${sfUser.last_name}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <div className="">
                <label
                  htmlFor="no_of_position"
                  className="block text-sm font-medium text-gray-700"
                >
                  Position #
                </label>
                <input
                  type="number"
                  name="no_of_position"
                  id="no_of_position"
                  autoComplete="no_of_position"
                  placeholder="10"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  disabled={true}
                  value={formik.values.no_of_position}
                />
                <div className=" italic text-[11px] mt-1">
                  <span className="font-bold">Total positions</span> -{' '}
                  {totalPosition}{' '}
                  <span className="font-bold">Used positions</span> -{' '}
                  {usedPosition}{' '}
                  <span className="font-bold">Available positions</span> -{' '}
                  {totalPosition - usedPosition}
                </div>
                {formik.touched.no_of_position && formik.errors.no_of_position ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.no_of_position}
                  </div>
                ) : null}
              </div>
              <div className="">
                <label
                  htmlFor="closing_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Closing Date - {selectedMPID}
                </label>
                {selectedMPID ? (
                  <p className="py-2.5 text-sm">
                    {formik.values.closing_date &&
                      format(new Date(formik.values.closing_date), 'dd-MM-yyyy')}
                  </p>
                ) : (
                  <input
                    type="date"
                    name="closing_date"
                    id="closing_date"
                    autoComplete="closing_date"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    disabled={true}
                    value={formik.values.closing_date}
                  />
                )}

                {/* {formik.touched.company_name && formik.errors.closing_date ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.closing_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <div className="col-span-4 sm:col-span-1">
                <label
                  htmlFor="country_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                {countryLoading ? 'Loading Country ...' : ' '}
                {countrySuccess ? (
                  selectedMPID ? (
                    <GetCountry countryid={formik.values.country_id} />
                  ) : (
                    <select
                      id="country_id"
                      name="country_id"
                      autoComplete="country_id"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={handleCountryChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country_id}
                    >
                      <option value="">-- Select Country--</option>
                      {filteredCountries?.map((cl) => (
                        <option key={cl.id} value={cl.id}>
                          {cl.country_name}
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="">
                <label
                  htmlFor="branch_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Branch
                </label>
                {branchLoading ? 'Loading Country ...' : ' '}
                {branchSuccess ? (
                  selectedMPID ? (
                    <GetBranchName branchId={formik.values.branch_id} />
                  ) : (
                    <>
                      <select
                        id="branch_id"
                        name="branch_id"
                        autoComplete="branch_id"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.branch_id}
                      >
                        <option value="">-- Select Branch--</option>
                        {filteredBranch.map((branch) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {/* <div className="">
                <label
                  htmlFor="department_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Choose Department
                </label>
                {deptsLoading ? 'Loading Department ...' : ' '}
                {deptsSuccess ? (
                  <select
                    id="department_id"
                    name="department_id"
                    autoComplete="department_id"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department_id}
                  >
                    <option value="">-- Select Department--</option>
                    {depts.data.map((dept) => (
                      <option key={dept.id} value={dept.id}>
                        {dept.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  ''
                )}
              </div> */}
              <div className="">
                <label
                  htmlFor="budget"
                  className="block text-sm font-medium text-gray-700"
                >
                  Budget
                </label>
                <input
                  type="text"
                  name="budget"
                  id="budget"
                  autoComplete="budget"
                  placeholder="10L"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  disabled={true}
                  value={formik.values.budget}
                />
                {formik.touched.budget && formik.errors.budget ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.budget}
                  </div>
                ) : null}
              </div>
            </div>
          </>

          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => onModalDataClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!(formik.isValid)}
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      ) : (
        <form
          className="flex flex-col justify-between"
          onSubmit={formik.handleSubmit}
        >
          <h1 className="text-lg text-black font-bold">{data.title}</h1>
          <div className="mt-5 col-span-4 sm:col-span-1">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-900"
            >
              Job Title *
            </label>

            <input
              type="text"
              name="title"
              id="title"
              autoComplete="title"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.title}
              </div>
            ) : null}
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense ">

            <label
              htmlFor="department_id"
              className="block text-sm font-medium text-gray-700"
            >
              Choose Department
            </label>
            {deptsLoading ? 'Loading Department ...' : ' '}
            {deptsSuccess ? (
              <select
                id="department_id"
                name="department_id"
                autoComplete="department_id"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.department_id}
              >
                <option value="">-- Select Department--</option>
                {depts.data.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </select>
            ) : (
              ''
            )}

            {/* <label
          htmlFor="country_id"
          className="block text-sm font-medium text-gray-700"
        >
          Department *
        </label>
        <select className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm">
          <option value="">-- Select Department --</option>
          {depts.data.map((deptsItem) => (
            <option key={deptsItem.id} value={deptsItem.id}>               
              {deptsItem.name}
            </option>
          ))}
        </select> */}
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="manpower_requirement_id"
                className="block text-sm font-medium text-gray-700"
              >
                Choose Manpower
              </label>
              {manpowerLoading ? 'Loading Manpowerlist ...' : ' '}
              {manpowerSuccess ? (
                <select
                  id="manpower_requirement_id"
                  name="manpower_requirement_id"
                  autoComplete="manpower_requirement_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={selectManpowerFilter}
                  onBlur={formik.handleBlur}
                  value={formik.values.manpower_requirement_id}
                >
                  <option value="">-- Select Manpower --</option>

                  {manpowerlist.data
                    .filter(function (mpList) {
                      return mpList.approval_status == 1; // Filter only approved one's
                    })
                    .map((mpList) => (
                      <option key={mpList.id} value={mpList.id}>
                        {mpList.manpower_agency}
                      </option>
                    ))}
                </select>
              ) : (
                ''
              )}
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="workflow_id"
                className="block text-sm font-medium text-gray-700"
              >
                Choose Workflow
              </label>
              {wfLoading ? 'Loading Workflow ...' : ' '}
              {wfSuccess ? (
                <select
                  id="workflow_id"
                  name="workflow_id"
                  autoComplete="workflow_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.workflow_id}
                >
                  <option value="">-- Select Workflow --</option>
                  {wf.data.map((wfItem) => (
                    <option key={wfItem.id} value={wfItem.id}>
                      {/* {' '}
                  {wfItem.branch} - {wfItem.designation}{' '} */}
                      {wfItem.title}
                    </option>
                  ))}
                </select>
              ) : (
                ''
              )}
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label
                htmlFor="approved_by"
                className="block text-sm font-medium text-gray-700"
              >
                Approved by
              </label>
              <select
                id="approved_by"
                name="approved_by"
                autoComplete="approved_by"
                className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.approved_by}
              >
                <option value={0}>-- Select ApprovedBy--</option>
                {getSignoffUSer?.map((sfUser) => (
                  <option key={sfUser.id} value={sfUser.id}>
                    {`${sfUser.first_name} ${sfUser.last_name}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <div className="">
                <label
                  htmlFor="no_of_position"
                  className="block text-sm font-medium text-gray-700"
                >
                  Position #
                </label>
                <input
                  type="number"
                  name="no_of_position"
                  id="no_of_position"
                  autoComplete="no_of_position"
                  placeholder="10"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.no_of_position}
                />
                <div className=" italic text-[11px] mt-1">
                  <span className="font-bold">Total positions</span> -{' '}
                  {totalPosition}{' '}
                  <span className="font-bold">Used positions</span> -{' '}
                  {usedPosition}{' '}
                  <span className="font-bold">Available positions</span> -{' '}
                  {totalPosition - usedPosition}
                </div>
                {formik.touched.no_of_position && formik.errors.no_of_position ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.no_of_position}
                  </div>
                ) : null}
              </div>
              <div className="">
                <label
                  htmlFor="closing_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Closing Date - {selectedMPID}
                </label>
                {selectedMPID ? (
                  <p className="py-2.5 text-sm">
                    {formik.values.closing_date &&
                      format(new Date(formik.values.closing_date), 'dd-MM-yyyy')}
                  </p>
                ) : (
                  <input
                    type="date"
                    name="closing_date"
                    id="closing_date"
                    autoComplete="closing_date"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.closing_date}
                  />
                )}

                {/* {formik.touched.company_name && formik.errors.closing_date ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.closing_date}
                </div>
              ) : null} */}
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <div className="col-span-4 sm:col-span-1">
                <label
                  htmlFor="country_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Choose Country
                </label>
                {countryLoading ? 'Loading Country ...' : ' '}
                {countrySuccess ? (
                  selectedMPID ? (
                    <GetCountry countryid={formik.values.country_id} />
                  ) : (
                    <select
                      id="country_id"
                      name="country_id"
                      autoComplete="country_id"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={handleCountryChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country_id}
                    >
                      <option value="">-- Select Country--</option>
                      {filteredCountries?.map((cl) => (
                        <option key={cl.id} value={cl.id}>
                          {cl.country_name}
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="">
                <label
                  htmlFor="branch_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Choose Branch
                </label>
                {branchLoading ? 'Loading Country ...' : ' '}
                {branchSuccess ? (
                  selectedMPID ? (
                    <GetBranchName branchId={formik.values.branch_id} />
                  ) : (
                    <>
                      <select
                        id="branch_id"
                        name="branch_id"
                        autoComplete="branch_id"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.branch_id}
                      >
                        <option value="">-- Select Branch--</option>
                        {filteredBranch.map((branch) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {/* <div className="">
              <label
                htmlFor="department_id"
                className="block text-sm font-medium text-gray-700"
              >
                Choose Department
              </label>
              {deptsLoading ? 'Loading Department ...' : ' '}
              {deptsSuccess ? (
                <select
                  id="department_id"
                  name="department_id"
                  autoComplete="department_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department_id}
                >
                  <option value="">-- Select Department--</option>
                  {depts.data.map((dept) => (
                    <option key={dept.id} value={dept.id}>
                      {dept.name}
                    </option>
                  ))}
                </select>
              ) : (
                ''
              )}
            </div> */}
              <div className="">
                <label
                  htmlFor="budget"
                  className="block text-sm font-medium text-gray-700"
                >
                  Budget
                </label>
                <input
                  type="text"
                  name="budget"
                  id="budget"
                  autoComplete="budget"
                  placeholder="10L"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.budget}
                />
                {formik.touched.budget && formik.errors.budget ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.budget}
                  </div>
                ) : null}
              </div>
            </div>
          </>

          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => onModalDataClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!(formik.isValid)}
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AddJobRequest;

const DateToIndiaFormat = (inputFormat) => {
  const pad = (s) => {
    return s < 10 ? '0' + s : s;
  };
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
  // return <div>DateToIndiaFormat</div>;
};

export default DateToIndiaFormat;

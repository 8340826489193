import { apiSlice } from '../../app/api/apiSlice';
// import CandDetails from '../../pages/userworkspace/mytask/hiringstage/handover/handoverdetails/CandDetails';

export const handoverApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Handover', 'HandoverList', 'HandoverDetails', 'HandoverApproval'],
  endpoints: (builder) => ({
    handover: builder.query({
      query: () => ({
        url: '/handover',
        method: 'GET',
        keepUnusedDataFor: 5,
      }),
      providesTags: ['Handover'],
      // keepUnusedDataFor: 5,
    }),
    handoverApproval: builder.query({
      query: () => ({
        url: '/handover',
        method: 'GET',
      }),
      providesTags: ['HandoverApproval'],
      keepUnusedDataFor: 5,
    }),
    candHandoverlist: builder.query({
      query: (id) => ({
        url: `/handover/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['HandoverList'],
      keepUnusedDataFor: 5,
    }),
    candHandoverDetails: builder.query({
      query: (id) => ({
        url: `/handover/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['HandoverDetails'],
      keepUnusedDataFor: 5,
    }),
    handoverNegotation: builder.mutation({
      query: (data) => ({
        url: '/handover/candidate/negotation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['HandoverList', 'HandoverDetails', 'HandoverApproval'],
    }),

    handoverCandidate: builder.mutation({
      query: (id) => ({
        url: `/handover/candidate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['HandoverList', 'HandoverDetails', 'HandoverApproval'],
    }),

    // offerletterSubmit: builder.mutation({
    //   query: (data) => ({
    //     url: '/offer/candidate/offer_letter',
    //     method: 'PUT',
    //     body: { ...data },
    //   }),
    //   invalidatesTags: ['OfferList', 'OfferDetails'],
    // }),
  }),
});

export const {
  useHandoverQuery,
  useCandHandoverlistQuery,
  useCandHandoverDetailsQuery,
  useHandoverNegotationMutation,
  useHandoverCandidateMutation,

  // useOfferletterSubmitMutation,
} = handoverApiSlice;

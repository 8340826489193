import React, { useState, useEffect } from 'react';
import {
  useAddManpowerMutation,
  useUpdateManpowerMutation,
} from '../../../../features/manpower/manpowerApiSlice';
import { useCountryQuery } from '../../../../features/country/countryApiSlice';
import { useBranchQuery } from '../../../../features/branch/branchApiSlice';
import { useDepartmentQuery } from '../../../../features/department/departmentApiSlice';
import Notification from '../../../../components/common/Notification';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'sonner';
import { useTeamsSignoffQuery } from '../../../../features/teams/teamsApiSlice';

const AddManpower = ({ onModalDataClose, showNotify, data }) => {

  const [addManpower, { isLoading }] = useAddManpowerMutation();
  const [updateManpower, { isLoading: isLoadingupdate }] =
    useUpdateManpowerMutation();
  const [showNotification, setShowNotification] = useState(false);
  const { data: usersSignoff, isLoading: userSignoffLoading } =
    useTeamsSignoffQuery({ refetchOnMountOrArgChange: true });

  const getSignoffUSer = usersSignoff?.data?.map((user) => {
    let numberArray = user.privileges.split(',');
    if (numberArray.indexOf('4') !== -1) {
      return user;
    }
  });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });
  console.log(countryList)
  // Added Department list to display - Update
  const {
    data: departmentList,
    error: departmentErr,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
    isSuccess: departmentSuccess,
  } = useDepartmentQuery({ refetchOnMountOrArgChange: true });

  // Extract unique country IDs from the cities array
  const uniqueCountryIds = [
    ...new Set(branchs?.data?.map((branch) => branch.location.country_id)),
  ];
  const filteredCountries = countryList?.data?.filter((country) =>
    uniqueCountryIds.some((cityId) => cityId === country.id)
  );

  const closeWindow = () => {
    return onModalDataClose;
  };
  const initialValues =
    data.mode == 'Edit'
      ? {
        id: data.mpList.id || 0,
        country_id: data.mpList.country_id || '',
        branch_id: data.mpList.branch_id || '',
        manpower_agency: data.mpList.manpower_agency || '',
        no_of_position: data.mpList.no_of_position || '',
        budget: data.mpList.budget || '',
        priority: '',
        department_id: data.mpList.department_id || '',
        comments: data.mpList.comments || '',
        closing_date: data.mpList.closing_date || '',
        approved_by: data.mpList.approved_by || '',
      }
      : {
        country_id: '',
        branch_id: '',
        department_id: '',
        manpower_agency: '',
        no_of_position: '',
        budget: '',
        priority: '',
        department: '',
        comments: '',
        closing_date: '',
        approved_by: '',
      };
  const validationSchema = yup.object({
    manpower_agency: yup.string().required('Please enter Title  '),
    country_id: yup.string().required('Please select Country '),
    priority: yup.string().required('Please select Priority '),
    department_id: yup.string().required('Please select Depatment '),
    branch_id: yup.string().required('Please select Branch '),
    approved_by: yup.string().required('Please select approved by '),
    closing_date: yup.string().required('Please select closing date '),
    no_of_position: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Number must be non-negative')
      .required('No. of Position is required'),
    budget: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Number must be non-negative')
      .required('Budget is required'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      if (data.mode == 'Edit') {
        const accountData = await updateManpower(validationSchema).unwrap();
        toast.success('Manpower saved');
      } else {
        const accountData = await addManpower(validationSchema).unwrap();
        formik.values.name = '';
        formik.values.location_id = '';
        toast.success('Manpower created');
      }
      setShowNotification(!showNotification);
      onModalDataClose();
      // onClose();
      // showNotify()
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  let filterBranchValues = [];
  const [filteredBranch, setFilteredBranch] = useState([]);

  const handleCountryChange = (event) => {
    const country = event.target.value;
    formik.setFieldValue('country_id', country);
    formik.setFieldValue('branch_id', ''); // Reset state when country changes

    filterBranchValues = branchs?.data?.filter(
      (branch) => branch.location.country_id == country
    );
    setFilteredBranch(filterBranchValues);
  };

  useEffect(() => {
    if (formik.values.country_id !== '') {
      // formik.setFieldValue('country_id', country);
      // formik.setFieldValue('branch_id', ''); // Reset state when country changes

      filterBranchValues = branchs?.data?.filter(
        (branch) => branch.location.country_id == formik.values.country_id
      );
      setFilteredBranch(filterBranchValues);
    }
  }, []);

  return (
    <>
      <Notification isShow={false} />
      <form
        className="flex flex-col justify-between"
        onSubmit={formik.handleSubmit}
      // onSubmit={(e) => onModalDataSubmit(e)}
      >
        <h1 className="text-lg text-black font-bold">{data.title}</h1>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="country_id"
              className="block text-sm font-medium text-gray-700"
            >
              Choose country *
            </label>
            {countryLoading ? 'Loading Country..' : ' '}
            {countrySuccess ? (
              <>
                <select
                  id="country_id"
                  name="country_id"
                  autoComplete="country_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={handleCountryChange}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country_id}
                >
                  <option val="-1">-- Select Country --</option>
                  {countryList &&
                    countryList?.data &&
                    countryList?.data.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.country_name}
                      </option>
                    ))}
                </select>
                {formik.touched.country_id && formik.errors.country_id ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.country_id}
                  </div>
                ) : null}
              </>
            ) : (
              ' '
            )}
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="priority"
              className="block text-sm font-medium text-gray-700"
            >
              Priority *
            </label>
            <select
              id="priority"
              name="priority"
              autoComplete="priority"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.priority}
            >
              <option>-- Select Priority--</option>
              <option value={'Low'}>Low</option>
              <option value={'Medium'}>Medium</option>
              <option value={'High'}>High</option>
            </select>
            {formik.touched.priority && formik.errors.priority ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.priority}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense ">
          <label
            htmlFor="department_id"
            className="block text-sm font-medium text-gray-700"
          >
            Department *
          </label>
          <select
            id="department_id"
            name="department_id"
            autoComplete="department_id"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3 
           focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.department_id}
          >
            <option value="">-- Select Department --</option>
            {departmentList &&
              departmentList?.data &&
              departmentList?.data.map((deptsItem) => (
                <option key={deptsItem?.id} value={deptsItem?.id}>
                  {deptsItem.name}
                </option>
              ))}
          </select>
          {formik.touched.department_id && formik.errors.department_id ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.department_id}
            </div>
          ) : null}
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="branch_id"
              className="block text-sm font-medium text-gray-700"
            >
              Branch *
            </label>
            {branchLoading ? 'Loading Branch...' : ' '}
            {branchSuccess ? (
              <>
                <select
                  id="branch_id"
                  name="branch_id"
                  autoComplete="branch_id"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branch_id}
                >
                  <option value="">-- Select Branch --</option>
                  {filteredBranch.length > 0 ? (
                    filteredBranch.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No branches in this country</option>
                  )}
                </select>
                {formik.touched.branch_id && formik.errors.branch_id ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.branch_id}
                  </div>
                ) : null}
              </>
            ) : (
              ' '
            )}
          </div>
          <div className="">
            <label
              htmlFor="manpower_agency"
              className="block text-sm font-medium text-gray-700"
            >
              Manpower Requirement Title *
            </label>
            <input
              type="text"
              name="manpower_agency"
              id="manpower_agency"
              autoComplete="manpower_agency"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.manpower_agency}
            />
            {formik.touched.manpower_agency && formik.errors.manpower_agency ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.manpower_agency}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="">
            <label
              htmlFor="no_of_position"
              className="block text-sm font-medium text-gray-700"
            >
              Position *
            </label>
            <input
              type="number"
              name="no_of_position"
              id="no_of_position"
              autoComplete="no_of_position"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.no_of_position}
            />
            {formik.touched.no_of_position && formik.errors.no_of_position ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.no_of_position}
              </div>
            ) : null}
          </div>
          <div className="">
            <label
              htmlFor="budget"
              className="block text-sm font-medium text-gray-700"
            >
              Budget *
            </label>
            <input
              type="text"
              name="budget"
              id="budget"
              autoComplete="budget"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.budget}
            />
            {formik.touched.budget && formik.errors.budget ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.budget}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="">
            <label
              htmlFor="closing_date"
              className="block text-sm font-medium text-gray-700"
            >
              Closing Date *
            </label>
            <input
              type="date"
              name="closing_date"
              id="closing_date"
              autoComplete="closing_date"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.closing_date}
            />
            {formik.touched.closing_date && formik.errors.closing_date ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.closing_date}
              </div>
            ) : null}
          </div>

          <div className="col-span-4 sm:col-span-1">
            <label
              htmlFor="approved_by"
              className="block text-sm font-medium text-gray-700"
            >
              Approved by *
            </label>
            <select
              id="approved_by"
              name="approved_by"
              autoComplete="approved_by"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.approved_by}
            >
              <option value={0}>-- Select ApprovedBy--</option>
              {getSignoffUSer?.map((sfUser) => (
                <option key={sfUser.id} value={sfUser.id}>
                  {`${sfUser.first_name} ${sfUser.last_name}`}
                </option>
              ))}
            </select>
            {formik.touched.approved_by && formik.errors.approved_by ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.approved_by}
              </div>
            ) : null}
          </div>

          <div className="col-span-2">
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-gray-700"
            >
              Comments
            </label>
            <textarea
              rows={5}
              name="comments"
              id="comments"
              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              // defaultValue={''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.comments}
            />
            {/* {formik.touched.comments && formik.errors.comments ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.comments}
                  </div>
                ) : null} */}
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="button"
            className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => onModalDataClose()}
          >
            Cancel
          </button>
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              disabled={!(formik.dirty && formik.isValid)}
              type="submit"
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              Save
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default AddManpower;

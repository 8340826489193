import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  useTeamsQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
} from '../../features/teams/teamsApiSlice';
import { useDepartmentQuery } from '../../features/department/departmentApiSlice';
import { useDesignationQuery } from '../../features/designation/designationApiSlice';
import { useLocationQuery } from '../../features/location/locationApiSlice';
import { useCountryQuery } from '../../features/country/countryApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Notification from '../../components/common/Notification';
import { toast } from 'sonner';
import {
  useBranchQuery,
  useDeleteBranchMutation,
} from '../../features/branch/branchApiSlice';

const AddEmployee = () => {
  let { id } = useParams();
  const [addEmployee, { isLoading }] = useAddEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [errMsg, setErrMsg] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  // List of user details
  const {
    data: users,
    error: userError,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
    isSuccess: userIsSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error,
    isLoading: isFetching,
    isSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  // List of department details
  const {
    data: depts,
    error: deptsError,
    isLoading: deptsIsLoading,
    isFetching: deptsIsFetching,
    isSuccess: deptsIsSuccess,
  } = useDepartmentQuery({ refetchOnMountOrArgChange: true });

  // List of designation details
  const {
    data: desigs,
    error: desigsError,
    isLoading: desigsIsLoading,
    isFetching: desigsIsFetching,
    isSuccess: desigsIsSuccess,
  } = useDesignationQuery({ refetchOnMountOrArgChange: true });

  // List of location details
  const {
    data: locations,
    error: locationError,
    isLoading: locationIsLoading,
    isFetching: locationIsFetching,
    isSuccess: locationIsSuccess,
  } = useLocationQuery({ refetchOnMountOrArgChange: true });

  // List of country details
  const {
    data: countrys,
    error: countryError,
    isLoading: countryIsLoading,
    isFetching: countryIsFetching,
    isSuccess: countryIsSuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const currentUserValue = users?.data.filter((user) => user.id == id);

  const initialValues = id
    ? {
      id: currentUserValue[0]?.id || '',
      first_name: currentUserValue[0]?.first_name || '',
      last_name: currentUserValue[0]?.last_name || '',
      email: currentUserValue[0]?.email || '',
      mobile: currentUserValue[0]?.mobile || '',
      blood_group: currentUserValue[0]?.blood_group || '',
      employee_code: currentUserValue[0]?.employee_code || '',
      employee_type: currentUserValue[0]?.employee_type || '',
      designation_id: currentUserValue[0]?.designation_id || '',
      department_id: currentUserValue[0]?.department_id || '',
      dialCode: '91',
      doj: currentUserValue[0]?.doj || '',
      dob: currentUserValue[0]?.dob || '',
      country_id: currentUserValue[0]?.country_id || '',
      role_id: currentUserValue[0]?.role_id || '2',
      branch_id: currentUserValue[0]?.branch_id || '',
      state_id: currentUserValue[0]?.state_id || '',
      photo: currentUserValue[0]?.photo || '',
      document: currentUserValue[0]?.documents || '',
      password: currentUserValue[0]?.password || '',
    }
    : {
      id: currentUserValue[0]?.id || '',
      first_name: currentUserValue[0]?.first_name || '',
      last_name: currentUserValue[0]?.last_name || '',
      email: currentUserValue[0]?.email || '',
      mobile: currentUserValue[0]?.mobile || '',
      blood_group: currentUserValue[0]?.blood_group || '',
      password: currentUserValue[0]?.password || '',
      employee_code: currentUserValue[0]?.employee_code || '',
      employee_type: currentUserValue[0]?.employee_type || '',
      designation_id: currentUserValue[0]?.designation_id || '',
      department_id: currentUserValue[0]?.department_id || '',
      dialCode: '91',
      doj: currentUserValue[0]?.doj || '',
      dob: currentUserValue[0]?.dob || '',
      country_id: currentUserValue[0]?.country_id || '',
      role_id: currentUserValue[0]?.role_id || '2',
      branch_id: currentUserValue[0]?.branch_id || '',
      state_id: currentUserValue[0]?.state_id || '1',
      photo: currentUserValue[0]?.photo || '',
      document: currentUserValue[0]?.documents || '',
    };

  const validationSchema = id
    ? yup.object({
      first_name: yup.string().required('Please enter  first name'),
      last_name: yup
        .string()
        .required('Please enter last name')
        .min(3, 'Please enter Minimum three character'),
      email: yup.string().required('Please enter email address'),
      mobile: yup.string().required('Please enter mobile number'),
      dob: yup.string().required('Please enter date of birth'),
      doj: yup.string().required('Please enter date of joining'),
      designation_id: yup.string().required('Please select designation'),
      department_id: yup.string().required('Please select department'),
      state_id: yup.string().required('Please select state'),
      country_id: yup.string().required('Please select country'),
      branch_id: yup.string().required('Please select branch'),
      employee_type: yup.string().required('Please select Team Role'),
      // employee_code: yup.string().required('please select employee code'),
      // password: yup.string().required('Please enter password'),
      // blood_group: yup.string().required('Please enter blood group'),
    })
    : yup.object({
      first_name: yup.string().required('Please enter  first name'),
      last_name: yup
        .string()
        .required('Please enter last name')
        .min(3, 'Please enter Minimum three character'),
      email: yup.string().required('Please enter email address'),

      mobile: yup.string().required('Please enter mobile number'),
      dob: yup.string().required('Please enter date of birth'),
      doj: yup.string().required('Please enter date of joining'),
      designation_id: yup.string().required('Please select designation'),
      department_id: yup.string().required('Please select department'),
      state_id: yup.string().required('Please select state'),
      country_id: yup.string().required('Please select country'),
      branch_id: yup.string().required('Please select branch'),
      employee_type: yup.string().required('Please select Team Role'),
      // employee_code: yup.string().required('please select employee code'),
      password: yup.string().required('Please enter password'),
      // blood_group: yup.string().required('Please enter blood group'),
    });

  const onSubmit = async (validationSchema) => {
    setErrMsg('');
    try {
      if (id > 0) {
        const employeeData = await updateEmployee(validationSchema).unwrap();
        toast.success('Teams Updated');
        // setShowNotification(!showNotification);
        navigate(-1);
      } else {
        const employeeData = await addEmployee(validationSchema).unwrap();
        formik.values.name = '';
        formik.values.description = '';
        // setShowNotification(!showNotification);
        toast.success('Teams Added');
        navigate(-1);
      }
      // navigate(-1);
      // onClose();
    } catch (error) {
      if (!error) {
        setErrMsg('No Server Response');
      } else {
        setErrMsg(error.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Notification isShow={showNotification} />

      <section aria-labelledby="payment-details-heading">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-between"
        >
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div className="flex justify-between items-center">
                <h2
                  id="emplyoee-details-heading"
                  className="text-lg font-bold leading-6 text-gray-900"
                >
                  {id ? 'Edit Employee' : 'Add Employee'}
                </h2>

                <div className="flex">
                  <Link
                    type="button"
                    to={'/app/team'}
                    className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
                  >
                    <ArrowLeftIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Back
                  </Link>
                </div>
              </div>
              <div className="mt-2 text-sm text-red-600">
                {errMsg ? errMsg : ''}
              </div>
              <div className="mt-6">
                <h3 className="text-sm font-bold uppercase text-purplecolor-300">
                  Personal Information
                </h3>
                <div className="mt-3 grid xl:grid-cols-4 sm:grid-cols-3 gap-6 border-b-2 border-gray-200 pb-6">
                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      autoComplete="cc-given-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      autoComplete="cc-family-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="mobile"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      autoComplete="mobile"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.mobile}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1 relative">
                    <label
                      htmlFor="blood_group"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Blood Group
                    </label>
                    <input
                      type="text"
                      name="blood_group"
                      id="blood_group"
                      autoComplete="blood_group"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.blood_group}
                    />

                    {formik.touched.blood_group && formik.errors.blood_group ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.blood_group}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <label
                      htmlFor="dob"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      autoComplete="dob"
                      className="bg-inputbg border border-inputborder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                    />
                    {formik.touched.dob && formik.errors.dob ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.dob}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Branch
                    </label>
                    <select
                      id="branch_id"
                      name="branch_id"
                      autoComplete="branch_id"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.branch_id}
                    >
                      <option value="-1">-- Select Branch --</option>
                      {branchs?.data.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.branch_id && formik.errors.branch_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.branch_id}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country_id"
                      name="country_id"
                      autoComplete="country-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country_id}
                    >
                      <option value="-1">--Select Country --</option>
                      {countrys?.data.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.country_name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.country_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <h3 className="mt-6 text-sm font-bold uppercase text-purplecolor-300">
                  Official Information
                </h3>
                <div className="mt-3 grid xl:grid-cols-4 sm:grid-cols-3 gap-6">
                  <div className="col-span-4 sm:col-span-1 relative">
                    <label
                      htmlFor="doj"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Join
                    </label>
                    <input
                      type="date"
                      name="doj"
                      id="doj"
                      autoComplete="doj"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.doj}
                    />
                    {formik.touched.doj && formik.errors.doj ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.doj}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="employee_code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Employee Code
                    </label>
                    <input
                      type="text"
                      name="employee_code"
                      id="employee_code"
                      autoComplete="employee_code"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employee_code}
                    />
                    {formik.touched.employee_code &&
                      formik.errors.employee_code ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.employee_code}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="employee_type"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Team Role
                      {/* Employee Type */}
                    </label>
                    <select
                      id="employee_type"
                      name="employee_type"
                      autoComplete="employee_type"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employee_type}
                    >
                      <option value="-1">-- Select Team Role --</option>
                      <option>Admin</option>
                      <option>HR</option>
                      <option>Interviewer</option>
                    </select>
                    {formik.touched.employee_type &&
                      formik.errors.employee_type ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.employee_type}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="designation_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Designation
                    </label>
                    <select
                      id="designation_id"
                      name="designation_id"
                      autoComplete="designation"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.designation_id}
                    >
                      <option value="-1">-- Select Designation ---</option>
                      {desigs?.data.map((desig) => (
                        <option key={desig.id} value={desig.id}>
                          {desig.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.designation_id &&
                      formik.errors.designation_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.designation_id}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="department_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Department
                    </label>
                    <select
                      id="department_id"
                      name="department_id"
                      autoComplete="department"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.department_id}
                    >
                      <option value="-1">-- Select Department --</option>
                      {depts?.data.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.department_id &&
                      formik.errors.department_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.department_id}
                      </div>
                    ) : null}
                  </div>
                  {!id ? (
                    <div className="col-span-4 sm:col-span-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Password
                      </label>
                      <input
                        type="text"
                        name="password"
                        id="password"
                        autoComplete="cc-given-name"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="border border-t-gray-300 bg-white px-4 py-3 text-right sm:px-6">
              {isLoading ? (
                <button
                  type="submit"
                  disabled
                  className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                >
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                  disabled={!(formik.dirty && formik.isValid)}
                  type="submit"
                  className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddEmployee;

import { createSlice } from '@reduxjs/toolkit';

const candidateSlice = createSlice({
  name: 'candidate',
  initialState: null,
  reducers: {
    setCandId: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCandId } = candidateSlice.actions;

export default candidateSlice.reducer;

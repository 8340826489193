import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { selectCandidateInterview } from '../../../../../../../features/hiringstages/candidateInterviewSlice';
import { useGetInterviewCandDetailsQuery } from '../../../../../../../features/hiringstages/interviewApiSlice';
import { useSelector } from 'react-redux';
// import { json } from 'stream/consumers';

// **** code by suganthi
import ModalData from '../../../../../../../components/common/ModalData';
import { InterviewerScoreDetails } from '../modal/InterviewerScoreDetails';

function InterviewScore() {
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const candId = useSelector(selectCandidateInterview);
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useGetInterviewCandDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });
  // console.log(canddetails)
  const interviewData = canddetails?.data?.interview;
  const techSkills = canddetails?.data?.technical_skills;

  let reversedInterviewData = interviewData?.slice().reverse();
  console.log(reversedInterviewData)

  function calculateWeightedAverage(category) {
    // console.log(category)
    // const skills = [category];
    const totalRating = category.reduce((acc, skill) => acc + skill.rating, 0);
    const averageRating = totalRating / category.length;
    return averageRating;
  }

  function totalPer(technicalSkillsArray) {
    // if (!Array.isArray(technicalSkillsArray)) {
    // console.log('technical_skills is not an array:', technicalSkillsArray);
    //   return 0; // Return a default value or handle the error as needed
    // }

    const { mandatoryAverage, preferredAverage, generalAverage, otherSkillsAverage } =
      getAverageRatings(technicalSkillsArray);

    // // Flatten the skills array to make it easier to process
    // const allSkills = technicalSkillsArray.flatMap(category => category.skills);

    // const mandatoryAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'mandatory')
    // );
    // const preferredAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'prefered')
    // );
    // const generalAverage = calculateWeightedAverage(
    //   allSkills.find((skill) => skill.name === 'general')
    // );

    const mandatoryWeight = 0.7;
    const preferredWeight = 0.2;
    const generalWeight = 0.1;
    // const otherSkillsWeight = 0.1;

    const totalWeightedAverage =
      mandatoryAverage * mandatoryWeight +
      preferredAverage * preferredWeight +
      generalAverage * generalWeight
    // + otherSkillsAverage;
    // console.log('totalWeightedAverage : ', totalWeightedAverage);
    return totalWeightedAverage;
  }

  // **** code by tist

  function getAverageRatings(skillsArray) {
    // console.log(skillsArray)
    if (skillsArray.length === 0) {
      return {
        mandatoryAverage: 0,
        preferredAverage: 0,
        generalAverage: 0,
      };
    }
    const skillCategories = {};

    skillsArray.forEach((record) => {
      record.skills.forEach((skill) => {
        if (!skillCategories[record.name]) {
          skillCategories[record.name] = [];
        }
        skillCategories[record.name].push(skill);
      });
    });
    // console.log('skillCategories : ', skillCategories);
    const mandatorySkills = skillCategories['mandatory'] || [];
    const otherSkills = skillCategories['Other Skills'] || [];
    // console.log('otherSkills : ', otherSkills);
    // Combine mandatory and Other Skills
    const combinedSkills = [...mandatorySkills, ...otherSkills];
    // console.log('combinedSkills : ', combinedSkills);
    const mandatoryAverage = calculateWeightedAverage(
      combinedSkills
    );
    // console.log(mandatoryAverage)

    const preferredAverage = calculateWeightedAverage(
      skillCategories['prefered']
    );
    const generalAverage = calculateWeightedAverage(skillCategories['general']);

    // const otherSkillsAverage = calculateWeightedAverage(
    //   skillCategories['Other Skills']
    // );
    // console.log(otherSkillsAverage)
    return { mandatoryAverage, preferredAverage, generalAverage };
  }

  let mergedSkills = [];

  if (Array.isArray(reversedInterviewData)) {
    reversedInterviewData.forEach((interview) => {
      // console.log(interview.technical_skills)
      if (interview.technical_skills) {
        try {
          const skills = interview.technical_skills;
          mergedSkills = mergedSkills.concat(skills);
        } catch (error) {
          console.log('Error parsing interview.technical_skills:', error);
        }
      }
    });
  } else {
    console.log('data is not an array:', reversedInterviewData);
  }
  // console.log('mergedSkills : ', mergedSkills);
  const totalWeightedAverage = (totalPer(mergedSkills) * 10).toFixed(1);
  // console.log('totalWeightedAverage : ', totalWeightedAverage);

  const openModalDataWindow = (sendtoModal) => {
    // console.log(sendtoModal)
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const onModalDataSubmit = (e) => {
    e.preventDefault();
    // showNotificationMsg();
  };

  const updateLocalSkillsId = (selId) => {
    console.log('selId ', selId);
    // const isSelected = selectedIds?.includes(selId);
    // if (isSelected) {
    //   setSelectedIds(selectedIds?.filter((selectedId) => selectedId !== selId));
    // } else {
    //   setSelectedIds([...selectedIds, selId]);
    // }
  };
  const uniqueInterviewData = reversedInterviewData?.reduce((acc, current) => {
    const exists = acc.find(
      (item) => item?.interview_stage?.id === current?.interview_stage?.id
    );
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, []);
  //****** */

  return (
    <>
      {reversedInterviewData?.length > 0 ? (
        <>
          <div className=" ">
            <div className="">
              <div className="">
                <div className="">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 bg-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <td
                            scope="col"
                            className="py-3.5 w-[20px] pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Factors
                          </td>
                          {uniqueInterviewData.map((list, index) => (
                            <td className="px-3 py-4 text-sm text-gray-500 ">
                              <Link
                                type="button"
                                to={''}
                                onClick={() =>
                                  openModalDataWindow({
                                    stageId: list?.interview_stage?.id
                                  })
                                }
                                className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                              >
                                Level {' '}
                                {list?.interview_stage?.sno} -{' '}
                                {list?.interview_stage?.title}{' '}
                                - {list?.interview_mode}
                              </Link>
                            </td>
                          ))
                          }
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className="whitespace-nowrap px-3 py-4 text-lg text-gray-800 font-semibold capitalize">
                            Average Score
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-800 text-center text-lg font-semibold capitalize">
                            {totalWeightedAverage}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </>
      ) : (
        <div>
          <h1> No interview</h1>
        </div>
      )}
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        <InterviewerScoreDetails
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          data={openModalDataValues}
          updateSkillsArray={updateLocalSkillsId}
        />
      </ModalData>
    </>
  );
}

export default InterviewScore;

import React from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import { selectCurrentEmail } from '../../features/auth/register/registerSlice';

const TermsCondition = () => {
  const registeredEmail = useSelector(selectCurrentEmail);
  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-start py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full ">
          <div className="bg-white py-8 px-10 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" width="240" />
            </div>

            <div>
              <div>
                {/* <CheckCircleIcon className="bg-white" /> */}

                <h5 className="mt-6 text-2xl font-semibold text-start tracking-tight text-gray-900">
                  Term and Conditions
                </h5>
                <p className="text-start text-sm font-normal text-verify pb-5 mt-1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishin
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>

                <p className="text-start text-sm font-normal text-verify pb-5 mt-1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishin
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>

                <p className="text-start text-sm font-normal text-verify pb-5 mt-1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishin
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>

                <p className="text-start text-sm font-normal text-verify pb-5 mt-1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishin
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>
              </div>

              <div className="">
                <div className="flex justify-center" aria-hidden="true">
                  <div className="w-48 border-t border-gray-300 md:divide-y-8 flex justify-center text-center" />
                </div>
              </div>
              <div>
                <p className="text-center text-xs font-normal text-verify pt-6">
                  Clickhere
                  <Link
                    to={'/register'}
                    className="text-clickhere text-md font-semibold"
                  >
                    {' '}
                    Register
                  </Link>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;

import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowUpIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  PlusIcon,
  PaperClipIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import ModalData from '../../../../../components/common/ModalData';

import CandDetails from './CandDetails';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import {
  useCandJoiningDetailsQuery,
  useJoiningNegotationMutation,
} from '../../../../../features/hiringstages/joiningApiSlice';
import CandCalling from './modal/CandCalling';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const JoiningDetails = ({ toggleJoining }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const candId = useSelector(selectCandidateInterview);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useCandJoiningDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  const [joiningNegotation, { isLoading: joiningNegotationLoading }] =
    useJoiningNegotationMutation();

  const canddetailsObj = canddetails?.data;
  const candJoingingArray = canddetailsObj?.joinings;
  const candJoingingReverse = canddetailsObj?.joinings?.slice().reverse();

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
      role: 'Member',
    },
    // More people...
  ];

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getOverallScore = (data) => {
    let value =
      JSON.parse(data?.interview_with_other_company) +
      JSON.parse(data?.other_offers_in_hand) +
      JSON.parse(data?.attitude) +
      JSON.parse(data?.response_to_communication) +
      JSON.parse(data?.knowledge_about_company) +
      JSON.parse(data?.interest_towards_company) +
      JSON.parse(data?.satisfaction_towards_CTC) +
      JSON.parse(data?.satisfaction_towards_job_role) +
      JSON.parse(data?.interest_to_involve_with_internal_teams) +
      JSON.parse(data?.location_satisfaction);

    return (value / 10) * 10;
  };

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    negotiated_doj: canddetailsObj?.negotiated_doj || '',
    // negotiated_salary: canddetailsObj?.negotiated_salary || '',
    negotiated_salary: canddetailsObj?.negotiated_salary || '6000',
    negotiated_notice_period: canddetailsObj?.negotiated_notice_period || '',
    payout_option_required: canddetailsObj?.payout_option_required || '',
    payout_amount: canddetailsObj?.payout_amount || '',
    negotiation_comments: canddetailsObj?.negotiation_comments || '',
  };

  const validationSchema = yup.object({
    negotiated_salary: yup.string().required('Please enter negotiate salary'),
    negotiated_doj: yup.string().required('Please select the date of joining'),
    negotiated_notice_period: yup
      .string()
      .required('please enter the notice period'),
    payout_option_required: yup
      .string()
      .required('Please select the Buyout option '),
    payout_amount: yup.string().required('Please enter the Buyout amount'),
    negotiation_comments: yup.string().required('Please enter the Comments'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const rrrr = await joiningNegotation(validationSchema).unwrap();
      alert('Negotation sumbitted Successfully');
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const gotoJoiningList = (value, candid) => {
    toggleJoining(value);
  };

  const getPrediction = () => {
    let predictionScore = 0;
    candJoingingReverse?.length > 0 &&
      candJoingingReverse.map((joining) => {
        predictionScore = getOverallScore(joining);
      });
    return Math.round(predictionScore);
  };

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoJoiningList('joininglist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails canddetails={canddetails} />

        <div className="basis-5/6">
          <div className="flex flex-row  ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
                <div className="text-right">
                  <button
                    className="flex bg-blue-500 justify-center p-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                    onClick={() =>
                      openModalDataWindow({
                        mode: 'Add',
                        title: `Calling Score`,
                        data: {
                          obj: canddetailsObj,
                        },
                      })
                    }
                  >
                    Add Calls
                  </button>
                </div>
                <div className="bg-white ">
                  <div className="px-6 py-4">
                    <div className="">
                      <div className="">
                        <div className="inline-block min-w-full py-0 align-middle bg-white ">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="border-y border-gray-300">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Factors
                              </th>
                              {candJoingingReverse?.length > 0 &&
                                candJoingingReverse.map((joining) => (
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-md font-semibold text-gray-900"
                                  >
                                    {`Level: ${joining?.level} Calling Score`}
                                  </th>
                                ))}
                              {/* <h1 className="  font-semibold px-0 text-xl mt-3 text-gray-700 pb-4">
                                Basic Skill
                              </h1> */}
                            </thead>
                            <tbody>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interview with other company
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interview_with_other_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Other offers in hand
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.other_offers_in_hand * 10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Attitude
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${joining?.attitude * 10}%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Response to communication
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.response_to_communication *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Knowledge about company
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.knowledge_about_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest towards company
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interest_towards_company *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards CTC
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.satisfaction_towards_CTC *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards Job Role
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.satisfaction_towards_job_role *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest to involve with internal teams
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.interest_to_involve_with_internal_teams *
                                            10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Location Satisfaction
                                </th>
                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining, i) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {joining != null
                                        ? `${
                                            joining?.location_satisfaction * 10
                                          }%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                              <tr className="border-t border-gray-300">
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-0"
                                >
                                  Overall Score:
                                </th>

                                {candJoingingReverse?.length > 0 &&
                                  candJoingingReverse.map((joining) => (
                                    <th
                                      scope="col"
                                      className="py-3.5 text-sidebar text-xl font-semibold pl-4 pr-3  sm:pl-0 text-center"
                                    >
                                      {joining
                                        ? `${getOverallScore(joining)}%`
                                        : '-'}
                                    </th>
                                  ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white flex justify-start py-4 px-10">
                  <span className="text-xl font-bold">
                    Current Joining Status :
                    <b className="text-blue-600 text-xl font-semibold">
                      {getPrediction()} Unpredictable
                    </b>
                  </span>
                </div>

                <form
                  className="  border-t border-gray-300 py-4 mx-10"
                  onSubmit={formik.handleSubmit}
                >
                  <div class="flex flex-row">
                    <div class="basis-3/5">
                      <div className=" rounded-md mx-0">
                        {' '}
                        <div className="mt-0 grid grid-cols-2 gap-6 px-0">
                          <div className="grid grid-cols-2">
                            <div className="mt-3 ">
                              <label
                                htmlFor="expected_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Expected Salary
                              </label>
                            </div>
                            {/* <div>{canddetailsObj?.salary_expectation}</div> */}
                            <div>
                              <h5 className="mt-0 font-bold text-sm read-only">
                                {canddetailsObj?.salary_expectation}
                              </h5>
                            </div>
                          </div>

                          <div className="grid grid-cols-2">
                            <div className="mt-3 ">
                              <label
                                htmlFor="negotiated_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated Salary
                              </label>
                            </div>
                            <div>
                              <input
                                type="number"
                                name="negotiated_salary"
                                id="negotiated_salary"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.negotiated_salary}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              />
                              {formik.touched.negotiated_salary &&
                              formik.errors.negotiated_salary ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.negotiated_salary}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mt-0 grid grid-cols-2 gap-6 px-0">
                          <div className="grid grid-cols-2">
                            <div className="mt-3 ">
                              <label
                                htmlFor="negotiated_DOJ"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated DOJ
                              </label>
                            </div>
                            <div>
                              <input
                                type="date"
                                name="negotiated_doj"
                                id="negotiated_doj"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.negotiated_doj}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              />

                              {formik.touched.negotiated_doj &&
                              formik.errors.negotiated_doj ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.negotiated_doj}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="grid grid-cols-2 ">
                            <div className="mt-3 mr-6 ">
                              <label
                                htmlFor="notice_period"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Notice Period
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                name="negotiated_notice_period"
                                id="negotiated_notice_period"
                                placeholder="15 days"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.negotiated_notice_period}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              />

                              {formik.touched.negotiated_notice_period &&
                              formik.errors.negotiated_notice_period ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.negotiated_notice_period}
                                </div>
                              ) : null}

                              {/* <select
                            name="notice_period"
                            id="notice_period"
                            autoComplete="notice_period"
                            placeholder="Chennai, India"

                            className="bg-inputbg border border-inputBorder mt-1 block w-64 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                          >
                            <option value="-1">
                              -- Select Notice Period --
                            </option>
                            <option value="-1"> 10 Days </option>
                            <option value="-1"> 15 Days </option>
                          </select> */}

                              <div className="mt-2 text-sm text-red-600">
                                {/* {formik.errors.title} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-0 grid grid-cols-2 gap-6 px-0">
                          <div className="grid grid-cols-2">
                            <div className="mt-3 ">
                              <label
                                htmlFor="buyout_option"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Option
                              </label>
                            </div>
                            <div>
                              <select
                                id="payout_option_required"
                                name="payout_option_required"
                                autoComplete="payout_option_required"
                                className="bg-white border border-inputBorder mt-0 w-40 rounded-md  py-2.5 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.payout_option_required}
                              >
                                <option>-- Select --</option>
                                <option value={'1'}>Yes</option>
                                <option value={'2'}>No </option>
                              </select>

                              {formik.touched.payout_option_required &&
                              formik.errors.payout_option_required ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.payout_option_required}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="grid grid-cols-2">
                            <div className="mt-3 mr-5">
                              <label
                                htmlFor="buyout_amount"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Amount
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                name="payout_amount"
                                id="payout_amount"
                                autoComplete="payout_amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.payout_amount}
                                className="bg-inputbg border border-inputBorder mt-1  w-40 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              />

                              {formik.touched.payout_amount &&
                              formik.errors.payout_amount ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.payout_amount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="basis-2/5 pl-4">
                      <div>
                        <label
                          htmlFor="negotiation_comments"
                          className="block text-sm font-semibold text-gray-700 pb-2"
                        >
                          Comments
                        </label>

                        <textarea
                          rows={5}
                          name="negotiation_comments"
                          id="negotiation_comments"
                          className="bg-inputbg w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder=""
                          defaultValue={''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.negotiation_comments}
                        />
                        {formik.touched.negotiation_comments &&
                        formik.errors.negotiation_comments ? (
                          <div className="mt-2 text-sm text-red-600">
                            {formik.errors.negotiation_comments}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end my-3">
                    <div>
                      <div className="flex justify-end">
                        <div>
                          <button
                            type="submit"
                            className=" rounded-lg  bg-approve text-white py-2 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                          >
                            Submit for Approval
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        // data={openModalDataValues}
      >
        {/* <EditJD /> */}
        {/* <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        /> */}

        {/* Modal window */}
        <CandCalling dataval={openModalDataValues} />
        {/* Modal window */}
      </ModalData>
    </>
  );
};
export default JoiningDetails;

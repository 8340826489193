import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Wysiwyg from '../components/common/Wysiwyg';

const EmailTemplate = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="grid grid-cols-1 gap-4">
        <div className="bg-white p-4 rounded-md shadow-3xl">
          <div className="flex justify-between items-center">
            <h2
              id="account-details-heading"
              className=" mb-2 flex text-lg font-bold leading-6 text-gray-900"
            >
              Email Template
            </h2>

            <div className="flex">
              <Link
                type="button"
                to={'..'}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex w-full p-3 justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
              >
                <ArrowLeftIcon
                  className="-ml-1 mr-2 h-5 w-5 text"
                  aria-hidden="true"
                />
                Back
              </Link>
            </div>
          </div>
          <Wysiwyg />
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;

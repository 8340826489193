import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAccountApiMutation,
  useAccountQuery,
} from '../features/account/accountApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Notification from '../components/common/Notification';

const OfferTemplate = () => {
  const [accountApi, { isLoading }] = useAccountApiMutation();
  const [showNotification, setShowNotification] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const { data } = useAccountQuery({ refetchOnMountOrArgChange: true });

  const initialValues = {
    company_name: data?.data.company_name || '',
    city: data?.data.city || '',
    zipCode: data?.data.zipCode || '',
    country_id: data?.data.country_id || null,
    state_id: data?.data.state_id || null,
    address: data?.data.address || '',
  };
  const validationSchema = yup.object({
    name: yup.string().required('Please enter  name'),
    subject: yup.string().required('Please enter subject'),
    date: yup.date().required('Please enter date '),
    salary: yup.number().required('Please enter salary '),
    address: yup.string().required('Please enter the address'),
    designation: yup.string().required('Please enter designation'),
    description: yup.string().required('Please enter description'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const accountData = await accountApi({
        name: formik.values.name,
        suject: formik.values.subject,
        date: formik.values.date,
        salary: formik.values.salary,
        address: formik.values.address,
        designation: formik.values.designation,
        description: formik.values.description,
      }).unwrap();

      setShowNotification(!showNotification);
    } catch (error) {
      if (!error) {
        setErrMsg('No Server Response');
      } else {
        setErrMsg(error.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <>
      <Notification isShow={showNotification} />
      <section aria-labelledby="account-details-heading">
        <form onSubmit={formik.handleSubmit}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div className="flex justify-between items-center">
                <h2
                  id="account-details-heading"
                  className=" flex text-lg font-bold leading-6 text-gray-900"
                >
                  Offer Details
                </h2>

                <div className="flex">
                  <Link
                    type="button"
                    to={'..'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
                  >
                    <ArrowLeftIcon
                      className="-ml-1 mr-2 h-5 w-5 text"
                      aria-hidden="true"
                    />
                    Back
                  </Link>
                </div>
              </div>

              {errMsg && (
                <div className="mt-2 text-sm text-red-600">{errMsg}</div>
              )}

              <div className="mt-6 grid grid-cols-3 gap-6">
                <div className="">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="cc-given-name"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    autoComplete="subject"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.subject}
                    </div>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    autoComplete="date"
                    className="bg-inputbg border border-inputborder mt-1 block w-42 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.date}
                  />
                  {formik.touched.date && formik.errors.date ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.date}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="mb-6">
                    <label
                      htmlFor="salary_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Salary
                    </label>
                    <input
                      type="text"
                      name="salary"
                      id="salary"
                      autoComplete="salary"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.salary}
                    />
                    {formik.touched.salary && formik.errors.salary ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.salary}
                      </div>
                    ) : null}
                  </div>

                  <div className="">
                    <label
                      htmlFor="designation"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Designation
                    </label>
                    <input
                      type="text"
                      name="designation"
                      id="designation"
                      autoComplete="designation"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.designation}
                    />
                    {formik.touched.designation && formik.errors.designation ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.designation}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    rows={5}
                    name="address"
                    id="address"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    // defaultValue={''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.address}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-6">
                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    rows={5}
                    name="description"
                    id="description"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    // defaultValue={''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.description}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="border border-t-gray-300 bg-white px-4 py-3 text-right sm:px-6">
              {isLoading ? (
                <button
                  type="submit"
                  disabled
                  className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                >
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                  disabled={!(formik.dirty && formik.isValid)}
                  type="submit"
                  className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                >
                  Save
                </button>
              )}
              {/* <button
                type="button"
                className="mt-3 inline-flex  justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                onClick={onClose}
                ref={cancelButtonRef}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default OfferTemplate;

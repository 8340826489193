import { apiSlice } from '../../../app/api/apiSlice';

export const registerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerApi: builder.mutation({
      query: (data) => ({
        url: '/accounts/register',
        method: 'POST',
        body: { ...data },
      }),
    }),
  }),
});

export const { useRegisterApiMutation } = registerApiSlice;


// const chartData = [
//     {name:'Scheduled Acceptence',data: {name:'name1',value:[
//         new Date('2023-05-19').getTime(),
//         new Date('2023-05-24').getTime()
//       ]}},
//       {name:'Feedback Pending',data: {name:'name2', value:[
//         new Date('2023-05-18').getTime(),
//         new Date('2023-05-25').getTime()
//       ]}},
// ]

// import React, { useState } from 'react';
// import ReactApexChart from 'react-apexcharts';



// const RangeBarChart = () => {
//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [selectedBranch, setSelectedBranch] = useState('');
//   const [selectedDepartment, setSelectedDepartment] = useState('');
//   const [selectedLast30Days, setSelectedLast30Days] = useState('');

//   const countryOptions = ['India', 'USA', 'UK'];
//   const branchOptions = ['Chennai', 'Bangalore', 'Pune'];
//   const departmentOptions = ['Department1', 'Department2', 'Department3'];
//   const last30DaysOptions = ['Last 30 days', 'Last month', 'Last Year'];

//   // Sample data for the chart
// const chartData = [
//     {
//       name: 'Scheduled Acceptance',
//       data: {
//         name: 'John',
//         value: [
//           new Date('2023-05-19').getTime(),
//           new Date('2023-05-24').getTime()
//         ]
//       }
//     },
//     {
//       name: 'Feedback Pending',
//       data: {
//         name: 'Jack',
//         value: [
//           new Date('2023-05-18').getTime(),
//           new Date('2023-05-25').getTime()
//         ]
//       }
//     }
//   ];
  
//   // Transform the data into the format required by ApexCharts
//   const series = chartData.map(item => ({
//     name: item.name,
//     data: [
//       {
//         x: item.data.name,
//         y: item.data.value
//       }
//     ]
//   }));

//   const options = {
//     chart: {
//       type: 'rangeBar',
//       height: 350,
//       stacked: true,
//       fontFamily: 'Poppins',
//       foreColor: '#FFFFFF',
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         dataLabels: {
//           total: {
//             enabled: true,
//             offsetX: 0,
//             style: {
//               fontSize: '13px',
//               fontWeight: 900
//             }
//           }
//         },
//         barHeight: '40%',
//         borderRadius: 10,
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       style: {
//         fontSize: '15px',
//         fontFamily: 'Poppins',
//         colors: ['#FFFFFF'],
//       },
//     },
//     stroke: {
//       show: false,
//       width: 1,
//       colors: ["#fff"],
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//     },
//     xaxis: {
//       type: 'datetime',
//       labels: {
//         format: 'dd/MM',
//         show: false,
//       },
//       axisBorder: {
//         show: true,
//         color: '#6234C1',
//         height: 2,
//       },
//       axisTicks: {
//         show: false,
//       },
//     },
//     yaxis: {
//       labels: {
//         formatter: function (value) {
//           return value;
//         }
//       },
//       axisBorder: {
//         show: true,
//         color: '#6234C1',
//         width: 2,
//       },
//     },
//     grid: {
//       show: true,
//       borderColor: '#42297D',
//       xaxis: {
//         lines: {
//           show: true,
//         }
//       },
//       yaxis: {
//         lines: {
//           show: true,
//         }
//       }
//     },
//     colors: ['#53BA9E', '#FFC10B', '#FA2C50'],
//   };

//   return (
//     <div>
//       <div className='flex justify-between p-5'>
//         <div>
//           <h1 className='text-white text-[13px] font-poppins'></h1>
//         </div>
//         <div>
//           <select
//             className="bg-[#39216B] text-white text-[13px]"
//             value={selectedCountry}
//             onChange={(e) => setSelectedCountry(e.target.value)}
//           >
//             <option value="">Country</option>
//             {countryOptions.map((country, index) => (
//               <option key={index} value={country}>{country}</option>
//             ))}
//           </select>

//           <select
//             className="bg-[#39216B] text-white text-[13px]"
//             value={selectedBranch}
//             onChange={(e) => setSelectedBranch(e.target.value)}
//           >
//             <option value="">Branch</option>
//             {branchOptions.map((branch, index) => (
//               <option key={index} value={branch}>{branch}</option>
//             ))}
//           </select>

//           <select
//             className="bg-[#39216B] text-white text-[13px]"
//             value={selectedDepartment}
//             onChange={(e) => setSelectedDepartment(e.target.value)}
//           >
//             <option value="">Department</option>
//             {departmentOptions.map((department, index) => (
//               <option key={index} value={department}>{department}</option>
//             ))}
//           </select>

//           <select
//             className="bg-[#39216B] text-white text-[13px]"
//             value={selectedLast30Days}
//             onChange={(e) => setSelectedLast30Days(e.target.value)}
//           >
//             <option value="">Last 30 Days</option>
//             {last30DaysOptions.map((days, index) => (
//               <option key={index} value={days}>{days}</option>
//             ))}
//           </select>
//         </div>
//       </div>
//       <ReactApexChart options={options} series={series} type="rangeBar" height={380} width={567} />
//     </div>
//   );
// }

// export default RangeBarChart;

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RangeBarChart = ({chartData,chartName}) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedLast30Days, setSelectedLast30Days] = useState('');

  const countryOptions = ['India', 'USA', 'UK'];
  const branchOptions = ['Chennai', 'Bangalore', 'Pune'];
  const departmentOptions = ['Department1', 'Department2', 'Department3'];
  const last30DaysOptions = ['Last 30 days', 'Last month', 'Last Year'];

//   const chartData = [
//     {
//       name: 'Scheduled Acceptance',
//       data: {
//         name: 'Task 1',
//         value: [
//           new Date('2023-05-19').getTime(),
//           new Date('2023-05-24').getTime()
//         ]
//       }
//     },
//     {
//       name: 'Feedback Pending',
//       data: {
//         name: 'Task 2',
//         value: [
//           new Date('2023-05-18').getTime(),
//           new Date('2023-05-25').getTime()
//         ]
//       }
//     }
//   ];

const series = chartData.map(item => ({
    name: item.name,
    data: item.data.map(subItem => ({
      x: subItem.name,
      y: subItem.value
    }))
  }));

  const options = {
    chart: {
      type: 'rangeBar',
      height: 350,
      fontFamily: 'Poppins',
      foreColor: '#FFFFFF',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        },
        barHeight: '-30%',
        borderRadius: 2.5,
      },
    },
    dataLabels: {
      enabled: false,
    //   formatter: function (val, opts) {
    //     const startDate = new Date(val[0]);
    //     const endDate = new Date(val[1]);
    //     return `${startDate.getDate()}/${startDate.getMonth() + 1} - ${endDate.getDate()}/${endDate.getMonth() + 1}`;
    //   },
      style: {
        fontSize: '15px',
        fontFamily: 'Poppins',
        colors: ['#FFFFFF'],
      },
    },
    tooltip: {
      shared: false,
    //   intersect: false,  
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM',
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#6234C1',
        height: 2,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        }
      },
      axisBorder: {
        show: true,
        color: '#6234C1',
        width: 2,
      },
    },
    grid: {
      show: true,
      borderColor: '#42297D',
      xaxis: {
        lines: {
          show: true,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      }
    },
    colors: ['#53BA9E', '#FFC10B', '#FA2C50'],
  };

  return (
    <div>
      <div className='flex justify-between p-5'>
        <div>
          <h1 className='text-white text-[13px] font-poppins'>{chartName}</h1>
        </div>
        <div>
          <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>{country}</option>
            ))}
          </select>

          <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>{branch}</option>
            ))}
          </select>

          <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Department</option>
            {departmentOptions.map((department, index) => (
              <option key={index} value={department}>{department}</option>
            ))}
          </select>

          <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedLast30Days}
            onChange={(e) => setSelectedLast30Days(e.target.value)}
          >
            <option value="">Last 30 Days</option>
            {last30DaysOptions.map((days, index) => (
              <option key={index} value={days}>{days}</option>
            ))}
          </select>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="rangeBar" height={380} width={567} />
    </div>
  );
}

export default RangeBarChart;



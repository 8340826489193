import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { usePushtoScreeningMutation } from '../../../../../features/hiringstages/sourcingApiSlice';
import axios from 'axios';

export const AddPushToScreening = ({
  data,
  showNotification,
  onModalDataClose,
}) => {
  // const selectedSourcing = useSelector((data) => data.sourcing);
  const token = useSelector((data) => data.auth.token);
  console.log("Pushing Token: ",token)
  console.log("Pushing data : ",data)

  const [pushtoScreening, { isCandLoading }] = usePushtoScreeningMutation();

  const compare =
    data.mandatoryPercentage < 50 && data.mandatoryPercentage >= 34;

  const initialValues = compare
    ? {
        recommendation: '1',
        recommendation_comments: '',
      }
    : {};
  // const validationSchema =
  //   data.mandatoryPercentage <= 35 && data.mandatoryPercentage >= 50
  //     ? yup.object({
  //         recommendation_comments: yup
  //           .string()
  //           .required('Please enter Recommendation'),
  //       })
  //     : yup.object({});

  const onSubmit = async (validationSchema) => {
    // let newData = compare ? { ...validationSchema } : {};

    // data.mandatoryPercentage <= 35 && data.mandatoryPercentage > 50
    //   ? { ...validationSchema, recommendation: 1 }
    //   : ;
    // let newData = {};

    let dataid = data.id;
    try {
      const updateCandidateData = await pushtoScreening({
        body: validationSchema,
        id: dataid,
      }).unwrap();

      // axios
      //   .post(
      //     `https://dev-api.dakshamentors.com/sourcing/candidate/${dataid}`,
      //     newData,
      //     {
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     console.log('res -->>> ', response.data);
      //     setUploadedFile(response.data);
      //   })
      //   .catch((error) => {
      //     console.error('error', error);
      //   });

      // setShowNotification(true);
      onModalDataClose();
    } catch (error) {}
    onModalDataClose();
  };
  const formik = useFormik({
    initialValues,
    // validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form className="flex flex-col " onSubmit={formik.handleSubmit}>
      <div className=" sm:grid sm:grchid-flow-row-dense sm:grid-cols-1 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <div className="space-y-1">
            <h5 className="mb-2 text-lg font-semibold tracking-tight text-gray-900">
              Push to screening
            </h5>

            <span className="text-md font-normal  flex pb-3">
              Candidate Profile Match:
              <p className="font-semibold pl-2">
                {' '}
                {data.mandatoryPercentage} %
              </p>
            </span>
            {data.mandatoryPercentage >= 35 && data.mandatoryPercentage < 50 ? (
              <>
                <label htmlFor="recomendation" className="">
                  Recomendation
                </label>

                <textarea
                  rows={5}
                  name="recommendation_comments"
                  id="recommendation_comments"
                  className="block w-full mt-3 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  defaultValue={''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.recommendation_comments}
                />
                {formik.touched.recommendation_comments &&
                formik.errors.recommendation_comments ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.recommendation_comments}
                  </div>
                ) : null}
              </>
            ) : (
              ' '
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <div>
          <button
            type="button"
            onClick={() => onModalDataClose()}

            // className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-4 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            className="inline-flex mr-3 justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
          >
            Cancel
          </button>
        </div>
        {data.mandatoryPercentage >= 35 ? (
          <div>
            <button
              type="submit"
              // type="button"
              className="inline-flex  justify-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Submit
            </button>
          </div>
        ) : (
          ' '
        )}
      </div>
    </form>
  );
};

import React from 'react';
// import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/solid';

import { Link } from 'react-router-dom';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
  Bars3Icon,
  CheckIcon,
} from '@heroicons/react/20/solid';

// import dropdown from "../src/assets/images/dropdown.png";
import dropdown from '../assets/images/dropdown.png';

const transactions = [
  //   {
  //     name: "Lindsay Walton",
  //     title: "Front-end Developer",
  //     email: "lindsay.walton@example.com",
  //     role: "Member",
  //   },

  //   {
  //     name: "Marimuthu Prabhu",
  //     title: "UI/UX Designer",
  //     email: "Skmprabhu2521@gmail.com",
  //     role: "Leader",
  //   },
  //   {
  //     id: "AAPS0L",
  //     company: "Chase & Co.",
  //     share: "CAC",
  //     commission: "+$4.37",
  //     price: "$3,509.00",
  //     quantity: "12.00",
  //     netAmount: "$4,397.00",
  //   },
  {
    id: 'Software Engineer',
    posi: 'chennai, India',
    company: '$.',
    share: '^',
    commission: '&',
    price: '#',
    quantity: '@',
    netAmount: '?',
    check: '*',
    checking: '$',
    over: '%',
  },
  // {
  //   id: "EWSDFG",
  //   company: "$.",
  //   share: "^",
  //   commission: "&",
  //   price: "#",
  //   quantity: "@",
  //   netAmount: "?",
  //   check: "*",
  //   checking: "$",
  //   over: "%",
  // },

  // {
  //   id: "Software Engineer",
  //   posi: "chennai, India",
  //   company: "$.",
  //   share: "^",
  //   commission: "&",
  //   price: "#",
  //   quantity: "@",
  //   netAmount: "?",
  //   check: "*",
  //   checking: "$",
  //   over: "%",
  // },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function WorkflowEdit() {
  return (
    <div>
      <div className="flex justify-between ">
        <div className="flex">
          <form className=" flex space-x-4" action="#">
            <div className="min-w-0 flex-1">
              <label htmlFor="employess" className="sr-only">
                Employess
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="employessemployess"
                  id="employess"
                  className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Employess"
                />
              </div>
              {/* <div className="">
                <a href="#">
                  <img src={dropdown} alt="dropdown" />
                </a>
              </div> */}
            </div>
            <button
              type="button"
              className="inline-flex justify-center rounded-full items-center border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
            >
              <Bars3Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        <div className="flex">
          <Link
            type="button"
            to={'/app/workflowedit'}
            className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
            // onClick={() => setOpenDrawer(!openDrawer)}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
            Add Team
          </Link>
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-2">
        <div className="sm:flex sm:items-center">
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Export
          </button>
        </div> */}
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3  border-r-2 text-center text-xs font-semibold text-workflowTable sm:pl-6"
                      >
                        Workflow
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-xs font-semibold text-workflowTable"
                      >
                        Job RQ
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-xs font-semibold text-workflowTable"
                      >
                        Job Disc
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-xs font-semibold text-workflowTable"
                      >
                        Skill Capture
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-xs font-semibold text-workflowTable"
                      >
                        Sourcing
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-xs font-semibold text-workflowTable"
                      >
                        Screening
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-xs font-semibold text-workflowTable"
                      >
                        Interview
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-xs font-semibold text-workflowTable"
                      >
                        Offer
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-xs font-semibold text-workflowTable"
                      >
                        Joining
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap  text-center text-xs  border-r-2 font-semibold text-workflowTable"
                      >
                        Handover
                      </th>

                      <th
                        scope="col"
                        className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {transactions.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">
                            Software Engineer
                          </h3>
                          <h4 className="text-left">Chennai, India</h4>
                        </td>
                        <td className="whitespace-nowrap font-medium text-gray-900 border-r-2">
                          {/* {transaction.company} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                          {/* {transaction.share} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.commission} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.price} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.quantity} */}
                          <div className="flex justify-center">
                            <div className="bg-screeningL1 px-2 py-1.5 text-white text-sm font-bold">
                              L1
                            </div>
                            <div className="bg-screeningL2 px-2 py-1.5 text-white text-sm font-bold">
                              L2
                            </div>
                            <div className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold">
                              L3
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.netAmount} */}
                          <div className="flex justify-center">
                            <div className="bg-screeningL1 px-2 py-1.5 text-white text-sm font-bold">
                              L1
                            </div>
                            <div className="bg-screeningL2 px-2 py-1.5 text-white text-sm font-bold">
                              L2
                            </div>
                            <div className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold">
                              L3
                            </div>
                            <div className="bg-screeningL4 px-2 py-1.5 text-white text-sm font-bold">
                              L4
                            </div>
                            <div className="bg-screeningL5 px-2 py-1.5 text-white text-sm font-bold">
                              L5
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.check} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.checking} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.over} */}
                          <div className="flex justify-center">
                            <CheckIcon
                              className=" h-5 w-5 text-greentick"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 pr-3 text-right text-sm font-medium ">
                          {/* <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                          <span className="sr-only">, {transaction.id}</span>
                        </a> */}
                          <a href="#" title="Edit">
                            <PencilIcon
                              className="ml-1 mr-2 h-5 w-5 text-blueicon "
                              aria-hidden="true"
                            />
                          </a>
                          <a href="#" title="View">
                            <EyeIcon
                              className="ml-1 mr-2 h-5 w-5 text-blueicon "
                              aria-hidden="true"
                            />
                          </a>
                          <a href="#" title="Delete">
                            <TrashIcon
                              className="ml-1 mr-2 h-5 w-5 text-blueicon "
                              aria-hidden="true"
                            />
                          </a>
                          <button
                            type="button"
                            className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Signoffs
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, Fragment } from 'react';

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  HandThumbUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';

import Drawer from '../../../../components/common/Drawer';
import { useBranchQuery } from '../../../../features/branch/branchApiSlice';

import Notification from '../../../../components/common/Notification';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import {
  PencilIcon,
  TrashIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import Modal from '../../../../components/common/Modal';
import ModalData from '../../../../components/common/ModalData';

import AddManpowerRQMT from '../../../../features/manpowerrqmt/AddManpowerRQMT';
import AddManpower from './AddManpower';
import DateToIndiaFormat from '../../../../components/common/DateToIndiaFormat';

import {
  useManpowerQuery,
  useManpowerApprovalQuery,
  useDeleteManpowerMutation,
} from '../../../../features/manpower/manpowerApiSlice';

export default function MTManpower() {
  const {
    data: manpowerlist,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useManpowerQuery({ refetchOnMountOrArgChange: true });
  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
      // ? `${filterdata[0]?.name} - ${filterdata[0]?.location.name}`
      // : '-';
    }
  };

  const approvalStatus = (approvalid, comments) => {
    if (approvalid == 0) {
      return (
        <div className="flex justify-between px-2 py-2 border-grey-600 border-b-21 rounded-lg">
          <div>
            {/* <a href="" className="">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </a> */}
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Waiting for Approval</dt>
                <dd className="text-md text-gray-500 flex">
                  {'Waiting for Approval'}
                  <Popover className="relative ml-2">
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <DocumentIcon
                            className="-ml-1 mr-2 h-5 w-5 text text-orange-600"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative bg-white text-left p-4">
                                {'Comments'}
                              </div>
                              <div className="bg-gray-50 px-4 pb-4 font-normal text-left">
                                {comments}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 1) {
      return (
        <div className="flex justify-between px-2 py-2 border-screeningL3 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <CheckCircleIcon
                className="h-6 w-6 text-screeningL3"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Job Assigned</dt>
                <div className="flex">
                  <dd className="text-md text-gray-500 mr-2">Approved</dd>

                  <Popover className="relative flex">
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <DocumentIcon
                            className="-ml-1 mr-2 h-5 w-5 text text-orange-600"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative bg-white text-left p-4">
                                {'Comments'}
                              </div>
                              <div className="bg-gray-50 px-4 pb-4 font-normal text-left">
                                {comments}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 2) {
      return (
        <div className="flex justify-between px-2 py-2 border-blue-600 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <HandThumbUpIcon
                className="h-6 w-6 text-blue-500"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Approved</dt>
                <dd className="text-md text-gray-500 flex">
                  {'Query'}
                  <Popover className="relative ml-2">
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <DocumentIcon
                            className="-ml-1 mr-2 h-5 w-5 text text-orange-600"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative bg-white text-left p-4">
                                {'Comments'}
                              </div>
                              <div className="bg-gray-50 px-4 pb-4 font-normal text-left">
                                {comments}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
    if (approvalid == 3) {
      return (
        <div className="flex justify-between px-2 py-2 border-red-600 border-b-21 rounded-lg">
          <div>
            <a href="" className="">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="flex pr-2">
            <div>
              <dl className="flex flex-grow flex-col justify-between">
                <dt className="sr-only">Job Assigned</dt>
                <dd className="text-md text-gray-500 flex">
                  Rejected
                  <Popover className="relative ml-2">
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <DocumentIcon
                            className="-ml-1 mr-2 h-5 w-5 text text-orange-600"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative bg-white text-left p-4">
                                {'Comments'}
                              </div>
                              <div className="bg-gray-50 px-4 pb-4 font-normal text-left">
                                {comments}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    }
  };

  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [manpowerID, setManpowerId] = useState(null);

  const [deleteManpower, { isLoading: deleteManpowerLoading }] =
    useDeleteManpowerMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setManpowerId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };
  const onModalSubmit = async (manpowerID) => {
    try {
      const deleteAdminMytask = await deleteManpower(manpowerID).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };
  const closeNotificationMsg = () => {
    setShowNotification(false);
  };
  const noList = () => (
    <div className="flex h-[calc(100vh-275px)]">
      <div className="text-center bg-white w-1/4 m-auto p-10">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Manpower Requirement{' '}
        </h3>

        <div className="mt-6">
          <div>
            <Link
              type="button"
              to={''}
              className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              onClick={() =>
                openModalDataWindow({
                  mode: 'Add',
                  title: 'Create Manpower',
                })
              }
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Create Manpower
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Notification isShow={false} />
      {isLoading ? 'Loading...' : ''}
      {isSuccess ? (
        <>
          {manpowerlist.data.length > 0 ? (
            <div className="flex h-full flex-col">
              <div className="lg:pb-4">
                <div className="flex justify-between ">
                  <div className="flex"></div>
                  <div className="flex">
                    <Link
                      type="button"
                      to={''}
                      className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Add',
                          title: 'Create Manpower',
                        })
                      }
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Create Manpower
                    </Link>
                  </div>
                </div>
              </div>
              <div className="">
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5"
                >
                  {manpowerlist?.data.map((mpList) => (
                    <li
                      key={mpList.id}
                      className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg font-bold"
                    >
                      <div className="flex flex-1 flex-col p-0">
                        <div className="py-3 border border-b-gray-300 font-medium">
                          {mpList.manpower_agency}
                        </div>
                        <div className="flex justify-between px-2 py-2">
                          <div>
                            <dl className="flex flex-grow flex-col justify-between text-left">
                              <dt className="sr-only">City</dt>
                              <dd className="text-md text-blue-500 font-semibold">
                                {getBranchName(mpList.branch_id)}
                              </dd>
                              <dt className="sr-only">Area</dt>
                              <span className="rounded-ful text-xs font-medium text-gray-500">
                                {getLocationName(mpList.branch_id)}
                              </span>
                            </dl>
                          </div>
                          <div className="flex pr-2 self-center">
                            {/* {mpList.approval_status !== 1 && ( */}
                            <PencilIcon
                              className="h-5 w-5 mr-2 text-green-700 cursor-pointer"
                              aria-hidden="true"
                              onClick={() =>
                                openModalDataWindow({
                                  mode: 'Edit',
                                  title: 'Edit Manpower',
                                  mpList,
                                })
                              }
                            />
                            {/* )} */}
                            <TrashIcon
                              className="h-5 w-5 mr-2 text-red-600 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => openModalWindow(mpList.id)}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <div
                            className="rounded-full bg-paleblue text-white flex justify-center items-center text-3xl w-115 h-115"
                            // style={{ width: '115px', height: '115px' }}
                          >
                            {mpList.no_of_position}
                          </div>
                        </div>
                        <div className="flex justify-between border-b-2 border-gray-200 px-2 py-2">
                          <div>
                            <dl className="flex flex-grow flex-col justify-between">
                              <dt className="sr-only ">Publish</dt>
                              <dd className="text-sm text-gray-500">
                                Published
                              </dd>
                              <dt className="sr-only text-sm">Publish Date</dt>
                              <dd className="">
                                <span className="rounded-ful px-2 text-xs font-medium text-gray-500">
                                  {DateToIndiaFormat(mpList.createdAt)}
                                </span>
                              </dd>
                            </dl>
                          </div>
                          <div className="flex pr-2">
                            <div>
                              <dl className="flex flex-grow flex-col justify-between">
                                <dt className="sr-only">DeadLine</dt>
                                <dd className="text-sm text-gray-500">
                                  DeadLine
                                </dd>
                                <dt className="sr-only">Deadline Date</dt>
                                <dd className="">
                                  <span className="rounded-ful px-2 text-xs font-medium text-gray-500">
                                    {DateToIndiaFormat(mpList.closing_date)}
                                  </span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>

                        {approvalStatus(
                          mpList.approval_status,
                          mpList.comments
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            noList()
          )}
          {/* <pre>
            {JSON.stringify(
              JSON.parse(JSON.stringify(manpowerlist?.data)),
              null,
              2
            )}
          </pre> */}
        </>
      ) : (
        ''
      )}

      <Drawer
        isOpen={openDrawer}
        // title={isEdit ? 'Edit Location' : 'My Task'}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        <AddManpowerRQMT
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(manpowerID)}
      ></Modal>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        <AddManpower
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        />
      </ModalData>
    </>
  );
}

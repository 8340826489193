import { apiSlice } from '../../app/api/apiSlice';

export const dashboardApiSlice = apiSlice.injectEndpoints({
  // tagTypes: ['Profile'],
  endpoints: (builder) => ({
    manpowerChart: builder.mutation({
      query: () => ({
        url: '/report/manpower',
        method: 'POST',
      }),
    }),
    manpowerByBranchChart: builder.mutation({
      query: (data) => ({
        url: '/report/manpower_branch',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobrequestChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_request',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobrequestByBranchChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_request_locationwise',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobrequestByStatusChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_request_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobrequestByHandoverChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_request_handover_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobDescCountryChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_description_countrywise_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobDescLocationChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_description_locationwise_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    jobDescStatusChart: builder.mutation({
      query: (data) => ({
        url: '/report/job_description_statuswise',
        method: 'POST',
        body: { ...data },
      }),
    }),
    skillCaptureStatusChart: builder.mutation({
      query: (data) => ({
        url: '/report/skill_capture_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    sourcingStatusChart: builder.mutation({
      query: (data) => ({
        url: '/report/sourcing_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
    screeningStatusChart: builder.mutation({
      query: (data) => ({
        url: '/report/screening_status',
        method: 'POST',
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useManpowerChartMutation,
  useManpowerByBranchChartMutation,
  useJobrequestChartMutation,
  useJobrequestByBranchChartMutation,
  useJobrequestByStatusChartMutation,
  useJobrequestByHandoverChartMutation,
  useJobDescCountryChartMutation,
  useJobDescLocationChartMutation,
  useJobDescStatusChartMutation,
  useSkillCaptureStatusChartMutation,
  useSourcingStatusChartMutation,
  useScreeningStatusChartMutation,
} = dashboardApiSlice;

import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';
import { useSelector } from 'react-redux';

export const AddEducations = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
}) => {
  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();
  const profileId = useSelector((state) => state.candidatedetails.id);

  const initialValues = {
    education: data.obj?.education || '',
    course: data.obj?.course || '',
    speciallization: data.obj?.speciallization || '',
    university: data.obj?.university || '',
    achievement: data.obj?.achievement || '',
    passingOutYear: data.obj?.passingOutYear || '',

    courseType: data.obj?.courseType || '',
    gradingSystem: data.obj?.gradingSystem || '',
    id: data.obj?.id || '',
  };

  const validationSchema = yup.object({
    education: yup.string().required('Please Enter  Educations'),
    course: yup.string().required('Please enter the course'),
    speciallization: yup.string().required('Please enter speciallization'),
    university: yup.string().required('Please enter university'),
    achievement: yup.string().required('Please enter achievements'),

    passingOutYear: yup.date().required('Please passing out year'),

    courseType: yup.string().required('Please select course type'),
    gradingSystem: yup
      .number()
      .required('Please enter Percentage')
      .positive('Please enter only Positive number'),
  });

  const onSubmit = async (validationSchema) => {
    let values = {
      profile_id: profileId,
      educations: [],
    };
    values.educations.push(validationSchema);

    try {
      const updateCandidateData = await updateCandidate(values).unwrap();

      setShowNotification(true);
      onModalDataClose();
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form className="flex flex-col" onSubmit={formik.handleSubmit}>
      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="education"
            className="block text-sm font-medium text-gray-700"
          >
            Education* (UG/PG)
          </label>
          <input
            type="text"
            name="education"
            id="education"
            autoComplete="cc-education"
            className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.education}
          />
          {formik.touched.education && formik.errors.education ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.education}
            </div>
          ) : null}
        </div>
        <div className="">
          <label
            htmlFor="course"
            className="block text-sm font-medium text-gray-700"
          >
            Course Name*
          </label>

          <input
            type="text"
            id="course"
            name="course"
            autoComplete="cc-course"
            className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.course}
          />

          {formik.touched.course && formik.errors.course ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.course}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="specialization"
            className="block text-sm font-medium text-gray-700"
          >
            Specialization*
          </label>
          <input
            type="text"
            name="speciallization"
            id="speciallization"
            autoComplete="cc-specialization"
            className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.speciallization}
          />

          {formik.touched.speciallization && formik.errors.speciallization ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.speciallization}
            </div>
          ) : null}
        </div>

        <div className="">
          <label
            htmlFor="universityinstitute_id"
            className="block text-sm font-medium text-gray-700"
          >
            University/Institute*
          </label>

          <input
            type="text"
            id="university"
            name="university"
            autoComplete="cc-universityinstitute"
            className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.university}
          />
          {formik.touched.university && formik.errors.university ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.university}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="col-span-12 sm:col-span-2">
          <div className="col-span-2 ">
            <label
              htmlFor="mode_of_education"
              className="block text-sm font-medium text-gray-700 pb-2"
            >
              Mode of Education
            </label>
            <div className="flex h-6 items-center">
              <div className="flex h-6 items-center ">
                <input
                  id="Full-Time
                "
                  aria-describedby="Full-Time
                -description"
                  name="courseType"
                  value={'fulltime'}
                  type="radio"
                  checked={formik.values.courseType === 'fulltime'}
                  className="h-4 w-4 rounded-md bg-red-400 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="Full-Time"
                  className="font-medium text-gray-900"
                >
                  Full-Time
                </label>
              </div>

              <div className="flex h-6 items-center pl-10">
                <input
                  id="Part-Time"
                  aria-describedby="Part-Time-description"
                  name="courseType"
                  value={'parttime'}
                  checked={formik.values.courseType === 'parttime'}
                  type="radio"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="Part-Time" className="font-medium ">
                  Part-Time
                </label>
              </div>

              <div className="flex h-6 items-center pl-10">
                <input
                  id="Correspondence_DistanceLearning"
                  aria-describedby="Correspondence_DistanceLearning-description"
                  name="courseType"
                  value={'distance'}
                  checked={formik.values.courseType === 'distance'}
                  type="radio"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // value={formik.values.courseType}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="Correspondence_DistanceLearning"
                  className="font-medium "
                >
                  Correspondence / Distance Learning
                </label>
              </div>
            </div>
          </div>

          {formik.touched.courseType && formik.errors.courseType ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.courseType}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="passingOutYear"
            className="block text-sm font-medium text-gray-700"
          >
            Passing Out Year*
          </label>
          <input
            type="month"
            name="passingOutYear"
            id="passingOutYear"
            autoComplete="cc-passingOutYear"
            // className="bg-inputbg border border-inputborder mt-1 block w-42 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            className="bg-inputbg border border-inputBorder mt-1 w-44 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passingOutYear}
          />
          {formik.touched.passingOutYear && formik.errors.passingOutYear ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.passingOutYear}
            </div>
          ) : null}
        </div>
        <div className="">
          <label
            htmlFor="gradingSystem"
            className="block text-sm font-medium text-gray-700"
          >
            Percentage(%)
          </label>

          <input
            type="number"
            id="gradingSystem"
            name="gradingSystem"
            autoComplete="cc-GradingSystem"
            className="bg-inputbg border border-inputBorder mt-1 w-44 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gradingSystem}
          />
          {formik.touched.gradingSystem && formik.errors.gradingSystem ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.gradingSystem}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-1 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="achievement"
            className="block text-sm font-medium text-gray-700"
          >
            Any special achievements/certification *
          </label>
          <textarea
            rows={4}
            cols={50}
            placeholder="Any special achievements"
            maxLength={100}
            required={true}
            title="Any special achievements"
            name="achievement"
            id="achievement"
            type="text"
            className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.achievement}
          />
          {formik.touched.achievement && formik.errors.achievement ? (
            <div className="mt-2 text-sm w-full text-red-600">
              {formik.errors.achievement}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div>
          <button
            type="button"
            onClick={() => onModalDataClose()}
            className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
          >
            Cancel
          </button>
        </div>
        <div>
          {/* <button
              type="button"
              className="inline-flex mr-4 mt-4  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Publish
            </button> */}
          <button
            type="submit"
            className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
            // className="inline-flex  justify-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

import React from 'react';
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline';

export const Table = ({
  header,
  value,
  isEditing,
  openDrawerWindow,
  fullObjData,
}) => {
  return (
    <>
      <table className="min-w-full divide-y divide-gray-300 ]">
        <thead className="bg-gray-100">
          <tr>
            {header.map((table, i) => {
              return (
                <th
                  scope="col"
                  key={table.id}
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {table.title}
                </th>
              );
            })}
          </tr>
          {/* <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Candidate Name
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
            >
              Designation
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
            >
              Location
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
            >
              Requested Date
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 text-sm font-semibold text-gray-900"
            >
              Signoff by
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 text-sm font-semibold text-gray-900"
            >
              Signoff Date
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 text-sm font-semibold text-gray-900"
            >
              Status
            </th>

            <th
              scope="col"
              className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
            >
              <span className="sr-only">Edit</span>
            </th>
          </tr> */}
        </thead>

        <tbody className="divide-y divide-gray-200 bg-white ">
          {value?.map((val, i) => {
            return (
              <tr key={i}>
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 font-medium sm:pl-6 border-r-2">
                  {val.name}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.designation}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.location}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.requested}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.signby}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.signoff}
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                  {val.status}
                </td>
                <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                  {isEditing ? (
                    <PencilIcon
                      className="ml-1 mr-2 h-5 w-5 text-purple-500"
                      aria-hidden="true"
                      onClick={() => openDrawerWindow('edit', fullObjData[i])}
                    />
                  ) : (
                    <EyeIcon
                      className="ml-1 mr-2 h-5 w-5 text-purple-500"
                      aria-hidden="true"
                      onClick={() => openDrawerWindow('view', fullObjData[i])}
                    />
                  )}
                  {/* <a
      href="#"
      className="text-indigo-600 hover:text-indigo-900"
    >
      Edit
      <span className="sr-only">, {transaction.id}</span>
    </a> */}
                  {/* <a title="Edit">
                                  <PencilIcon
                                    className="ml-1 mr-2 h-5 w-5 text-purple-500 "
                                    aria-hidden="true"
                                    onClick={() =>
                                      openDrawerWindow(
                                        'view',
                                        transaction
                                      )
                                    }
                                  />
                                </a> */}
                  {/* <a title="View">
                    <EyeIcon
                      className="ml-1 mr-2 h-5 w-5 text-purple-500 "
                      aria-hidden="true"
                      onClick={() =>
                        openDrawerWindow('view', userSettings.data[i])
                      }
                    />
                  </a> */}
                  {/* <a href="#" title="Delete">
              <TrashIcon
                className="ml-1 mr-2 h-5 w-5 text "
                aria-hidden="true"
              />
            </a> */}
                  {/* <button
              type="button"
              className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Signoffs
            </button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
        {/* <tbody className="divide-y divide-gray-200 bg-white ">
          {userSettings?.data?.map((transaction, i) => (
            <tr key={transaction.id}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 font-medium sm:pl-6 border-r-2">
                {transaction.first_name} {transaction.last_name}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                {transaction.designation_name}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                {transaction.branch_name}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 font-medium border-r-2">
                -
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 font-medium border-r-2">
                {displayUsername(
                  transaction.workspace_privilege[0].approved_by
                )}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                -
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 font-medium border-r-2">
                {displayStatus(
                  transaction.workspace_privilege[0].approval_status
                )}
              </td>

              <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                <a title="View">
                  <EyeIcon
                    className="ml-1 mr-2 h-5 w-5 text-purple-500 "
                    aria-hidden="true"
                    onClick={() =>
                      openDrawerWindow('view', userSettings.data[i])
                    }
                  />
                </a>
              </td>
            </tr>
          ))}
        </tbody> */}
      </table>
    </>
  );
};

export default Table;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://localhost:3000',
  baseUrl: 'https://api.skillstrove.com',
  // baseUrl: 'https://bf89-223-178-84-227.ngrok-free.app/',

  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    headers.set('Content-Type', 'application/json');
    // multipart/form-data; boundary=<calculated when request is sent></calculated>
    // headers.set('Content-Type', 'multipart/form-data');
    if (token) {
      // headers.set('authorization', `${token}`);
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const confirmBox = window.confirm(result.error.data.message);
    if (confirmBox) {
      window.location.href = '/login';
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  onError: (error) => {
    // Handle the error here
    console.error('An error occurred:', error);
  },
});

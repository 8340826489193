import React,{useState} from "react";
import ReactApexChart from "react-apexcharts";

const HorizontalGrpBarChart = ({chartData, series, chartName}) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    //Define options for countries, branches
    const countryOptions = ['India','USA','UK'];
    const branchOptions = ['Chennai','Bangalore','Pune'];

    
  //  const series =[
  //   {
  //       name:"Closed",
  //       data:manpowerData ? manpowerData.map((item)=>item.value1) :[],
  //   },
  //   {
  //       name:"Openings",
  //       data:manpowerData ? manpowerData.map((item)=>item.value2) :[],
  //   }
  //  ];
   const getSeriesData = () => {
    return series.map((series, index) => ({
      name: series,
      data: chartData.map((item) => item[`value${index + 1}`] || 0),
    }))
   }
   const options = {
    chart: {
      type: "bar",
      height: 380,
      fontFamily: 'Poppins', // Set font family for all text elements
      foreColor: '#FFFFFF', // Set color for all text elements to white
      toolbar: {
        show: false, //Hide the toolbar (menu bar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: true, // Ensure dataLabels is properly defined with enabled property
          position: "top",
        },
        barHeight: '60%'
      },
    },
    dataLabels: {
        enabled: true, 
        // formatter: function (val, opts) {
        //     return opts.w.config.series[opts.seriesIndex]; //Display value
        // },
        style: {
            fontSize: '15px',
            fontFamily: 'Poppins',
            colors:['#FFFFFF'], //color of the data labels
        }
    },
    stroke: {
      show: false,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
        categories:chartData ? chartData.map((item) => item.name) : [],
        labels: {
          show: false //Hide the x-axis labels
        },
        axisBorder: {
          show: true,
          color: '#6234C1', //color of x-axis line
          height: 2
        },
        axisTicks: {
          show: false,
          // color: '#6234C1', //color of x-axis ticks
        },
  },
  yaxis: {
      axisBorder: {
          show: true,
          color: '#6234C1', //color of x-axis line
          width: 2,
      },
  },
  grid: {
      show: true,
      borderColor: '#42297D',
      xaxis: {
          lines: {
            show: true // Show vertical grid lines
          }
        },
        yaxis: {
          lines: {
            show: true // Show horizontal grid lines
          }
        }
  },
    colors: ['#5EEE6E','#F92D51']
    // yaxis: {
    //     labels: {
    //         style: {
    //             fontSize: '12px', // Adjust font size of y-axis labels
    //             fontFamily: 'Poppins',
    //         },
    //     },
    //     grid: {
    //         show: true, // Show the y-axis gridlines
    //         borderColor: '#FFFFFF', // Set the color of y-axis gridlines to white
    //         strokeDashArray: 0, // Optionally, set the strokeDashArray to make gridlines solid
    //         position: 'back', // Set the grid lines behind the data points
    //     },
    // },
  };
   return (
    <div>
            <div className='flex justify-between p-5'>
                <div>
                   <h1 className='text-white text-[16px] font-poppins'>{chartName}</h1>
                </div>
                <div>
                                    {/* Dropdown for country */}
                <select
                    className=" bg-[#39216B] text-[13px] text-white "
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                >
                    <option value="">Country</option>
                    {countryOptions.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                    ))}
                </select>
                
                {/* Dropdown for branch */}
                <select
                    className="ml-1  text-[13px] bg-[#39216B] text-white "
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                >
                    <option value="">Branch</option>
                    {branchOptions.map((branch, index) => (
                        <option key={index} value={branch}>{branch}</option>
                    ))}
                </select>
                </div>
            </div>
        <ReactApexChart options={options} series={getSeriesData()} type='bar' height={380} width={570}/>
    </div>
   ); 
}

export default HorizontalGrpBarChart
import { React, Fragment } from 'react';
// import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';

import { Link } from 'react-router-dom';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';

const transactions = [
  {
    // id: "Software Engineer",
    // posi: "chennai, India",
    // company: "$.",
    // share: "^",
    // commission: "&",
    // price: "#",
    // quantity: "@",
    // netAmount: "?",
    // check: "*",
    // checking: "$",
    // over: "%",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Workflow() {
  return (
    <div>
      <div className="flex justify-between ">
        <div className="flex">
          <form className=" flex space-x-4" action="#">
            <div className="min-w-0 flex-1">
              <div>
                <select
                  id="year"
                  name="year"
                  autoComplete="year"
                  className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                >
                  <option>Year</option>
                  <option>1990</option>
                  <option>2023</option>
                </select>
              </div>
            </div>
            <div>
              <select
                id="month"
                name="month"
                autoComplete="month"
                className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              >
                <option>Month</option>
                <option>Jan</option>
                <option>Dec</option>
              </select>
            </div>
            <div>
              <select
                id="status"
                name="status"
                autoComplete="status"
                className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              >
                <option>Status</option>
                <option>Complete</option>
                <option>Pending</option>
              </select>
            </div>
          </form>
        </div>
        <div className="flex">
          <Link
            type="button"
            to={'/app/createworkflow'}
            className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
            // onClick={() => setOpenDrawer(!openDrawer)}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
            Add Workflow
          </Link>
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-2">
        <div className="sm:flex sm:items-center">
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Export
          </button>
        </div> */}
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3  border-r-2 text-center text-sm font-bold text-black sm:pl-6"
                      >
                        Workflow +++
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                      >
                        Job Desc
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                      >
                        Skill Capture
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                      >
                        Skill Capture
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                      >
                        Sourcing
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                      >
                        Screening
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                      >
                        Interview
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                      >
                        Offer
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                      >
                        Joining
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                      >
                        Handover
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                      >
                        Approved by
                      </th>

                      <th
                        scope="col"
                        className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {transactions.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">
                            Software Engineer
                          </h3>
                          <h4 className="text-left">Chennai, India</h4>
                        </td>
                        <td className="whitespace-nowrap font-medium text-gray-900 border-r-2">
                          {/* {transaction.company} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                          {/* {transaction.share} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.commission} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.price} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.quantity} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.netAmount} */}

                          <div className="flex justify-between">
                            <div>
                              <div className="flex justify-center mt-2">
                                <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                  T
                                </div>
                                <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                  2
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="flex justify-center mt-2 mb-2">
                                <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                  S
                                </div>
                                <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                  1
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center">
                            <div className="bg-screeningL1 px-2 py-1.5 text-white text-sm font-bold border-r-2 rounded-l-lg">
                              L1
                            </div>
                            <div className="bg-screeningL2 px-2 py-1.5 text-white text-sm font-bold border-r-2">
                              L2
                            </div>
                            <div className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold border-r-2">
                              L3
                            </div>
                            <div className="bg-screeningL4 px-2 py-1.5 text-white text-sm font-bold border-r-2">
                              L4
                            </div>
                            <div className="bg-screeningL5 px-2 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              L5
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.check} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.checking} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.over} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">Approved</h3>
                        </td>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">
                            Robin Williams
                          </h3>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          <div className="flex justify-center">
                            <div className="flex self-center">
                              <a href="#" title="Edit">
                                <PencilIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                              <a href="#" title="View">
                                <EyeIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                              <a href="#" title="Delete">
                                <TrashIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                            </div>
                            <div>
                              <button
                                type="button"
                                className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none  sm:w-auto"
                              >
                                Signoffs
                              </button>
                            </div>
                          </div>

                          {/* <div className="">
                            <a href="#" title="Edit">
                              <PencilIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                            <a href="#" title="View">
                              <EyeIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                            <a href="#" title="Delete">
                              <TrashIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <button
                            type="button"
                            className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Signoffs
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {transactions.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">Finance Head</h3>
                          <h4 className="text-left">Texas, USA</h4>
                        </td>
                        <td className="whitespace-nowrap font-medium text-gray-900 border-r-2">
                          {/* {transaction.company} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                          {/* {transaction.share} */}
                          <div className="flex justify-center">
                            <MinusIcon
                              className=" h-5 w-5 text-gray-500"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.commission} */}
                          <div className="flex justify-center">
                            <MinusIcon
                              className=" h-5 w-5 text-gray-500"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.price} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.quantity} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.netAmount} */}

                          <div className="flex justify-between">
                            <div>
                              <div className="flex justify-center mt-2">
                                <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                  T
                                </div>
                                <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                  2
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="flex justify-center mt-2 mb-2">
                                <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                  S
                                </div>
                                <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                  1
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center">
                            <div className="bg-screeningL1 px-2 py-1.5 text-white text-sm font-bold">
                              L1
                            </div>
                            <div className="bg-screeningL2 px-2 py-1.5 text-white text-sm font-bold">
                              L2
                            </div>
                            <div className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold">
                              L3
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.check} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.checking} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {/* {transaction.over} */}
                          <div className="flex justify-center mt-2">
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              T
                            </div>
                            <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              2
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 mb-2">
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                              S
                            </div>
                            <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                              1
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">Approved</h3>
                        </td>
                        <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                          {/* {transaction.id} <br />
                          {transaction.posi} */}
                          <h3 className="text-left font-bold">
                            Robin Williams
                          </h3>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          <div className="flex justify-center">
                            <div className="flex self-center">
                              <a href="#" title="Edit">
                                <PencilIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                              <a href="#" title="View">
                                <EyeIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                              <a href="#" title="Delete">
                                <TrashIcon
                                  className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                  aria-hidden="true"
                                />
                              </a>
                            </div>
                            <div>
                              <button
                                type="button"
                                className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:w-auto"
                              >
                                Signoffs
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="justify-between mt-6 hidden sm:flex sm:flex-1 sm:items-center sm:justify-between px-0">
          <div>
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">20</span> of{' '}
                <span className="font-medium">69</span> results
              </p>
            </div>
          </div>
          <div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <a
                  href="#"
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-20"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                <a
                  href="#"
                  aria-current="page"
                  className="relative z-10 inline-flex items-center  px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                >
                  1
                </a>
                <a
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
                >
                  2
                </a>
                <a
                  href="#"
                  className="relative hidden items-center px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20 md:inline-flex"
                >
                  3
                </a>
                {/* <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                        ...
                      </span> */}
                <a
                  href="#"
                  className="relative hidden items-center  px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20 md:inline-flex"
                >
                  4
                </a>
                <a
                  href="#"
                  className="relative inline-flex items-center  px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
                >
                  5
                </a>

                <a
                  href="#"
                  className="relative inline-flex items-center rounded-r-md  px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-20"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </nav>
            </div>
          </div>
          <div>
            <div className="flex">
              <p className="flex items-center pr-3 text-sm">Items per page</p>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    20
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            5
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            10
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            20
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { apiSlice } from '../../app/api/apiSlice';

export const accountApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    account: builder.query({
      query: () => ({
        url: '/accounts/profile',
        method: 'GET',
      }),
      providesTags: ['Profile'],
      keepUnusedDataFor: 5,
    }),
    accountApi: builder.mutation({
      query: (data) => ({
        url: '/accounts/profile',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const { useAccountApiMutation, useAccountQuery } = accountApiSlice;

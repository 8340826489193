import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';

import authReducer from '../features/auth/authSlice';
import registerReducer from '../features/auth/register/registerSlice';
import userprivilegeReducer from '../features/privileges/userWSPSlice';
import sourcingReducer from '../features/hiringstages/sourcingSlice';
import screeningReducer from '../features/hiringstages/screeningSlice';
import candidateReducer from '../features/hiringstages/candidateSlice';
import candidateInterviewReducer from '../features/hiringstages/candidateInterviewSlice';
import candidateDetailsReducer from '../features/hiringstages/candidateDetailsSlice';
import userWorkspaceReducer from '../features/privileges/userWorkspaceSlice';
import adminWorkspaceReducer from '../features/privileges/adminWorkspaceSlice';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  register: registerReducer,
  userprivilege: userprivilegeReducer,
  sourcing: sourcingReducer,
  candidatedetails: candidateDetailsReducer,
  candidate: candidateReducer,
  screening: screeningReducer,
  candidateinterview: candidateInterviewReducer,
  userWorkspace: userWorkspaceReducer,
  adminWorkspace: adminWorkspaceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default (state, action) =>
  reducer(action.type === 'USER_LOGOUT' ? undefined : state, action);

import { apiSlice } from '../../app/api/apiSlice';

export const skillApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Skilltype'],
  endpoints: (builder) => ({
    skill: builder.query({
      query: () => ({
        url: '/master/skilltype',
        method: 'GET',
      }),
      providesTags: ['Skilltype'],
      keepUnusedDataFor: 5,
    }),
    addSkill: builder.mutation({
      query: (data) => ({
        url: '/master/skilltype',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Skilltype'],
    }),
    updateSkill: builder.mutation({
      query: (data) => ({
        url: '/master/skilltype',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Skilltype'],
    }),
    deleteSkill: builder.mutation({
      query: (id) => ({
        url: `/master/skilltype/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Skilltype'],
    }),
  }),
});

export const {
  useSkillQuery,
  useAddSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
} = skillApiSlice;

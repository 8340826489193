import { useSelector } from 'react-redux';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { selectCurrentToken } from '../features/auth/authSlice';
import { Toaster } from 'sonner';
import React from 'react';

const ProtectedAuth = () => {
  const token = useSelector(selectCurrentToken);
  const location = useLocation();
  return token ? (
    <>
      <Outlet />
      <div className="bg-red-600 w-auto">
        <Toaster
          position="top-right"
          className=""
          duration={1400}
          richColors
          toastOptions={{
            unstyled: true,
            classNames: {
              toast:
                'flex justify-center items-center py-3 px-5 rounded-xl w-fit gap-1 text-xs',
            },
          }}
        />
      </div>
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedAuth;

import React, { Fragment, useEffect, useState } from 'react';
import { BellIcon, Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { setCredentials } from '../features/auth/authSlice';
import { Link, useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../features/auth/authSlice';
import { selectCurrentUser } from '../features/auth/authSlice';

import { apiSlice } from '../app/api/apiSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useSelector(selectCurrentUser);

  const pageTitle = [
    {
      path: '/app/interviewrequest/*',
      title: 'Interview Request',
    },
    {
      path: '/app/calendar',
      title: 'Calendar',
    },

    {
      path: '/app',
      title: 'Dashboard',
    },
    {
      path: '/app/dashboard',
      title: 'Dashboard',
    },
    {
      path: '/app/team/*',
      title: 'Teams',
    },
    {
      path: '/app/settings/*',
      title: 'Settings',
    },
    {
      path: '/app/adminworkspace',
      title: 'Admin Workspace',
    },
    {
      path: '/app/userworkspace',
      title: 'User Workspace',
    },
    {
      path: '/app/assignworkflowsignoff/*',
      title: 'Workflow Signoff',
    },
    {
      path: '/app/assignworkflowtask/*',
      title: 'Workflow',
    },
    {
      path: '/app/createworkflow',
      title: 'Create Workflow',
    },
  ];

  const [{ route }] = matchRoutes(pageTitle, location);

  const userNavigation = [
    // { name: 'Your Profile', href: '#' },
    // { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
  ];

  const appLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logOut());
  };

  return (
    <>
      <div className="sticky top-0 z-10 flex h-16 px-6 flex-shrink-0 bg-white">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between ">
          <div className="self-center">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              {/* {pageTitleSelected[0].title || 'Custom'} */}
              {route.title || 'Dashboard'}
            </h1>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  {/* <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}

                  <span className="font-bold text-gray-700 rounded-full w-10 h-10 border-2 border-purple-900 flex items-center justify-center">
                    {/* R */}
                    {userName.charAt(0).toUpperCase()}
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <span className={'block px-4 py-2 text-sm text-gray-700'}>
                      {`Welcome, ${userName}`}
                    </span>
                  </Menu.Item>
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          // href={item.href}
                          // to={'/login'}
                          onClick={appLogout}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

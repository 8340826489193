import React, { useState } from 'react';

import {
  PlusIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useFormik } from 'formik';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  PhoneIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowUpIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import Modal from '../../../../../../components/common/Modal';
import {
  useCandOfferDetailsQuery,
  useOfferDocumentMutation,
} from '../../../../../../features/hiringstages/offerApiSlice';
import { selectCandidateInterview } from '../../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
const people = [
  {
    name: 'Comments',
    title: 'Strong Hire',
    percentage: '70%',
  },
  // More people...
];
const Documents = ({}) => {
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const initialValues = {
    profile_id: '',
    document_aadhaar: '',
    document_pan: '',
  };

  const candId = useSelector(selectCandidateInterview);
  const {
    data: canddetails,
    isLoading: canddetailsLoading,
    isSuccess,
  } = useCandOfferDetailsQuery(candId, { refetchOnMountOrArgChange: true });

  const documentsData = canddetails?.data?.documents;
  const openModalWindow = (id) => {
    // setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
  });
  return (
    <>
      {/* Content Soft */}
      <div className="basis-5/6">
        <div className="flex flex-row  ">
          <div className="w-full ">
            <div className="bg-white rounded-lg">
              <div className="">
                <div className="">
                  <div className="bg-gray-200  py-5 rounded-lg  px-5">
                    {' '}
                    <div className="bg-white rounded-lg px-5 py-5 ">
                      {/* <div className="flex justify-between">
                        <h1 className="text-xl font-bold text-center self-center">
                          Documents from Candidate
                        </h1>
                        <span className=" flex whitespace-nowrap  py-2 text-sm text-gray-500 ml-7">
                          <a
                            href={'#'}
                            target="_blank"
                            title="Edit"
                            className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                          >
                            {' '}
                            <DocumentArrowUpIcon
                              className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                              aria-hidden="true"
                            />{' '}
                            Upload Document
                          </a>
                        </span>
                      </div> */}
                      <div className="kb-file-upload mb-6  flex justify-between">
                        <div>
                          <h1 className="font-bold text-2xl">
                            Document from Candidate
                          </h1>
                        </div>
                      </div>

                      <div className="mt-2 flow-root">
                        <div className="">
                          <div className="inline-block min-w-full py-2 align-middle">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-100 text-md font-bold text-gray-900">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6"
                                    >
                                      File Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-bold text-gray-900"
                                    >
                                      Attachments
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left flex justify-end mr-6 text-sm font-bold text-gray-900"
                                    >
                                      {/* Action */}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {documentsData.map((document) => (
                                    <tr key={document.id}>
                                      <td className="whitespace-nowrap  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {/* {person.name} */}
                                        {document.title}
                                      </td>
                                      <td className="whitespace-nowrap  text-sm text-gray-500">
                                        {/* {person.title} */}
                                        <span className=" flex whitespace-nowrap py-2 text-sm text-gray-500">
                                          <a
                                            href={document.document_path}
                                            target="_blank"
                                            title="Edit"
                                            className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                                          >
                                            {' '}
                                            <ArrowDownTrayIcon
                                              className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                              aria-hidden="true"
                                            />{' '}
                                            Download
                                          </a>
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap flex justify-end mr-6  text-sm text-gray-500">
                                        {/* {person.email} */}
                                        {/* <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                       <a
                                         className="bg-red-500 px-4 py-2 items-center flex rounded-3xl text-white"
                                         onClick={() => openModalWindow({})}
                                       >
                                         {' '}
                                         <XMarkIcon
                                           className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                                           aria-hidden="true"
                                         />{' '}
                                         Remove
                                       </a>
                                     </span> */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
    </>
  );
};

export default Documents;

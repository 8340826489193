import { Fragment, useEffect, useState } from 'react';

import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  UserCircleIcon,
  DevicePhoneMobileIcon,
  PhoneIcon,
  EnvelopeIcon,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddCandidateMutation,
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../features/hiringstages/sourcingApiSlice';
import Notification from '../../../../../components/common/Notification';
import { useSelector, useDispatch } from 'react-redux';

import { setCandDetails } from '../../../../../features/hiringstages/candidateDetailsSlice';

import CandKeyskills from './tabs/CandKeyskills';
import CandEmployment from './tabs/CandEmployment';
import CandEducation from './tabs/CandEducation';
import CandTechskills from './tabs/CandTechskills';
import CandProjects from './tabs/CandProjects';
import shortid from 'shortid';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Document, Page } from 'react-pdf';
import Modal from 'react-modal';
import CandResume from './tabs/CandResume';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const AddCandidate = ({ toggleSourcing }) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();

  const [docFilePath, setDocFilePath] = useState('');
  const [docFileUrl, setDocFileUrl] = useState('');

  const [docPhotoPath, setDocPhotoPath] = useState('');
  const [docPhotoUrl, setDocPhotoUrl] = useState('');

  const [selectedPhoto, setSelectedPhoto] = useState([]);

  const InputChange1 = (e) => {
    // --For Multiple File Input

    const file = e.target.files[0];
    const imgname = e.target.files[0].name;
    setSelectedPhoto(e.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: 'image/png',
              lastModified: Date.now(),
            });

            setImage(file);
          },
          'image/jpeg',
          0.8
        );
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const FileUploadSubmit1 = async (e) => {
    e.preventDefault();

    if (selectedPhoto != '') {
      var formData = new FormData();
      formData.append('file', selectedPhoto);

      var options = formData;
      try {
        axios
          .post('https://api.skillstrove.com/users/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setDocPhotoPath(response.data.data.filePath);
            setDocPhotoUrl(response.data.data.url);
            // setImage('');
          })

          .catch((error) => {});
      } catch (error) {}
    }
  };

  const dispatch = useDispatch();

  const selectedSourcing = useSelector((data) => data.sourcing);
  const token = useSelector((data) => data.auth.token);

  const selectedCand = useSelector((data) => data.candidate);

  const {
    data: canddetails,
    error: canddetailsErr,
    isLoading: canddetailsLoading,
    isFetching: canddetailsFetching,
    isSuccess: canddetailsSuccess,
  } = useGetCandDetailsQuery(selectedCand, { refetchOnMountOrArgChange: true });

  if (canddetailsSuccess) {
    dispatch(setCandDetails(canddetails?.data));
  }

  const [image, setImage] = useState('');
  const [docs, setDocs] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const [addCandidate, { isCandLoading }] = useAddCandidateMutation();
  const [updateCandidate, { isEditCandLoading }] = useUpdateCandidateMutation();

  const showNotificationMsg = () => {
    setShowNotification(true);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const gotoAddSourcing = (value) => {
    toggleSourcing(value);
  };

  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    setSelectedFile(e.target.files[0]);

    if (e.target.files[0] != '') {
      var formData = new FormData();
      formData.append('file', e.target.files[0]);

      var options = formData;
      try {
        axios
          .post('https://api.skillstrove.com/users/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setDocFilePath(response.data.data.filePath);
            setDocFileUrl(response.data.data.url);
            // formik.values.resume = response.data.filePath;
          })

          .catch((error) => {});
      } catch (error) {}
    }
  };

  useEffect(() => {
    setImage(canddetails?.data.photo);
    setDocs([
      { uri: canddetails?.data.resume }, // Replace with your PDF link
    ]);
  }, [canddetails]);

  const initialValues = {
    profile_id: selectedCand || '',
    job_request_id: selectedSourcing.job_request_id,
    firstName: canddetails?.data.firstName || '',
    lastName: canddetails?.data.lastName || '',
    fullName: '',
    mobile: canddetails?.data.mobile || '',
    work_mobile: canddetails?.data.work_mobile || '',
    address: canddetails?.data.address || '',
    email: canddetails?.data.email || '',
    current_designation: canddetails?.data.current_designation || '',
    photo: docPhotoPath != '' ? docPhotoPath : '',
    resume: docFilePath != '' ? docFilePath : '',
    experience: canddetails?.data.experience || 0,
    salary_expectation: canddetails?.data.salary_expectation || 0,
    notice_period: canddetails?.data.notice_period || 0,
    current_company: canddetails?.data.current_company || '',
    dob: canddetails?.data.dob || '',
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required('Please enter fullname')
      .min(3, 'Please enter Minimum three character'),
    lastName: yup.string().required('Please enter fullname'),
    // .min(3, 'Please enter Minimum three character'),
    current_designation: yup.string().required('Please enter designation'),
    mobile: yup
      .number()
      .required('Please enter mobile number')
      .positive('Please enter only Positive number'),

    work_mobile: yup
      .number()
      .required('Please enter Office number')
      .positive('Please enter only Positive number'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter email id'),
    address: yup.string().required('Please enter address'),
    experience: yup
      .number()
      .required('Please enter total experience')
      .positive('Please enter only Positive number'),
    salary_expectation: yup
      .number()
      .required('Please enter expected CTC')
      .positive('Please enter only Positive number'),
    notice_period: yup
      .number()
      .required('Please enter notice period in')
      .positive('Please enter only Positive number'),
    current_company: yup.string().required('Please enter current company'),
    dob: yup.string().required('Please enter Date of Birth'),
  });

  const onSubmit = async (validationSchema) => {
    setDocFileUrl('');
    const newObj = {
      ...validationSchema,
      fullName: validationSchema.firstName + ' ' + validationSchema.lastName,
    };

    try {
      if (selectedCand > 0) {
        const employeeData = await updateCandidate(newObj).unwrap();
        setShowNotification(true);
      } else {
        const employeeData = await addCandidate(newObj).unwrap();
        setShowNotification(true);
      }
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <Notification isShow={showNotification} onClose={closeNotificationMsg} />

      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-end items-center mb-4">
          <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
            <button
              type="button"
              className="inline-flex rounded-3xl bg-transparent border-0 py-3 px-6 text-sm font-medium  focus:outline-none "
              // onClick={() => gotoSourcingList('sourcinglist')}
              onClick={() => gotoAddSourcing('cancelcandidate')}
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Back
            </button>
          </div>
        </div>
        <div className=" pt-0 sm:pt-0">
          <div className="mt-0  pb-0 sm:pb-0">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 " />
              <div className="relative  px-6 lg:px-0">
                <div className="rounded-lg bg-white shadow-lg">
                  <dl className=" sm:grid sm:grid-cols-5 flex items-center justify-between ">
                    <div className="flex justify-center flex-col col-span-2 border-b border-gray-100  sm:border-0 sm:border-r px-6">
                      <div className=" flex w-full ">
                        <div className="w-2/5">
                          <div className="kb-file-upload mt-3">
                            <div className="file-upload-box ">
                              <input
                                type="file"
                                id="fileupload1"
                                className="file-upload-input"
                                onChange={InputChange1}
                              />
                              <span className="flex">
                                {/* {selectedfile1.length > 0 ? (
                                  selectedfile1.map((data, index) => {
                                    const {
                                      id,
                                      filename,
                                      filetype,
                                      fileimage,
                                      datetime,
                                      filesize,
                                    } = data;
                                    return (
                                      <img
                                        src={fileimage}
                                        alt=""
                                        className=" w-40 rounded-full flex"
                                      />
                                    );
                                  }) */}
                                {image ? (
                                  <img
                                    src={
                                      typeof image == 'object'
                                        ? URL.createObjectURL(image)
                                        : image
                                    }
                                    alt="upload image"
                                    className="w-40 h-40 rounded-full"
                                  />
                                ) : (
                                  <UserCircleIcon
                                    className="h-40 w-40 text-gray-200 "
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          {image ? (
                            <div className="kb-buttons-box flex justify-center">
                              <button
                                type="button"
                                onClick={(e) => FileUploadSubmit1(e)}
                                className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 my-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                              >
                                Upload Photo
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        {/* {setDocPhotoPath != '' ? (
                          <span className="text-red-500 text-xs font-bold block">
                            (Save the form to upload the photo)
                          </span>
                        ) : (
                          ''
                        )} */}

                        <div className="w-3/5 self-center">
                          <p>
                            <label
                              htmlFor="firstName"
                              className="block text-sm font-medium text-gray-900"
                            >
                              FirstName *
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              onChange={formik.handleChange}
                              placeholder="First name"
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <div className="mt-2 text-sm text-red-600">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </p>
                          <p className="pt-3">
                            <label
                              htmlFor="lastName"
                              className="block text-sm font-medium text-gray-900"
                            >
                              LastName *
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              onChange={formik.handleChange}
                              placeholder="Last name"
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <div className="mt-2 text-sm text-red-600">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </p>
                          <p className="pt-3">
                            <label
                              htmlFor="current_designation"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Current designation *
                            </label>
                            <input
                              type="text"
                              name="current_designation"
                              id="current_designation"
                              className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              onChange={formik.handleChange}
                              placeholder="Current designation"
                              onBlur={formik.handleBlur}
                              value={formik.values.current_designation}
                            />
                            {formik.touched.current_designation &&
                            formik.errors.current_designation ? (
                              <div className="mt-2 text-sm text-red-600">
                                {formik.errors.current_designation}
                              </div>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center  border-b border-gray-100 sm:border-0 sm:border-r px-6">
                      <div className="">
                        <h2 className=" text-lg font-semibold p-4">
                          Contact Details
                        </h2>
                        <span className="flex mb-3">
                          <DevicePhoneMobileIcon
                            className="h-6 w-6  text-sidebar mr-4 "
                            aria-hidden="true"
                          />
                          *
                          <div className="">
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              autoComplete="mobile"
                              className="bg-inputbg border border-inputBorder block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.mobile}
                            />
                            {formik.touched.mobile && formik.errors.mobile ? (
                              <div className="mt-2 text-sm text-red-600">
                                {formik.errors.mobile}
                              </div>
                            ) : null}
                          </div>
                        </span>

                        <span className="flex items-start mb-3">
                          <PhoneIcon
                            className="h-6 w-6  text-sidebar mr-4 "
                            aria-hidden="true"
                          />{' '}
                          *
                          <div className="">
                            <input
                              type="number"
                              name="work_mobile"
                              id="work_mobile"
                              autoComplete="work_mobile"
                              className="bg-inputbg border border-inputBorder block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.work_mobile}
                            />
                            {formik.touched.work_mobile &&
                            formik.errors.work_mobile ? (
                              <div className="mt-2 text-sm text-red-600">
                                {formik.errors.work_mobile}
                              </div>
                            ) : null}
                          </div>
                          {/* <h1 className=' font-semibold bg-gray-100 text-sm pl-3 py-2 mx-5 w-48 mt-3 border border-gray-300 rounded-md text-gray-500'>080 12356987</h1> */}
                        </span>

                        <span className="flex items-start mb-4">
                          <EnvelopeIcon
                            className="h-6 w-6 text-sidebar mr-4"
                            aria-hidden="true"
                          />{' '}
                          *
                          <div>
                            <div className="">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="bg-inputbg border border-inputBorder block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/* <h1 className=' font-semibold bg-gray-100 text-sm pl-3 py-2 mx-3 w-48 mt-3 border border-gray-300 rounded-md text-gray-500'>robin@skillstrove.com</h1> */}
                        </span>
                        {/* <CardsUser /> */}
                      </div>
                    </div>
                    {/* <!-- Contact Details --> */}
                    <div className="w-full border-b border-gray-100  sm:border-0 sm:border-r px-6">
                      <div className=" mb-4">
                        <h2 className=" text-lg font-semibold px-4">
                          Address *
                        </h2>

                        <div className="mt-2">
                          <textarea
                            rows={4}
                            name="address"
                            id="address"
                            className=" w-full rounded-md border px-4 py-1.5 text-black shadow-sm font-semibold  placeholder:text-black bg-gray-100 focus:outline-none sm:text-sm sm:leading-6"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="mt-2 text-sm text-red-600">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </div>

                        {/* <span className='flex items-end mb-4 mx-4'> 
          
          <textarea className=' font-semibold bg-gray-100 text-sm pl-3 py-2 mx-3 w-48 mt-3 border border-gray-300 rounded-md text-gray-500'>#28, Rosary Church Colony,
Houston, Texas
United States</textarea>
         </span> */}
                      </div>
                    </div>

                    <div className="w-full border-b border-gray-100  sm:border-0 sm:border-r px-6">
                      <div className="">
                        {/* file upload */}
                        <div className="fileupload-view">
                          <div className="row justify-content-center m-0">
                            <div className="col-md-6">
                              <div className="card mt-5">
                                <div className="card-body">
                                  <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                      <div className="kb-data-title">
                                        <h6>
                                          Document Upload(Doc, Docx, Pdf file)
                                        </h6>
                                      </div>
                                    </div>
                                    {/* <form onSubmit={FileUploadSubmit}> */}
                                    <div className="kb-file-upload mb-6">
                                      <div className="file-upload-box rounded-full px-3 bg-sidebar ">
                                        <input
                                          type="file"
                                          id="fileupload"
                                          className="file-upload-input"
                                          accept=".doc,.docx,.pdf"
                                          onChange={handleFileChange}
                                          multiple
                                        />
                                        <span className="flex">
                                          <ArrowUpOnSquareIcon
                                            className="h-8 w-8 text-white mr-4"
                                            aria-hidden="true"
                                          />
                                          <span className=" text-white self-center">
                                            Upload Resume / CV
                                          </span>
                                        </span>
                                      </div>
                                    </div>

                                    {docFileUrl != '' ? (
                                      <>
                                        <a
                                          href={docFileUrl}
                                          className=" underline block"
                                          target="_blank"
                                        >
                                          Preview Link
                                        </a>
                                        <span className="text-red-500 text-xs font-bold block">
                                          (Save the form to upload the resume)
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          href={canddetails?.data.resume}
                                          className=" underline"
                                          target="_blank"
                                        >
                                          Download Resume
                                        </a>
                                        {docs?.length > 0 ? (
                                          // <DocViewer documents={docs} />
                                          <button onClick={openModal}>
                                            View Resume
                                          </button>
                                        ) : (
                                          ''
                                        )}

                                        {/* <DocViewer
                                          key={canddetails.id}
                                          documents={[
                                            {
                                              uri: URL.createObjectURL(
                                                canddetails?.data.resume
                                              ),
                                              fileType: file.type,
                                            },
                                          ]}
                                          pluginRenderers={DocViewerRenderers} // Use the correct property name
                                        /> */}
                                      </>
                                    )}

                                    {/* {canddetails?.data.resume != null ? (
                                      <a
                                        href={canddetails?.data.resume}
                                        className=" underline"
                                        target="_blank"
                                      >
                                        Download Resume
                                      </a>
                                    ) : (
                                      ''
                                    )} */}

                                    <div className="kb-buttons-box">
                                      {/* <button
                                        type="button"
                                        onClick={handleDocUpload}
                                        className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 my-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                      >
                                        Upload
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* file upload */}
                      </div>
                    </div>
                  </dl>

                  <div className="grid grid-cols-6 gap-4 px-5 py-5 ">
                    <div className="">
                      <label
                        htmlFor="experience"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Experience (Years) *
                      </label>

                      <input
                        type="number"
                        name="experience"
                        id="experience"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        // placeholder="First name"
                        onBlur={formik.handleBlur}
                        value={formik.values.experience}
                      />
                      {formik.touched.experience && formik.errors.experience ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.experience}
                        </div>
                      ) : null}
                    </div>

                    <div className="">
                      <label
                        htmlFor="salary_expectation"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Current Salary *
                      </label>

                      <input
                        type="number"
                        name="salary_expectation"
                        id="salary_expectation"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        // placeholder="First name"
                        onBlur={formik.handleBlur}
                        value={formik.values.salary_expectation}
                      />
                      {formik.touched.salary_expectation &&
                      formik.errors.salary_expectation ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.salary_expectation}
                        </div>
                      ) : null}
                    </div>

                    <div className="">
                      <label
                        htmlFor="notice_period"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Notice Period (In Days) *
                      </label>

                      <input
                        type="number"
                        name="notice_period"
                        id="notice_period"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        // placeholder="First name"
                        onBlur={formik.handleBlur}
                        value={formik.values.notice_period}
                      />
                      {formik.touched.notice_period &&
                      formik.errors.notice_period ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.notice_period}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="current_company"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Current Company *
                      </label>

                      <input
                        type="text"
                        name="current_company"
                        id="current_company"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        // placeholder="First name"
                        onBlur={formik.handleBlur}
                        value={formik.values.current_company}
                      />
                      {formik.touched.current_company &&
                      formik.errors.current_company ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.current_company}
                        </div>
                      ) : null}
                    </div>

                    <div className="">
                      <label
                        htmlFor="dob"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Date of Birth *
                      </label>

                      <input
                        type="date"
                        name="dob"
                        id="dob"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        // placeholder="First name"
                        onBlur={formik.handleBlur}
                        value={formik.values.dob}
                      />
                      {formik.touched.dob && formik.errors.dob ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.dob}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <div className="mr-3">
                      <button
                        type="button"
                        onClick={() => gotoAddSourcing('cancelcandidate')}
                        // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 my-4  ml-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
                        className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 mb-4  mr-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
                        // className="inline-flex  justify-center rounded-md border border-transparent bg-violet-900 px-4 my-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {selectedCand > 0 ? (
        <div className="flex flex-row mt-5 ">
          <div className="w-full gap-4 rounded-lg">
            <div className="rounded-lg bg-white shadow-lg ">
              <div className="">
                <Tab.Group as="div" className="mt-2">
                  <Tab.List className="border-b border-gray-200 -mb-px flex space-x-8 px-5 py-0">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          General skill
                        </a>
                      </span>
                    </Tab>

                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          Employment
                        </a>
                      </span>
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          Education
                        </a>
                      </span>
                    </Tab>

                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          Relevant skills
                        </a>
                      </span>
                    </Tab>

                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          Project
                        </a>
                      </span>
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? ' text-black p-2 rounded-md text-sm '
                                : 'text-skillcapstext p-2 rounded-md text-sm'
                            )
                          }
                        >
                          Resume
                        </a>
                      </span>
                    </Tab>
                  </Tab.List>
                  <Tab.Panels as={Fragment}>
                    <Tab.Panel className="space-y-4 py-3">
                      {/* Content Skill */}
                      <CandKeyskills
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={showNotificationMsg}
                      />
                    </Tab.Panel>
                    <Tab.Panel className="space-y-4 py-3">
                      {/* Content Pref */}
                      <CandEmployment
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={CandEmployment}
                      />
                    </Tab.Panel>
                    <Tab.Panel className=" space-y-4 py-3">
                      <CandEducation
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={showNotificationMsg}
                      />
                    </Tab.Panel>
                    <Tab.Panel className="space-y-4 py-3">
                      <CandTechskills
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={showNotificationMsg}
                      />
                    </Tab.Panel>
                    <Tab.Panel className="space-y-4 py-3">
                      <CandProjects
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={showNotificationMsg}
                      />
                    </Tab.Panel>
                    <Tab.Panel className="space-y-4 py-3">
                      {/* <CandProjects
                        gotoAddSourcing={gotoAddSourcing}
                        showNotificationMsg={showNotificationMsg}
                      /> */}
                      <CandResume resume={canddetails?.data.resume} />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Resume"
        // Add your custom styles for the modal here
      >
        <Document
          file={docs[0]?.uri}
          onLoadSuccess={({ numPages }) =>
            console.log(`Number of pages: ${numPages}`)
          }
        >
          <Page pageNumber={1} />
        </Document>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

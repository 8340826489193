import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  email: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegister: (state, action) => {
      const { message, email } = action.payload;
      state.message = message;
      state.email = email;
    },
  },
});

export const { setRegister } = registerSlice.actions;

export default registerSlice.reducer;

export const selectCurrentEmail = (state) => state.register.email;

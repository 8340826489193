import React, { Fragment, useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useAdminPrivSetQuery } from '../features/privileges/adminSettingsApiSlice';
import { Dialog, Transition } from '@headlessui/react';
import {
  ChartPieIcon,
  InboxIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import SidebarLogo from '../assets/images/logodash.png';
import { useSelector } from 'react-redux';
import {
  selectCurrentUserRole,
  selectCurrentUserID,
  selectCurrentemail,
  selectCurrentEmployeeType,
} from '../features/auth/authSlice';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const Sidebar = () => {
  const userRole = useSelector(selectCurrentUserRole);
  const currentUserId = useSelector(selectCurrentUserID);
  const currentEmployeeType = useSelector(selectCurrentEmployeeType);
  const menuitems =
    currentEmployeeType == 'Interviewer'
      ? [
          {
            name: 'Interview Request',
            href: '/app/interviewrequest',
            icon: ChartPieIcon,
          },
          {
            name: 'Calendar',
            href: '/app/calendar',
            icon: CalendarIcon,
          },
        ]
      : userRole == 1
      ? [
          { name: 'Dashboard', href: '/app/dashboard', icon: ChartPieIcon },
          { name: 'Team', href: '/app/team', icon: UserGroupIcon },
          { name: 'Settings', href: '/app/settings', icon: Cog6ToothIcon },
        ]
      : [{ name: 'Dashboard', href: '/app/dashboard', icon: ChartPieIcon }];

  const {
    data: adminSettings,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useAdminPrivSetQuery(currentUserId, { refetchOnMountOrArgChange: true });

  let adminpriv;
  let otherPriv;
  let userWorkspacePrivileges;

  if (!isLoading) {
    let arraryAalue = [];
    adminpriv = adminSettings?.data?.adminPrivileges;
    userWorkspacePrivileges = adminSettings?.data?.userWorkspacePrivileges;
    if (userRole !== 1) {
      if (adminpriv) {
        if (Object.keys(adminpriv).length > 0) {
          menuitems.push({
            name: 'Admin Workspace',
            href: '/app/adminworkspace',
            icon: InboxIcon,
          });
        }
      }

      if (userWorkspacePrivileges) {
        if (Object.keys(userWorkspacePrivileges).length > 0) {
          menuitems.push({
            name: 'User Workspace',
            href: '/app/userworkspace',
            icon: UserGroupIcon,
          });
        }
      }

      if (adminpriv?.hasOwnProperty('Other admin privileges')) {
        if (adminpriv['Other admin privileges']?.length > 0 && userRole !== 1) {
          adminpriv['Other admin privileges'].map((selectedList) => {
            if (selectedList.title == 'Team') {
              arraryAalue.push({
                name: 'Team',
                href: '/app/team',
                icon: UserGroupIcon,
              });
            }

            if (selectedList.title == 'Master data') {
              arraryAalue.push(
                { name: 'Department', href: '/app/settings/department' },
                { name: 'Designation', href: '/app/settings/designation' },
                { name: 'Branch', href: '/app/settings/branch' },
                { name: 'Location', href: '/app/settings/location' },
                { name: 'Skilltype', href: '/app/settings/skilltype' },
                { name: 'Skill', href: '/app/settings/skill' }
              );
            }

            if (selectedList.title == 'Email template') {
              arraryAalue.push({
                name: 'Email Template',
                href: '/app/settings/emailtemplate',
              });
            }

            if (selectedList.title == 'Offer template') {
              arraryAalue.push({
                name: 'Offer Template',
                href: '/app/settings/offertemplate',
              });
            }
          });

          menuitems.push({
            name: 'Settings',
            href: '/app/settings',
            icon: Cog6ToothIcon,
            current: false,
            children: arraryAalue,
          });
        }
      }
    }
  }
  return (
    <>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-purplecolor-300 pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img className="" src={SidebarLogo} alt="Rsaas" />
          </div>

          <div className="mt-8 flex flex-1 flex-col">
            <nav className="flex-1 space-y-2 pb-4">
              {menuitems.map((item) =>
                !item.children ? (
                  <div key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-indigo-100 bg-purplecolor-200 rounded-full group flex items-center px-4 py-2 text-base font-medium mx-4'
                          : 'text-indigo-100 hover:bg-[#4a318b] hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4'
                      }
                      // className={classNames(
                      //   item.current
                      //     ? 'bg-gray-600 text-white'
                      //     : ' text-indigo-100 hover:bg-purplecolor-200 hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4',
                      //   'text-indigo-100 hover:bg-purplecolor-200 hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4'
                      // )}
                    >
                      <item.icon
                        className="mr-3 h-7 w-7 text-purplecolor-400"
                        aria-hidden="true"
                      />

                      {item.name}
                    </NavLink>
                  </div>
                ) : (
                  <Disclosure as="div" key={item.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-white'
                              : ' text-indigo-100 hover:bg-purplecolor-200 hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4',
                            ' text-indigo-100 hover:bg-purplecolor-200 hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4'
                          )}
                        >
                          <item.icon
                            className="mr-3 h-7 w-7 text-purplecolor-400"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.name}</span>
                          <svg
                            className={classNames(
                              open ? 'rotate-90 text-white' : 'text-white',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-white'
                            )}
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1 ml-10 border-b-2 border-gray-400">
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              href={subItem.href}
                              className="text-indigo-100 hover:bg-purplecolor-200 hover:rounded-full group flex items-center px-4 py-2 text-base font-medium rounded-md mx-4"
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

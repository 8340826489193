import React from 'react';
import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';

// import Screening from '../components/common/Screening';
import { useScreeningApprovalQuery } from '../../../../../features/hiringstages/screeningApiSlice';

import { Link } from 'react-router-dom';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import {
  PlusIcon,
  PencilSquareIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';

import { Dialog, Menu, Transition } from '@headlessui/react';
import CandidateList from './CandidateList';
import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';
import GetCountry from '../../../../../components/common/GetCountry';
import GetBranchName from '../../../../../components/common/GetBranchName';
import GetLocationName from '../../../../../components/common/GetLocationName';
import { getStatusCode } from '../../../UserWSUtils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const transactions = [
  {
    name: 'Robin Williams',
    exp: '4 Years',
    salary_exp: '10 L',
    notice_prd: '1 Month',
    current_company: 'HCL',
    current_pos: '3D Animator',
  },
  {
    name: 'Theodore',
    exp: '6 Years',
    salary_exp: '12 L',
    notice_prd: '2 Months',
    current_company: 'Capgemini',
    current_pos: '3D Modeler',
  },
  {
    name: 'Dwayne',
    exp: '8 Years',
    salary_exp: '16 L',
    notice_prd: '3 Months',
    current_company: 'TCS',
    current_pos: 'Lead Designer',
  },
  // More transactions...
];

export const SOScreening = () => {
  const {
    data: screeningList,
    error: screeningListError,
    isLoading: screeningListLoading,
    isFetching: screeningListFetching,
    isSuccess: screeningListSuccess,
  } = useScreeningApprovalQuery({ refetchOnMountOrArgChange: true });
  return (
    <>
      <div>
        {screeningList?.data.slice(0, 3).map((screening) => (
          <div className=" pt-0 sm:pt-0" key={screening.job_request_id}>
            <Disclosure as="div" className="pt-6">
              {({ open }) => (
                <>
                  {}
                  <dt>
                    <Disclosure.Button
                      className="flex w-full text-left text-gray-900 bg-white items-center shadow-lg rounded-lg "
                      onClick={() => console.log('DD click', open)}
                    >
                      <span className="text-base font-semibold leading-7 w-full">
                        <div className="mt-0  pb-0 sm:pb-0">
                          <div className="relative">
                            <div className="absolute inset-0 h-1/2 " />
                            <div className="relative  px-6 lg:px-0">
                              <div className="">
                                <dl className="rounded-t-lg bg-white  sm:grid sm:grid-cols-10 px-3">
                                  <div className="flex  border-b border-gray-100 py-3 text-center self-center sm:border-0 sm:border-r col-span-2">
                                    <span className="self-center mr-3">
                                      {open ? (
                                        <ChevronUpIcon
                                          className="ml-1 mr-2 h-7 w-7 text-white bg-purple-800 rounded-full "
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          className="ml-1 mr-2 h-7 w-7 text-white bg-purple-800 rounded-full "
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>

                                    <div className="">
                                      {' '}
                                      <p className="order-1 mt-2 text-md font-medium leading-6 text-gray-500">
                                        {screening.job_request_title}
                                      </p>
                                      <p className="order-2 text-md font-semibold tracking-tight text-indigo-600">
                                        {/* <GetBranchName
                                          branchId={screening.branch_id}
                                        />
                                        ,{' '} */}
                                        <GetLocationName
                                          branchId={screening.branch_id}
                                        />
                                        ,{' '}
                                        <GetCountry
                                          countryid={screening.country_id}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-col border-b border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6  text-indigo-600">
                                      Position
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                      {screening.no_of_position}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6 text-indigo-600">
                                      Department
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                      {screening.department_id}
                                    </dd>
                                  </div>

                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Emp Type
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                      {screening.employment_type}
                                    </dd>
                                  </div>

                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Salary
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight text-gray-500">
                                      {screening.salary_budget}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Sourced
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                      {screening.sourced}
                                    </dd>
                                  </div>
                                  {/* <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Source by
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                      Robin Williams
                                    </dd>
                                  </div> */}
                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Timeline Status
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500 flex justify-center">
                                      {getStatusCode(screening.Taskstatus)}
                                    </dd>
                                  </div>
                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      Signoff Status
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500 flex justify-center">
                                      {getStatusCode(screening.SignoffStatus)}
                                    </dd>
                                  </div>

                                  <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                                    <dt className="order-1 mt-2 text-lg font-medium leading-6   text-indigo-600">
                                      RQ Date
                                    </dt>
                                    <dd className="order-2 text-md font-semibold tracking-tight  text-gray-500">
                                      {DateToIndiaFormat(
                                        screening.closing_date
                                      )}
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <CandidateList screeningId={screening.job_request_id} />
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </>
  );
};

import { apiSlice } from '../../app/api/apiSlice';

export const changePasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changePasswordApi: builder.mutation({
      query: (credentials) => ({
        url: '/users/resetpassword',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useChangePasswordApiMutation } = changePasswordApiSlice;

import { useState } from 'react';

import {
  ArchiveBoxIcon,
  PencilSquareIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import Notification from '../../../../../components/common/Notification';

import { useSourcingQuery } from '../../../../../features/hiringstages/sourcingApiSlice';
import { useBranchQuery } from '../../../../../features/branch/branchApiSlice';
import { useDepartmentQuery } from '../../../../../features/department/departmentApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';
import { getStatusCode } from '../../../UserWSUtils';

export default function Sourcing({ toggleSourcing }) {
  const [showNotification, setShowNotification] = useState(false);

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: depts,
    error: deptsError,
    isLoading: deptsLoading,
    isFetching: deptsFetching,
    isSuccess: deptsSuccess,
  } = useDepartmentQuery({ refetchOnMountOrArgChange: true });

  const {
    data: sourcingList,
    error: sourcingListError,
    isLoading: sourcingListLoading,
    isFetching: sourcingListFetching,
    isSuccess: sourcingListSuccess,
  } = useSourcingQuery({ refetchOnMountOrArgChange: true });

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };
  const getDepartmentName = (deptsId) => {
    if (deptsSuccess) {
      const deptsName = depts?.data.filter((dept) => {
        return dept.id === deptsId;
      });
      return deptsName[0]?.name;
    }
  };

  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'New'}
          </div>
        </div>
      );
    }
  };

  const gotoAddSourcing = (value, obj) => {
    toggleSourcing(value, obj);
  };

  return (
    <>
      <div className="mt-0 flex flex-col px-6">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-2">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100 text-md font-bold text-gray-900">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left  sm:pl-6"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left "
                    >
                      Branch
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Positions
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left "
                    >
                      Candidate Sourced
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left "
                    >
                      Department
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Emp Type
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Salary
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Signoff by
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Timeline Status
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Signoff Status
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 "
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white ">
                  {sourcingList?.data
                    .filter((data) => data.job_request_approval_status == 1)
                    .map((sourcing) => (
                      <tr key={sourcing.job_request_id}>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm  sm:pl-6 border-r-2">
                          {sourcing.job_request_title}
                        </td>{' '}
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {getBranchName(sourcing.branch_id)}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {sourcing.no_of_position}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                          {sourcing.sourced || '-'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                          {getDepartmentName(sourcing.department_id)}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {sourcing.employment_type || '-'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {sourcing.budget}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {getUsername(sourcing.job_request_approved_by)}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                          {displayStatus(sourcing.job_request_approval_status)}
                        </td>
                        {getStatusCode(sourcing.Taskstatus)}
                        {getStatusCode(sourcing.SignoffStatus)}
                        <td className="relative flex items-center justify-start whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                          {/* <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                          <span className="sr-only">, {transaction.id}</span>
                        </a> */}

                          <PencilSquareIcon
                            className="ml-1 mr-2 h-7 w-7 text-purple-500 cursor-pointer "
                            aria-hidden="true"
                            onClick={() =>
                              gotoAddSourcing('addsourcing', sourcing)
                            }
                          />

                          <a href="#" title="View">
                            <CheckCircleIcon
                              className="ml-1 mr-2 h-7 w-7 text-green-500 "
                              aria-hidden="true"
                              // onClick={() => openModalWindow()}
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
    </>
  );
}

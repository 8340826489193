import { apiSlice } from '../../app/api/apiSlice';

export const adminSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    adminPrivSet: builder.query({
      query: (id) => ({
        url: `/settings/privileges/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useAdminPrivSetQuery } = adminSettingsApiSlice;

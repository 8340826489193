import { createSlice } from '@reduxjs/toolkit';

const candidateInterviewSlice = createSlice({
  name: 'candidateInterview',
  initialState: null,
  reducers: {
    setCandId: (state, action) => {
      console.log(action.payload)
      return action.payload;
    },
  },
});

export const { setCandId } = candidateInterviewSlice.actions;

export default candidateInterviewSlice.reducer;

export const selectCandidateInterview = (state) => state.candidateinterview;

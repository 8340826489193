import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdateJobrequestApprovalMutation } from '../../../../features/manpower/jobrequestApiSlice';
import { getStatusToToast } from '../../../../features/utils/Utils';

const SOJobRequestDrawer = ({ data, isEdit, onClose }) => {
  const [notes, setNotes] = useState('');
  const [updateJobrequestApproval, { isLoading }] =
    useUpdateJobrequestApprovalMutation();

  const initialValues = {
    id: data?.id || '',
    approval_status: 0,
    comments: '',
  };
  const validationSchema = yup.object({
    // details: yup.string().required('Please enter Manpower Requirement details'),
    // Requirement: yup.string().required('Please enter Requirement'),
  });
  const onSubmit = async (validationSchema) => {
    // try {
    //   if (isEdit) {
    //     const accountData = await updateManpowerRQMT(validationSchema).unwrap();
    //   } else {
    //     const accountData = await addManpowerRQMT(validationSchema).unwrap();
    //     formik.values.name = '';
    //     formik.values.description = '';
    //   }
    //   setShowNotification(!showNotification);
    //   // onClose();
    // } catch (error) {
    //   console.log('error ', error.status, error.data.message);
    //   if (!error) {
    //     setErrMsg('No Server Response');
    //   } else {
    //     setErrMsg(error.data.message);
    //   }
    //   console.log('errMsg ', errMsg);
    // }
  };

  const onSubmitClicked = async (approvalId) => {
    try {
      const accountData = await updateJobrequestApproval({
        id: data.id,
        approval_status: approvalId || 0,
        comments: notes || '',
      }).unwrap();
      getStatusToToast(approvalId, 'Job Request');
      onClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <>
      <form
        // onSubmit={formik.handleSubmit}
        className="flex flex-col justify-between"
      >
        <div className="flex justify-between border-b-2 border-gray-200">
          <div className=" items-center">
            <h4 className="text-xl">{data.location}</h4>
            <h5 className="mb-3 text-sm font-normal tracking-tight text-gray-900">
              {data.branch}
            </h5>
          </div>
          <div>
            <strong className="flex w-full text-4xl font-medium text-purple-700">
              {data.position}
            </strong>
          </div>
        </div>

        <div className="space-y-1 mt-5 border-b-2 border-gray-200">
          <h2 className="mb-2 text-lg font-bold tracking-tight text-purple-900">
            Requested by
          </h2>
          <h3 className="mb-2 text-md font-normal tracking-tight text-black">
            {data.requestedBy}
          </h3>
        </div>

        <div className="space-y-1 pt-5">
          <h5 className="mb-2 text-sm font-normal tracking-tight text-gray-900">
            Notes
          </h5>
          <label htmlFor="description" className="sr-only">
            Notes
          </label>
          <textarea
            rows={8}
            name="notes"
            id="notes"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            // defaultValue={''}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.notes}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
          {/* {formik.touched.notes && formik.errors.notes ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.notes}
            </div>
          ) : null} */}
          <div className="flex flex-shrink-0 justify-between py-4">
            <button
              type="button"
              className=" rounded-lg  bg-queryBlue text-white py-1 px-6 mr-3 text-sm font-medium  shadow-sm focus:outline-none "
              onClick={() => onSubmitClicked(2)}
            >
              Query
            </button>
            <button
              type="button"
              className=" rounded-lg border border-red-600 bg-red-600 py-1 px-6 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
              onClick={() => onSubmitClicked(3)}
            >
              Reject
            </button>
            <button
              type="button"
              className="inline-flex justify-center rounded-lg border border-transparent bg-approve py-1 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
              onClick={() => onSubmitClicked(1)}
            >
              Approve
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SOJobRequestDrawer;

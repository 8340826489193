import React, { Fragment, useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { RadioGroup } from '@headlessui/react';
import { useTeamsQuery } from '../././../features/teams/teamsApiSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CardsUser = ({ getUserId }) => {
  const [selected, setSelected] = useState();
  const { data: users } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const onChangeValue = (selected) => {
    setSelected();
    // e.preventDefault;
    getUserId(selected);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState();

  useEffect(() => {
    setSearchResults(users?.data);
  }, [users]);

  return (
    <>
      {/* <RadioGroup value={selected} onChange={setSelected}> */}
      <div className="bg-white p-4 rounded-md shadow-3xl h-[calc(100vh-10rem)]">
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            Search by Name
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="hello overflow-auto h-[calc(100%-36px)] pr-2">
          <RadioGroup value={selected} onChange={(e) => onChangeValue(e)}>
            <RadioGroup.Label className="sr-only">
              {' '}
              Server size{' '}
            </RadioGroup.Label>
            <div className="space-y-4 mt-4">
              {searchResults
                ?.filter((item) =>
                  searchTerm.toLowerCase() === ''
                    ? item
                    : item.first_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                )
                .map((user) => (
                  <RadioGroup.Option
                    key={user.id}
                    value={user}
                    className={({ checked, active }) =>
                      classNames(
                        checked
                          ? 'border-transparent bg-selectedbg'
                          : 'border-gray-300',
                        active
                          ? 'border-indigo-600 ring-1 ring-indigo-600'
                          : '',
                        'relative block cursor-pointer rounded-lg border  px-4 py-2 shadow-sm focus:outline-none sm:flex sm:justify-between'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span className="flex items-center">
                          <span className="bg-indigo-600 flex-shrink-0 flex items-center justify-center w-9 h-9 mr-4 text-white  text-sm font-medium rounded-full">
                            {user.first_name.charAt(0).toUpperCase()}
                          </span>
                          <span className="flex flex-col text-sm">
                            <RadioGroup.Label
                              as="span"
                              className="font-medium text-gray-900"
                            >
                              {user.first_name} {user.last_name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="text-gray-500 italic"
                            >
                              Designation
                            </RadioGroup.Description>
                          </span>
                        </span>

                        <span
                          className={classNames(
                            active ? 'border' : 'border-2 ',
                            checked
                              ? 'border-indigo-600'
                              : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    </>
  );
};
function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default CardsUser;

import React from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import { selectCurrentEmail } from '../../features/auth/register/registerSlice';

const AccountActivate = () => {
  const registeredEmail = useSelector(selectCurrentEmail);
  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-10 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" width="240" />
            </div>

            <div>
              <div>
                {/* <CheckCircleIcon className="bg-white" /> */}
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#10B410"
                    className=" h-24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>

                <h5 className="mt-6 text-2xl font-semibold text-center tracking-tight text-gray-900">
                  Account activated successfully
                </h5>
                <p className="text-center text-xs font-normal text-verify pb-5 mt-1">
                  Thank you for registering and activating your account
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>
              </div>

              <div className="">
                <div className="flex justify-center" aria-hidden="true">
                  <div className="w-48 border-t border-gray-300 md:divide-y-8 flex justify-center text-center" />
                </div>
              </div>
              <div>
                <p className="text-center text-xs font-normal text-verify pt-6">
                  Clickhere
                  <Link
                    to={'/login'}
                    className="text-clickhere text-md font-semibold"
                  >
                    {' '}
                    Login
                  </Link>{' '}
                  {/* to resend confirmation email. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountActivate;

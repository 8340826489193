import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetInterviewCandDetailsQuery } from '../../../../../../../features/hiringstages/interviewApiSlice';
import { selectCandidateInterview } from "../../../../../../../features/hiringstages/candidateInterviewSlice";
// import { useGetScreenCandDetailsQuery } from "../../../../../../../features/hiringstages/screeningApiSlice";
import { useGetCandScreeningScoreQuery } from "../../../../../../../features/hiringstages/screeningApiSlice";


function ScreeningScore() {
  const candId = useSelector(selectCandidateInterview);
  console.log(candId);

  const {
    data: screeningScore,
    // isLoading,
    // isSuccess,
    // isError,
  } = useGetCandScreeningScoreQuery(candId, {
    refetchOnnMountOrArgChange: true,
  });
  console.log(screeningScore);


  // function calculateWeightAverage(category) {
  //     const totalRating = category.reduce((acc, skill) => acc + skill.raing, 0);
  //     const averageRating = totalRating / category.length;
  //     return averageRating;
  // }
  return (
    <>
      <div className=" ">

        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 bg-gray-200 ">
          <table className=" divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-gray-800 font-semibold capitalize">
                  Average Score
                </td>
                <td className=" px-3 py-4 text-gray-800 text-center text-lg font-semibold capitalize">

                  {screeningScore?.data?.screening_score}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </>
  )


}

export default ScreeningScore;
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAccountApiMutation,
  useAccountQuery,
} from '../../../features/account/accountApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Notification from '../../../components/common/Notification';
import { toast } from 'sonner';

const Account = () => {
  const [accountApi, { isLoading }] = useAccountApiMutation();
  const [showNotification, setShowNotification] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const {
    data,
    isLoading: dataLoading,
    isSuccess,
  } = useAccountQuery({ refetchOnMountOrArgChange: true });

  const stateObj = [
    { id: '1', name: 'Tamil Nadu' },
    { id: '2', name: 'Kerala' },
    { id: '3', name: 'Karnataka' },
  ];

  const initialValues = {
    company_name: data?.data.company_name || '',
    city: data?.data.city || '',
    zipCode: data?.data.zipCode || '',
    country_id: data?.data.country_id || '',
    state_id: data?.data.state_id || '',
    address: data?.data.address || '',
  };
  const validationSchema = yup.object({
    company_name: yup.string().required('Please enter company name'),
    city: yup.string().required('Please enter city'),
    state_id: yup.string().required('Please enter state'),
    country_id: yup.string().required('Please enter country'),
    zipCode: yup.number().required('Please enter zip code'),
    address: yup.string().required('Please enter address'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const accountData = await accountApi({
        company_name: formik.values.company_name,
        city: formik.values.city,
        zipCode: formik.values.zipCode,
        country_id: formik.values.country_id,
        state_id: formik.values.state_id,
        address: formik.values.address,
      }).unwrap();

      toast.success('Account details updated');
      // setShowNotification(!showNotification);
    } catch (error) {
      if (!error) {
        setErrMsg('No Server Response');
      } else {
        setErrMsg(error.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  if (dataLoading) return <div>Loading...</div>;

  if (isSuccess) {
    return (
      <>
        <Notification isShow={showNotification} />
        <section aria-labelledby="account-details-heading">
          <form onSubmit={formik.handleSubmit}>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="bg-white py-6 px-4 sm:p-6">
                <div className="flex justify-between items-center">
                  <h2
                    id="account-details-heading"
                    className=" flex text-lg font-bold leading-6 text-gray-900"
                  >
                    Account Details
                  </h2>

                  <div className="flex">
                    {/* <Link
                    type="button"
                    to={'/app/settings'}
                    className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
                  > */}
                    <Link
                      type="button"
                      to={'..'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
                    >
                      <ArrowLeftIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Back
                    </Link>
                  </div>
                </div>

                {errMsg && (
                  <div className="mt-2 text-sm text-red-600">{errMsg}</div>
                )}

                <div className="mt-6 grid grid-cols-3 gap-6">
                  <div className="">
                    <label
                      htmlFor="company_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      id="company_name"
                      autoComplete="cc-given-name"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_name}
                    />
                    {formik.touched.company_name &&
                    formik.errors.company_name ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.company_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-4 sm:col-span-1">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <select
                      id="city"
                      name="city"
                      autoComplete="city"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option>-- Select City --</option>
                      <option value={'Madurai'}>Madurai</option>
                      <option value={'Trichy'}>Trichy</option>
                      <option value={'Virudhunagar'}>Virudhunagar</option>
                    </select>
                    {formik.touched.city && formik.errors.city ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>

                  {/* <div className="">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="city"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.city}
                    </div>
                  ) : null}
                </div> */}

                  {/* <div className="">
                  <label
                    htmlFor="state_id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="state_id"
                    id="state_id"
                    autoComplete="state"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state_id}
                  />
                  {formik.touched.state_id && formik.errors.state_id ? (
                    <div className="mt-2 text-sm text-red-600">
                      {formik.errors.state_id}
                    </div>
                  ) : null}
                </div> */}

                  <div className="col-span-4 sm:col-span-1">
                    {/* <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <select
                    id="city"
                    name="city"
                    autoComplete="city-name"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option>-- Select City --</option>
                    <option value={'Madurai'}>Madurai</option>
                    <option value={'Trichy'}>Trichy</option>
                    <option value={'Virudhunagar'}>Virudhunagar</option>
                  </select> */}

                    <label
                      htmlFor="state_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State
                    </label>
                    <select
                      id="state_id"
                      name="state_id"
                      autoComplete="state_id"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state_id}
                    >
                      <option value="-1">-- Select State --</option>
                      {stateObj.map((state) => (
                        <option value={state.id} key={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.state_id && formik.errors.state_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.state_id}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {/* <div className="mb-6">
                    <label
                      htmlFor="country_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      name="country_id"
                      id="country_id"
                      autoComplete="country"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country_id}
                    />
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.country_id}
                      </div>
                    ) : null}
                  </div> */}
                    <div className="mb-6">
                      <div className="col-span-4 sm:col-span-1">
                        <label
                          htmlFor="country_id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <select
                          id="country_id"
                          name="country_id"
                          autoComplete="country_id"
                          className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                          value={formik.values.country_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="-1"> -- Select Country --</option>
                          <option value={'1'}>United States</option>
                          <option value={'2'}>Canada</option>
                          <option value={'3'}>Mexico</option>
                          <option value={'4'}>India</option>
                        </select>
                        {formik.touched.country_id &&
                        formik.errors.country_id ? (
                          <div className="mt-2 text-sm text-red-600">
                            {formik.errors.country_id}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="">
                      <label
                        htmlFor="zipCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        autoComplete="zipCode"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zipCode}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode ? (
                        <div className="mt-2 text-sm text-red-600">
                          {formik.errors.zipCode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <textarea
                      rows={5}
                      name="address"
                      id="address"
                      className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                      // defaultValue={''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      defaultValue={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="mt-2 text-sm text-red-600">
                        {formik.errors.address}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="border border-t-gray-300 bg-white px-4 py-3 text-right sm:px-6">
                {isLoading ? (
                  <button
                    type="submit"
                    disabled
                    className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : isSuccess ? (
                  <button
                    disabled={!(formik.dirty && formik.isValid)}
                    type="submit"
                    className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
                  >
                    Save
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </section>
      </>
    );
  }
};

export default Account;

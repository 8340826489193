import { apiSlice } from '../../app/api/apiSlice';

export const adminWSPApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['AdminMaster', 'Getadminchecklist'],
  endpoints: (builder) => ({
    adminWSP: builder.query({
      query: () => ({
        url: '/master/adminprivileges',
        method: 'GET',
      }),
      providesTags: ['AdminMaster'],
      keepUnusedDataFor: 5,
    }),

    getAdminWSP: builder.query({
      query: (id) => ({
        url: `/settings/useradminprivileges?user_id=${id}`,
        method: 'GET',
      }),
      providesTags: ['Getadminchecklist'],
      keepUnusedDataFor: 5,
    }),
    addAdminpriv: builder.mutation({
      query: (data) => ({
        url: '/settings/useradminprivileges',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Getadminchecklist'],
    }),
    // getAdminSettings: builder.query({
    //   query: (id) => ({
    //     url: `/settings/privileges/${id}`,
    //     method: 'GET',
    //   }),
    // }),
    // addAdminpriv: builder.mutation({
    //   query: (data) => ({
    //     url: '/settings/useradminprivileges',
    //     method: 'POST',
    //     body: { ...data },
    //   }),
    // }),
  }),
});

export const {
  useAdminWSPQuery,
  useGetAdminWSPQuery,
  useAddAdminprivMutation,
} = adminWSPApiSlice;

import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowUpIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  PlusIcon,
  PaperClipIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import ModalData from '../../../../../components/common/ModalData';

import CandDetails from './CandDetails';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import {
  useCandJoiningDetailsQuery,
  useJoiningApprovalMutation,
  useJoiningNegotationMutation,
} from '../../../../../features/hiringstages/joiningApiSlice';
import CandCalling from './modal/CandCalling';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const JoiningDetails = ({ toggleJoining }) => {
  const [joiningComments, setJoiningComments] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const candId = useSelector(selectCandidateInterview);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const [joiningApproval, { isLoading: approvalLoading }] =
    useJoiningApprovalMutation();
  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useCandJoiningDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  const [joiningNegotation, { isLoading: joiningNegotationLoading }] =
    useJoiningNegotationMutation();

  const canddetailsObj = canddetails?.data;
  const candJoingingArray = canddetailsObj?.joinings;

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
      role: 'Member',
    },
    // More people...
  ];

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getOverallScore = (data) => {
    let value =
      data.interview_with_other_company +
      data.other_offers_in_hand +
      data.attitude +
      data.response_to_communication +
      data.knowledge_about_company +
      data.interest_towards_company +
      data.satisfaction_towards_CTC +
      data.satisfaction_towards_job_role +
      data.interest_to_involve_with_internal_teams +
      data.location_satisfaction;

    return `${value / 10}%`;
  };

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    negotiated_doj: canddetailsObj?.negotiated_doj || '',
    // negotiated_salary: canddetailsObj?.negotiated_salary || '',
    negotiated_salary: canddetailsObj?.negotiated_salary || '6000',
    negotiated_notice_period: canddetailsObj?.negotiated_notice_period || '',
    payout_option_required: canddetailsObj?.payout_option_required || '',
    payout_amount: canddetailsObj?.payout_amount || '',
    negotiation_comments: canddetailsObj?.negotiation_comments || '',
  };

  const validationSchema = yup.object({
    negotiated_salary: yup.string().required('Please enter negotiate salary'),
    negotiated_doj: yup.string().required('Please select the date of joining'),
    negotiated_notice_period: yup
      .string()
      .required('please enter the notice period'),
    payout_option_required: yup
      .string()
      .required('Please select the Buyout option '),
    payout_amount: yup.string().required('Please enter the Buyout amount'),
    negotiation_comments: yup.string().required('Please enter the Comments'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const rrrr = await joiningNegotation(validationSchema).unwrap();
      alert('Negotation sumbitted Successfully');
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const gotoJoiningList = (value, candid) => {
    toggleJoining(value);
  };

  const handleClick = async (btnStatus) => {
    const initialValues = {
      profile_id: candId,
      joining_approval_status: 0,
      joining_comments: joiningComments,
      joining_priority: '',
    };

    let newData;
    if (btnStatus === 'Query') {
      newData = {
        ...initialValues,
        joining_approval_status: 3,
      };
    }
    if (btnStatus === 'Reject') {
      newData = {
        ...initialValues,
        joining_approval_status: 2,
      };
    }
    if (btnStatus === 'Approval') {
      newData = {
        ...initialValues,
        joining_approval_status: 1,
      };
    }
    try {
      const joiningApprovalData = await joiningApproval({
        ...newData,
      }).unwrap();
      alert(`Data ${btnStatus} successfully`);
    } catch (error) {
      alert('Error');
    }
  };

  return (
    <>
      <div className="flex justify-end  mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
            onClick={() => gotoJoiningList('joininglist')}
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails canddetails={canddetails} />

        <div className="basis-5/6">
          <div className="flex flex-row  ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
                <div className="bg-white ">
                  <div className="px-6 py-4">
                    <div className="">
                      <div className="">
                        <div className="inline-block min-w-full py-0 align-middle bg-white ">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Factors
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 w-3/12 text-md font-semibold text-gray-900"
                                >
                                  1st Calling Score
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 w-3/12 text-md font-semibold text-gray-900"
                                >
                                  2nd Calling Score
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 w-3/12 text-md font-semibold text-gray-900"
                                >
                                  3rd Calling Score
                                </th>
                              </tr>
                            </thead>
                            {/* <tbody className="divide-y divide-gray-200">
                              {people.map((person) => (
                                <tr key={person.email}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {person.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-col">
                                    {canddetailsObj?.joinings[0] ? (
                                      <div className=" w-full text-center bg-gray-400 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none ">
                                        Rating
                                      </div>
                                    ) : (
                                      <Link
                                        type="button"
                                        className="flex bg-blue-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                                        onClick={() =>
                                          openModalDataWindow({
                                            mode: 'Add',
                                            title: '1st Calling Score',
                                            data: 1,
                                          })
                                        }
                                      >
                                        Rating
                                      </Link>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                    {canddetailsObj?.joinings[1] ? (
                                      <div className=" w-full text-center bg-gray-400 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none ">
                                        Rating
                                      </div>
                                    ) : (
                                      <Link
                                        type="button"
                                        className="flex bg-blue-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                                        onClick={() =>
                                          openModalDataWindow({
                                            mode: 'Add',
                                            title: '2nd Calling Score',
                                            data: 2,
                                          })
                                        }
                                      >
                                        Rating
                                      </Link>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {canddetailsObj?.joinings[2] ? (
                                      <div className=" w-full text-center bg-gray-400 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none ">
                                        Rating
                                      </div>
                                    ) : (
                                      <Link
                                        type="button"
                                        className="flex bg-blue-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                                        onClick={() =>
                                          openModalDataWindow({
                                            mode: 'Add',
                                            title: '3rd Calling Score',
                                            data: 3,
                                          })
                                        }
                                      >
                                        Rating
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody> */}
                          </table>

                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="border-y border-gray-300">
                              {/* <h1 className="  font-semibold px-0 text-xl mt-3 text-gray-700 pb-4">
                                Basic Skill
                              </h1> */}
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12  pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interview with other company
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {/* {`${canddetailsObj?.joinings[0]?.interview_with_other_company}%` ||
                                    '-'} */}
                                  {/* 85% */}

                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.interview_with_other_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3 w-3/12 text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.interview_with_other_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3 w-3/12 text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.interview_with_other_company}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 w-3/12 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Other offers in hand
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {/* {canddetailsObj?.joinings[0]
                                    ?.other_offers_in_hand || '-'} */}

                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.other_offers_in_hand}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {/* {canddetailsObj?.joinings[1]
                                    ?.other_offers_in_hand || '-'} */}

                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.other_offers_in_hand}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 w-3/12 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {/* {canddetailsObj?.joinings[2]
                                    ?.other_offers_in_hand || '-'} */}

                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.other_offers_in_hand}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Attitude
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.attitude}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.attitude}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.attitude}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Response to communication
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.response_to_communication}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.response_to_communication}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.response_to_communication}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Knowledge about company
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.knowledge_about_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.knowledge_about_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.knowledge_about_company}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest towards company
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.interest_towards_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.interest_towards_company}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.interest_towards_company}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards CTC
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.satisfaction_towards_CTC}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.satisfaction_towards_CTC}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.satisfaction_towards_CTC}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Satisfaction towards Job Role
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.satisfaction_towards_job_role}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.satisfaction_towards_job_role}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.satisfaction_towards_job_role}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Interest to involve with internal teams
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.interest_to_involve_with_internal_teams}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.interest_to_involve_with_internal_teams}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.interest_to_involve_with_internal_teams}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Location Satisfaction
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[0] != null
                                    ? `${canddetailsObj?.joinings[0]?.location_satisfaction}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[1] != null
                                    ? `${canddetailsObj?.joinings[1]?.location_satisfaction}%`
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5  pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  {canddetailsObj?.joinings[2] != null
                                    ? `${canddetailsObj?.joinings[2]?.location_satisfaction}%`
                                    : '-'}
                                </th>
                              </tr>
                              <tr className="border-t border-gray-300">
                                <th
                                  scope="col"
                                  className="py-3.5 w-40 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-0"
                                >
                                  Overall Score:
                                </th>

                                <th
                                  scope="col"
                                  className="py-3.5 text-sidebar text-xl font-semibold pl-4 pr-3  sm:pl-0 text-center"
                                >
                                  {canddetailsObj?.joinings[0]
                                    ? getOverallScore(
                                        canddetailsObj?.joinings[0]
                                      )
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 text-sidebar text-xl font-semibold pl-4 pr-3  sm:pl-0 text-center"
                                >
                                  {canddetailsObj?.joinings[1]
                                    ? getOverallScore(
                                        canddetailsObj?.joinings[1]
                                      )
                                    : '-'}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 text-sidebar text-xl font-semibold pl-4 pr-3 sm:pl-0 text-center"
                                >
                                  {canddetailsObj?.joinings[2]
                                    ? getOverallScore(
                                        canddetailsObj?.joinings[2]
                                      )
                                    : '-'}
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white flex justify-start py-4 px-10">
                  <span className="text-xl font-bold">
                    Current Joining Status :
                    <b className="text-blue-600 text-xl font-semibold">
                      {' '}
                      Unpredictable
                    </b>
                  </span>
                </div>

                <form
                  className="  border-t border-gray-300 py-4 mx-6"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="">
                    <div className="">
                      <div className=" rounded-md shadow-3xl mx-0">
                        {' '}
                        <div className="mt-0 grid grid-cols-3 gap-6 px-0">
                          <div className="grid grid-cols-2">
                            <div className="mt-0">
                              <label
                                htmlFor="expected_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Expected Salary :
                              </label>
                            </div>
                            <div>
                              <h5 className="mt-0 font-bold text-sm read-only">
                                {canddetailsObj?.salary_expectation}
                              </h5>
                            </div>
                          </div>

                          <div className="grid grid-cols-2">
                            <div className="mt-0 ">
                              <label
                                htmlFor="negotiated_salary"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated Salary :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_salary}
                              </h4>
                            </div>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="mt-0 ">
                              <label
                                htmlFor="negotiated_DOJ"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Negotiated DOJ :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_doj}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="mt-0 grid grid-cols-3 gap-6 px-0">
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mt-0 mr-6 ">
                              <label
                                htmlFor="notice_period"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Notice Period :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_notice_period}
                              </h4>
                            </div>
                          </div>{' '}
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mt-0 ">
                              <label
                                htmlFor="buyout_option"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Option :
                              </label>
                            </div>
                            <div>
                              <h4 className="mt-0 font-bold text-sm">
                                {canddetailsObj?.negotiated_notice_period}
                              </h4>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-3">
                            <div className="mr-5">
                              <label
                                htmlFor="buyout_amount"
                                className="block text-sm  font-medium text-gray-900 mr-4"
                              >
                                Buyout Amount :
                              </label>
                            </div>
                            <h4 className="mt-0 font-bold text-sm">
                              {canddetailsObj?.payout_amount}
                            </h4>
                          </div>
                        </div>
                        <div className="mx-0 mt-4 grid grid-cols-1 gap-6 px-0 border-t border-gray-300 pt-2">
                          <div>
                            <label
                              htmlFor="comments"
                              className="block text-md font-semibold text-gray-700 pb-1"
                            >
                              Comments
                            </label>

                            <div>
                              <p className="mt-1 font-medium text-sm text-gray-500">
                                {canddetailsObj?.negotiation_comments}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mx-0 mt-3 grid grid-cols-1 gap-6 px-0 border-t border-gray-300 pt-2">
                          <div>
                            <label
                              htmlFor="feedback"
                              className="block text-md font-semibold text-gray-700 pb-2"
                            >
                              Feedback
                            </label>

                            <textarea
                              rows={5}
                              name="comments"
                              id="comments"
                              className="bg-inputbg w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder=""
                              value={joiningComments}
                              onChange={(e) =>
                                setJoiningComments(e.target.value)
                              }
                              // defaultValue={''}
                              // onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              // value={formik.values.feedback}
                            />
                          </div>
                        </div>
                        <div className="mx-0 mt-0 w-full">
                          <div className="mt-4 space-x-8 w-full flex justify-start">
                            {/* <div>
                              {' '}
                              <button
                                type="button"
                                className=" rounded-lg  bg-queryBlue text-white py-1 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                                // onClick={onClose}
                              >
                                Query
                              </button>
                            </div> */}
                            <button
                              type="button"
                              className=" rounded-lg  bg-blue-500 hover:bg-blue-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Query')}
                            >
                              Query
                            </button>
                            <button
                              type="button"
                              className=" rounded-lg  bg-red-600 hover:bg-red-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Reject')}
                            >
                              Reject
                            </button>

                            <button
                              type="button"
                              className=" rounded-lg  bg-green-400 hover:bg-green-600 text-white py-2 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                              onClick={() => handleClick('Approval')}
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        // data={openModalDataValues}
      >
        {/* <EditJD /> */}
        {/* <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        /> */}

        {/* Modal window */}
        <CandCalling dataval={openModalDataValues} />
        {/* Modal window */}
      </ModalData>
    </>
  );
};
export default JoiningDetails;

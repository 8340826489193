import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.jpg';
import { selectCurrentToken } from '../features/auth/authSlice';

const PageNotFound = () => {
  // const registeredEmail = useSelector(selectCurrentEmail);
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);
  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-10 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" width="240" />
            </div>

            <div>
              <div>
                {/* <CheckCircleIcon className="bg-white" /> */}
                <div className="flex justify-center"></div>

                <h5 className="text-9xl font-extrabold text-center tracking-tight text-gray-900">
                  404
                </h5>
                <p className="text-center text-lg font-normal text-verify pb-5 mt-1">
                  Page not found
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>
              </div>

              <div className="flex justify-center">
                {token !== '' ? (
                  <Link
                    to={'..'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Back
                  </Link>
                ) : (
                  <Link
                    to={'/login'}
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Back to Login Page
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

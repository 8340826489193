import React, { useState } from 'react';
// import {useSelector} from 'react-redux';
import ReactApexChart from 'react-apexcharts';
// import {ApexOptions} from 'apexcharts';

const Piechart = ({ chartData, chartName }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');//commented by lakshmi
  //Define options for countries, branches
  // const countryOptions = ['India','USA','UK'];
  // const branchOptions = ['Chennai','Bangalore','Pune'];
  const countryOptions = chartData?.map((obj) => obj.name);
  const branchOptions = ['Chennai', 'Bangalore', 'Pune'];

  // const pieChartData = [
  //     {name:'India', value : 150},
  //     {name:'USA', value:120},
  //     {name:'UK',value:100},
  //     {name:'Germany',value: 110},
  //     {name:'Russia',value:50},
  // ];
  const options = {
    labels: chartData ? chartData.map((item) => item.name) : [],
    colors: ['#FA2C50', '#FFC111', '#56BA96', '#B7117F', '#7396F2'],
    chart: {
      fontFamily: 'Inter var', // Set font family for all text elements
      foreColor: '#000000', // Set color for all text elements to white
      fontSize: '8px',
      fontWeight: 500,
      height: 'auto',
    },
    // title: {
    //     text: 'Manpower',
    //     style: {
    //         color: '#FFFFFF',
    //         fontSize: '20px',
    //         fontFamily: 'Poppins'
    //     }
    // },
    legend: {
      fontSize: '12px',
      fontFamily: 'Inter var',
      position: 'bottom',
      horizontalAlign: 'right',
      offsetX: -20,
    },
    dataLabels: {
      enabled: true, //Enable data labels
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]; //Display value
      },
      style: {
        fontSize: '14px',
        fontFamily:  'Inter var',
        // colors: ['#FFFFFF'], //color of the data labels
        colors: ['#fff'], //color of the data labels
        fontWeight:''
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };
  const series = chartData ? chartData.map((item) => parseInt(item.value)) : [];
  return (
    <div>
      <div className="flex justify-between p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          <select
            className="bg-[#fff] text-[13px] text-black "
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions?.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>

          {/* Dropdown for branch */}
          {/*commented by lakshmi*/}
          {/* <select
            className="ml-1  text-[13px] bg-[#39216B] text-white "
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select> */}
        </div>
      </div>
      <ReactApexChart
        type="pie"
        series={series}
        options={options}
        width={570}
        height={425}
      />
    </div>
  );
};

export default Piechart;

// import React from 'react';
// import {
//   ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ZAxis, Cell
// } from 'recharts';

// const chartData = [
//   { branch_id: 4, location_name: 'Bangalore', name: 'Dev Ops', value: 243 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'HR-6', value: 533 },
//   { branch_id: 6, location_name: 'Bangalore', name: 'Java script', value: 33 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'DevSecOps', value: 32 },
//   { branch_id: 4, location_name: 'Chennai', name: 'Dev Ops', value: 29 },
// ];

// const colors = ['#ff7300', '#387908', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d'];

// const processData = (data) => {
//   const locations = [...new Set(data.map(d => d.location_name))];
//   const names = [...new Set(data.map(d => d.name))];

//   return { locations, names, data };
// };

// const { locations, names, data } = processData(chartData);

// const BubbleChartJobrequest = () => (
//   <ScatterChart width={600} height={400} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
//     <CartesianGrid />
//     <XAxis type="category" dataKey="location_name" name="Location" tick={{ fontSize: 12 }} allowDuplicatedCategory={false} />
//     <YAxis type="category" dataKey="name" name="Name" tick={{ fontSize: 12 }} />
//     <ZAxis type="number" dataKey="value" range={[60, 400]} name="Value" />
//     <Tooltip cursor={{ strokeDasharray: '3 3' }} />
//     <Legend />
//     <Scatter name="Job Request" data={data} fill="#8884d8">
//       {data.map((entry, index) => (
//         <Cell key={`cell-${index}`} fill={colors[names.indexOf(entry.name) % colors.length]} />
//       ))}
//     </Scatter>
//   </ScatterChart>
// );

// export default BubbleChartJobrequest;

// import React from 'react';
// import {
//   ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ZAxis, Cell
// } from 'recharts';

// const chartData = [
//   { branch_id: 4, location_name: 'Bangalore', name: 'Dev Ops', value: 243 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'HR-6', value: 533 },
//   { branch_id: 6, location_name: 'Bangalore', name: 'Java script', value: 33 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'DevSecOps', value: 32 },
//   { branch_id: 4, location_name: 'Chennai', name: 'Dev Ops', value: 29 },
// ];

// const colors = ['#ff7300', '#387908', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d'];

// const processData = (data) => {
//   const locations = [...new Set(data.map(d => d.location_name))];
//   const names = [...new Set(data.map(d => d.name))];

//   return { locations, names, data };
// };

// const { locations, names, data } = processData(chartData);

// console.log(locations,names,data);
// const BubbleChartJobrequest = () => (
//   <ScatterChart
//     width={600}
//     height={400}
//     margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
//   >
//     <CartesianGrid />
//     <XAxis
//       type="category"
//       dataKey="location_name"
//       name="Location"
//       tick={{ fontSize: 12 }}
//       allowDuplicatedCategory={false}
//       domain={locations}
//     />
//     <YAxis
//       type="category"
//       dataKey="name"
//       name="Name"
//       tick={{ fontSize: 12 }}
//       domain={names}
//     />
//     <ZAxis type="number" dataKey="value" range={[60, 400]} name="Value" />
//     <Tooltip cursor={{ strokeDasharray: '3 3' }} />
//     <Legend />
//     <Scatter name="Job Request" data={data} fill="#8884d8">
//       {data.map((entry, index) => (
//         <Cell key={`cell-${index}`} fill={colors[names.indexOf(entry.name) % colors.length]} />
//       ))}
//     </Scatter>
//   </ScatterChart>
// );

// export default BubbleChartJobrequest;

// import React from 'react';
// import {
//   ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ZAxis, Cell
// } from 'recharts';

// const chartData = [
//   { branch_id: 4, location_name: 'Bangalore', name: 'Dev Ops', value: 243 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'HR-6', value: 533 },
//   { branch_id: 6, location_name: 'Bangalore', name: 'Java script', value: 33 },
//   { branch_id: 21, location_name: 'Bangalore', name: 'DevSecOps', value: 32 },
//   { branch_id: 4, location_name: 'Chennai', name: 'Dev Ops', value: 29 },
// ];

// const colors = ['#ff7300', '#387908', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d'];

// const processData = (data) => {
//   const locations = [...new Set(data.map(d => d.location_name))];
//   const names = [...new Set(data.map(d => d.name))];
//   return { locations, names, data };
// };

// const { locations, names, data } = processData(chartData);

// console.log('locations:', locations);
// console.log('names:', names);
// console.log('data:', data);

// const BubbleChart = () => (
//   <ScatterChart
//     width={600}
//     height={400}
//     margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
//   >
//     <CartesianGrid />
//     <XAxis
//       type="category"
//       dataKey="location_name"
//       name="Location"
//       tick={{ fontSize: 12 }}
//       allowDuplicatedCategory={false}
//     />
//     <YAxis
//       type="category"
//       dataKey="name"
//       name="Name"
//       tick={{ fontSize: 12 }}
//     />
//     <ZAxis type="number" dataKey="value" range={[60, 400]} name="Value" />
//     <Tooltip cursor={{ strokeDasharray: '3 3' }} />
//     <Legend />
//     <Scatter name="Job Request" data={data} fill="#8884d8">
//       {data.map((entry, index) => (
//         <Cell key={`cell-${index}`} fill={colors[names.indexOf(entry.name) % colors.length]} />
//       ))}
//     </Scatter>
//   </ScatterChart>
// );

// export default BubbleChart;

import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

const JobRequestBubbleChart = () => {
  const data = [
    { branch_id: 4, location_name: "Bangalore", name: "Dev Ops", value: 243 },
    { branch_id: 21, location_name: "Bangalore", name: "HR-6", value: 533 },
    { branch_id: 6, location_name: "Bangalore", name: "Java script", value: 33 },
    { branch_id: 21, location_name: "Bangalore", name: "DevSecOps", value: 32 },
    { branch_id: 4, location_name: "Chennai", name: "Dev Ops", value: 29 }
  ];

  const options = {
    data: data,
    series: [{
      type: 'bubble',
      xKey: 'location_name',
      yKey: 'name',
      sizeKey: 'value',
      labelKey: 'value',
      marker: {
        size: 5,
        maxSize: 60,
      },
    }],
    axes: [
      {
        type: 'category',
        position: 'bottom',
        title: { text: 'Location' },
      },
      {
        type: 'category',
        position: 'left',
        title: { text: 'Job Type' },
      }
    ],
    legend: {
      enabled: false
    },
    title: {
      text: 'Job Request Status Report',
    },
  };

  return (
    <div className="p-4 bg-[#39216B] text-white">
      <h2 className="text-center text-2xl mb-4">Job Request Status Report</h2>
      <AgChartsReact options={options} />
    </div>
  );
};

export default JobRequestBubbleChart;






import React, { useState } from 'react';

import CardsUser from '../../../components/common/CardsUser';

import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';

import { useUserWSPQuery } from '../../../features/privileges/userWSPApiSlice';
import { useAddUserprivMutation } from '../../../features/privileges/userSettingsApiSlice';
import UserPrivilegelist from './UserPrivilegelist';

const UserWorkspace = () => {
  const navigate = useNavigate();
  const [selectedID, setselectedID] = useState(0);
  const [addUserpriv, { isLoading: addLoading }] = useAddUserprivMutation();

  const {
    data: usersWSP,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useUserWSPQuery({ refetchOnMountOrArgChange: true });

  const selectedUser = (selectedUser) => {
    setselectedID(selectedUser.id);
  };

  const selectedOptions = async (e) => {
    const semaValue = {
      user_id: selectedID,
      workspace_privilege_id: e.target.value,
      status: e.target.checked ? 1 : 0,
    };
    try {
      const userPrivil = await addUserpriv(semaValue).unwrap();
    } catch (error) {}
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm font-bold leading-4 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
          User Workspace Privilege
        </p>
        <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
          <Link
            type="button"
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <CardsUser getUserId={selectedUser} />
        <div className="bg-white p-4 col-span-2 text-lg font-semibold overflow-auto rounded-md shadow-3xl h-[calc(100vh-10rem)]">
          {selectedID > 0 ? (
            <>
              <UserPrivilegelist userid={selectedID} />
            </>
          ) : (
            'Select a user to view the privileage list '
          )}
        </div>
      </div>
    </div>
  );
};

export default UserWorkspace;

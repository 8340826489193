import React from 'react';
import { useTeamsQuery } from '../features/teams/teamsApiSlice';

const useTeamsUsers = () => {
  const {
    data: teamsUsers,
    error: teamsUsersErr,
    isLoading: teamsUsersLoading,
    isFetching: teamsUsersFetching,
    isSuccess: teamsUsersSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });
  return {
    teamsUsers,
    teamsUsersLoading,
    teamsUsersSuccess,
  };
};

export default useTeamsUsers;

import { apiSlice } from '../../app/api/apiSlice';

export const designationApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Designation'],
  endpoints: (builder) => ({
    designation: builder.query({
      query: () => ({
        url: '/master/designation',
        method: 'GET',
      }),
      providesTags: ['Designation'],
      keepUnusedDataFor: 5,
    }),
    addDesignation: builder.mutation({
      query: (data) => ({
        url: '/master/designation',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Designation'],
    }),
    updateDesignation: builder.mutation({
      query: (data) => ({
        url: '/master/designation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Designation'],
    }),
    deleteDesignation: builder.mutation({
      query: (id) => ({
        url: `/master/designation/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Designation'],
    }),
  }),
});

export const {
  useDesignationQuery,
  useAddDesignationMutation,
  useUpdateDesignationMutation,
  useDeleteDesignationMutation,
} = designationApiSlice;

import { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import { Link } from 'react-router-dom';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  PhoneIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowUpIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import {
  PlusIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  UserCircleIcon,
  DevicePhoneMobileIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';

import ModalData from '../../../../../components/common/ModalData';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { selectCandidateInterview } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
// import CandDetails from './offerdetails/CandDetails';

// import InterviewScores from './offerdetails/tabs/InterviewScores';
import InterviewScores from '../interview/interviewprocess/tabs/InterviewScore';

import Negotation from './offerdetails/tabs/Negotation';
import OfferLetter from './offerdetails/tabs/OfferLetter';
import Documents from './offerdetails/tabs/Documents';
import { useCandOfferDetailsQuery } from '../../../../../features/hiringstages/offerApiSlice';
import CandDetails from './offerdetails/CandDetails';
import InterviewScore from '../interview/interviewprocess/tabs/InterviewScore';
import CandResume from './offerdetails/tabs/CandResume';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function OfferDetails({ toggleOffer }) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const candId = useSelector(selectCandidateInterview);

  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useCandOfferDetailsQuery(candId, { refetchOnMountOrArgChange: true });

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };

  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
    showNotificationMsg();
  };

  const formik = useFormik({});

  const gotoOfferList = (value, candid) => {
    toggleOffer(value);
  };

  return (
    <>
      <div className="flex justify-end mb-4">
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            onClick={() => gotoOfferList('offerlist')}
            className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
          >
            <ArrowLeftIcon
              className="-ml-1 mr-2 h-5 w-5 text"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
      </div>

      <div className="flex flex-row mt-0  gap-4">
        <CandDetails canddetails={canddetails} />

        <div className="basis-5/6">
          <div className="flex flex-row ">
            <div className="w-full ">
              <div className="bg-white shadow-lg rounded-lg">
                <div className=" border border-gray-100 ">
                  {/* <h1 className=" flex font-semibold px-0 text-xl mt-3 text-gray-700 border-b border-gray-200 pb-4 mx-5">
                  Screening Score
                </h1> */}

                  <Tab.Group as="div" className="mt-4">
                    <Tab.List className="flex bg-white px-1 py-0 mx-5 my-0">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-8 py-2 font-bold text-md rounded-t-lg mr-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 mx-6'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Interview Scores
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black px-8 py-2 font-bold text-md rounded-t-lg mx-6'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 mx-6'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Negotation
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black  px-8 py-2 font-bold text-md rounded-t-lg ml-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 mx-5'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Offer Letter
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black  px-8 py-2 font-bold text-md rounded-t-lg ml-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 mx-5'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Documents
                          </a>
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'bg-gray-200 text-black  px-8 py-2 font-bold text-md rounded-t-lg ml-3'
                              : // 'border-indigo-500 bg-gray-100 text-indigo-600 px-1 py-1'
                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 mx-5'
                            // 'whitespace-nowrap   py-0  px-0'
                          )
                        }
                      >
                        <span>
                          <a
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? ' text-black p-2 rounded-md text-sm '
                                  : 'text-skillcapstext p-2 rounded-md text-sm '
                              )
                            }
                          >
                            Resume
                          </a>
                        </span>
                      </Tab>
                    </Tab.List>
                    <Tab.Panels as={Fragment}>
                      <Tab.Panel className="space-y-16 py-3 bg-gray-200 mx-6 mb-6 rounded-r-lg">
                        {/* <InterviewScores /> */}
                        <InterviewScore />
                      </Tab.Panel>
                      <Tab.Panel className="space-y-4 py-0 mx-6 mb-6 rounded-r-lg">
                        <Negotation canddetails={canddetails} />
                      </Tab.Panel>
                      <Tab.Panel className="space-y-16 py-0 mx-6 mb-6 rounded-r-lg">
                        <OfferLetter canddetails={canddetails} />
                      </Tab.Panel>
                      <Tab.Panel className="space-y-16 py-0 mx-6 mb-6 rounded-r-lg">
                        <Documents />
                      </Tab.Panel>
                      <Tab.Panel className="space-y-16 py-0 mx-6 mb-6 rounded-r-lg">
                        <CandResume />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        isOpen={openDrawer}
        title={'Add Department'}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <h1>Hello</h1>
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        {/*  <AddEmployment
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        />
        <AddEducation
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        /> */}
        {/* <AddProject
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        /> */}
      </ModalData>
    </>
  );
}

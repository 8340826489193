import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'sonner';
import Notification from '../../components/common/Notification';
// import Drawer from '../../../components/common/Drawer';

import { usePrivStatusMutation } from '../../features/privileges/userSettingsApiSlice';

const AddMyTaskUserPriv = ({ data, isEdit, onClose }) => {
  const [privStatus] = usePrivStatusMutation();
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const updateStatus = async (approveStatus) => {
    try {
      const statusData = {
        approval_status: approveStatus,
        comments: message,
        user_id: data.user_id,
      };

      const accountData = await privStatus(statusData);
      if (approveStatus === 1) {
        toast.success('Manpower Approved');
      } else if (approveStatus === 2) {
        toast.info('Manpower Queried');
      } else if (approveStatus === 3) {
        toast.error('Manpower Rejected');
      }
      setMessage('');
      onClose();
    } catch (error) {}
  };

  return (
    <>
      {/* <Notification isShow={showNotification} /> */}

      <div className="flex flex-col justify-between">
        <div className="space-y-1 border-b border-gray-300 pb-3 pt-0">
          <h1 className="text-xl text-violet-700 font-semibold capitalize">
            {data.first_name} {data.last_name}
          </h1>
          <h3 className="text-md text-black font-semibold capitalize">
            {data.designation_name}
          </h3>
          <p className="text-sm text-gray-500">{data.branch_name}</p>
        </div>

        <div className="space-y-1 border-b border-gray-300 pb-3 pt-3">
          <div>
            <h1 className="text-1xl text-violet-700 font-semibold capitalize">
              My task
            </h1>
            {data.workspace_privilege.map((priv) => (
              <div className="flex justify-between pt-3">
                <div className="flex items-center">
                  <h4 className="text-sm font-semibold">{priv.title}</h4>
                </div>
                <div>
                  {priv.status == 1 ? (
                    <button
                      type="button"
                      className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-1 px-10 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                    >
                      {' '}
                      Enabled
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="flex w-full justify-center rounded-3xl border border-transparent bg-gray-400 py-1 px-10 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                    >
                      {' '}
                      Disabled
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {isEdit ? (
          <div className="space-y-1 pt-5">
            <textarea
              rows={4}
              name="notes"
              id="notes"
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              // defaultValue={''}
              placeholder="Query"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <div className="flex flex-shrink-0 justify-between py-4">
              <button
                type="button"
                className=" rounded-lg  bg-queryBlue text-white py-1 px-6 mr-3 text-sm font-medium  shadow-sm focus:outline-none "
                onClick={() => updateStatus(2)}
              >
                Query
              </button>
              <button
                type="button"
                className=" rounded-lg border border-red-600 bg-red-600 py-1 px-6 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
                onClick={() => updateStatus(3)}
              >
                Reject
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-lg border border-transparent bg-approve py-1 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
                onClick={() => updateStatus(1)}
              >
                Approve
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <div>Hello</div> */}
    </>
  );
};

export default AddMyTaskUserPriv;

import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

function InterviewScores() {
  const people = [
    {
      name: 'Comments',
      title: 'Strong Hire',
      percentage: '70%',
    },
    // More people...
  ];
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {/* <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 w-[20px] pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Factors
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L1-Screening
                      <p className=" text-sm font-semibold text-gray-900">
                        Telephonic
                      </p>
                      <p className="text-blue-500">Johnsmith</p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L2-Screening
                      <p className=" text-sm font-semibold text-gray-900">
                        Social Media
                      </p>
                      <p className="text-blue-500">Steve</p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L1-Interview
                      <p className=" text-sm font-semibold text-gray-900">
                        Telephonic
                      </p>
                      <p className="text-blue-500">Steve</p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L2-Interview
                      <p className=" text-sm font-semibold text-gray-900">
                        Face to Face
                      </p>
                      <p className="text-blue-500">Jacob</p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L3-Interview
                      <p className=" text-sm font-semibold text-gray-900">
                        Face to Face
                      </p>
                      <p className="text-blue-500">Steve</p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  w-[30px] text-center text-md font-semibold text-gray-900"
                    >
                      L4-Interview
                      <p className=" text-sm font-semibold text-gray-900">
                        Technical
                      </p>
                      <p className="text-blue-500">Steve</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 w-[30px] text-sm text-gray-500">
                      
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-violet-600 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          Strong Hire
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-violet-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          Hire
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-red-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          Need 2nd Openion
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-violet-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          Hire
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-violet-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          Hire
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex bg-red-500 justify-center py-2  text-white rounded-lg  text-sm font-medium  shadow-sm focus:outline-none "
                        >
                          <ArrowLeftIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          No Hire
                        </Link>
                        <Link
                          type="button"
                          to={'/app/settings'}
                          className="flex mt-2  text-blue-500 underline justify-center  rounded-3xl  text-sm font-medium  shadow-smfocus:outline-none "
                        >
                          Comments
                        </Link>
                      </td>
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <a
            href="#"
            className="text-indigo-600 hover:text-indigo-900"
          >
            Edit
            <span className="sr-only">
              , {person.name}
            </span>
          </a>
        </td> 
                    </tr>
                  ))}
                </tbody>
              </table>

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="border-y border-gray-300">
                  <h1 className="  font-semibold px-0 text-xl mt-3 text-gray-700 border-t border-gray-200 pb-4">
                    Basic Skill
                  </h1>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Speak Grammar
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      75%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      90%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      65%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      95%
                    </th>
                  </tr>
                </thead>
              </table>

              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 w-40 pl-4 pr-3 text-left text-lg font-bold text-gray-900 sm:pl-0"
                    >
                      Mandatory Skill
                    </th>
                  </tr>
                </thead>
              </table>

              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 w-40 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Project Management
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-center   text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-center   text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right  text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>{' '}
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-right   text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>{' '}
                    <th
                      scope="col"
                      className="py-3.5  pl-4 pr-3 text-right   text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>{' '}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center  text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      85%
                    </th>
                  </tr>
                </thead>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterviewScores;

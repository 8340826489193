import React, { useEffect, useState } from 'react';
import { XCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useSkillsQuery } from '../../../../../../features/skillnew/skillnewApiSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AddKeySkill } from '../modal/AddKeySkill';
import ModalData from '../../../../../../components/common/ModalData';
import useCandidate from '../../../../../../hooks/useCandidate';
import { useUpdateCandidateMutation } from '../../../../../../features/hiringstages/sourcingApiSlice';
import Drawer from '../../../../../../components/common/Drawer';
import AddSkillnew from '../../../../../../features/skillnew/AddSkillnew';
import Notification from '../../../../../../components/common/Notification';
import Modal from '../../../../../../components/common/Modal';

const CandKeyskills = ({ gotoAddSourcing, showNotificationMsg }) => {
  const [idToDelete, setIdToDelete] = useState(null);

  const candiid = useSelector((state) => state.candidatedetails?.id) || [];
  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);
  console.log(canddetails)
  
  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();

  const [keyskills, setKeyskills] = useState();
  const [filterKS, setFilterKS] = useState([]);

  useEffect(() => {
    const keySkillArray =
      canddetails?.data.key_skills !== null ? canddetails?.data.key_skills : [];
    setKeyskills(keySkillArray);
  }, [canddetails]);
  console.log(keyskills)

  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);

  const {
    data: skillslist,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    return () => {
      setOpenModalData([]);
    };
  }, []);

  const getSkillName = (skillid) => {
    const skillName = skillslist?.data.find((element) => element.id == skillid);
    return skillName.name;
  };

  const filterSkill = (skill) => {
    setKeyskills(keyskills.filter((item) => item != skill));
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
    showNotificationMsg();
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const updateKeyskills = async () => {
    // e.preventDefault(e);
    let values = {
      profile_id: candiid,
      key_skills: keyskills,
    };
    try {
      const updateCandidateData = await updateCandidate(values).unwrap();
      showNotificationMsg();
      closeModalWindow();
    } catch (error) { }
  };

  const openModalWindow = (id) => {
    setIdToDelete(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    const fitleredKeyskills = keyskills.filter(
      (skills) => skills != idToDelete
    );

    let values = {
      profile_id: candiid,
      key_skills: fitleredKeyskills,
    };
    try {
      const updateCandidateData = await updateCandidate(values).unwrap();
      showNotificationMsg();
      closeModalWindow();
    } catch (error) { }
  };

  if (isSuccess) {
    return (
      <>
        <div className="flex justify-between px-6 pt-2 items-center">
          <h1 className="text-2xl font-bold">General Skill List</h1>
          <div className="flex">
            <Link
              type="button"
              to={''}
              className="flex mr-2 justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              onClick={() => openDrawerWindow()}
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Add
            </Link>
            <Link
              type="button"
              to={''}
              className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              onClick={() =>
                openModalDataWindow({
                  mode: 'Add',
                  title: 'Add Key Skill',
                })
              }
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Add General Skill
            </Link>
          </div>
        </div>

        <div className="border-t border-gray-200 px-10 py-4">
          {keyskills?.length != 0 ? (
            <div className="border-b border-gray-200 pb-4">
              {keyskills?.map((skill) => (
                <span
                  key={skill}
                  className="inline-flex mr-4 items-left rounded-2xl bg-blue-100 px-5 py-2 text-md font-medium text-black"
                >
                  {getSkillName(skill)}
                  <XCircleIcon
                    className="h-6 w-6 text-gray-900 ml-3 cursor-pointer"
                    aria-hidden="true"
                    // onClick={() => deleteSkill()}
                    onClick={() => openModalWindow(skill)}
                  />
                </span>
              ))}
            </div>
          ) : (
            'No Skills, Add Skill'
          )}
          {/* <div className="flex justify-end">
            <div className="mr-3">
              <button
                type="button"
                onClick={() => gotoAddSourcing('cancelcandidate')}
                className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4  ml-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={(e) => updateKeyskills(e)}
                className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 mt-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
              >
                Save
              </button>
            </div>
          </div> */}
        </div>

        <Notification
          isShow={showNotification}
          onClose={() => closeNotificationMsg()}
        />
        <Drawer
          isOpen={openDrawer}
          title={isEdit ? 'Edit Skill' : 'Add Skill'}
          // title={isEdit ? '' : 'Add Skill'}
          onClose={() => closeDrawerWindow()}
          onSubmit={() => onSubmit()}
        >
          <AddSkillnew
            data={currentValue}
            isEdit={isEdit}
            onClose={() => closeDrawerWindow()}
          />
        </Drawer>

        <ModalData
          isModalDataOpen={openModalData}
          onModalDataClose={closeModalDataWindow}
        >
          <AddKeySkill
            onModalDataClose={() => closeModalDataWindow()}
            onModalDataSubmit={() => onModalDataSubmit()}
            setShowNotification={() => showNotificationMsg()}
            data={openModalDataValues}
          />
        </ModalData>

        <Modal
          isModalOpen={openModal}
          onClose={() => closeModalWindow()}
          onSubmit={() => onModalSubmit()}
        ></Modal>
      </>
    );
  }
};

export default CandKeyskills;

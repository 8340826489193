import { useSkillcapturebyidQuery } from '../features/hiringstages/skillcaptureApiSlice';
const useGetSkillcapturebyid = (id) => {
  const {
    data: skillcapturebyid,
    error: errSkillcapturebyid,
    isLoading: loadingSkillcapturebyid,
    isFetching: fetchingSkillcapturebyid,
    isSuccess: successSkillcapturebyid,
  } = useSkillcapturebyidQuery(id, { refetchOnMountOrArgChange: true });
  return {
    skillcapturebyid,
    loadingSkillcapturebyid,
    successSkillcapturebyid,
  };
};

export default useGetSkillcapturebyid;

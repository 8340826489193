import { createSlice } from '@reduxjs/toolkit';

const candidateDetailsSlice = createSlice({
  name: 'candidateDetails',
  initialState: null,
  reducers: {
    setCandDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCandDetails } = candidateDetailsSlice.actions;

export default candidateDetailsSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useGetSkills from '../../../../../../../hooks/useGetSkills';
// import useCandidate from '../../../../../../../hooks/useCandidate';

import { useSelector } from 'react-redux';
import { useGetInterviewCandDetailsQuery } from '../../../../../../../features/hiringstages/interviewApiSlice';
import useGetSkillcapturebyid from '../../../../../../../hooks/useSkillcapturebyid';

export const InterviewerScoreDetails = ({
    onModalDataClose,
    onModalDataSubmit,
    setShowNotification,
    data,
    updateSkillsArray,
}) => {


    const candiid = useSelector((state) => state.candidateinterview) || [];
    const {
        data: canddetails,
        isLoading: canddetailsLoading,
        isSuccess: canddetailsSuccess,
    } = useGetInterviewCandDetailsQuery(candiid, {
        refetchOnMountOrArgChange: true,
    });
    console.log(canddetails)
    const interviewData = canddetails?.data?.interview;
    const techSkills = canddetails?.data?.technical_skills;

    // console.log('canddetails ', canddetails?.data.interview);

    const filteredInterviewData = interviewData.filter(
        (interview) => interview.interview_stage?.id === data.stageId
    );

    // Log the filtered data to check the result
    // console.log('Filtered Interview Data:', filteredInterviewData);

    let reversedInterviewData = filteredInterviewData?.slice().reverse();
    console.log('reversedInterviewData ', reversedInterviewData);

    const submitform = (e) => {
        e.preventDefault();
        // console.log('e --> ', e.target);
        // onModalDataClose();
    };

    const getRecomendCode = (code) => {
        console.log(code);
        if (code === 1) {
            return (
                // <span className="bg-purple-700 px-6 py-2 text-white">Strong Hire</span>
                <div className="flex justify-start">
                    <div className="block rounded-md bg-purple-700 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                        Strong Hire
                    </div>
                </div>
            );
        } else if (code === 2) {
            return (
                <div className="flex justify-start">
                    <div className="block rounded-md bg-purple-700 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                        Hire
                    </div>
                </div>
            );
            //  <span className="bg-purple-700 px-6 py-2 text-white">Hire</span>
        } else if (code === 3) {
            return (
                <div className="flex justify-start">
                    <div className="block rounded-md bg-red-400 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                        Need 2nd Openion
                    </div>
                </div>
                // <span className="bg-red-400 px-3 py-2 text-white">
                //   Need 2nd Openion
                // </span>
            );
        } else if (code === 4) {
            return (
                <div className="flex justify-start">
                    <div className="block rounded-md bg-red-400 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
                        No Hire
                    </div>
                </div>
            );

            // <span className="bg-red-600 px-3 py-2 text-white">No Hire</span>
        } else {
            return '-';
        }
    };


    return (
        <>
            {/* onSubmit={(e) => submitform(e)} */}
            <form className="flex flex-col" onSubmit={submitform}>
                <div>
                    {(() => {
                        // Step 1: Aggregate technical skills from all interviews
                        const aggregatedSkills = [];
                        const interviewers = [];
                        reversedInterviewData.forEach((interview) => {
                            if (interview.technical_skills) {
                                // console.log(interview.technical_skills)
                                const skillObject = {};  // Object to hold the skills for this interviewer                                
                                try {
                                    const technicalSkills = interview.technical_skills;
                                    technicalSkills.forEach((tech) => {
                                        // Initialize category if not already present
                                        if (!skillObject[tech.name]) {
                                            skillObject[tech.name] = {};
                                        }
                                        // Initialize variables to calculate average
                                        let totalRating = 0;

                                        tech.skills.forEach((skill) => {
                                            let skillCount = tech.skills.length;
                                            // Calculate total rating

                                            totalRating += skill.rating;
                                            // Calculate average rating
                                            let averageRating = skillCount > 0 ? totalRating / skillCount : 0;
                                            // Assign the maximum rating for the skill category under the interviewer
                                            if (!skillObject[tech.name][interview.interviewer_name] ||
                                                skillObject[tech.name][interview.interviewer_name] < skill.rating) {
                                                skillObject[tech.name][interview.interviewer_name] = averageRating;
                                            }
                                        });
                                    });
                                } catch (e) {
                                    console.error("Error parsing technical skills data for interview:", interview, e);
                                }
                                // console.log(skillObject);
                                // Add this interviewer's skills to the aggregated array
                                // Combine skillObject with interview_recommendation in a single object
                                const aggregatedEntry = {
                                    skills: skillObject,
                                    interview_recommendation: interview.interview_recommendation
                                };
                                aggregatedSkills.push(aggregatedEntry);

                            }
                            // Collect the interviewer names
                            if (!interviewers.includes(interview.interviewer_name)) {
                                interviewers.push(interview.interviewer_name);
                                // console.log(interviewers)
                            }
                            // //collect the interviewr recommentation
                            // if(!interviewers.includes(interview.interview_recommendation)) {
                            //     interviewers.push(interview.interviewer_recommendation);
                            //     console.log(interviewers);
                            // }

                        });
                        console.log(aggregatedSkills);
                        console.log(interviewers);

                        // Step 2: Render the table with aggregated data
                        return (
                            aggregatedSkills.length > 0 && interviewers.length > 0 ? (
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="px-3 py-4 text-lg text-gray-800 font-semibold capitalize">Skills</th>
                                            {interviewers.map((interviewer, index) => (
                                                <th key={index} className="px-3 py-4 text-green-700 text-sm">{interviewer}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(aggregatedSkills[0].skills).map((skillCategory, skillIndex) => (
                                            <tr key={skillIndex}>
                                                {/* Display skill category name */}
                                                <td className="whitespace-nowrap px-3 py-4 text-gray-500 text-sm">{skillCategory}</td>

                                                {/* Loop through interviewers to display their respective ratings */}
                                                {interviewers.map((interviewer, index) => (
                                                    <td key={index} className="whitespace-nowrap px-3 py-4 text-gray-500 text-sm">
                                                        {/* Check and display the rating for the interviewer */}
                                                        {aggregatedSkills.find((skills) => skills.skills[skillCategory]?.[interviewer])?.skills[skillCategory][interviewer] ?? '-'}
                                                    </td>
                                                ))}
                                            </tr>

                                        ))}
                                        {/* Add a row for interview recommendation */}
                                        <tr>
                                            <td className="whitespace-nowrap px-3 py-4 text-gray-500 text-sm">Recommendation</td>
                                            {interviewers.map((interviewer, index) => {
                                                // Get the corresponding interview_recommendation using the index
                                                const recommendation = aggregatedSkills[index]?.interview_recommendation ?? '-';

                                                return (
                                                    <td key={index} className="whitespace-nowrap px-3 py-4 text-gray-500 text-sm">
                                                        {getRecomendCode(recommendation)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <p>No skills available</p>
                            )
                        );
                    })()}
                </div>
                <div className="flex justify-end">
                    <div className="mr-3">
                        <button
                            type="button"
                            onClick={() => onModalDataClose()}
                            className="rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>

        </>
    );
};

import React from 'react';
import { useBranchQuery } from '../../features/branch/branchApiSlice';

const GetLocationName = ({ branchId }) => {
  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
      // ? `${filterdata[0]?.name} - ${filterdata[0]?.location.name}`
      // : '-';
    }
  };
  return <>{getLocationName(branchId)}</>;
};

export default GetLocationName;

import React, { useState, useEffect } from 'react';
// import {useSelector} from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useJobDescCountryChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';
// import {ApexOptions} from 'apexcharts';

const JobDescriptionPieChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setselectedLocationId] = useState(0); //commented by lakshmi
  //Define options for countries, branches
  // const countryOptions = ['India','USA','UK'];
  // const branchOptions = ['Chennai','Bangalore','Pune'];
  //   const countryOptions = chartData?.map((obj) => obj.name);
  //   const branchOptions = ['Chennai', 'Bangalore', 'Pune'];

  // const pieChartData = [
  //     {name:'India', value : 150},
  //     {name:'USA', value:120},
  //     {name:'UK',value:100},
  //     {name:'Germany',value: 110},
  //     {name:'Russia',value:50},
  // ];

  const [
    jobDescriptionChart,
    { data: jobDescription, isLoading: jobDescriptionLoading },
  ] = useJobDescCountryChartMutation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId]);

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setselectedLocationId(id);
  };

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
    };
    const dataObj = cleanObjectData(data);
    jobDescriptionChart(dataObj);
  };

  const chartData = jobDescription?.data;

  const chartName = 'Job Description';

  const options = {
    labels: chartData ? chartData.map((item) => item.name) : [],
    colors: ['#FA2C50', '#FFC111', '#56BA96', '#B7117F', '#7396F2'],
    chart: {
      fontFamily: 'Poppins', // Set font family for all text elements
      foreColor: '#000', // Set color for all text elements to white
      fontSize: '8px',
      fontWeight: 500,
      height: 'auto',
    },
    // title: {
    //     text: 'Manpower',
    //     style: {
    //         color: '#FFFFFF',
    //         fontSize: '20px',
    //         fontFamily: 'Poppins'
    //     }
    // },
    legend: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      position: 'bottom',
      horizontalAlign: 'right',
      offsetX: -20,
    },
    dataLabels: {
      enabled: true, //Enable data labels
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]; //Display value
      },
      style: {
        fontSize: '15px',
        fontFamily: 'Inter var',
        colors: ['#fff'], //color of the data labels
        fontWeight:''
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };
  const series = chartData ? chartData.map((item) => parseInt(item.value)) : [];
  return (
    <div>
      <div className="flex justify-between p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          {/* <select
            className=" bg-[#39216B] text-[13px] text-white "
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions?.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select> */}

          {/* Dropdown for branch */}
          {/*commented by lakshmi*/}
          {/* <select
            className="ml-1  text-[13px] bg-[#39216B] text-white "
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select> */}
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            isDaysFilter={true}
            showCountry={true}
            showDepartment={true}
            showLocation={true}
          />
        </div>
      </div>
      <ReactApexChart
        type="pie"
        series={series}
        options={options}
        width={570}
        height={425}
      />
    </div>
  );
};

export default JobDescriptionPieChart;

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PlusIcon } from '@heroicons/react/24/outline';

const DateRangePicker = ({ data, addDateTimeArray, singleDate }) => {
  const [dateFields, setDateFields] = useState([
    {
      id: 1,
      interview_time_start: null,
      interview_time_end: null,
      removable: false,
    },
  ]);

  useEffect(() => {
    if (data?.length > 0) {
      setDateFields(
        data.map((item) => ({
          id: item.id,
          interview_time_start: new Date(item.interview_time_start),
          interview_time_end: item.interview_time_end ? new Date(item.interview_time_end) : null,
          removable: item.removable,
        }))
      );
    }
  }, [data]);

  const handleAddField = (e) => {
    e.preventDefault();
    const newDateFields = [
      ...dateFields,
      {
        id: dateFields.length + 1,
        interview_time_start: null,
        interview_time_end: null,
        removable: true,
      },
    ];
    setDateFields(newDateFields);
  };

  const handleRemoveField = (id) => {
    const newDateFields = dateFields.filter((field) => field.id !== id);
    setDateFields(newDateFields);
    addDateTimeArray(newDateFields);  // Update parent component state
  };

  const handleFromDateChange = (date, id) => {
    const newDateFields = dateFields.map((field) =>
      field.id === id ? { ...field, interview_time_start: date, interview_time_end: null } : field
    );
    setDateFields(newDateFields);
    addDateTimeArray(newDateFields);  // Update parent component state
  };

  const handleToDateChange = (date, id) => {
    const newDateFields = dateFields.map((field) =>
      field.id === id ? { ...field, interview_time_end: date } : field
    );
    setDateFields(newDateFields);
    addDateTimeArray(newDateFields);  // Update parent component state
  };

  return (
    <div className="mt-3">
      <div className="flex justify-start items-center mb-2">
        <label
          htmlFor="timeslot"
          className="block text-sm font-medium text-gray-900 mr-10"
        >
          Time Slot
        </label>
        <button
          onClick={handleAddField}
          className="inline-flex rounded-3xl border border-transparent mr-2 bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Time
        </button>
      </div>

      {dateFields.map((field) => (
        <div key={field.id} className="flex items-center">
          <DatePicker
            selected={field.interview_time_start}
            onChange={(date) => handleFromDateChange(date, field.id)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="From Date and Time"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md py-2 px-3 mr-2 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            minDate={new Date()}  // Restrict selection to today and future dates
          />
          <span> - </span>
          <DatePicker
            selected={field.interview_time_end}
            onChange={(date) => handleToDateChange(date, field.id)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="To Date and Time"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            // minDate={field.interview_time_start}  // Prevents selecting a date before 'From Date'
          />
          {field.removable && (
            <button
              className="ml-2"
              onClick={() => handleRemoveField(field.id)}
            >
              Remove
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default DateRangePicker;

import { Fragment, useState, useEffect } from 'react';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import logoDashIcon from '../assets/images/logodashicon.png';
// import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import AddMyTaskUserPriv from '../features/mytaskuserpriv/AddMyTaskUserPriv';

import {
  useAdminMyTaskUserPrevillageQuery,
  useDeleteAdminMyTaskUserPrevillageMutation,
} from '../features/myadminuserprivillage/AdminMyTaskUserPrevillageApiSlice';
import Drawer from '../components/common/Drawer';
import { Link, useParams, useNavigate } from 'react-router-dom';
import MytaskUserPriv from './workflow/MytaskUserPriv';

import {
  ArchiveBoxIcon,
  Bars3Icon,
  BellIcon,
  FlagIcon,
  InboxIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Notification from '../components/common/Notification';

import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import Modal from '../components/common/Modal';
import AddAdminMyTaskUserPrevillage from '../features/myadminuserprivillage/AddAdminMyTaskUserPrevillage';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUserID } from '../features/auth/authSlice';

import { useAdminPrivSetQuery } from '../features/privileges/adminSettingsApiSlice';
import { useUserPrivSetQuery } from '../features/privileges/userSettingsApiSlice';

import { useTeamsQuery } from '../features/teams/teamsApiSlice';

import MytaskWorkflow from './workflow/MytaskWorkflow';
import MytaskWorkflowSignoffs from './workflow/MytaskWorkflowSignoffs';

import WorkflowAssign from './workflow/WorkflowAssign';
import WorkflowSignoffs from './workflow/WorkflowSignoffs';
import {
  updateLevelOne,
  updateLevelTwo,
  levelOne,
  levelTwo,
} from '../features/privileges/adminWorkspaceSlice';

// import MytaskUserPriv from './workflow/MytaskUserPriv';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminMytaskUserprivillage() {
  const levelone = useSelector(levelOne);
  const leveltwo = useSelector(levelTwo);
  const dispatch = useDispatch();

  const [levelOneState, setLevelOneState] = useState(0);
  const [levelTwoState, setLevelTwoState] = useState(0);

  useEffect(() => {
    setLevelOneState(levelone);
  }, [levelone]);

  useEffect(() => {
    setLevelTwoState(leveltwo);
  }, [leveltwo]);

  const currentUserId = useSelector(selectCurrentUserID);
  const {
    data: adminSettings,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useAdminPrivSetQuery(currentUserId, { refetchOnMountOrArgChange: true });
  const {
    data: userSettings,
    error: userErr,
    isLoading: userloading,
  } = useUserPrivSetQuery({ refetchOnMountOrArgChange: true });

  const { data: users, isLoading: userLoading } = useTeamsQuery({
    refetchOnMountOrArgChange: true,
  });

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    }
  };

  const displayUsername = (id) => {
    if (!userLoading) {
      let findUser = users?.data.filter((el) => el.id == id);
      if (findUser[0]) {
        return findUser[0]?.first_name + ' ' + findUser[0]?.last_name;
      }
    }
  };

  let adminpriv;
  let ifMyTask;
  let ifSignoff;
  let ifMYuserpriv;
  let ifMYworkflow;
  let ifMYworkflowsf;
  let ifSFuserpriv;
  let ifSFworkflow;
  let ifSFworkflowsf;

  if (!isLoading) {
    adminpriv = adminSettings?.data?.adminPrivileges;

    ifMyTask = adminpriv['My Task'];
    ifSignoff = adminpriv['Signoff'];

    if (ifMyTask) {
      ifMYuserpriv = ifMyTask.some((el) => el.title == 'User privileges');
      ifMYworkflow = ifMyTask.some((data) => data.title == 'Workflow');
      ifMYworkflowsf = ifMyTask.some(
        (data) => data.title == 'Workflow signoff'
      );
    }

    if (ifSignoff) {
      ifSFuserpriv = ifSignoff.some((data) => data.title === 'User privileges');
      ifSFworkflow = ifSignoff.some((data) => data.title === 'Workflow');
      ifSFworkflowsf = ifSignoff.some(
        (data) => data.title === 'Workflow signoff'
      );
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);

  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);
  const [deleteAdminMyTaskUserPrevillage] =
    useDeleteAdminMyTaskUserPrevillageMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(obj);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async (deptId) => {
    try {
      const deleteAdminMytask =
        await deleteAdminMyTaskUserPrevillage(deptId).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const noList = () => (
    <>
      <h1 className="font-extrabold text-red-600 p-4 ">NO DATA</h1>
    </>
  );

  const handleLevelOne = (index) => {
    dispatch(updateLevelOne(index));
  };
  const handleLevelTwo = (index) => {
    dispatch(updateLevelTwo(index));
  };
  return (
    <>
      {!isLoading ? (
        <div className="">
          <section aria-labelledby="features-heading" className="">
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
              <Tab.Group
                as="div"
                className="mt-4"
                selectedIndex={levelOneState}
                onChange={handleLevelOne}
              >
                <div className="-mx-4 flex overflow-x-auto sm:mx-0 bg-white rounded-t-lg">
                  <div className="flex-auto border-b border-gray-200 px-4 sm:px-0">
                    <Tab.List className="-mb-px flex">
                      {/* {tabs.map((tab) => ( */}
                      {ifMyTask ? (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                            )
                          }
                        >
                          {/* {tab.name} */}
                          MYTASK
                        </Tab>
                      ) : (
                        ''
                      )}
                      {ifSignoff ? (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                            )
                          }
                        >
                          {/* {tab.name} */}
                          SIGNOFF
                        </Tab>
                      ) : (
                        ''
                      )}
                      {/* ))} */}
                    </Tab.List>
                  </div>
                </div>

                <Tab.Panels as={Fragment}>
                  {/* {tabs.map((tab) => ( */}
                  {ifMyTask ? (
                    <Tab.Panel className="space-y-16 ">
                      <Tab.Group
                        as="div"
                        className=""
                        selectedIndex={levelTwoState}
                        onChange={handleLevelTwo}
                      >
                        <Tab.List className="-mb-px flex bg-white rounded-b-lg">
                          {/* {tabs.map((tab) => ( */}
                          {ifMYuserpriv ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              User Privilege
                            </Tab>
                          ) : (
                            ''
                          )}
                          {ifMYworkflow ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              Workflow
                            </Tab>
                          ) : (
                            ''
                          )}
                          {ifMYworkflowsf ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              Workflow Signoff
                            </Tab>
                          ) : (
                            ''
                          )}
                        </Tab.List>
                        <Tab.Panels as={Fragment}>
                          {ifMYuserpriv ? (
                            <Tab.Panel className="space-y-16 pb-6">
                              <MytaskUserPriv isEditnew={false} />
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                          {ifMYworkflow ? (
                            <Tab.Panel className="space-y-16 py-6">
                              {/* My Task - content - Workflow */}
                              <MytaskWorkflow />
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                          {ifMYworkflowsf ? (
                            <Tab.Panel className="space-y-16 py-6">
                              {/* My Task - content - Workflow Signoff */}
                              <MytaskWorkflowSignoffs />
                              {/* <WorkflowSignoffs /> */}
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                        </Tab.Panels>
                      </Tab.Group>
                    </Tab.Panel>
                  ) : (
                    ''
                  )}
                  {ifSignoff ? (
                    <Tab.Panel className="space-y-16">
                      <Tab.Group
                        as="div"
                        className=""
                        selectedIndex={levelTwoState}
                        onChange={handleLevelTwo}
                      >
                        <Tab.List className="-mb-px flex bg-white">
                          {/* {tabs.map((tab) => ( */}
                          {ifSFuserpriv ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              User Privilege
                            </Tab>
                          ) : (
                            ''
                          )}
                          {ifSFworkflow ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              Workflow
                            </Tab>
                          ) : (
                            ''
                          )}
                          {ifSFworkflowsf ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                )
                              }
                            >
                              {/* {tab.name} */}
                              Workflow Signoff
                            </Tab>
                          ) : (
                            ''
                          )}
                        </Tab.List>
                        <Tab.Panels as={Fragment}>
                          {ifSFuserpriv ? (
                            <Tab.Panel className="space-y-16">
                              {userloading ? (
                                'Loading ...'
                              ) : (
                                <MytaskUserPriv isEditnew={true} />
                              )}

                              {/* Sign Off - content - User Privilege */}
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                          {ifSFworkflow ? (
                            <Tab.Panel className="space-y-16 py-6">
                              {/* Sign Off - content - Workflow */}
                              <WorkflowAssign />
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                          {ifSFworkflowsf ? (
                            <Tab.Panel className="space-y-16 py-6">
                              {/* Sign Off - content - Workflow Signoff */}
                              <WorkflowSignoffs />
                            </Tab.Panel>
                          ) : (
                            ''
                          )}
                        </Tab.Panels>
                      </Tab.Group>
                    </Tab.Panel>
                  ) : (
                    ''
                  )}
                  {/* ))} */}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </section>
        </div>
      ) : (
        'Loading...'
      )}

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />

      <Drawer
        isOpen={openDrawer}
        title={isEdit ? 'Edit Interview' : 'Add Interview'}
        onClose={() => closeDrawerWindow()}
        // onSubmit={() => onSubmit()}
      >
        <AddMyTaskUserPriv
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
    </>
  );
}

import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';

import { PencilSquareIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';

// import Designation from "./Designation";

import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';

import { useSkillcaptureQuery } from '../../../../../features/hiringstages/skillcaptureApiSlice';
import { useSkillsQuery } from '../../../../../features/skillnew/skillnewApiSlice';
import { useSkillQuery } from '../../../../../features/skill/skillApiSlice';

import { useBranchQuery } from '../../../../../features/branch/branchApiSlice';
import { useCountryQuery } from '../../../../../features/country/countryApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';
import { useJobdescQuery } from '../../../../../features/hiringstages/jobdescApiSlice';

import AddSkillCapture from './AddSkillCapture';
import ModalData from '../../../../../components/common/ModalData';
import { getStatusCode } from '../../../UserWSUtils';

// import { useGetCountryName } from '../../../../../features/utils/Utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SkillCapture = () => {
  const {
    data: scs,
    error: scsError,
    isLoading: scsLoading,
    isFetching: scsFetching,
    isSuccess: scsSuccess,
  } = useSkillcaptureQuery({ refetchOnMountOrArgChange: true });
  console.log(' sklTypes -- ', scs);
  const {
    data: sklTypes,
    error: sklTypesError,
    isLoading: sklTypesLoading,
    isFetching: sklTypesFetching,
    isSuccess: sklTypesSuccess,
  } = useSkillQuery({ refetchOnMountOrArgChange: true });

  const {
    data: skls,
    error: sklsError,
    isLoading: sklsLoading,
    isFetching: sklsFetching,
    isSuccess: sklsSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: jds,
    error: jdsError,
    isLoading: jdsLoading,
    isFetching: jdsFetching,
    isSuccess: jdsSuccess,
  } = useJobdescQuery({ refetchOnMountOrArgChange: true });

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);

  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentModalValue, setCurrentModalValue] = useState(null);

  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();

  const people = [
    {
      city: 'Bangalore',
      area: 'Koramangala',
      publish: 'Published',
      p_date: '05/09/2020',
      deadline: '31/03/2022',
      d_date: '15/09/2020',
      jobassigned: 'Job Assigned',
      number: '20',
      id: 'MPR ID 02345',
      role: 'Senior Manager',
      department: 'Sales & Marketing',
      designation: 'Fullstack Developer',
      branch: 'Chennai, India',
      signoff_name: 'Robin Williams',
      timeline_status: 'On Track',
      status: 'Completed',

      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
  ];

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };
  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const openModalDataWindow = (mode, obj) => {
    setCurrentModalValue(obj);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getSkillTypeName = (skillTypeId) => {
    if (sklTypesSuccess) {
      const skillTypeName = sklTypes?.data.filter((sklType) => {
        return sklType.id == skillTypeId;
      });
      return skillTypeName[0]?.name;
    }
  };

  const getJDRequirement = (jrID) => {
    if (jdsSuccess) {
      const jdrequirment = jds?.data.filter((jd) => {
        return jd.job_request_id == jrID;
      });
      return jdrequirment[0].job_requirement;
    }
  };

  const getJDDesc = (jrID) => {
    if (jdsSuccess) {
      const jddesc = jds?.data.filter((jd) => {
        return jd.job_request_id == jrID;
      });

      return jddesc[0].job_description;
    }
  };

  const getSkillName = (skillId) => {
    if (sklsSuccess) {
      const skillName = skls?.data.filter((skl) => {
        return skl.id == skillId;
      });
      return skillName[0]?.name;
    }
  };

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
      // ? `${filterdata[0]?.name} - ${filterdata[0]?.location.name}`
      // : '-';
    }
  };

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };

  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'New'}
          </div>
        </div>
      );
    }
  };

  const setPriority = (priorityId) => {
    if (priorityId == 1) {
      return 'Low';
    } else if (priorityId == 2) {
      return 'Medium';
    } else if (priorityId == 3) {
      return 'High';
    } else {
      return '-';
    }
  };

  return (
    <>
      {scsLoading ? 'Loading...' : ''}
      {scsFetching ? 'Fetching...' : ''}

      {scsSuccess ? (
        <div className="">
          <ul
            role="list"
            className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3"
          >
            {scs?.data.map((sc) => (
              <li
                key={sc.id}
                className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg"
              >
                <div className="flex flex-2 flex-col p-0">
                  <div className=" flex justify-between pt-5 px-5">
                    <div className="py-0 px-0">
                      <h2 className="py-0 text-gray-600 font-semibold text-lg">
                        {sc.job_request_title}
                      </h2>
                      <p className=" text-gray-600 font-light text-left">
                        {getLocationName(sc.branch_id)}
                        {', '}
                        {getCountryName(sc.country_id)}
                      </p>
                    </div>
                    <div className="py-0">
                      <p className=" px-0 pt-0 text-black font-semibold text-4xl">
                        {sc.no_of_position}
                      </p>
                    </div>
                    <div>
                      {/* <span>{sc.approval_status}</span> */}
                      <span className="bg-sidebar rounded-3xl ">
                        {/* {sc.approval_status != 1 ? ( */}
                        <PencilSquareIcon
                          className="h-12 w-12 text-white bg-blue-600 rounded-full p-3"
                          aria-hidden="true"
                          // onClick={() => openModalWindow()}
                          onClick={() =>
                            openModalDataWindow('edit', {
                              id: sc.id,
                              title: sc.job_request_title,
                              duedate: sc.due_date,
                              positions: sc.no_of_position,
                              job_request_id: sc.job_request_id,
                              branch: getBranchName(sc.branch_id),
                              location: getCountryName(sc.country_id),
                              skilllist: sc.skill_list,
                              priority: sc.priority,
                              job_requirement: getJDRequirement(
                                sc.job_request_id
                              ),
                              job_description: getJDDesc(sc.job_request_id),
                            })
                          }
                        />
                        {/* ) : (
                          ''
                        )} */}
                      </span>
                    </div>
                  </div>

                  {sklTypesLoading ? 'Loading Tabs...' : ''}
                  {sklTypesSuccess ? (
                    <Tab.Group as="div" className="mt-4 h-40">
                      {sc?.skill_list[0]?.skill_type_id ? (
                        <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 mx-5 my-3 rounded-md">
                          {sc.skill_list.map((sklList) => (
                            <Tab
                              // key={sklType.id}
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'bg-white text-black px-2 py-1 rounded-md font-semibold text-sm'
                                    : 'border-transparent text-gray-500 px-2 hover:border-gray-300 hover:text-gray-700'
                                )
                              }
                            >
                              {getSkillTypeName(sklList.skill_type_id)}
                            </Tab>
                          ))}
                        </Tab.List>
                      ) : (
                        <Tab.List className="flex justify-start bg-skillcaps px-2 py-2 mx-5 my-3 rounded-md">
                          No Skill Types Found
                        </Tab.List>
                      )}

                      <Tab.Panels as={Fragment}>
                        {sc.skill_list.map((sklList) => (
                          <Tab.Panel className="space-y-16 py-3">
                            <div className=" border-t py-5">
                              {Array.isArray(sklList.skills) ? (
                                sklList.skills.map((skill) => (
                                  <span className="inline-flex mr-2 items-left rounded-full bg-blue-100 px-3 py-1.5 text-sm font-medium text-black mb-3">
                                    {getSkillName(skill.skill_id)}{' '}
                                    {skill.min ? `${skill.min} Years - ` : ' '}{' '}
                                    {skill.max ? `${skill.max} Years` : ' '}
                                  </span>
                                ))
                              ) : (
                                <p>No skills available</p>
                              )}
                            </div>
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                  ) : (
                    ''
                  )}

                  <div className="flex h-28 justify-between border-b border-t border-gray-200 px-5 py-5 mt-8">
                    <table className="w-full">
                      <thead>
                        <tr className="text-sm text-blue-500 font-semibold align-top">
                          <td>SignedOff By</td>
                          <td>Deadline</td>
                          <td>Timeline Status</td>
                          <td>Signoff Status</td>
                          <td>Status</td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="text-sm text-black">
                          <td>{getUsername(sc.approved_by)}</td>
                          <td>{DateToIndiaFormat(sc.closing_date)}</td>
                          {getStatusCode(sc.Taskstatus)}
                          {getStatusCode(sc.SignoffStatus)}
                          <td>{displayStatus(sc.approval_status)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="my-5 sm:mt-6 flex flex-row justify-between items-center mx-5 py-2.5 rounded-lg">
                    <div className="">
                      {sc.priority ? (
                        <>
                          <label htmlFor="">Priority: </label>
                          <span>{setPriority(sc.priority)}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}

      <Drawer
        isOpen={openDrawer}
        title={'Add Department'}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <h1>Hello</h1>
      </Drawer>

      {/* <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        <AddSkillCapture />
      </Modal> */}

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
        size={'6xl'}
      >
        <AddSkillCapture
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        />
      </ModalData>
    </>
  );
};

export default SkillCapture;

import { apiSlice } from '../../app/api/apiSlice';
import CandDetails from '../../pages/userworkspace/mytask/hiringstage/offer/offerdetails/CandDetails';

export const offerApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    'Offer',
    'OfferList',
    'OfferDetails',
    'OfferApproval',
    'OfferApproval',
  ],
  endpoints: (builder) => ({
    offer: builder.query({
      query: () => ({
        url: '/offer',
        method: 'GET',
      }),
      providesTags: ['Offer'],
      keepUnusedDataFor: 5,
    }),
    offerApproval: builder.query({
      query: () => ({
        url: '/offer/approval',
        method: 'GET',
      }),
      providesTags: ['OfferApproval'],
      keepUnusedDataFor: 5,
    }),
    negotation: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/negotation',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    offerletterSubmit: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/offer_letter',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    offerletterApproval: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),

    candOfferlist: builder.query({
      query: (id) => ({
        url: `/offer/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['OfferList'],
      keepUnusedDataFor: 5,
    }),

    candOfferDetails: builder.query({
      query: (id) => ({
        url: `/offer/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['OfferDetails'],
      keepUnusedDataFor: 5,
    }),

    offerDocument: builder.mutation({
      query: (data) => ({
        url: '/offer/candidate/documents',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['OfferList', 'OfferDetails', 'OfferApproval'],
    }),
  }),
});

export const {
  useOfferQuery,
  useOfferApprovalQuery,
  useCandOfferlistQuery,
  useCandOfferDetailsQuery,
  useNegotationMutation,
  useOfferletterApprovalMutation,
  useOfferletterSubmitMutation,
  useOfferDocumentMutation,
} = offerApiSlice;

import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/logo.jpg';
import { Form, Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import { useChangePasswordApiMutation } from '../../features/changepassword/changePasswordApiSlice';

const Changepassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const navigate = useNavigate();
  const [searchParams, setSearchParms] = useSearchParams();
  const [token, setToken] = useState(searchParams.get('token'));
  const [changePasswordApi, { isLoading }] = useChangePasswordApiMutation();

  const lowercaseRegex = /(?=.*[a-z])/;
  const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;

  const initialValues = { newpassword: '', confirmpassword: '' };
  const validationSchema = yup.object({
    newpassword: yup
      .string()
      .required('Please enter the password')
      .matches(lowercaseRegex, 'One lowercase required!')
      .matches(uppercaseRegex, 'One uppercase required!')
      .matches(numericRegex, 'One number required!')
      .min(8, 'Minimum 8 characters required!'),

    confirmpassword: yup
      .string()
      .oneOf([yup.ref('newpassword')], 'Password must be the same!')
      .required('please enter the confirm password'),
  });

  const onSubmit = async (validationSchema) => {
    const data = { password: formik.values.newpassword, token };
    try {
      const changePwd = await changePasswordApi(data).unwrap();
      setErrMsg(changePwd.message);
    } catch (error) {
      setErrMsg(error.data.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    setErrMsg('');

    return () => {};
  }, [formik.values.newpassword, formik.values.confirmpassword]);

  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" />
            </div>

            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div className="space-y-1 relative">
                <label htmlFor="email" className="font-bold">
                  Change Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'type' : 'password'}
                    name="newpassword"
                    id="newpassword"
                    minLength={8}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter new password *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newpassword}
                  />
                  {formik.values.newpassword && (
                    <div
                      className=" cursor-pointer absolute top-2 right-0  items-center pr-3"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeIcon
                          className="h-5 w-5 text-clickhere"
                          aria-hidden="true"
                        />
                      ) : (
                        <EyeSlashIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  )}
                </div>
                {formik.touched.newpassword && formik.errors.newpassword ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.newpassword}
                  </div>
                ) : null}
              </div>

              <div className="space-y-1 relative">
                <label htmlFor="email" className="font-bold">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? 'type' : 'password'}
                    name="confirmpassword"
                    id="confirmpassword"
                    minLength={8}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Confirm Password *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmpassword}
                  />
                  {formik.values.confirmpassword && (
                    <div
                      className=" cursor-pointer absolute top-2 right-0  items-center pr-3"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <EyeIcon
                          className="h-5 w-5 text-clickhere"
                          aria-hidden="true"
                        />
                      ) : (
                        <EyeSlashIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  )}
                </div>
                {formik.touched.confirmpassword &&
                formik.errors.confirmpassword ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.confirmpassword}
                  </div>
                ) : null}
                {errMsg && (
                  <div className="mt-2 text-sm text-red-600">{errMsg}</div>
                )}
              </div>

              <div>
                {isLoading ? (
                  <button
                    type="submit"
                    disabled
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm focus:outline-none "
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={!(formik.dirty && formik.isValid)}
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none "
                  >
                    Change Password
                  </button>
                )}
              </div>
            </form>

            <div className="text-sm text-center pb-8 pt-8   ">
              <p className=" text-sm font-medium text-black text-center">
                Already have a company account?{' '}
                <Link
                  to={'/login'}
                  className="font-medium text-indigo-600 mt-4 text-sm"
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;

import { apiSlice } from '../../app/api/apiSlice';

export const departmentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Department'],
  endpoints: (builder) => ({
    department: builder.query({
      query: () => ({
        url: '/master/department',
        method: 'GET',
      }),
      providesTags: ['Department'],
      keepUnusedDataFor: 5,
    }),
    addDepartment: builder.mutation({
      query: (data) => ({
        url: '/master/department',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Department'],
    }),
    updateDepartment: builder.mutation({
      query: (data) => ({
        url: '/master/department',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Department'],
    }),
    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: `/master/department/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Department'],
    }),
  }),
});

export const {
  useDepartmentQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentApiSlice;

import { apiSlice } from "../../app/api/apiSlice";

export const AdminMyTaskUserPrevillageApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AdminMyTaskUserPrevillage"],
  endpoints: (builder) => ({
    adminmytaskuserprevillage: builder.query({
      query: () => ({
        url: "/master/adminmytaskuserprevillage",
        method: "GET",
      }),
      providesTags: ["AdminMyTaskUserPrevillage"],
    }),
    addAdminMyTaskUserPrevillage: builder.mutation({
      query: (data) => ({
        url: "/master/adminmytaskuserprevillage",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AdminMyTaskUserPrevillage"],
    }),
    updateAdminMyTaskUserPrevillage: builder.mutation({
      query: (data) => ({
        url: "/master/adminmytaskuserprevillage",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["AdminMyTaskUserPrevillage"],
    }),
    deleteAdminMyTaskUserPrevillage: builder.mutation({
      query: (id) => ({
        url: `/master/adminmytaskuserprevillage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AdminMyTaskUserPrevillage"],
    }),
  }),
});

export const {
  useAdminMyTaskUserPrevillageQuery,
  useAddAdminMyTaskUserPrevillageMutation,
  useUpdateAdminMyTaskUserPrevillageMutation,
  useDeleteAdminMyTaskUserPrevillageMutation,
} = AdminMyTaskUserPrevillageApiSlice;

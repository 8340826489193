import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  branch_id: null,
  budget: null,
  closing_date: null,
  country_id: null,
  department_id: null,
  employment_type: null,
  job_request_approval_status: null,
  job_request_approved_by: null,
  job_request_comments: null,
  job_request_id: null,
  job_request_priority: null,
  job_request_title: null,
  manpower_requirement_id: null,
  no_of_position: null,
  salary_budget: null,
  sourced: null,
  workflow_id: null,
};

const sourcingSlice = createSlice({
  name: 'sourcing',
  initialState,
  reducers: {
    setSourcing: (state, action) => {
      const {
        branch_id,
        budget,
        closing_date,
        country_id,
        department_id,
        employment_type,
        job_request_approval_status,
        job_request_approved_by,
        job_request_comments,
        job_request_id,
        job_request_priority,
        job_request_title,
        manpower_requirement_id,
        no_of_position,
        salary_budget,
        sourced,
        workflow_id,
      } = action.payload;

      state.branch_id = branch_id;
      state.budget = budget;
      state.closing_date = closing_date;
      state.country_id = country_id;
      state.department_id = department_id;
      state.employment_type = employment_type;
      state.job_request_approval_status = job_request_approval_status;
      state.job_request_approved_by = job_request_approved_by;
      state.job_request_comments = job_request_comments;
      state.job_request_id = job_request_id;
      state.job_request_priority = job_request_priority;
      state.job_request_title = job_request_title;
      state.manpower_requirement_id = manpower_requirement_id;
      state.no_of_position = no_of_position;
      state.salary_budget = salary_budget;
      state.sourced = sourced;
      state.workflow_id = workflow_id;
    },
  },
});

export const { setSourcing } = sourcingSlice.actions;

export default sourcingSlice.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import { selectCurrentEmail } from '../../features/auth/register/registerSlice';

const Accountverify = () => {
  const registeredEmail = useSelector(selectCurrentEmail);
  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" />
            </div>

            <div>
              <div>
                <h5 className="mb-3 text-2xl font-bold text-center tracking-tight text-gray-900">
                  Verify your account
                </h5>
                <p className="text-center text-sm font-normal text-verify pb-8">
                  Check your email inbox ({registeredEmail}) to confirm the
                  account and complete sign-up.
                  {/* Check your email inbox to confirm the account and complete
                  sign-up. */}
                </p>
              </div>

              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300 md:divide-y-8" />
                </div>
              </div>
              <div>
                <p className="text-center text-sm font-normal text-verify pt-6">
                  {/* In case you don't see the email after a few minutes, please */}
                  <Link
                    to={'/login'}
                    className="text-clickhere text-1xl font-semibold"
                  >
                    {' '}
                    Login
                  </Link>{' '}
                  {/* to resend confirmation email. */}
                </p>
              </div>

              {/* <div>
                <p className="text-center text-sm font-normal text-verify pt-6">
                  In case you don't see the email after a few minutes, please
                  <a href="/" className="text-clickhere text-1xl font-semibold">
                    {' '}
                    click here
                  </a>{' '}
                  to resend confirmation email.
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className=" pt-4">
          <p className="text-center text-md font-normal text-gray-500 ">
            Having any trouble here?
            <Link
              to={'/contactdetails'}
              className="text-clickhere text-1xl font-semibold"
            >
              Contact us
            </Link>
            {/* <a href="/" className="text-clickhere text-1xl font-semibold">
              {' '}
              Contact us
            </a>{' '} */}
          </p>
        </div>
      </div>
    </>
  );
};

export default Accountverify;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddSkillsMutation,
  useUpdateSkillsMutation,
  useDeleteSkillsMutation,
} from './skillnewApiSlice';
import Notification from '../../components/common/Notification';
import { useSkillQuery } from '../skill/skillApiSlice';

import { Switch } from '@headlessui/react';
import { useEffect } from 'react';
import { toast } from 'sonner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AddSkillnew = ({ data, isEdit, onClose }) => {
  const [addSkill, { isLoading }] = useAddSkillsMutation();
  const [updateSkill] = useUpdateSkillsMutation();
  const [DeleteSkill] = useDeleteSkillsMutation();
  const [errMsg, setErrMsg] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [enabled, setEnabled] = useState(data?.level || false);

  const {
    data: skillTypes,
    error,
    isLoading: skillingLoading,
    isFetching,
    isSuccess,
  } = useSkillQuery();

  const initialValues = {
    id: data?.id || '',
    name: data?.name || '',
    skill_type_id: data?.typeid || '',
  };
  const validationSchema = yup.object({
    name: yup.string().required('Please enter  name'),
    skill_type_id: yup.string().required('Please select skill type'),
    // check: yup.boolean().oneOf([true], 'Please Click the status'),
  });
  const onSubmit = async (validationSchema) => {
    const newArray = { ...validationSchema, level: enabled };
    try {
      if (isEdit) {
        const accountData = await updateSkill(newArray).unwrap();
        toast.success('Skill updated');
      } else {
        const accountData = await addSkill(newArray).unwrap();
        formik.values.name = '';
        formik.values.skill_type_id = '';
        toast.success('Skill added');
      }

      onClose();
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Notification isShow={showNotification} />
      <form
        // onSubmit={formik.handleSubmit}
        className="flex flex-col justify-between"
      >
        <div className="space-y-1">
          <h5 className="mb-3 text-sm font-normal tracking-tight text-gray-900">
            Name *
          </h5>

          <input
            type="text"
            name="name"
            id="name"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            placeholder=" Name *"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.name}
            </div>
          ) : null}
        </div>
        <div className="space-y-1 pt-5">
          <label
            htmlFor="skill_type_id"
            className="block text-sm font-medium text-gray-700"
          >
            Skill Type
          </label>

          <select
            id="skill_type_id"
            name="skill_type_id"
            autoComplete="branch-name"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.skill_type_id}
          >
            <option val="-1">-- Select Skill type --</option>
            {skillTypes?.data.map((skillType) => (
              <option key={skillType.id} value={skillType.id}>
                {skillType.name}
              </option>
            ))}
          </select>

          {formik.touched.skill_type_id && formik.errors.skill_type_id ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.skill_type_id}
            </div>
          ) : null}
        </div>

        <div className="space-y-1 pt-5">
          <label
            htmlFor="skill_type_id"
            className="block text-sm font-medium text-gray-700"
          >
            Range (Enable to show Min and Max Range for Skill Capture )
          </label>
          <div className="pt-2">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2'
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                className={classNames(
                  enabled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              >
                <span
                  className={classNames(
                    enabled
                      ? 'opacity-0 duration-100 ease-out'
                      : 'opacity-100 duration-200 ease-in',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  className={classNames(
                    enabled
                      ? 'opacity-100 duration-200 ease-in'
                      : 'opacity-0 duration-100 ease-out',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </div>
        </div>

        <div className="space-y-1 pt-5">
          <div className="flex flex-shrink-0 justify-end py-4">
            <button
              type="button"
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                type="submit"
                disabled
                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
            ) : (
              <button
                // disabled={!(formik.dirty && formik.isValid)}
                // type="submit"
                type="button"
                onClick={formik.handleSubmit}
                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSkillnew;

import React, { useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';

// Hooks
import useGetSkillcapturebyid from '../../../../../hooks/useSkillcapturebyid';
import useGetSkills from '../../../../../hooks/useGetSkills';

const { useSelector } = require('react-redux');

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CandFilter = ({ applyFilter }) => {
  // Fetching from REDUX
  const jobreqid = useSelector((state) => state.sourcing.job_request_id);

  // Search
  const [searchTerm, setSearchTerm] = useState('');

  // Fetching from HOOKS
  const { skills, loadingSkills, successSkills } = useGetSkills();
  const { skillcapturebyid, loadingSkillcapturebyid, successSkillcapturebyid } =
    useGetSkillcapturebyid(jobreqid);

  // state values and constants
  const jobrequestSkillArray = skillcapturebyid?.data[0]?.skill_list || [];
  const skillListArray = skills?.data || [];
  const [checkboxes, setCheckboxes] = useState([]);

  const [skillCaptureArray, setSkillCaptureArray] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [selectedSkillList, setSelectedSkillList] = useState([]);

  useEffect(() => {
    const arr2 = [];

    // Create an object to store skills by weightage
    const skillsByWeightage = {};

    // Iterate through jobrequestSkillArray
    jobrequestSkillArray.forEach((jobrequestSkill) => {
      const skillType = jobrequestSkill.skill_type_id;

      if (Array.isArray(jobrequestSkill.skills)) {
        jobrequestSkill.skills.forEach((skill) => {
          const weightage = skill.weightage;
          const skillId = skill.skill_id;

          // Find the skill name from skillListArray
          const skillDetails = skillListArray.find(
            (item) => item.id === parseInt(skillId)
          );

          if (skillDetails) {
            const skillObj = {
              skill_id: parseInt(skillId),
              name: skillDetails.name,
              isCheched: false,
            };

            if (!skillsByWeightage[weightage]) {
              skillsByWeightage[weightage] = [];
            }

            skillsByWeightage[weightage].push(skillObj);
          }
        });
      } else {
        console.log(`jobrequestSkill.skills is not an array for skill type ID: ${skillType}`);
      }
    });

    // Push the skills organized by weightage to arr2
    Object.keys(skillsByWeightage).forEach((weightage) => {
      const weightageObj = {};
      weightageObj[weightage] = skillsByWeightage[weightage];
      arr2.push(weightageObj);
    });

    setSelectedSkillList(arr2);
  }, [jobrequestSkillArray, skillListArray]);

  const sendFilterData = (e) => {
    const { name, value } = e.target;
    if (name === 'search') {
      setSearchTerm(value);
    }

    applyFilter(e);
  };

  const updateSkillStateforChecked = (e, weightageName) => {
    const updatedValue = selectedSkillList.map((category) => {
      if (category[weightageName]) {
        const updatedSkills = category[weightageName].map((skill) => {
          if (skill.skill_id == e.target.value) {
            return { ...skill, isCheched: !skill.isCheched };
          }
          return skill;
        });
        return { ...category, [weightageName]: updatedSkills };
      }
      return category;
    });

    setSelectedSkillList(updatedValue);
    sendFilterData(e);
  };

  function SkillBlock({ skills, title }) {
    return (
      <>
        <div className="border-t border-gray-300 pt-4 mb-4">
          <h2 className=" text-lg font-semibold px-4">
            {[...title][0].toUpperCase() + [...title].slice(1).join('')}
          </h2>
          {skills.map((skill, index) => (
            <div className="space-y-5 px-4 pt-2" key={index}>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={`${title}-${index}`}
                    aria-describedby="comments-description"
                    name={skill.name}
                    type="checkbox"
                    value={skill.skill_id}
                    checked={skill.isCheched}
                    // onChange={(e) => sendFilterData(e)}
                    onChange={(e) => updateSkillStateforChecked(e, title)}
                    className="h-4 w-4 rounded border-gray-300 bg-gray-800 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={`${title}-${index}`}
                    className="font-medium text-gray-900"
                  >
                    {skill.name}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  const options = [];
  for (let i = 0; i <= 30; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return (
    <div>
      <div className="basis-80 shadow-lg">
        <div className="bg-white rounded-md shadow-3xl border border-gray-100 mt-2">
          {/* Search */}
          <div className="min-w-0 flex-1 px-4 pt-4">
            <label htmlFor="search" className="sr-only">
              Search Candidates
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Search Candidates"
                value={searchTerm}
                onChange={(e) => sendFilterData(e)}
              />
            </div>
            {searchTerm}
          </div>

          {/* Experience */}
          <div>
            <h2 className=" text-lg font-semibold p-4 border-t mt-4 border-gray-300 ">
              Experience
            </h2>
            <div className=" mb-4">
              <div className="flex flex-row px-4 items-center justify-between">
                <div>
                  <div className="flex">
                    <select
                      onChange={(e) => sendFilterData(e)}
                      name="min"
                      className="bg-inputbg border border-inputBorder mt-1 block rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    >
                      {options}
                    </select>
                  </div>
                </div>
                <div>-</div>
                <div>
                  <div className="flex">
                    <select
                      onChange={(e) => sendFilterData(e)}
                      name="max"
                      className="bg-inputbg border border-inputBorder mt-1 block  rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    >
                      {options}
                    </select>
                  </div>
                </div>
                <p className="pl-3">Years</p>
              </div>

              {/* <div className="flex flex-row mt-3 px-4 items-center">
                <div className="">
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded bg-gray-100 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6 mr-3">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900"
                        >
                          Above
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <select className="bg-inputbg border border-inputBorder mt-1 block  rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm">
                    <option value="">0</option>
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4</option>
                    <option value="">5</option>
                  </select>
                </div>
                <p className="pl-3">Years</p>
              </div>

              <div className="flex flex-row mt-3 px-4 pb-4 items-center">
                <div className="">
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-800 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6 mr-3">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900"
                        >
                          Below
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <select className="bg-inputbg border border-inputBorder mt-1 block  rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm">
                    <option value="">0</option>
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4</option>
                    <option value="">5</option>
                  </select>
                </div>
                <p className="pl-3">Years</p>
              </div> */}
            </div>
          </div>

          {/* Checkboxlist */}
          <div>
            {selectedSkillList.map((item, index) => {
              const key = Object.keys(item)[0];
              return <SkillBlock key={index} title={key} skills={item[key]} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandFilter;

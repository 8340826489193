import React, { useState } from 'react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useJoiningCandidateMutation } from '../../../../../../features/hiringstages/joiningApiSlice';
function CandCalling({ dataval }) {
  // const [joiningCandidate] = useJoiningCandidateMutation;

  const [first, setfirst] = useState({
    interview_with_other_company: 0,
    other_offers_in_hand: 0,
    attitude: 0,
    response_to_communication: 0,
    knowledge_about_company: 0,
    interest_towards_company: 0,
    satisfaction_towards_CTC: 0,
    satisfaction_towards_job_role: 0,
    interest_to_involve_with_internal_teams: 0,
    location_satisfaction: 0,
  });

  const initialValues = {
    id: dataval?.data,
    level: dataval?.data,
    interview_with_other_company: first.interview_with_other_company || 0,
    other_offers_in_hand: first.other_offers_in_hand || 0,
    attitude: first.attitude || 0,
    response_to_communication: first.response_to_communication || 0,
    knowledge_about_company: first.knowledge_about_company || 0,
    interest_towards_company: first.interest_towards_company || 0,
    satisfaction_towards_CTC: first.satisfaction_towards_CTC || 0,
    satisfaction_towards_job_role: first.satisfaction_towards_job_role || 0,
    interest_to_involve_with_internal_teams:
      first.interest_to_involve_with_internal_teams || 0,
    location_satisfaction: first.location_satisfaction || 0,
  };

  const validationSchema = yup.object({
    interview_with_other_company: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    other_offers_in_hand: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    attitude: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    response_to_communication: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    knowledge_about_company: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),

    interest_towards_company: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),

    satisfaction_towards_CTC: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    satisfaction_towards_job_role: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
    interest_to_involve_with_internal_teams: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),

    location_satisfaction: yup
      .number()
      .integer()
      .min(1)
      // .max()
      .required(),
  });

  const onSubmit = async (validationSchema) => {
    // try {
    //   const rrrr = await joiningCandidate(validationSchema).unwrap();
    //   console.log('send data', rrrr);
    //   alert('sumbitted Successfully');
    // } catch (error) {
    //   console.log('error', error);
    // }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <form className="bg-white rounded-lg" onSubmit={formik.handleSubmit}>
      <div>
        <h1 className="text-xl font-bold text-center">{dataval?.title}</h1>
      </div>
      <div className="mx-0 mt-6 grid grid-cols-2 gap-10 px-0">
        <div className="mt-0 px-5">
          <label
            htmlFor="interview_with_other_company"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Interview with other company
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.interview_with_other_company]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                interview_with_other_company: sliderVal,
              }))
            }
          />
          {formik.touched.interview_with_other_company &&
          formik.errors.interview_with_other_company ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.interview_with_other_company}
            </div>
          ) : null}
        </div>

        <div className="px-5">
          <label
            htmlFor="interest_towards_company"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Interest towards company
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.interest_towards_company]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                interest_towards_company: sliderVal,
              }))
            }
          />
          {formik.touched.interest_towards_company &&
          formik.errors.interest_towards_company ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.interest_towards_company}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mx-0 mt-6 grid grid-cols-2 gap-10 px-0">
        <div className="mt-4 px-5">
          <label
            htmlFor="other_offers_in_hand"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Other offers in hand
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.other_offers_in_hand]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                other_offers_in_hand: sliderVal,
              }))
            }
          />
          {formik.touched.other_offers_in_hand &&
          formik.errors.other_offers_in_hand ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.other_offers_in_hand}
            </div>
          ) : null}
        </div>

        <div className="mt-4 px-5">
          <label
            htmlFor="satisfaction_towards_CTC"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Satisfaction towards CTC
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.satisfaction_towards_CTC]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                satisfaction_towards_CTC: sliderVal,
              }))
            }
          />
          {formik.touched.satisfaction_towards_CTC &&
          formik.errors.satisfaction_towards_CTC ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.satisfaction_towards_CTC}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mx-0 mt-6 grid grid-cols-2 gap-10 px-0">
        <div className="mt-4 px-5">
          <label
            htmlFor="attitude"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Attitude
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.attitude]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                attitude: sliderVal,
              }))
            }
          />
          {formik.touched.attitude && formik.errors.attitude ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.attitude}
            </div>
          ) : null}
        </div>

        <div className="mt-4 px-5">
          <label
            htmlFor="satisfaction_towards_job_role"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Satisfaction towards job role
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.satisfaction_towards_job_role]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                attitude: sliderVal,
              }))
            }
          />
          {formik.touched.satisfaction_towards_job_role &&
          formik.errors.satisfaction_towards_job_role ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.satisfaction_towards_job_role}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mx-0 mt-6 grid grid-cols-2 gap-10 px-0">
        <div className="mt-4 px-5">
          <label
            htmlFor="response_to_communication"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Response to communication
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.response_to_communication]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                response_to_communication: sliderVal,
              }))
            }
          />

          {formik.touched.response_to_communication &&
          formik.errors.response_to_communication ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.response_to_communication}
            </div>
          ) : null}
        </div>

        <div className="mt-4 px-5">
          <label
            htmlFor="interest_to_involve_with_internal_teams"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Interest to involve with internal teams
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[
              formik.values.interest_to_involve_with_internal_teams,
            ]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                interest_to_involve_with_internal_teams: sliderVal,
              }))
            }
          />
          {formik.touched.interest_to_involve_with_internal_teams &&
          formik.errors.interest_to_involve_with_internal_teams ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.interest_to_involve_with_internal_teams}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mx-0 mt-6 grid grid-cols-2 gap-10 px-0">
        <div className="mt-4 px-5">
          <label
            htmlFor="knowledge_about_company"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Knowledge about company
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.knowledge_about_company]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                knowledge_about_company: sliderVal,
              }))
            }
          />
          {formik.touched.knowledge_about_company &&
          formik.errors.knowledge_about_company ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.knowledge_about_company}
            </div>
          ) : null}
        </div>

        <div className="mt-4 px-5">
          <label
            htmlFor="location_satisfaction"
            className="block text-sm  font-medium text-gray-900 mr-4 pb-2"
          >
            Location Satisfaction
          </label>
          <Slider
            // range
            // className="cursor-pointer "
            marks={{
              0: 0,
              10: 10,
            }}
            min={0}
            step={1}
            max={10}
            allowCross={false}
            defaultValue={[formik.values.location_satisfaction]}
            onChange={(sliderVal) =>
              setfirst((prev) => ({
                ...prev,
                location_satisfaction: sliderVal,
              }))
            }
          />
          {formik.touched.location_satisfaction &&
          formik.errors.location_satisfaction ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.location_satisfaction}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <button
          type="submit"
          className=" rounded-lg  bg-sidebar py-2 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
        >
          Save
        </button>
      </div>
    </form>
  );
}

export default CandCalling;

import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';

import {
  ArchiveBoxIcon,
  PencilSquareIcon,
  XMarkIcon,
  PlusIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';

import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';
import Notification from '../../../../../components/common/Notification';
import Wysiwyg from '../../../../../components/common/Wysiwyg';
import ModalData from '../../../../../components/common/ModalData';
import EditJD from '../../../mytask/hiringstage/jobdescription/EditJD';
import { AddJD } from './AddJD';

import { useJobdescApprovalQuery } from '../../../../../features/hiringstages/jobdescApiSlice';
import { useBranchQuery } from '../../../../../features/branch/branchApiSlice';
import { useCountryQuery } from '../../../../../features/country/countryApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';
import { getStatusCode } from '../../../UserWSUtils';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function JobDesc() {
  const {
    data: jds,
    error: jdsError,
    isLoading: jdsLoading,
    isFetching: jdsFetching,
    isSuccess: jdsSuccess,
  } = useJobdescApprovalQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  //   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);

  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);
  //   const [deleteAdminMyTaskUserPrevillage] =
  //     useDeleteAdminMyTaskUserPrevillageMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  //   const onModalSubmit = async (deptId) => {
  //     try {
  //       const deleteAdminMytask = await deleteAdminMyTaskUserPrevillage(
  //         deptId
  //       ).unwrap();
  //       console.log(deleteAdminMytask.message);
  //       closeModalWindow();
  //       showNotificationMsg();
  //     } catch (error) {
  //       console.log(JSON.stringify(error));
  //     }
  //   };

  const onModalSubmit = () => {
    // setOpenModal(!openModal);
  };

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    // setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
  };

  const transactions = [
    {
      title: 'Software Engineer',
      department: 'Andriod',
      emp_type: 'Full Time',
      experience: '2 - 4 Years',
      positions: '20',
      salary: '10L - 20L',
      prepared_by: 'Robin Williams',
      status: 'Approved',
      req_date: '05/08/2022',
      action: '',
    },
    {
      title: ' HR Manager',
      department: 'Human Resources',
      emp_type: 'Part Time',
      experience: '2 - 4 Years',
      positions: '50',
      salary: '20L - 50L',
      prepared_by: 'Theodore',
      status: 'Waiting',
      req_date: '05/08/2022',
      action: '',
    },

    {
      title: 'Senior Marketer',
      department: 'Marketing',
      emp_type: 'Part Time',
      experience: '2 - 4 Years',
      positions: '100',
      salary: '50L - 1Cr',
      prepared_by: 'Dwayne',
      status: 'Rejected',
      req_date: '05/08/2022',
      action: '',
    },
  ];

  const tableHeader = [
    'Job Title',
    // 'Department',
    'Emp Type',
    'Experience',
    'Positions',
    'Salary',
    'Prepared by',
    'Req Date',
    'Status',
    'Timeline Status',
    'Signoff Status',
    'Action',
  ];
  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'New'}
          </div>
        </div>
      );
    }
  };
  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
    }
  };

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };
  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };
  return (
    <>
      {jdsLoading ? 'Loading...' : ''}
      {jdsSuccess ? (
        <>
          <div className="mt-3 flex flex-col px-6">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-2">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-100 text-md font-bold text-gray-900">
                      <tr>
                        {tableHeader.map((headData) => (
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left  sm:pl-6"
                          >
                            {headData}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {jds?.data.length > 0 ? (
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {jds?.data
                          .filter((jdf) => jdf.job_request_approval_status == 1)
                          .map((jd) => (
                            <tr key={jd.id}>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 border-r-2">
                                {jd.job_request_title}
                              </td>
                              {/* <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                                {jd.department}
                              </td> */}
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                {jd.employment_type}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jd.required_experience}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jd.no_of_position}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jd.salary_budget}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {getUsername(jd.createdBy)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.req_date} */}
                                {DateToIndiaFormat(jd.createdAt)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.status} */}
                                {displayStatus(jd.approval_status)}
                              </td>
                              {getStatusCode(jd.Taskstatus)}
                              {getStatusCode(jd.SignoffStatus)}
                              <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                <CheckCircleIcon
                                  className="ml-1 mr-2 h-7 w-7 text-green-500 "
                                  aria-hidden="true"
                                  onClick={() =>
                                    openDrawerWindow('edit', {
                                      id: jd.id,
                                      title: jd.job_request_title,
                                      position: jd.no_of_position,
                                      branch: getBranchName(jd.branch_id),
                                      location: getLocationName(jd.branch_id),
                                      requestedBy: getUsername(jd.createdBy),
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="bg-white p-2">
                        ``
                        <tr>
                          <td
                            className="p-2 text-red-600 font-bold"
                            colSpan={10}
                          >
                            No Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
      <Drawer
        isOpen={openDrawer}
        // title={isEdit ? "Edit Location" : "My Task"}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        {/* <AddSignOffManpowerRQMT
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        /> */}
        <AddJD
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
          showNotification={() => showNotificationMsg()}
        />
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        helo
      </Modal>

      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        {/* <AddManpower
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        /> */}
        <EditJD />
      </ModalData>
    </>
  );
}

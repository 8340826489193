import { apiSlice } from '../../app/api/apiSlice';

export const jobdescApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Jobdesc', 'Jobdescapproval'],
  endpoints: (builder) => ({
    jobdesc: builder.query({
      query: () => ({
        url: '/job/jobdescription',
        method: 'GET',
      }),
      providesTags: ['Jobdesc'],
      // keepUnusedDataFor: 5,
    }),
    jobdescApproval: builder.query({
      query: () => ({
        url: '/job/jobdescription/approval',
        method: 'GET',
      }),
      providesTags: ['Jobdescapproval'],
      keepUnusedDataFor: 5,
    }),
    addJobdesc: builder.mutation({
      query: (data) => ({
        url: '/job/jobdescription',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Jobdesc', 'Jobdescapproval'],
    }),
    updateJobdesc: builder.mutation({
      query: (data) => ({
        url: '/job/jobdescription',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Jobdesc', 'Jobdescapproval'],
    }),
    approveJobdesc: builder.mutation({
      query: (data) => ({
        url: '/job/jobdescription/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Jobdesc', 'Jobdescapproval'],
    }),
    // deleteDepartment: builder.mutation({
    //   query: (id) => ({
    //     url: `/master/department/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Department'],
    // }),
  }),
});

export const {
  useJobdescQuery,
  useJobdescApprovalQuery,
  useAddJobdescMutation,
  useUpdateJobdescMutation,
  useApproveJobdescMutation,
  // useDeleteDepartmentMutation,
} = jobdescApiSlice;

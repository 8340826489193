import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';

import { PencilSquareIcon, EyeIcon } from '@heroicons/react/24/outline';

// import Drawer from "../../../../../components/common/Drawer";
import Drawer from '../../../../../components/common/Drawer';
import Modal from '../../../../../components/common/Modal';
import DateToIndiaFormat from '../../../../../components/common/DateToIndiaFormat';

import ModalData from '../../../../../components/common/ModalData';
import EditJD from './EditJD';
import { useJobdescQuery } from '../../../../../features/hiringstages/jobdescApiSlice';
// import { hasExpectedRequestMetadata } from '@reduxjs/toolkit/dist/matchers';

import { useBranchQuery } from '../../../../../features/branch/branchApiSlice';
import { useCountryQuery } from '../../../../../features/country/countryApiSlice';
import { useTeamsQuery } from '../../../../../features/teams/teamsApiSlice';
import { useWorkflowQuery } from '../../../../../features/workflow/workflowApiSlice';
import { getStatusCode } from '../../../UserWSUtils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function JobDesc() {
  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: jds,
    error: jdsError,
    isLoading: jdsLoading,
    isFetching: jdsFetching,
    isSuccess: jdsSuccess,
  } = useJobdescQuery({}, { refetchOnMountOrArgChange: true });

  const {
    data: wf,
    error: wfError,
    isLoading: wfLoading,
    isFetching: wfFetching,
    isSuccess: wfSuccess,
  } = useWorkflowQuery({ refetchOnMountOrArgChange: true });

  //   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState(false);

  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentModalValue, setCurrentModalValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);
  //   const [deleteAdminMyTaskUserPrevillage] =
  //     useDeleteAdminMyTaskUserPrevillageMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const showNotificationMsg = () => {
    setShowNotification(true);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  //   const onModalSubmit = async (deptId) => {
  //     try {
  //       const deleteAdminMytask = await deleteAdminMyTaskUserPrevillage(
  //         deptId
  //       ).unwrap();
  //       console.log(deleteAdminMytask.message);
  //       closeModalWindow();
  //       showNotificationMsg();
  //     } catch (error) {
  //       console.log(JSON.stringify(error));
  //     }
  //   };

  const onModalSubmit = () => {
    // setOpenModal(!openModal);
  };

  const openModalDataWindow = (mode, obj) => {
    // setManpowerId(id);
    // setOpenModalDataValues(sendtoModal);
    const combinedValue = {
      ...obj,
      mode: mode,
    }
    setCurrentModalValue(combinedValue);
    setOpenModalData(true);
  };
  
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const tableHeader = [
    'Job Title',
    'Workflow Title',
    'Location',
    'Branch',
    'Positions',
    'Signoff by',
    // 'Timeline Status',
    'Deadline',
    'Status',
    'Timeline Status',
    'Signoff Status',
    'Action',
  ];

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };

  const getWorkflowTitle = (workflowid) => {
    if (wfSuccess) {
      const workflowName = wf?.data.filter((wff) => {
        return wff.id == workflowid;
      });
      return workflowName[0]?.title;
    }
  };

  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'New'}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {jdsLoading ? 'Loading...' : ''}
      {jdsSuccess ? (
        <>
          <div className="mt-0 flex flex-col px-6">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-2">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-100 text-md font-bold text-gray-900">
                      <tr>
                        {tableHeader.map((headData, i) => (
                          <th
                            scope="col"
                            key={i}
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left  sm:pl-6"
                          >
                            {headData}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {jds?.data.length > 0 ? (
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {jds?.data
                          .filter((jdf) => jdf.job_request_approval_status == 1)
                          .map((jd) => (
                            <tr key={jd.id}>
                              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                                {jd.job_request_title}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 border-r-2">
                                {/* {jd.workflow_id} */}
                                {getWorkflowTitle(jd.workflow_id)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                {/* {jd.country_id} */}
                                {getCountryName(jd.country_id)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.branch_id} */}
                                {getBranchName(jd.branch_id)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jd.no_of_position}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.approved_by} */}
                                {getUsername(jd.approved_by)}
                              </td>
                              {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jd.timeline_status} 
                              </td> */}
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.closing_date} */}
                                {DateToIndiaFormat(jd.closing_date)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {/* {jd.approval_status} */}
                                {displayStatus(jd.approval_status)}
                              </td>
                              {getStatusCode(jd.Taskstatus)}
                              {getStatusCode(jd.SignoffStatus)}

                              <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                {/* {jd.approval_status !== 1 ? ( */}

                                {/* <pre>{JSON.stringify(jd, undefined, 2)}</pre> */}
                                <PencilSquareIcon
                                  className="ml-1 mr-2 h-7 w-7 text-purple-500"
                                  aria-hidden="true"
                                  // onClick={() => openDrawerWindow()}
                                  onClick={() =>
                                    openModalDataWindow('edit', {
                                      id: jd.id,
                                      title: jd.job_request_title,
                                      positions: jd.no_of_position,
                                      job_request_id: jd.job_request_id,
                                      branch: getBranchName(jd.branch_id),
                                      location: getCountryName(jd.country_id),
                                      priority: jd.priority,
                                      job_requirement: jd.job_requirement,
                                      job_description: jd.job_description,
                                      employment_type: jd.employment_type,
                                      required_experience:
                                        jd.required_experience,
                                      salary_budget: jd.salary_budget,
                                    })
                                  }
                                />
                                <EyeIcon 
                                className="ml-1 mr-2 h-7 w-7 text-purple-500"
                                aria-hidden="true"
                                // onClick={() => openDrawerWindow()}
                                onClick={() =>
                                  openModalDataWindow('View', {
                                    id: jd.id,
                                    title: jd.job_request_title,
                                    positions: jd.no_of_position,
                                    job_request_id: jd.job_request_id,
                                    branch: getBranchName(jd.branch_id),
                                    location: getCountryName(jd.country_id),
                                    priority: jd.priority,
                                    job_requirement: jd.job_requirement,
                                    job_description: jd.job_description,
                                    employment_type: jd.employment_type,
                                    required_experience:
                                      jd.required_experience,
                                    salary_budget: jd.salary_budget,
                                  })
                                }
                                />
                                {/* ) : (
                                  ''
                                )} */}
                                {/* </a> */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="bg-white p-2">
                        ``
                        <tr>
                          <td
                            className="p-2 text-red-600 font-bold"
                            colSpan={10}
                          >
                            No Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <Drawer
        isOpen={openDrawer}
        // title={isEdit ? "Edit Location" : "My Task"}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        {/* <AddSignOffManpowerRQMT
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        /> */}
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      ></Modal>
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        {/* <AddManpower
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          showNotify={() => showNotificationMsg()}
          data={openModalDataValues}
        /> */}
        {/* <EditJD /> */}
        <EditJD
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        />
      </ModalData>
    </>
  );
}

import { apiSlice } from '../../app/api/apiSlice';

export const branchApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Branch'],
  endpoints: (builder) => ({
    branch: builder.query({
      query: () => ({
        url: '/master/branch',
        method: 'GET',
      }),
      providesTags: ['Branch'],
      keepUnusedDataFor: 5,
    }),
    addBranch: builder.mutation({
      query: (data) => ({
        url: '/master/branch',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Branch'],
    }),
    updateBranch: builder.mutation({
      query: (data) => ({
        url: '/master/branch',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Branch'],
    }),
    deleteBranch: builder.mutation({
      query: (id) => ({
        url: `/master/branch/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Branch'],
    }),
  }),
});

export const {
  useBranchQuery,
  useAddBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
} = branchApiSlice;

import React, { useState, useEffect } from 'react';

function InternetStatusAlert() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOnline) {
    return (
      <div
        style={{
          backgroundColor: 'red',
          color: 'white',
          padding: '10px',
          textAlign: 'center',
        }}
      >
        You are currently offline. Some features may not be available.
      </div>
    );
  }

  // Return null or any other component when the internet is available
  return null;
}

export default InternetStatusAlert;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddBranchMutation,
  useUpdateBranchMutation,
} from './branchApiSlice';
import Notification from '../../components/common/Notification';
import { useLocationQuery } from '../../features/location/locationApiSlice';
import { toast } from 'sonner';

const AddBranch = ({ data, isEdit, onClose }) => {
  const [addBranch] = useAddBranchMutation();
  const [updateBranch, { isLoading }] = useUpdateBranchMutation();
  const [errMsg, setErrMsg] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const {
    data: locations,
    error: locationError,
    isLoading: locationIsLoading,
    isFetching: locationIsFetching,
    isSuccess: locationIsSuccess,
  } = useLocationQuery();

  // alert(data.name, data.description);

  const initialValues = {
    id: data?.id || '',
    name: data?.name || '',
    location_id: data?.location_id || '',
    email: data?.email || '',
    mobile: data?.mobile || '',
  };
  const validationSchema = yup.object({
    name: yup.string().required('Please enter Branch name'),
    location_id: yup.string().required('Please enter Location '),
    // email: yup
    //   .string()
    //   .email('Please enter a valid email')
    //   .required('Please enter official your email'),
    // mobile: yup
    //   .string()
    //   .required('Please enter a official phone number')
    //   .min(10, 'Minimum 10 numbers only')
    //   .max(10, 'Maximum 10 numbers only'),
    // description: yup.string().required('Please enter description'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      if (isEdit) {
        const accountData = await updateBranch(validationSchema).unwrap();
        toast.success('Branch updated');
      } else {
        const accountData = await addBranch(validationSchema).unwrap();
        toast.success('Brach added');
        formik.values.name = '';
        formik.values.location_id = '';
      }
      onClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <>
      <Notification isShow={showNotification} />

      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-between"
      >
        <div className="space-y-1">
          <h5 className="mb-3 text-sm font-normal tracking-tight text-gray-900">
            Branch Name *
          </h5>

          <input
            type="text"
            name="name"
            id="name"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.name}
            </div>
          ) : null}
        </div>

        <div className="space-y-1">
          <h5 className="mb-2 mt-4 text-sm font-normal tracking-tight text-gray-900">
            Branch Location *
          </h5>
          <select
            id="location_id"
            name="location_id"
            autoComplete="branch-name"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location_id}
          >
            <option val="-1">-- Select location --</option>
            {locations?.data.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>

          {formik.touched.location_id && formik.errors.location_id ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.location_id}
            </div>
          ) : null}
        </div>

        {/* <div className="space-y-1">
          <h5 className="mb-2 mt-4 text-sm font-normal tracking-tight text-gray-900">
            Official Email *
          </h5>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            placeholder=" Official Email Id"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.email}
            </div>
          ) : null}
        </div> */}

        {/* <div>
          <h5 className="mb-2 mt-4 text-sm font-normal tracking-tight text-gray-900">
            Official Phone Number
          </h5>

          <div className="rounded-md shadow-sm">
            <input
              type="number"
              name="mobile"
              id="mobile"
              placeholder="Official Phone Number"
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
              // className="block w-full rounded-md border-gray-300 pl-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pl-14 sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.mobile}
              </div>
            ) : null}
          </div>
        </div> */}

        <div className="flex flex-shrink-0 justify-end py-4">
          <button
            type="button"
            className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            Cancel
          </button>
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              disabled={!(formik.dirty && formik.isValid)}
              type="submit"
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none"
            >
              Save
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default AddBranch;

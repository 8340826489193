import React, { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  useGetInterviewCandDetailsQuery,
  useGetCandInterviewListDetailsQuery,
  useScheduleInterviewMutation,
  useAddScheduleInterviewMutation,
  useDeleteInterviewerMutation,
  useSkillsUpdateMutation,
  useInterviewRescheduleMutation
} from '../../../../../../../features/hiringstages/interviewApiSlice';
import { selectCandidateInterview } from '../../../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import useTeamsUsers from '../../../../../../../hooks/useTeamsUsers';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangePicker from '../../../../../../../components/common/DateRangePicker';
import { Link } from 'react-router-dom';
import ModalData from '../../../../../../../components/common/ModalData';
import { AddKeySkill } from '../modal/AddKeySkill';
import { useSkillsQuery } from '../../../../../../../features/skillnew/skillnewApiSlice';
import AddSkillnew from '../../../../../../../features/skillnew/AddSkillnew';
import Drawer from '../../../../../../../components/common/Drawer';
import Notification from '../../../../../../../components/common/Notification';
import useGetSkillcapturebyid from '../../../../../../../hooks/useSkillcapturebyid';
import Modal from '../../../../../../../components/common/Modal';


function ScheduleHrInterview({ toggleInterview }) {
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const { teamsUsers, teamsUsersLoading, teamsUsersSuccess } = useTeamsUsers();
  const [idToDelete, setIdToDelete] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [keyskills, setKeyskills] = useState();

  const candId = useSelector(selectCandidateInterview);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [combinedInterviews, setCombinedInterviews] = useState([]);


  const [removeCandInterviewer] = useDeleteInterviewerMutation();

  const [scheduleInterview, { isLoading: loadingSchedule }] =
    useScheduleInterviewMutation();

  const [addScheduleInterview, { isLoading: loadingAddSchedule }] =
    useAddScheduleInterviewMutation();

  const [reScheduleInterview, { isLoading: loadingReSchedule }] =
    useInterviewRescheduleMutation();

  const [updateSkillsData] = useSkillsUpdateMutation();
  const {
    data: skillslist,
    error,
    isLoading: skillsLoading,
    isFetching,
    isSuccess: skillsSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  const getDateTimeValues = (dateTimeArr) => {
    // console.log(dateTimeArr)
    setSelectedDates(dateTimeArr);
  };

  const {
    data: interviewCandDetails,
    error: interviewCandDetailsErr,
    isLoading: interviewCandDetailsLoding,
    isSuccess: interviewCandDetailsSuccess,
  } = useGetInterviewCandDetailsQuery(candId, { refetchOnMountOrArgChange: true });
  console.log(interviewCandDetails)

  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useGetCandInterviewListDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });
  console.log(canddetails)
  const { skillcapturebyid, loadingSkillcapturebyid, successSkillcapturebyid } =
    useGetSkillcapturebyid(canddetails?.job_request_id);

  const interviewData = canddetails?.data?.interview;
  // const hasReschduleCurrent = interviewData?.some((interview) => interview.status === -1);

  // console.log(interviewData)

  useEffect(() => {
    if (canddetails?.data.interview.length > 0) {
      if (canddetails?.data?.interview[0]?.interview_recommendation != null) {
        setSelectedIds([]);
      } else {
        const evaluationSkill =
          canddetails?.data?.interview[0]?.evaluation_skill;
        setSelectedIds(evaluationSkill !== null ? evaluationSkill : []);
        setKeyskills(evaluationSkill);
      }

      if (canddetails?.data?.interview[0]?.status == 0) {
        // console.log(canddetails?.data?.interview)
        setSelectedDates(
          []
        );
      }
      else if (canddetails?.data?.interview[0]?.status != -1) {
        const slotAvailablity =
          canddetails?.data?.interview[0].slot_availablity;
        setSelectedDates(
          Array.isArray(slotAvailablity)
            ?
            slotAvailablity?.map((item, index) => ({
              id: index + 1,
              interview_time_end: item.interview_time_end,
              interview_time_start: item.interview_time_start,
              removable: index !== 0,
            }))
            : []
        );
      }


    }
  }, [isSuccess]);


  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };

  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const onModalDataSubmit = (e) => {
    e.preventDefault();
    // showNotificationMsg();
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  // function getNextInterviewLevel(interview, interview_level) {
  //   let nextStage = null;
  //   // console.log(interview, interview_level)

  //   if (interview.length === 0) {
  //     // If there are no interviews yet, return the first interview level
  //     nextStage = interview_level[0];
  //   } else {
  //     const interviewerStatus = interview.map(int => int.status); // Get all interview statuses
  //     // console.log(interviewerStatus)
  //     // Check if all statuses in the current stage are 5 (completed)
  //     const allCompleted = interviewerStatus.every(status => status === 5);
  //     const somePending = interviewerStatus.some(status => (status == 1 || status == 0 || status == 2));

  //     // Get the last interview level based on the highest `sno`
  //     const lastLevel = interview_level.reduce((prev, curr) =>
  //       (prev.sno > curr.sno ? prev : curr)
  //     );

  //     // If all interviewers in the current stage are completed
  //     if (allCompleted) {
  //       // Move to the next stage if there is one
  //       const currentStage = interview[0].interview_stage;
  //       const nextLevel = interview_level.find(level => level.sno > currentStage.sno);

  //       if (nextLevel) {
  //         nextStage = nextLevel; // Move to next stage
  //       } else {
  //         nextStage = []; // No more stages, interviews are fully completed
  //       }
  //     }
  //     // If some interviewers have a pending status (1), stay in the current stage
  //     else if (somePending) {
  //       // console.log(interview, interview_level)
  //       nextStage = interview[0].interview_stage; // Stay on the same level
  //     }
  //   }

  //   return nextStage || [];
  // }
  function getNextInterviewLevel(interview, interview_level) {
    let nextStage = null;
    console.log(interview, interview_level)

    if (interview.length === 0) {
      // If there are no interviews yet, return the first interview level
      nextStage = interview_level[0];
    } else {
      const interviewerRecommendation = interview.map(int => int.interview_recommendation); // Get all interview statuses
      console.log(interviewerRecommendation)

      const need2ndOpenion = interviewerRecommendation.some(status => (status == 3));

      const interviewerStatus = interview.map(int => int.status); // Get all interview statuses
      console.log(interviewerStatus)
      // Check if all statuses in the current stage are 5 (completed)
      const allCompleted = interviewerStatus.every(status => status === 5);
      const somePending = interviewerStatus.some(status => (status == 1 || status == 0 || status == 2));

      // Get the last interview level based on the highest `sno`
      const lastLevel = interview_level.reduce((prev, curr) =>
        (prev.sno > curr.sno ? prev : curr)
      );

      if (!need2ndOpenion) {
        // If all interviewers in the current stage are completed
        if (allCompleted) {
          // Move to the next stage if there is one
          const currentStage = interview[0].interview_stage;
          const nextLevel = interview_level.find(level => level.sno > currentStage.sno);

          if (nextLevel) {
            nextStage = nextLevel; // Move to next stage
          } else {
            nextStage = []; // No more stages, interviews are fully completed
          }
        }
        // If some interviewers have a pending status (1), stay in the current stage
        else if (somePending) {
          console.log(interview, interview_level)
          nextStage = interview[0].interview_stage; // Stay on the same level
        }
      }
      else {
        console.log(interview, interview_level)
        nextStage = interview[0].interview_stage; // Stay on the same level
      }
    }

    return nextStage || [];
  }

  // Example usage with provided data
  const interview = interviewCandDetails.data.interview;
  const interview_level = interviewCandDetails.data.interview_level;
  // console.log(interview_level);
  let selectedInterViewLevel = getNextInterviewLevel(interview, interview_level);

  // console.log(selectedInterViewLevel);

  if (Array.isArray(selectedInterViewLevel) && selectedInterViewLevel.length === 0) {
    console.log("Interview process is completed.");
  } else {
    console.log("Next interview stage:", selectedInterViewLevel);
  }

  let initialValues;
  if (interviewCandDetails?.data?.interview[0]?.status == 5) {
    initialValues = {
      id: 0,
      candid: candId,
      level: selectedInterViewLevel?.id || 1,
      evaluation_skill: selectedIds?.length > 0 ? selectedIds : [],
      interviewer: [],
      interview_date: '',
      slot_availablity: selectedDates,
      interview_mode: '',
      interview_detail: '',
      send_reminder: ['Interviewer', 'Candidate', 'Scheduler'],
      comment: '',
      // feedback: selectedInterViewLevel?.comment || '',
    };
  } else {
    initialValues = {
      id: canddetails?.data?.interview[0]?.id || 0,
      candid: candId,
      level: selectedInterViewLevel?.id || 1,
      evaluation_skill: selectedIds?.length > 0 ? selectedIds : [],
      // interviewer: canddetails?.data?.interview[0]?.interviewer || 0,
      interviewers: [],
      interview_date: '',
      slot_availablity: selectedDates,
      interview_mode: canddetails?.data?.interview[0]?.interview_mode || '',
      interview_detail: canddetails?.data?.interview[0]?.interview_detail || '',
      send_reminder: ['Interviewer', 'Candidate', 'Scheduler'],
      comment: canddetails?.data?.interview[0]?.comment || '',
      // feedback: selectedInterViewLevel?.comment || '',
    };
  }
  console.log('initialValues', initialValues);
  const validationSchema = yup.object({
    evaluation_skill: yup.array().min(1, 'Please select atleast one skill'),
    slot_availablity: yup.array().min(1, 'Please select atleast one slot'),
    // interviewer: yup.string().required('Please enter Interviewer'),

    interview_mode: yup.string().required('Please select Interview mode '),
    interview_detail: yup.string().required('Please enter Interview Details'),
    comment: yup.string().required('Please enter feedback'),
  });


  const onSubmit = async (values) => {
    // console.log('Result is', values);
    try {
      const interviewerDetails = canddetails?.data.interview
      // console.log("Update : ", interviewerDetails)
      if (interviewerDetails.length > 0) { // Update record
        for (const interviewer of interviewerDetails) {
          // console.log('Result is', interviewer.interviewer)
          const interviewData = {
            id: interviewer.id,
            candid: values.candid,
            level: values.level,
            evaluation_skill: values.evaluation_skill,
            interviewer: interviewer.interviewer,
            interview_date: values.interview_date,
            slot_availablity: values.slot_availablity,
            interview_mode: values.interview_mode,
            interview_detail: values.interview_detail,
            send_reminder: values.send_reminder,
            comment: values.comment,
            status: interviewer.status
          };
          // console.log("interviewData : ", interviewData)
          const result = await scheduleInterview(interviewData).unwrap();
          console.log(result)
        }
      }
      else {
        // console.log("new", values.interviewers)
        for (const interviewer of values.interviewers) { //add new record
          // console.log('Result is', values.evaluation_skill)
          const interviewData = {
            id: values.id,
            candid: values.candid,
            level: values.level,
            evaluation_skill: values.evaluation_skill,
            interviewer: interviewer.id,
            interview_date: values.interview_date,
            slot_availablity: values.slot_availablity,
            interview_mode: values.interview_mode,
            interview_detail: values.interview_detail,
            send_reminder: values.send_reminder,
            comment: values.comment,
            status: interviewer.status
          };
          // console.log("interviewData : ", interviewData)
          const result = await scheduleInterview(interviewData).unwrap();
          console.log(result)
        }
      }
      setShowNotification(true);
      toggleInterview();
      // onClose();--
    } catch (error) { }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const getSkillName = (skillid) => {
    const skillName = skillslist?.data.find((element) => element.id == skillid);
    return skillName?.name;
  };

  const updateLocalSkillsId = (selId) => {
    const isSelected = selectedIds?.includes(selId);
    if (isSelected) {
      setSelectedIds(selectedIds?.filter((selectedId) => selectedId !== selId));
    } else {
      setSelectedIds([...selectedIds, selId]);
    }
  };

  const getStatusName = (statusId) => {
    // console.log(statusId)
    if (statusId === 0) {
      return (
        <span className="bg-gray-400 text-black px-6 py-2 block w-full text-center">
          Yet to accept
        </span>
      );
    } else if (statusId === 1) {
      return (
        <span className="bg-blue-700 px-6 py-2 text-white block w-full text-center">
          Scheduled for Hr Interview - {statusId}
        </span>
      );
    } else if (statusId === 2) {
      return (
        <span className="bg-green-700 px-6 py-2 text-white block w-full text-center">
          Interview Accepted - {statusId}
        </span>
      );
    } else if (statusId === 3) {
      return (
        <span className="bg-red-600 px-3 py-2 text-white block w-full text-center">
          Interview rejected - {statusId}
        </span>
      );
    } else if (statusId === 4) {
      return (
        <span className="bg-purple-600 px-3 py-2 text-white block w-full text-center">
          Query raised by interviewer - {statusId}
        </span>
      );
    } else if (statusId === -1) {
      return (
        <span className="bg-orange-600 px-3 py-2 text-white block w-full text-center">
          Re-scheduled Request - {statusId}
        </span>
      );
    } else if (statusId === 5) {
      return (
        <span className="bg-green-300 px-3 py-2 text-black block w-full text-center">
          Interview completed and start next Interview - {statusId}
        </span>
      );
    } else {
      return (
        <span className="bg-gray-400 text-black px-6 py-2 block w-full text-center">
          Yet to accept
        </span>
      );
    }
  };
  const checkboxOptions = [
    { value: 'Interviewer', label: 'Interviewer' },
    { value: 'Candidate', label: 'Candidate' },
    { value: 'Scheduler', label: 'Scheduler' },
  ];

  const handleCheckboxChange = (value) => {
    const isChecked = formik.values.send_reminder.includes(value);

    if (isChecked) {
      formik.setValues({
        ...formik.values,
        send_reminder: formik.values.send_reminder.filter((v) => v !== value),
      });
    } else {
      formik.setValues({
        ...formik.values,
        send_reminder: [...formik.values.send_reminder, value],
      });
    }
  };

  // **** added by tist

  const handleAddInterviewer = async (e) => {
    // console.log(initialValues)
    // e.preventDefault();
    // console.log(formik.values.interviewers)
    // console.log('teamsUsers.data:', teamsUsers.data);
    // Ensure formik.values.interviewer is a number
    const interviewerId = Number(formik.values.interviewer);

    // Log the interviewerId after conversion
    // console.log('Converted Interviewer ID:', interviewerId);

    const selectedUser = teamsUsers.data.find(
      (user) =>
        user.id === interviewerId
    );

    // Initialize formik.values.interviewers if it is undefined or not an array
    if (!Array.isArray(formik.values.interviewers)) {
      formik.setFieldValue('interviewers', []);
      formik.values.interviewers = [];
    }

    if (selectedUser) {
      const interviewers = formik.values.interviewers || [];
      if (!interviewers.some((u) => u.id === selectedUser.id)) {
        try {
          const updatedInterviewers = [...formik.values.interviewers, selectedUser];
          formik.setFieldValue('interviewers', updatedInterviewers);
          // console.log('Updated Interviewers:', updatedInterviewers);
          if (initialValues.id) {
            for (const interviewer of updatedInterviewers) {
              const interviewData = {
                id: initialValues.id,
                candid: initialValues.candid,
                level: initialValues.level,
                evaluation_skill: initialValues.evaluation_skill,
                interviewer: interviewer.id,
                interview_date: initialValues.interview_date,
                slot_availablity: initialValues.slot_availablity,
                interview_mode: initialValues.interview_mode,
                interview_detail: initialValues.interview_detail,
                send_reminder: initialValues.send_reminder,
                comment: initialValues.comment,
              };
              // console.log("interviewData : ", interviewData)
              const result = await addScheduleInterview(interviewData).unwrap();
              console.log("result : ", result)
            }
          }

        } catch (error) {
          console.log('error ', error, error?.status, error?.data?.message);
        }
      }
      else {
        console.log('User is already in the interviewers list:', selectedUser);
      }
    }
  }


  const handleRemoveInterviewer = (index) => {
    // console.log("handleRemoveInterviewer :", index)
    const updatedInterviewers = [...formik.values.interviewers];
    updatedInterviewers.splice(index, 1);
    formik.setFieldValue('interviewers', updatedInterviewers);
    // setSelectedInterviewers((prev) => prev.filter((user) => user.id !== id));
  };

  const handleRemoveApiInterviewer = async (id, index) => {
    // console.log("handleRemoveApiInterviewer :", id)
    try {
      const removeCandInterviewerResponse = await removeCandInterviewer(id).unwrap();
      console.log("removeCandInterviewerResponse : ", removeCandInterviewerResponse.message)
      const updatedInterviewers = [...formik.values.interviewers];
      updatedInterviewers.splice(index, 1);
      formik.setFieldValue('interviewers', updatedInterviewers);
      setShowNotification(true);
      toggleInterview();
    } catch (error) {
      console.log('error ', error);
    }
  }

  const formatDateTime = (date, startTime, endTime) => {
    // Combine date and start time to create a Date object
    const startDateTime = new Date(`${date}T${startTime}`);
    // Use toLocaleString to format date and time
    const formattedDate = startDateTime.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedDate;
  };

  // code for - tist
  const openModalWindow = (id) => {
    setIdToDelete(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    // console.log(formik.values.evaluation_skill,idToDelete)

    const updatedSkills = formik.values.evaluation_skill.filter(
      (id) => id !== idToDelete
    );
    // Update the formik values with the new skills array
    formik.setFieldValue('evaluation_skill', updatedSkills);
    setSelectedIds(updatedSkills)
    // console.log(updatedSkills)

    try {
      if (initialValues.id) {
        // Loop through each interview record in the array
        canddetails.data.interview.forEach(async (interview) => {
          let { profile_id, interviewer, evaluation_skill } = interview;

          // Proceed only if evaluation_skill is not empty
          if (evaluation_skill && evaluation_skill.length > 0) {
            evaluation_skill = updatedSkills
            // Create the values object for each interview
            const values = {
              evaluation_skill,
              id: profile_id, // Assuming 'id' refers to 'profile_id'
              interviewid: interviewer,
            };

            // console.log('values:', values);

            // Perform the mutation for each interview record
            try {
              const updateCandidateData = await updateSkillsData(values).unwrap();
              console.log('Update successful for:', updateCandidateData);
            } catch (error) {
              console.error('Error updating candidate data for interview:', interview, error);
            }
          } else {
            console.log('Skipping interview due to empty evaluation_skill:', interview);
          }
        });
      }
      // showNotificationMsg();
      closeModalWindow();
    } catch (error) { }
  };

  useEffect(() => {
    const filteredInterviewers = formik.values.interviewers?.filter(interviewer => {
      const interviewerExists = canddetails?.data?.interview.some(existingInterviewer =>
        existingInterviewer.interviewer === interviewer.id
      );
      return !interviewerExists;
    }) || [];

    // Ensure that combinedInterviews is always an array
    setCombinedInterviews([
      ...(canddetails?.data?.interview || []),
      ...filteredInterviewers
    ]);


  }, [canddetails?.data?.interview, formik.values.interviewers]);


  const onReschedule = async (interviewIndex) => {
    // console.log("re schedule")
    const interviewWithStatusNegativeOne = interviewData?.find(interview => interview.status === -1);
    if (interviewWithStatusNegativeOne) {
      const { interview_date, interview_time_start, interview_time_end } = interviewWithStatusNegativeOne;
      setSelectedDates([
        {
          id: 0,
          interview_time_end: new Date(
            `${interview_date} ${interview_time_end}`
          ),
          interview_time_start: new Date(
            `${interview_date} ${interview_time_start}`
          ),
          removable: false,
        },
      ]);

      const interviewerDetails = canddetails?.data.interview
      // console.log("Update : ", interviewerDetails)
      if (interviewerDetails.length > 0) { // Update record
        for (const interviewer of interviewerDetails) {
          // console.log('Result is', interviewer.interviewer)
          // console.log(interviewer.id)
          const data = {
            interviewid: interviewer.id
          }
          const result = await reScheduleInterview(data).unwrap();
          // console.log(result)
        }
      }
    }
    else {
      setSelectedDates([
        {
          id: 1,
          interview_time_start: null,
          interview_time_end: null,
          removable: false,
        },
      ])
    }
  }
  // console.log("Combined Interview List:", combinedInterviews);

  if (isSuccess) {
    return (
      <>

        {Array.isArray(selectedInterViewLevel) && selectedInterViewLevel.length === 0 ? (
          <div className="text-center text-green-600 font-bold bg-green-100 p-2 rounded">
            All Interviews completed
          </div>
        ) : (

          <form onSubmit={formik.handleSubmit}>
            <div>{getStatusName(canddetails?.data?.interview[0]?.status)}</div>

            <div className="flex ">
              <div className=" bg-gray-200 py-4 rounded-lg w-full">
                <div className="flex px-5 pb-3 items-center justify-between">
                  <div className="flex">
                    <span className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold">
                      {selectedInterViewLevel?.sno}
                    </span>
                    <h2 className="self-center pl-3 flex justify-between ">
                      {selectedInterViewLevel?.title} - HR
                    </h2>
                  </div>

                  <div className="flex">
                    <Link
                      type="button"
                      to={''}
                      className="flex mr-2 justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() => openDrawerWindow()}
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Add
                    </Link>
                    <Link
                      type="button"
                      to={''}
                      className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Add',
                          title: 'Add Key Skill',
                          data: formik.values.evaluation_skill,
                        })
                      }
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Add Skill
                    </Link>
                  </div>
                </div>
                <div className="bg-white rounded-md shadow-3xl border border-gray-100 mx-5">
                  <div className=" py-5 px-5">
                    {Array.isArray(formik.values.evaluation_skill) &&
                      formik.values.evaluation_skill.map((skillsId) => (
                        <span
                          key={skillsId}
                          className="inline-flex mr-4 items-left rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black"
                        >
                          {getSkillName(skillsId)}
                          <XCircleIcon
                            className="h-6 w-6 text-gray-900 ml-3"
                            aria-hidden="true"
                            onClick={() => {
                              openModalWindow(skillsId);
                            }}
                          />
                        </span>
                      ))}
                  </div>
                  {formik.touched.evaluation_skill &&
                    formik.errors.evaluation_skill ? (
                    <div className="mt-2 text-sm w-full text-red-600">
                      {formik.errors.evaluation_skill}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='flex justify-between items-center'>
              <DateRangePicker
                data={selectedDates}
                addDateTimeArray={getDateTimeValues}
              />
              {formik.touched.slot_availablity &&
                formik.errors.slot_availablity ? (
                <div className="mt-2 text-sm w-full text-red-600">
                  {formik.errors.slot_availablity}
                </div>
              ) : null}

            </div>

            <div className="mt-6 grid grid-cols-2 gap-4 px-0">
              <div className="">
                <label
                  htmlFor="interviewer"
                  className="block text-sm font-medium text-gray-900"
                >
                  Interviewer
                </label>
                <div className='flex ustify-start items-center mb-2'>
                  <select
                    name="interviewer"
                    id="interviewer"
                    autoComplete="interviewer"
                    className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  
                    py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm mr-10"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interviewer}
                  >
                    <option value="">-- Select interviewer --</option>
                    {teamsUsers?.data
                      .filter((user) => user.employee_type === 'Interviewer')
                      .map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.first_name}
                        </option>
                      ))}
                  </select>
                  <button
                    type="button"
                    onClick={handleAddInterviewer}
                    className="inline-flex rounded-xl border border-transparent mr-2 bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                  >
                    Add
                  </button>
                </div>
                {formik.touched.interviewer && formik.errors.interviewer ? (
                  <div className="mt-2 text-sm w-full text-red-600">
                    {formik.errors.interviewer}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-6">
              <div className="inline-block min-w-full py-0 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className='bg-gray-200 '>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 w-[20px] pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 w-[20px] text-center text-sm font-semibold text-gray-900"
                      >
                        Acceptance Status
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 w-[20px] text-center text-sm font-semibold text-gray-900"
                      >
                        Scheduling Time
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 w-[20px] text-center text-sm font-semibold text-gray-900"
                      >
                        Re-scheduling Time
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 w-[50px] text-center text-sm font-semibold text-gray-900"
                      >
                        Action
                      </th>

                    </tr>
                  </thead>
                  {canddetails?.data ? (
                    <tbody className="divide-y divide-gray-200">
                      {(combinedInterviews?.length > 0) ? (
                        combinedInterviews.map((interview, interviewIndex) => (
                          <tr key={interviewIndex}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-green-600 sm:pl-0">
                              {interview.interviewer_name || `${interview.first_name}`}
                            </td>
                            <td className="whitespace-nowrap py-4 text-sm text-green-600">
                              {getStatusName(interview.status || (interview.current_interview && interview.current_interview[0]?.status) || 0)}
                            </td>
                            <td className="whitespace-nowrap py-4 text-sm text-center text-green-600">
                              {interview.interview_approval_status === 1 ? (
                                formatDateTime(interview.interview_date, interview.interview_time_start)
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="whitespace-nowrap py-4 text-sm text-center text-green-600">
                              {interview.status == -1 ? (
                                formatDateTime(interview.interview_date, interview.interview_time_start)
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="relative flex items-center text-center whitespace-nowrap py-6 text-sm text-red-600 ">
                              <button
                                type="button"
                                onClick={() => {
                                  if (formik.values.interviewers && formik.values.interviewers.length > 0) {
                                    // If the interviewer doesn't have an id, call handleRemoveInterviewer (from formik values)
                                    handleRemoveInterviewer(interviewIndex);
                                  } else {
                                    // If the interviewer has an id (from API data), call handleRemoveApiInterviewer
                                    handleRemoveApiInterviewer(interview.id, interviewIndex);
                                  }
                                }}
                                className="text-sm font-medium"
                              >
                                Remove
                              </button>

                              {interview.status === -1 ? (
                                <button
                                  type="button"
                                  className="text-sm font-medium pr-2"
                                  // className="inline-flex rounded-xl -mt-10 border border-transparent mr-2 bg-red-600 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none "
                                  onClick={() => onReschedule(interviewIndex)}
                                >
                                  /  Re-Schedule
                                </button>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center py-4 text-red-600">No candidate available</td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="3" className='text-center py-4 text-red-600'>No candidate details available</td>
                      </tr>
                    </tbody>
                  )}

                  {/* <tbody className="divide-y divide-gray-200">
                    {formik.values.interviewers.length > 0 ? (
                      formik.values.interviewers.map((interviewer, index) => (
                        <tr key={index}>

                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-green-600 sm:pl-0">
                            {interviewer.first_name} {interviewer.last_name}
                          </td>
                          <td className="whitespace-nowrap  py-4 text-sm text-green-600 ">
                            {getStatusName(
                              (interviewer.current_interview && interviewer.current_interview[0]?.status) || 0
                            )}
                          </td>
                          <td className="whitespace-nowrap  py-4 text-sm text-red-600">
                            <button
                              type="button"
                              onClick={() => handleRemoveInterviewer(index)}
                              className="flex rounded-3xl text-sm font-medium"
                            >
                              Remove
                            </button>

                          </td>

                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className='text-center py-4 text-red-600'>No available Interviewer</td>
                      </tr>
                    )}
                  </tbody> */}
                </table>

              </div>
            </div>



            <div className="flex">
              <div className="w-3/4 ">
                <div className=" gap-4 mx-0 rounded-lg py-4">
                  <div className=" grid grid-cols-3 gap-6 px-0">
                    <div className="">
                      <label
                        htmlFor="interview_mode"
                        className="block text-sm font-semibold text-gray-900"
                      >
                        Mode of Interview
                      </label>

                      <select
                        name="interview_mode"
                        id="interview_mode"
                        autoComplete="interview_mode"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.interview_mode}
                      >
                        <option value="">-- Select Mode of Interview --</option>

                        <option>Face to Face</option>
                        <option>Video Call</option>
                      </select>

                      {formik.touched.interview_mode &&
                        formik.errors.interview_mode ? (
                        <div className="mt-2 text-sm w-full text-red-600">
                          {formik.errors.interview_mode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-3 grid grid-cols-2 gap-6 px-0">
                    <div className="">
                      <label
                        htmlFor="interview_detail"
                        className="block text-sm font-semibold text-gray-900"
                      >
                        Interview Details (Venue / Link)
                      </label>

                      <input
                        type="text"
                        name="interview_detail"
                        id="interview_detail"
                        autoComplete="interview_detail"
                        className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.interview_detail}
                      />

                      {formik.touched.interview_detail &&
                        formik.errors.interview_detail ? (
                        <div className="mt-2 text-sm w-full text-red-600">
                          {formik.errors.interview_detail}0
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" sm:grid sm:grchid-flow-row-dense sm:grid-cols-2  px-0">
              <div className="col-span-12 sm:col-span-2">
                <div className="col-span-2 ">
                  <label
                    htmlFor="interviewer"
                    className="block text-sm font-semibold text-gray-700 pb-2"
                  >
                    Reminder
                  </label>
                  <div className="flex h-6 items-center">
                    {checkboxOptions.map((option) => (
                      <>
                        <div className="mr-5 flex">
                          <div className="flex h-6 items-center ">
                            <input
                              id="interviewer"
                              aria-describedby="interviewer-description"
                              name="interviewer"
                              type="checkbox"
                              value={option.value}
                              checked={formik.values.send_reminder.includes(
                                option.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(option.value)
                              }
                              className="h-4 w-4 rounded-md bg-red-400 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label
                              htmlFor="interviewer"
                              className="font-medium text-gray-900"
                            >
                              {option.label}
                            </label>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-1/2 mt-3">
              <div className="mx-0">
                <label
                  htmlFor="comment"
                  className="block text-sm font-semibold text-gray-700 pb-2"
                >
                  Feedback
                </label>

                <textarea
                  rows={5}
                  name="comment"
                  id="comment"
                  className="bg-inputbg w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Candidate not pickinng phone"
                  // defaultValue={''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.interview_detail}
                />

                {formik.touched.comment && formik.errors.comment ? (
                  <div className="mt-2 text-sm w-full text-red-600">
                    {formik.errors.comment}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end">
              <div className=" py-0 mx-0">
                {/* {canddetails?.data?.interview[0]??.status != 1 && ( */}
                <button
                  type="submit"
                  className=" rounded-xl border bg-green-500 w-full py-2 px-10 text-sm font-medium text-white shadow-sm focus:outline-none "
                >
                  Schedule
                </button>
                {/* )} */}
              </div>
            </div>
          </form >

        )
        }

        <Notification
          isShow={showNotification}
          onClose={() => closeNotificationMsg()}
        />

        <Drawer
          isOpen={openDrawer}
          title={isEdit ? 'Edit Skill' : 'Add Skill'}
          // title={isEdit ? '' : 'Add Skill'}
          onClose={() => closeDrawerWindow()}
          onSubmit={() => onSubmit()}
        >
          <AddSkillnew
            data={currentValue}
            isEdit={isEdit}
            onClose={() => closeDrawerWindow()}
          />
        </Drawer>

        <ModalData
          isModalDataOpen={openModalData}
          onModalDataClose={closeModalDataWindow}
        >
          <AddKeySkill
            onModalDataClose={() => closeModalDataWindow()}
            onModalDataSubmit={() => onModalDataSubmit()}
            data={openModalDataValues}
            updateSkillsArray={updateLocalSkillsId}
          />
        </ModalData>

        <Modal
          isModalOpen={openModal}
          onClose={() => closeModalWindow()}
          onSubmit={() => onModalSubmit()}
        ></Modal>
      </>
    );
  }
}


export default ScheduleHrInterview;

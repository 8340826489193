import { apiSlice } from '../../app/api/apiSlice';

export const userSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    tagTypes: ['UserSettingsPriv'],
    userPrivSet: builder.query({
      query: () => ({
        url: `/settings/userworkspaceprivileges`,
        method: 'GET',
      }),
      // refetchOnMountOrArgChange: 600,
      keepUnusedDataFor: 5,
      providesTags: ['UserSettingsPriv'],
    }),
    addUserpriv: builder.mutation({
      query: (data) => ({
        url: '/settings/userworkspaceprivileges',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['UserSettingsPriv'],
    }),
    privStatus: builder.mutation({
      query: (data) => ({
        url: '/settings/userworkspaceprivileges/signoff',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['UserSettingsPriv'],
    }),
  }),
});

export const {
  useUserPrivSetQuery,
  useAddUserprivMutation,
  usePrivStatusMutation,
} = userSettingsApiSlice;

import { apiSlice } from "../../app/api/apiSlice";

export const manpowerrqmtApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["ManpowerRQMT"],
  endpoints: (builder) => ({
    manpowerrqmt: builder.query({
      query: () => ({
        url: "/master/manpowerrqmt",
        method: "GET",
      }),
      providesTags: ["ManpowerRQMT"],
      // keepUnusedDataFor: 5,
    }),
    addManpowerRQMT: builder.mutation({
      query: (data) => ({
        url: "/master/manpowerrqmt",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["ManpowerRQMT"],
    }),
    updateManpowerRQMT: builder.mutation({
      query: (data) => ({
        url: "/master/manpowerrqmt",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["ManpowerRQMT"],
    }),
    deleteManpowerRQMT: builder.mutation({
      query: (id) => ({
        url: `/master/manpowerrqmt/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManpowerRQMT"],
    }),
  }),
});

export const {
  useManpowerRQMTQuery,
  useAddManpowerRQMTMutation,
  useUpdateManpowerRQMTMutation,
  useDeleteManpowerRQMTMutation,
} = manpowerrqmtApiSlice;

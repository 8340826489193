import { apiSlice } from '../../app/api/apiSlice';

export const jobrequestApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Jobrequest', 'JobrequestApproval'],
  endpoints: (builder) => ({
    jobrequest: builder.query({
      query: () => ({
        url: '/workflow/jobrequest',
        method: 'GET',
      }),
      providesTags: ['Jobrequest'],
      keepUnusedDataFor: 5,
    }),
    jobrequestApproval: builder.query({
      query: () => ({
        url: '/workflow/jobrequest/approval',
        method: 'GET',
      }),
      providesTags: ['JobrequestApproval'],
      keepUnusedDataFor: 5,
    }),
    addJobrequest: builder.mutation({
      query: (data) => ({
        url: '/workflow/jobrequest',
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Jobrequest', 'JobrequestApproval'],
    }),

    updateJobrequest: builder.mutation({
      query: (data) => ({
        url: '/workflow/jobrequest',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Jobrequest', 'JobrequestApproval'],
    }),

    updateJobrequestApproval: builder.mutation({
      query: (data) => ({
        url: '/workflow/jobrequest/approval',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Jobrequest', 'JobrequestApproval'],
    }),

    deleteJobrequest: builder.mutation({
      query: (id) => ({
        url: `/workflow/jobrequest/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Jobrequest', 'JobrequestApproval'],
    }),
  }),
});

export const {
  useJobrequestQuery,
  useJobrequestApprovalQuery,
  useAddJobrequestMutation,
  useUpdateJobrequestMutation,
  useUpdateJobrequestApprovalMutation,
  useDeleteJobrequestMutation,
} = jobrequestApiSlice;

import { Fragment, useState } from 'react';

import Drawer from '../../../../components/common/Drawer';

import Notification from '../../../../components/common/Notification';

import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import Modal from '../../../../components/common/Modal';
import {
  useJobrequestQuery,
  useJobrequestApprovalQuery,
} from '../../../../features/manpower/jobrequestApiSlice';
import DateToIndiaFormat from '../../../../components/common/DateToIndiaFormat';

import { useBranchQuery } from '../../../../features/branch/branchApiSlice';
import { useCountryQuery } from '../../../../features/country/countryApiSlice';
import { useTeamsQuery } from '../../../../features/teams/teamsApiSlice';
import SOJobRequestDrawer from './SOJobRequestDrawer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SOJobRequest() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);

  const {
    data: jobrequest,
    error: jobrequestError,
    isLoading: jobrequestLoading,
    isFetching: jobrequestFetching,
    isSuccess: jobrequestSuccess,
  } = useJobrequestQuery({ refetchOnMountOrArgChange: true });

  const {
    data: jobrequestApproval,
    error: jobrequestApprovalError,
    isLoading: jobrequestApprovalLoading,
    isFetching: jobrequestApprovalFetching,
    isSuccess: jobrequestApprovalSuccess,
  } = useJobrequestApprovalQuery({ refetchOnMountOrArgChange: true });

  const {
    data: branchs,
    error: branchErr,
    isLoading: branchLoading,
    isFetching: branchFetching,
    isSuccess: branchSuccess,
  } = useBranchQuery({ refetchOnMountOrArgChange: true });

  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const {
    data: users,
    isLoading: userLoading,
    error: userError,
    isFetching: userFetching,
    isSuccess: userSuccess,
  } = useTeamsQuery({ refetchOnMountOrArgChange: true });

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const getBranchName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.name;
    }
  };

  const getLocationName = (branchid) => {
    if (branchSuccess) {
      const filterdata = branchs?.data.filter((branch) => {
        return branch.id == branchid;
      });
      return filterdata[0]?.location.name;
    }
  };

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    }
  };

  const getUsername = (id) => {
    if (userSuccess) {
      const userfilterbyid = users?.data.filter((user) => user.id == id);
      return userfilterbyid[0]?.first_name
        ? `${userfilterbyid[0]?.first_name} ${userfilterbyid[0]?.last_name}`
        : ' - ';
    }
  };
  return (
    <>
      {jobrequestApprovalLoading ? 'Loading...' : ''}
      {jobrequestApprovalSuccess ? (
        <div className="flex h-full flex-col">
          <div className="px-4 sm:px-6 lg:px-0 ">
            <div className="mt-3 flex flex-col px-6">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-2">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Branch
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 border-r-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Location
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Country
                          </th>

                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Positions
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Budget
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Created Date
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Deadline
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Requested by
                          </th>

                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Approval Status
                          </th>

                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Approval By
                          </th>

                          {/* <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Priority
                          </th> */}

                          {/* <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Rq Date
                          </th> */}
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Action
                          </th>
                          {/* <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left border-r-2 text-sm font-semibold text-gray-900"
                          ></th> */}
                        </tr>
                      </thead>
                      {jobrequestApproval?.data.length > 0 ? (
                        <tbody className="divide-y divide-gray-200 bg-white ">
                          {jobrequestApproval?.data?.map((jr) => (
                            <tr>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 border-r-2">
                                {jr.title}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 border-r-2">
                                {getBranchName(jr.branch_id)}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 border-r-2">
                                {getLocationName(jr.branch_id)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                                {getCountryName(jr.country_id)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                {jr.no_of_position}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {jr.budget}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {DateToIndiaFormat(jr.createdAt)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {DateToIndiaFormat(jr.closing_date)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {getUsername(jr.createdBy)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {displayStatus(jr.approval_status)}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                {getUsername(jr.approved_by)}
                              </td>
                              {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                              {jr.approval_status}
                            </td> */}

                              <td className="relative flex items-center justify-center whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                {/* <a href="#" title="View"> */}
                                <EyeIcon
                                  className="ml-1 mr-2 h-5 w-5 text-purple-500 "
                                  aria-hidden="true"
                                  onClick={() =>
                                    openDrawerWindow('edit', {
                                      id: jr.id,
                                      position: jr.no_of_position,
                                      branch: getBranchName(jr.branch_id),
                                      location: getLocationName(jr.branch_id),
                                      requestedBy: getUsername(jr.createdBy),
                                    })
                                  }
                                />
                                {/* </a> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody className="bg-white p-2">
                          <tr>
                            <td
                              className="p-2 text-red-600 font-bold"
                              colSpan={12}
                            >
                              No Data
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <pre>
            {JSON.stringify(
              JSON.parse(JSON.stringify(jobrequestApproval?.data)),
              null,
              2
            )}
          </pre> */}
        </div>
      ) : (
        ''
      )}
      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
      <Drawer
        isOpen={openDrawer}
        // title={isEdit ? "Edit Location" : "My Task"}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        <SOJobRequestDrawer
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        // onSubmit={() => onModalSubmit(deptId)}
      ></Modal>
    </>
  );
}

import React, { useState } from 'react';
import Notification from '../../components/common/Notification';

import { useUpdatetaskStatusMutation } from '../../features/workflow/workflowApiSlice';
import { toast } from 'sonner';
import { getStatusToToast } from '../utils/Utils';
const SignOffWorkflow = ({ data, isEdit, onClose }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [updatetaskStatus, { isLoading }] = useUpdatetaskStatusMutation();
  console.log(data.hiringStageDetails)
  const updateStatus = async (approveStatus) => {
    const hiringStageid = 'hiring_Stage_id';
    const hiringStateData = data.hiringStageDetails.map((hsd) => ({
      id: hsd.id,
      hiringStageid: hsd.hiringStageId,
      taskUsers: hsd.taskUsers?.map((value) => ({
        id: value.user_id,
        approval_status: approveStatus,
        comments: message,
      })),
    }));

    try {
      const statusData = {
        workflow_id: data.id,
        hiring_stages: hiringStateData,
      };

      const updateSFStatus = await updatetaskStatus(statusData).unwrap();

      setMessage('');
      // setShowNotification(!showNotification);
      getStatusToToast(approveStatus, 'Workflow');
      onClose();
    } catch (error) { }
  };
  console.log("123344 - ", data)

  // Function to split interview stages
  const splitHiringStages = (data) => {
    console.log("data.hiringStageDetails - ", data.hiringStageDetails);

    // Ensure data.hiringStageDetails is an array
    if (!Array.isArray(data.hiringStageDetails)) {
      console.error("hiringStageDetails is not an array or is undefined.");
      return [];
    }

    return data.hiringStageDetails.flatMap((stage) => {
      if (stage.hiringStageTitle === "Interview" && Array.isArray(stage.interviewDetails)) {
        // Split interview stages
        return stage.interviewDetails.map((interview) => ({
          id: interview.workflow_hiring_stage_id,
          hiringStageId: data.id,
          taskDuration: interview.task_duration?.toString() || '',
          signoff_duration: interview.signoff_duration?.toString() || '',
          hiringStageTitle: `${interview.title} - Interviewer`,
          signOffUsers: Array.isArray(interview.signOffUsers)
            ? interview.signOffUsers.map((user) => ({
              id: user.user_id,
              approval_status: 1,
              comments: null,
              approved_by: interview.createdBy,
              user_id: user.user_id,
              first_name: user.first_name,
              last_name: user.last_name,
            }))
            : [], // Return an empty array if signOffUsers is not an array
          taskUsers: Array.isArray(interview.taskUsers)
            ? interview.taskUsers.map((user) => ({
              id: user.user_id,
              approval_status: 1,
              comments: null,
              approved_by: interview.createdBy?.toString() || '',
              user_id: user.user_id,
              first_name: user.first_name,
              last_name: user.last_name,
            }))
            : [], // Return an empty array if taskUsers is not an array
        }));
      } else {
        // Keep the stage as is
        return {
          id: stage.id,
          hiringStageId: stage.hiringStageId,
          taskDuration: stage.taskDuration,
          signoff_duration: stage.signoff_duration,
          hiringStageTitle: stage.hiringStageTitle,
          signOffUsers: Array.isArray(stage.signOffUsers) ? stage.signOffUsers : [],
          taskUsers: Array.isArray(stage.taskUsers) ? stage.taskUsers : [],
        };
      }
    });
  };

  const transformedData = splitHiringStages(data);
  console.log(transformedData);

  return (
    <>
      <Notification isShow={showNotification} />
      <div className="flex justify-between  border-gray-200">
        <div className=" items-center">
          <h4 className="text-lg text-sidebar font-semibold">
            {data.designation}
          </h4>
          <h5 className="mb-3 text-sm font-normal tracking-tight text-gray-500">
            {data.branch}
          </h5>
        </div>
      </div>
      <div className="overflow-y-auto h-96">
        {transformedData.map((task) => (
          <div className=" bg-workflow_drawerbg px-4 py-3 rounded-lg">
            <div className="flex justify-between">
              <div className=" items-center">
                <h4 className="text-md text-black font-semibold">
                  {task.hiringStageTitle}
                </h4>
              </div>
              <div>
                <p className="flex w-full text-sm font-medium text-sidebar">
                  Work Duration:
                  <span className=" text-sm font-medium text-sidebar">
                    {task.taskDuration} day
                  </span>
                </p>
              </div>
            </div>

            <div className="flex justify-start overflow-x-auto ">
              {task.taskUsers?.map((taskuser) => (
                <div className="bg-white py-1 px-4 my-3  border border-gray-300 rounded-2xl mx-0 shadow-md mr-3 ">
                  <h5 className="text-sm font-medium tracking-tight text-blue-500 truncate whitespace-nowrap overflow-hidden w-28">
                    {taskuser.first_name} {taskuser.last_name}
                  </h5>
                  <p className="text-xs text-black tracking-tight ">
                    HR Manager
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="space-y-1 pt-5">
        <h5 className="mb-2 text-sm font-normal tracking-tight text-gray-900">
          Notes *
        </h5>
        <label htmlFor="description" className="sr-only">
          Notes
        </label>
        <textarea
          rows={4}
          name="notes"
          id="notes"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          placeholder="Query"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        // defaultValue={''}
        />

        <div className="flex flex-shrink-0 justify-between py-4">
          <button
            type="button"
            className=" rounded-lg  bg-queryBlue text-white py-1 px-6 mr-3 text-sm font-medium  shadow-sm focus:outline-none "
            onClick={() => updateStatus(2)}
          >
            Query
          </button>
          <button
            type="button"
            className=" rounded-lg border border-red-600 bg-red-600 py-1 px-6 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
            onClick={() => updateStatus(3)}
          >
            Reject
          </button>
          {/* {isLoading ? (
              <button
                type="submit"
                disabled
                className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm focus:outline-none"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
            ) : ( */}
          <button
            type="submit"
            className="inline-flex justify-center rounded-lg border border-transparent bg-approve py-1 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
            onClick={() => updateStatus(1)}
          >
            Approve
          </button>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default SignOffWorkflow;

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

import { AddEmployment } from '../modal/AddEmployment';

import ModalData from '../../../../../../components/common/ModalData';
import {
  useGetCandDetailsQuery,
  useDeleteEmploymentMutation,
} from '../../../../../../features/hiringstages/sourcingApiSlice';
import useCandidate from '../../../../../../hooks/useCandidate';
import Modal from '../../../../../../components/common/Modal';
import Notification from '../../../../../../components/common/Notification';
import DateToIndiaFormat from '../../../../../../components/common/DateToIndiaFormat';

const CandEmployment = ({ gotoAddSourcing, showNotificationMsg }) => {
  const initialArr =
    useSelector((state) => state.candidatedetails.employments) || [];
  const candiid = useSelector((state) => state.candidatedetails?.id) || [];

  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);

  const canddetailsData = canddetails?.data.employments.map((cand) => cand);

  const [deleteEmployment, { isCandLoading }] = useDeleteEmploymentMutation();

  const [employents, setEmployents] = useState(initialArr);
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [deptId, setDeptId] = useState(null);

  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();

  const openModalDataWindow = (sendtoModal) => {
    // setManpowerId(id);
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(!openModalData);
  };
  const onModalDataSubmit = (e) => {
    e.preventDefault();
    showNotificationMsg();
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const onModalSubmit = async () => {
    try {
      const deleEmp = await deleteEmployment(deptId).unwrap();
      showNotificationMsg();
      closeModalWindow();
    } catch (error) {}

    // showNotificationMsg();
  };

  return (
    <>
      <div className="flex justify-between px-6 pt-2 items-center">
        <h1 className="text-2xl font-bold">Employment List</h1>
        <Link
          type="button"
          to={''}
          className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
          onClick={() =>
            openModalDataWindow({
              mode: 'Add',
              title: 'Create Employment',
            })
          }
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
          Create Employment
        </Link>
      </div>
      {canddetails?.data.employments.length > 0 ? (
        <>
          {canddetails?.data.employments.map((employent) => (
            <div className="border-t border-gray-200" key={employent.id}>
              <div className="flex justify-between px-6 py-5">
                <div className="pt-0">
                  <h4 className="font-semibold text-2xl text-blue-700">
                    {employent.designation}
                  </h4>
                  <h6 className="font-medium text-xl text-red-500 pb-2">
                    {employent.companyName}
                  </h6>
                  <h6 className="font-medium text-md text-gray-600 pt-1 pb-3">
                    {employent.employmentType} -{' '}
                    {DateToIndiaFormat(employent.joinDate)}{' '}
                    {DateToIndiaFormat(employent.endDate)
                      ? `- ${DateToIndiaFormat(employent.endDate)}`
                      : ''}
                    {/* (3 years 11 months) */}
                  </h6>
                  <p className="font-semibold">{employent.jobProfile}</p>
                </div>
                <div className="flex items-start ">
                  <div className="flex items-center">
                    {/* <div className="flex justify-end mr-2 mt-5"> */}
                    <PencilSquareIcon
                      className="-ml-1 mr-2 h-8 w-8 cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Edit',
                          obj: employent,
                        })
                      }
                    />
                    <TrashIcon
                      className="-ml-1 mr-5 h-8 w-8 cursor-pointer text-red-600"
                      aria-hidden="true"
                      onClick={() => openModalWindow(employent.id)}
                      // onClick={() => removeTechSkill(techskill.skill_id)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="mx-6">No employments Found</div>
      )}
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      >
        <AddEmployment
          onModalDataClose={() => closeModalDataWindow()}
          onModalDataSubmit={() => onModalDataSubmit()}
          setShowNotification={() => showNotificationMsg()}
          data={openModalDataValues}
        />
      </ModalData>

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />

      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit()}
      >
        modal
      </Modal>
    </>
  );
};

export default CandEmployment;

const LoadingIcon = () => (
  <svg
    role="status"
    className="inline mr-3 w-4 h-4 text-white animate-spin"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* SVG paths */}
  </svg>
);

export default LoadingIcon;

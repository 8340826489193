import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';

import { useSelector } from 'react-redux';
export const AddEmployment = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
}) => {
  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();
  const profileId = useSelector((state) => state.candidatedetails.id);

  const initialValues = {
    isCurrentEmloyment: data.obj?.isCurrentEmloyment || '',
    employmentType: data.obj?.employmentType || '',
    companyName: data.obj?.companyName || '',
    designation: data.obj?.designation || '',
    joinDate: data.obj?.joinDate || '',
    endDate: data.obj?.endDate || '',
    // salary: data.obj?.salary || '',
    jobProfile: data.obj?.jobProfile || '',
    id: data.obj?.id || '',
  };

  const validationSchema = yup.object({
    isCurrentEmloyment: yup
      .string()
      .required('Please select current employment'),
    employmentType: yup.string().required('Please select employment type'),
    companyName: yup.string().required('Please enter company name'),
    designation: yup.string().required('Please enter designation'),
    joinDate: yup.string().required('Please enter join date'),
    endDate: yup.string().required('Please enter relieveing date'),
    jobProfile: yup.string().required('Please enter job profile'),
  });

  const onSubmit = async (validationSchema) => {
    let values = {
      profile_id: profileId,
      employments: [],
    };
    values.employments.push(validationSchema);

    try {
      const updateCandidateData = await updateCandidate(values).unwrap();
      setShowNotification(true);
      onModalDataClose();
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      <form className="flex flex-col" onSubmit={formik.handleSubmit}>
        <div className=" ">
          <div className="col-span-2 ">
            <label
              htmlFor="isCurrentEmloyment"
              className="block text-sm font-medium text-gray-700 pb-2"
            >
              Is this your current employment?
            </label>
            <div className="items-center ">
              <select
                id="isCurrentEmloyment"
                name="isCurrentEmloyment"
                className="bg-inputbg w-full border border-inputBorder mt-1 block rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.isCurrentEmloyment}
              >
                <option val="-1">-- Select Current Employment --</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
              {formik.touched.isCurrentEmloyment &&
              formik.errors.isCurrentEmloyment ? (
                <div className="mt-2 text-sm w-full text-red-600">
                  {formik.errors.isCurrentEmloyment}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mt-4 ">
          <div className="col-span-2 ">
            <label
              htmlFor="employmentType"
              className="block text-sm font-medium text-gray-700 pb-2"
            >
              Employment type?
            </label>
            <div className="">
              <select
                id="employmentType"
                name="employmentType"
                className="bg-inputbg w-full border border-inputBorder mt-1 block rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.employmentType}
              >
                <option val="-1">-- Select Employment type --</option>
                <option value="Full time">Full time</option>
                <option value="Part time">Part time</option>
              </select>
              {formik.touched.employmentType && formik.errors.employmentType ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.employmentType}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-full">
            <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700"
            >
              Company Name *
            </label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              className="bg-inputbg border w-full border-inputBorder mt-1 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.companyName}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-row">
          <div className="w-full mt-4 ">
            <label
              htmlFor="designation"
              className="block text-sm font-medium text-gray-700"
            >
              Designation *
            </label>
            <input
              type="text"
              name="designation"
              id="designation"
              autoComplete="cc-designation"
              className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.designation}
            />
            {formik.touched.designation && formik.errors.designation ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.designation}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 ">
          <div className="col-span-4 sm:col-span-1 ">
            <label
              htmlFor="joinDate"
              className="block text-sm font-medium text-gray-700"
            >
              Join Date*
            </label>

            <input
              type="date"
              name="joinDate"
              id="joinDate"
              autoComplete="cc-joinDate"
              className="bg-inputbg border border-inputBorder mt-1 w-44 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.joinDate}
            />
            {formik.touched.joinDate && formik.errors.joinDate ? (
              <div className="mt-2 text-sm text-red-600">
                {formik.errors.joinDate}
              </div>
            ) : null}
          </div>
          {formik.values.isCurrentEmloyment == 2 ? (
            <div className="col-span-4 sm:col-span-1 ">
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700"
              >
                Relieveing Date*
              </label>

              <input
                type="date"
                name="endDate"
                id="endDate"
                autoComplete="cc-endDate"
                className="bg-inputbg border border-inputBorder mt-1 w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.endDate}
              />
              {formik.touched.endDate && formik.errors.endDate ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.endDate}
                </div>
              ) : null}
            </div>
          ) : (
            ''
          )}
        </div>

        {/* <div className="mt-5 sm:mt-6 ">
          <div>
            <label
              htmlFor="salary"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Current Salary
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="number"
                name="salary"
                id="salary"
                className=" w-full border rounded-md bg-inputbg border-inputBorder py-1.5 px-3 text-gray-900  placeholder:text-gray-400 focus:outline-none   focus:border-purplecolor-100 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="200000"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salary}
              />
              {formik.touched.salary && formik.errors.salary ? (
                <div className="mt-2 text-sm text-red-600">
                  {formik.errors.salary}
                </div>
              ) : null}
            </div>
          </div>
        </div> */}

        <div className="col-span-2 mt-6 border-b-2 border-gray-200 pb-8">
          <label
            htmlFor="job_profile"
            className="block text-sm font-medium text-gray-700"
          >
            Job Profile*
          </label>
          <textarea
            rows={5}
            name="jobProfile"
            id="jobProfile"
            className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            placeholder="Job profile"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jobProfile}
          />
          {formik.touched.jobProfile && formik.errors.jobProfile ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.jobProfile}
            </div>
          ) : null}
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <button
              type="button"
              onClick={() => onModalDataClose()}
              className=" rounded-3xl border border-gray-300 bg-white py-3 px-8 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
            >
              Cancel
            </button>
          </div>
          <div>
            {/* <button
                  type="button"
                  className="inline-flex mr-4 mt-4  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Publish
                </button> */}
            <button
              type="submit"
              className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
              // className="inline-flex  justify-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

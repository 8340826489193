import { Fragment, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Dialog, Menu, Transition, Tab, RadioGroup } from '@headlessui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PhoneIcon,
  ArrowLeftIcon,
  DevicePhoneMobileIcon,
  UserCircleIcon,
  DocumentArrowUpIcon,
} from '@heroicons/react/20/solid';

import { HandThumbUpIcon } from '@heroicons/react/24/outline';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import 'rc-tooltip/assets/bootstrap.css';
import {
  useGetInterviewCandDetailsQuery,
  useInterviewerUpdateMutation,
} from '../../features/hiringstages/interviewApiSlice';

import { selectCurrentUserID } from '../../features/auth/authSlice'

import { format } from 'date-fns';
// import InterviewScore from './InterviewScore';
// import InterviewScore from '../userworkspace/mytask/hiringstage/interview/interviewprocess/tabs/InterviewScore';
import useGetSkillcapturebyid from '../../hooks/useSkillcapturebyid';
import useGetSkills from '../../hooks/useGetSkills';
// import InterviewScore from '../../interview/interviewprocess/tabs/InterviewScore';

// import InterviewScore from '../userworkspace/mytask/hiringstage/interview/interviewprocess/tabs/InterviewScore';
import InterviewScore from './interviewCandScore';
import InterviewHrDetails from './InterviewHrDetails';

const memoryOptions = [
  { id: 1, name: 'Strong Hire' },
  { id: 2, name: 'Hire' },
  { id: 3, name: 'Need 2nd Openion' },
  { id: 4, name: 'No Hire' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const InterviewDetails = () => {
  const navigate = useNavigate();
  const { id, stage } = useParams();
  const user_id = useSelector(selectCurrentUserID)
  console.log(id, stage, user_id)
  if (stage == undefined) {
    stage = 5
  }
  const [mem, setMem] = useState({ id: 0, name: '' });
  const [skillsRating, setSkillsRating] = useState([]);


  const {
    data: interviewCandDetails,
    error: interviewCandDetailsErr,
    isLoading: interviewCandDetailsLoding,
    isSuccess: interviewCandDetailsSuccess,
  } = useGetInterviewCandDetailsQuery(id, { refetchOnMountOrArgChange: true });
  console.log(interviewCandDetails)
  const { skills, loadingSkills, successSkills } = useGetSkills();
  // console.log(skillsRating)

  const skillsArray = skills?.data;

  const filterInterviewCandDetailsData = interviewCandDetails?.data;

  // Filter interviews for the specific interviewer
  const output = filterInterviewCandDetailsData?.interview?.filter(interview => (interview.interviewer === user_id));

  const checkStage = filterInterviewCandDetailsData?.interview?.filter(interview => (interview.interviewer === user_id));

  const interviewCompleted = checkStage?.some(interview => {
    if (stage == 5) {
      return true
    }
    else {
      // console.log(stage)

      return false
    }
  });

  // console.log(interviewCompleted)

  // Prepare the final output
  const filterinterviewCandDetails = {
    data: {
      ...filterInterviewCandDetailsData,
      interview: output
    }
  };
  const interviewCandDetailsData = filterinterviewCandDetails?.data;
  // console.log(interviewCandDetailsData)
  
  
  const [interviewerUpdate, { isLoading }] = useInterviewerUpdateMutation();

  const { skillcapturebyid, loadingSkillcapturebyid, successSkillcapturebyid } =
    useGetSkillcapturebyid(interviewCandDetailsData?.job_request_id);

  const JRskillsArray = skillcapturebyid?.data[0].skill_list;


  useEffect(() => {
    if (interviewCandDetailsSuccess && successSkills) {
      const uniqueWeightages = [
        ...new Set(
          JRskillsArray?.flatMap((skillType) => {
            // Ensure skillType.skills exists and is an array before calling map

            if (Array.isArray(skillType.skills)) {
              return skillType.skills.map((skill) => skill.weightage);
            }
            return []; // Return an empty array if skillType.skills is not an array
          })
        ),
      ];

      const skillsValueArray = uniqueWeightages.map((weightage) => {
        const skills = JRskillsArray?.flatMap((skillType) => {
          return skillType.skills
            .filter((skill) => skill.weightage === weightage)
            .map((skill) => {
              const matchingSkill = skillsArray.find(
                (s) => s.id === parseInt(skill.skill_id)
              );
              return {
                skill_id: parseInt(skill.skill_id),
                name: matchingSkill.name,
                rating: 0,
              };
            });
        });

        return {
          name: weightage,
          skills,
        };
      });

      const matchingSkills = skillsArray.filter((skill) =>
        interviewCandDetailsData?.interview[0]?.evaluation_skill.includes(
          skill.id
        )
      );

      // Create newSkillArray based on the matchingSkills
      const thisInterviewSkills = {
        name: 'Other Skills',
        skills: matchingSkills.map((skill) => ({
          name: skill.name,
          rating: 0, // You can set the rating to any default value as needed
          skill_id: skill.id,
        })),
      };
      const mergedSkills = Array.isArray(thisInterviewSkills)
        ? thisInterviewSkills
        : [thisInterviewSkills];

      // console.log("skillsValueArray ", skillsValueArray)
      // console.log("thisInterviewSkills ", thisInterviewSkills)
      // console.log("matchingSkills ", matchingSkills)

      //change code tist - is_hr = 1 or 0 display all skills
      setSkillsRating([...skillsValueArray, ...mergedSkills])
      // interviewCandDetailsData.interview[0].interview_stage.is_hr == 0
      //   ? setSkillsRating([...skillsValueArray, ...mergedSkills])
      //   : setSkillsRating([...mergedSkills]);
      // *** end

    }
  }, [interviewCandDetailsSuccess, successSkills, JRskillsArray]);

  // console.log(skillsRating)

  const formatTooltip = (value) => {
    return `${value} %`;
  };

  const handleRatingChange = (value, tabName, skillId) => {
    const newRating = parseInt(value);
  };

  const initialValues = {
    interview_feedback: '',
    interview_recommendation: mem.id ? mem.id : 0,
    interview_recommendation_comments: mem.id ? mem.name : '',
    technical_skills: skillsRating,
  };

  const validationSchema = yup.object({
    interview_feedback: yup.string().required('Please enter Feedback'),
    interview_recommendation_comments: yup
      .string()
      .required('Please enter Feedback'),
  });

  const onSubmit = async (validationSchema) => {
    const data = {
      ...validationSchema,
      id: interviewCandDetailsData?.id,//36
      interviewid: interviewCandDetailsData?.interview[0].id,
    };

    try {
      const interviewerUpdateResponse = await interviewerUpdate(data).unwrap();
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  function handleModifyRating(categoryname, skilldID, ratingValue) {
    const updatedSkillsRating = skillsRating.map((category) => {
      if (category.name === categoryname) {
        return {
          ...category,
          skills: category.skills.map((skill) => {
            if (skill.skill_id === skilldID) {
              return {
                ...skill,
                rating: ratingValue,
              };
            }
            return skill;
          }),
        };
      }
      return category;
    });
    // console.log("updatedSkillsRating : ", updatedSkillsRating)
    setSkillsRating(updatedSkillsRating);
  }

  if (interviewCandDetailsSuccess) {

    return (
      output[0]?.interview_stage?.is_hr == 1 ? (
        <InterviewHrDetails />
      ) : (
        <div>
          <div className="flex justify-end items-center">
            <Link
              type="button"
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className=" rounded-3xl text-sm font-medium  shadow-smfocus:outline-none pb-6"
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text inline-block"
                aria-hidden="true"
              />
              Back
            </Link>
          </div>
          <div className="flex flex-row  ">
            <div className="basis-96 rounded-lg bg-white shadow-lg py-5 px-5">
              <div className="  pb-0">
                <div className="flex justify-center">
                  <UserCircleIcon
                    className="h-20 w-20 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="min-w-0 flex-1 pt-2 border-b border-gray-300 pb-4">
                  <h1 className="  flex font-semibold text-2xl text-black">
                    {interviewCandDetailsData?.fullName}
                  </h1>
                  <p className="flex  font-medium py-0 text-lg text-gray-600">
                    {interviewCandDetailsData?.current_designation} -{' '}
                    {interviewCandDetailsData?.current_company}
                  </p>
                </div>
              </div>

              {/* <div className="flex justify-center flex-col border-b border-gray-300 py-5  pb-4">
              <dt className="order-1 text-lg font-medium leading-6 text-gray-500 ">
                <h1 className=" font-semibold text-xl text-black">
                  Fullstack Developer
                </h1>
              </dt>
              <dd className="order-2 text-md font-bold tracking-tight text-indigo-600">
                <p className="  font-medium py-0 text-sm text-gray-600">
                  Chennai, India
                </p>
              </dd>
            </div> */}
              <div className="flex justify-center flex-col border-b border-gray-300 py-5  pb-4">
                <div className="">
                  <h2 className=" text-xl font-semibold  text-black">
                    Contact Details
                  </h2>
                </div>
                <div className="">
                  <div className="px-0 pt-2">
                    <div className="flex items-center">
                      <span>
                        <DevicePhoneMobileIcon
                          className="h-6 w-6 text-black"
                          aria-hidden="true"
                        />
                      </span>

                      <span className="">
                        <p className=" ml-0 px-2 py-1.5 text-black font-semibold  placeholder:text-black text-sm sm:leading-6 focus:outline-none">
                          {interviewCandDetailsData?.mobile}
                        </p>
                      </span>
                    </div>
                    <div className="flex items-center">
                      <PhoneIcon
                        className="h-6 w-6 text-black"
                        aria-hidden="true"
                      />

                      <span className="">
                        <p className="px-3 py-1.5 text-black font-semibold  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                          {interviewCandDetailsData?.work_mobile}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex-col border-b border-gray-300 py-5  pb-4 mb-4">
                <div className=" self-center">
                  <h2 className=" text-xl font-semibold text-black ">Address</h2>

                  <div>
                    <div className="pt-2">
                      <p className=" text-black font-semibold break-words  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                        {interviewCandDetailsData?.address}
                      </p>
                      {/* <p className=" text-black font-semibold break-words  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                      Houston,
                    </p>
                    <p className="  text-black font-semibold break-words  placeholder:text-black sm:text-sm sm:leading-6 focus:outline-none">
                      Texas United States
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="  mb-4">
                {interviewCandDetailsData?.resume ? (
                  <span className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500 ml-7">
                    <a
                      href={interviewCandDetailsData?.resume}
                      target="_blank"
                      title="Edit"
                      className="bg-sidebar px-4 py-2 items-center flex rounded-xl text-white"
                    >
                      {' '}
                      <DocumentArrowUpIcon
                        className="ml-1 mr-2 mt-1 h-7 w-7 items-center text-white "
                        aria-hidden="true"
                      />{' '}
                      Download Resume
                    </a>
                  </span>
                ) : (
                  <span className="text-center flex whitespace-nowrap px-2 py-2 text-md text-gray-500 justify-center font-bold ">
                    Resume not available
                  </span>
                )}
              </div>
            </div>
            <div className="basis-full rounded-lg bg-white shadow-lg py-5 px-5 ml-5">
              <form onSubmit={formik.handleSubmit}>
                <Tab.Group as="div" className="mt-0">
                  <Tab.List className="border-b border-gray-200 -mb-px flex space-x-8 px-5 py-0">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a>Interview</a>
                      </span>
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? ' px-2 py-2   font-semibold text-sm border-b-2 border-indigo-600 text-black '
                            : 'border-transparent text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      <span>
                        <a>Score Board</a>
                      </span>
                    </Tab>
                  </Tab.List>
                  <Tab.Panels as={Fragment}>

                    < Tab.Panel className="space-y-4 py-3">
                      {!interviewCompleted ? (
                        <>
                          <div className="flex flex-row">
                            <div className="basis-1/4 px-5">
                              <div>
                                <p className="py-3.5 pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                  {/* {tab.tabName}  */}
                                  Stage
                                </p>
                                <div className="flex mt-0">
                                  <span className="bg-stageL1 px-2 py-1.5 text-white text-sm font-bold">
                                    {
                                      interviewCandDetailsData?.interview[0]?.interview_stage?.sno
                                    }
                                  </span>
                                  <span className=" text-xl text-black ml-3 font-semibold">
                                    {
                                      interviewCandDetailsData?.interview[0]
                                        ?.interview_stage?.title
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="basis-1/4">
                              <h6 className="py-3.5 pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                {/* {tab.tabName}  */}
                                Venue
                              </h6>
                              <span className=" text-md text-black font-semibold">
                                {
                                  interviewCandDetailsData?.interview[0]
                                    ?.interview_mode
                                }{' '}
                                -{' '}
                                {
                                  interviewCandDetailsData?.interview[0]
                                    ?.interview_detail
                                }
                              </span>
                            </div>
                            <div className="basis-1/4">
                              <h6 className="py-3.5 pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                {/* {tab.tabName}  */}
                                Date{' '}
                                {
                                  interviewCandDetailsData?.interview[0]
                                    ?.interview_time_start
                                }
                              </h6>
                              <span className=" text-md text-black font-semibold">
                                {interviewCandDetailsData?.interview[0]
                                  ?.interview_date != null
                                  ? format(
                                    new Date(
                                      interviewCandDetailsData?.interview[0]?.interview_date
                                    ),
                                    'dd/MM/yyyy'
                                  )
                                  : '-'}
                              </span>
                            </div>
                            <div className="basis-1/4">
                              <h6 className="py-3.5 pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                {/* {tab.tabName}  */}
                                Time
                              </h6>
                              <span className=" text-md text-black font-semibold">
                                {interviewCandDetailsData?.interview[0]
                                  ?.interview_date != null &&
                                  interviewCandDetailsData?.interview[0]
                                    ?.interview_time_start != null
                                  ? format(
                                    new Date(
                                      `${interviewCandDetailsData?.interview[0]?.interview_date} ${interviewCandDetailsData?.interview[0]?.interview_time_start}`
                                    ),
                                    'HH:mm a'
                                  )
                                  : null}{' '}
                                -{' '}
                                {interviewCandDetailsData?.interview[0]
                                  ?.interview_date != null &&
                                  interviewCandDetailsData?.interview[0]
                                    ?.interview_time_end != null
                                  ? format(
                                    new Date(
                                      `${interviewCandDetailsData?.interview[0]?.interview_date} ${interviewCandDetailsData?.interview[0]?.interview_time_end}`
                                    ),
                                    'HH:mm a'
                                  )
                                  : null}
                              </span>
                            </div>
                          </div>

                          <Tab.Group as="div" className="mt-4">
                            <Tab.List className=" flex justify-start bg-skillcaps px-2 py-1 mx-5 my-3 rounded-md space-x-10">
                              {skillsRating?.map((skillList, index) => (
                                <Tab
                                  key={index}
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm capitalize'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 capitalize'
                                    )
                                  }
                                >
                                  <span>
                                    <a className="text-skillcapstext px-3 py-2 rounded-md text-md ">
                                      {skillList.name}
                                    </a>
                                  </span>
                                </Tab>
                              ))}
                            </Tab.List>
                            <Tab.Panels as={Fragment}>
                              {skillsRating?.map((skillList) => (
                                <Tab.Panel className="space-y-4 py-3">
                                  <div className="px-4 sm:px-6 lg:px-8  border-b border-gray-200 pb-4">
                                    <div className="mt-2 flow-root">
                                      <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-0 align-middle sm:px-6 lg:px-6">
                                          {skillList.skills.map((listskills) => (
                                            <div className="flex flex-row mb-6">
                                              <div className="basis-3/4 px-5 flex">
                                                <div className="basis-1/4">
                                                  <p className=" pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                                    {listskills.name}
                                                  </p>
                                                </div>
                                                <div className="mt-2 basis-1/2">
                                                  <Slider
                                                    marks={{
                                                      0: 0,
                                                      10: 10,
                                                    }}
                                                    min={0}
                                                    step={1}
                                                    max={10}
                                                    allowCross={false}
                                                    tipFormatter={formatTooltip}
                                                    // pushable={true}
                                                    // defaultValue={[8]}
                                                    value={[listskills.rating]}
                                                    onChange={(value) => {
                                                      handleModifyRating(
                                                        skillList.name,
                                                        listskills.skill_id,
                                                        value
                                                      );
                                                    }}
                                                  // value={skill.rating}
                                                  />
                                                </div>
                                                <div className="ml-5">
                                                  <span>Rating</span>{' '}
                                                  <span className=" font-bold">
                                                    {listskills.rating}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Panel>
                              ))}
                            </Tab.Panels>
                          </Tab.Group>

                          <div className="">
                            <div className="px-5">
                              <RadioGroup
                                value={mem}
                                onChange={setMem}
                                className="mt-2"
                              >
                                <h2 className="pl-4 pr-3 mb-2  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                  Overall Recommendation*
                                </h2>
                                <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                                  {memoryOptions.map((option, i) => (
                                    <RadioGroup.Option
                                      key={i}
                                      value={option}
                                      className={({ active, checked }) =>
                                        classNames(
                                          // option.inStock
                                          //   ? 'cursor-pointer focus:outline-none'
                                          //   : 'cursor-not-allowed opacity-25',
                                          active
                                            ? 'ring-2 ring-indigo-600 ring-offset-2'
                                            : '',
                                          checked
                                            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                          'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                                        )
                                      }
                                    >
                                      <RadioGroup.Label
                                        as="span"
                                        className="flex capitalize items-center"
                                      >
                                        <HandThumbUpIcon
                                          className=" mr-1  h-6 w-6 items-center  "
                                          aria-hidden="true"
                                        // onClick={() => openDrawerWindow()}
                                        />
                                        {option.name}
                                      </RadioGroup.Label>
                                    </RadioGroup.Option>
                                  ))}
                                </div>
                              </RadioGroup>
                              {formik.touched.interview_recommendation_comments &&
                                formik.errors.interview_recommendation_comments ? (
                                <div className="mt-2 text-sm text-red-600">
                                  {formik.errors.interview_recommendation_comments}
                                </div>
                              ) : null}
                            </div>

                            <div className=" w-3/4 px-5 mt-5">
                              <div className="mx-0">
                                <label
                                  htmlFor="interview_feedback"
                                  className="pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize"
                                >
                                  Feedback*
                                </label>

                                <textarea
                                  rows={5}
                                  name="interview_feedback"
                                  id="interview_feedback"
                                  className="bg-inputbg w-full mt-3 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Candidate not pickinng phone"
                                  // defaultValue={''}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.interview_feedback}
                                />
                                {formik.touched.interview_feedback &&
                                  formik.errors.interview_feedback ? (
                                  <div className="mt-2 text-sm text-red-600">
                                    {formik.errors.interview_feedback}
                                  </div>
                                ) : null}
                              </div>
                              <div className="flex justify-end mt-3">
                                <div className=" py-0 mx-0 mr-5">
                                  <button
                                    type="submit"
                                    className=" rounded-xl border bg-green-500 w-full py-2 px-10 text-sm font-medium text-white shadow-sm focus:outline-none "
                                  >
                                    Publish
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className=" bg-green-600 text-white text-center py-10 text-xl uppercase font-bold">
                          Interview Level Completed
                        </div>
                      )
                      }
                    </Tab.Panel>

                    <Tab.Panel className="space-y-16 py-0">
                      {/* <InterviewScore
                      interviewData={interviewCandDetailsData?.interview}
                    /> */}
                      <InterviewScore />
                    </Tab.Panel>

                    {/* <Tab.Panel>
                    <Tab.Group as="div" className="mt-4">
                      <Tab.List className="flex justify-start bg-skillcaps px-2 py-1 mx-5 my-3 rounded-md space-x-10">
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            )
                          }
                        >
                          <span>
                            <a className="text-skillcapstext px-3 py-2 rounded-md text-md uppercase">
                              Basic Skill
                            </a>
                          </span>
                        </Tab>
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'bg-white text-black px-3 py-2 rounded-md font-bold text-sm '
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            )
                          }
                        >
                          <span>
                            <a className="text-skillcapstext px-3 py-2 rounded-md text-md uppercase">
                              Skills
                            </a>
                          </span>
                        </Tab>
                      </Tab.List>
                      <Tab.Panels as={Fragment}>
                        <Tab.Panel className="space-y-16 py-3">
                          <div className="px-4 sm:px-6 lg:px-8  border-b border-gray-200 pb-4">
                            <div className="mt-2 flow-root">
                              <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-0 align-middle sm:px-6 lg:px-8">
                                  <div className="flex flex-row">
                                    <div className="basis-3/4 px-5 flex">
                                      <div className="basis-1/4">
                                        <p className=" pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                          Speak Grammar
                                        </p>
                                      </div>
                                      <div className="mt-2 basis-1/2">
                                        <Slider
                                          marks={{
                                            0: 0,
                                            10: 10,
                                          }}
                                          min={0}
                                          step={1}
                                          max={10}
                                          allowCross={false}
                                          tipFormatter={formatTooltip}
                                          
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-row">
                                    <div className="basis-3/4 px-5 flex my-10">
                                      <div className="basis-1/4">
                                        <p className=" pl-4 pr-3  text-md font-semibold text-gray-900 sm:pl-0 capitalize">
                                          Speak / Write English
                                        </p>
                                      </div>
                                      <div className="mt-2 basis-1/2">
                                        <Slider
                                         
                                          marks={{
                                            0: 0,
                                            10: 10,
                                          }}
                                          min={0}
                                          step={1}
                                          max={10}
                                          allowCross={false}
                                          tipFormatter={formatTooltip}
                                          
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </Tab.Panel> */}
                  </Tab.Panels>
                </Tab.Group>
              </form>
            </div>
          </div>
        </div >
      )
    );
  }
};

export default InterviewDetails;

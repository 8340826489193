import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUserPrivSetQuery } from '../../features/privileges/userSettingsApiSlice';
import { useAdminPrivSetQuery } from '../../features/privileges/adminSettingsApiSlice';
import { selectCurrentUserID } from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useTeamsQuery } from '../../features/teams/teamsApiSlice';
import Notification from '../../components/common/Notification';
import Drawer from '../../components/common/Drawer';
import DateToIndiaFormat from '../../components/common/DateToIndiaFormat';

import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import AddMyTaskUserPriv from '../../features/mytaskuserpriv/AddMyTaskUserPriv';
import Table from '../../components/common/Table';

const MytaskUserPriv = ({ hello, isEditnew }) => {
  const tableHeader = [
    {
      id: 1,
      title: 'Candidate Name',
    },
    {
      id: 2,
      title: 'Designation',
    },
    {
      id: 3,
      title: 'Location',
    },
    {
      id: 4,
      title: 'Requested Date',
    },
    {
      id: 5,
      title: 'Signoff by',
    },
    {
      id: 6,
      title: 'Signoff Date ',
    },
    {
      id: 7,
      title: 'Status ',
    },
    {
      id: 8,
      title: 'Action',
    },
  ];
  const { data: users, isLoading: userLoading } = useTeamsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');

  const currentUserId = useSelector(selectCurrentUserID);
  const [openDrawer, setOpenDrawer] = useState(false);

  const navigate = useNavigate();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else if (mode === 'view') {
      setEdit(false);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  // const closeModalWindow = () => {
  //   setOpenModal(!openModal);
  // };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const {
    data: adminSettings,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useAdminPrivSetQuery(currentUserId, { refetchOnMountOrArgChange: true });
  const {
    data: userSettings,
    error: userErr,
    isLoading: userloading,
  } = useUserPrivSetQuery({ refetchOnMountOrArgChange: true });
  const displayUsername = (id) => {
    if (!userLoading) {
      let findUser = users?.data.filter((el) => el.id == id);
      if (findUser[0]) {
        return findUser[0]?.first_name + ' ' + findUser[0]?.last_name;
      }
    }
  };
  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    }
  };

  const tableValues = userSettings?.data?.map((us) => ({
    name: `${us.first_name} ${us.last_name}`,
    designation: `${us.designation_name}`,
    location: `${us.branch_name}`,
    requested: DateToIndiaFormat(us.workspace_privilege[0].createdAt),
    signby: displayUsername(`${us.workspace_privilege[0].approved_by}`),
    signoff: DateToIndiaFormat(us.workspace_privilege[0].updatedAt),
    status: displayStatus(`${us.workspace_privilege[0].approval_status}`),
  }));

  const noList = () => (
    <>
      <td colSpan={7} className="font-extrabold text-red-600 p-4 ">
        NO DATA
      </td>
    </>
  );

  return (
    <>
      <div className="mt-1 flex flex-col">
        {/* <div className="flex justify-end mt-4">
          <Link
            type="button"
            to={'/app/userprivilege'}
            className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5 text" aria-hidden="true" />
            Create User Privilege
          </Link>
        </div> */}
        <div className="overflow-auto">
          <div className="inline-block min-w-full py-2 align-middle px-1">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <>
                <Table
                  header={tableHeader}
                  value={tableValues}
                  isEditing={isEditnew}
                  openDrawerWindow={openDrawerWindow}
                  fullObjData={userSettings?.data}
                />
              </>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        isOpen={openDrawer}
        title={isEdit ? 'Edit User Privilege' : 'Add User Privilege'}
        onClose={() => closeDrawerWindow()}
        // onSubmit={() => onSubmit()}
      >
        <AddMyTaskUserPriv
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
    </>
  );
};
export default MytaskUserPriv;

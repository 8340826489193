import { React, useState, Fragment } from 'react';
// import { PencilIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';

import { Link } from 'react-router-dom';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';

import Drawer from '../../components/common/Drawer';
import Modal from '../../components/common/Modal';
import Notification from '../../components/common/Notification';
import SignOffWorkflow from '../../features/signoffworkflow/SignOffWorkflow';

import { useWorkflowtaskQuery } from '../../features/workflow/workflowApiSlice';

const transactions = [
  {
    // id: "Software Engineer",
    // posi: "chennai, India",
    // company: "$.",
    // share: "^",
    // commission: "&",
    // price: "#",
    // quantity: "@",
    // netAmount: "?",
    // check: "*",
    // checking: "$",
    // over: "%",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const displayStatus = (status) => {
  console.log(status)
  if (status == 0) {
    return (
      <div className="flex justify-center">
        <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {'Approval Pending'}
        </div>
      </div>
    );
  } else if (status == 1) {
    return (
      <div className="flex justify-center">
        <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {'Approved'}
        </div>
      </div>
    );
  } else if (status == 2) {
    return (
      <div className="flex justify-center">
        <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {'Query'}
        </div>
      </div>
    );
  } else if (status == 3) {
    return (
      <div className="flex justify-center">
        <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {'Reject'}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center">
        <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {'New'}
        </div>
      </div>
    );
  }
};

export default function WorkflowAssign() {
  const {
    data: wf,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useWorkflowtaskQuery({ refetchOnMountOrArgChange: true });

  let datawf = [];

  if (isSuccess) {
    datawf = wf?.data?.map((dt) => dt);
  }

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    // setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async () => {
    try {
      // const deleteDept = await deleteDepartment(deptId).unwrap();
      // console.log(deleteDept.message);
      closeModalWindow();
      showNotificationMsg();
    } catch (error) { }
  };
  const noList = () => (
    <>
      <h1 className="font-extrabold text-red-600 p-4 ">NO DATA</h1>
    </>
  );
  return (
    <div>
      {!isLoading ? (
        <div>
          <div className="flex justify-between ">
            {/* <div className="flex">
              <form className=" flex space-x-4" action="#">
                <div className="min-w-0 flex-1">
                  <div>
                    <select
                      id="year"
                      name="year"
                      autoComplete="year"
                      className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3 focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                    >
                      <option>Year</option>
                      <option>1990</option>
                      <option>2023</option>
                    </select>
                  </div>
                </div>
                <div>
                  <select
                    id="month"
                    name="month"
                    autoComplete="month"
                    className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  >
                    <option>Month</option>
                    <option>Jan</option>
                    <option>Dec</option>
                  </select>
                </div>
                <div>
                  <select
                    id="status"
                    name="status"
                    autoComplete="status"
                    className="bg-white border border-inputBorder mt-1 block w-36 rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  >
                    <option>Status</option>
                    <option>Complete</option>
                    <option>Pending</option>
                  </select>
                </div>
              </form>
            </div> */}
          </div>

          <div className="px-4 sm:px-6 lg:px-2">
            <div className="sm:flex sm:items-center">
              {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Export
          </button>
        </div> */}
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto h-96 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3  border-r-2 text-center text-sm font-bold text-black sm:pl-6"
                          >
                            Workflow
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                          >
                            Job Desc
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                          >
                            Skill Capture
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 border-r-2 text-center text-sm font-bold text-black"
                          >
                            Sourcing
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                          >
                            Screening
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                          >
                            Interview
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                          >
                            Offer
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-center border-r-2 text-sm font-bold text-black"
                          >
                            Joining
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                          >
                            Handover
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                          >
                            Status
                          </th>
                          {/*<th
                            scope="col"
                            className="whitespace-nowrap  text-center text-sm  border-r-2 font-bold text-black"
                          >
                            Approved by
                          </th> */}

                          <th
                            scope="col"
                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      {datawf?.length > 0 ? (
                        <tbody className="divide-y divide-gray-200 bg-white ">
                          {datawf
                            .sort()
                            .reverse()
                            .map((wfres, i) => (
                              <tr key={wfres.id}>
                                <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                                  {/* {transaction.id} <br />
                          {transaction.posi} */}
                                  <h3 className="text-left font-bold">
                                    {wfres.title} - {wfres.id}
                                  </h3>
                                  {/* <h4 className="text-left">{wfres.branch}</h4> */}
                                </td>
                                <td className="whitespace-nowrap font-medium text-gray-900 border-r-2">
                                  {/* {transaction.company} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[0]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[0]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                  {/* {transaction.share} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[1]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[1]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>

                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.price} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[2]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[2]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.quantity} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[3]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[3]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.netAmount} */}

                                  <div className="flex justify-between">
                                    <div>
                                      <div className="flex justify-center mt-2">
                                        <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                          T
                                        </div>
                                        <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                          {wfres.hiringStageDetails[4]
                                            .taskDuration || 0}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="flex justify-center mt-2 mb-2">
                                        <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                          S
                                        </div>
                                        <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                          {wfres.hiringStageDetails[4]
                                            .signoff_duration || 0}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex justify-center">
                                    {wfres.hiringStageDetails[4].interviewDetails?.map(
                                      (val) => {
                                        const valIndex = `bg-screeningL${val.sno} px-2 py-1.5 text-white text-sm font-bold`;
                                        return (
                                          <div className={valIndex}>
                                            L{val.sno}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.check} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[5]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[5]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.checking} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[6]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[6]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  {/* {transaction.over} */}
                                  <div className="flex justify-center mt-2">
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      T
                                    </div>
                                    <div className="bg-workflowBlue px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[7]
                                        .taskDuration || 0}
                                    </div>
                                  </div>
                                  <div className="flex justify-center mt-2 mb-2">
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-l-lg border-r-2">
                                      S
                                    </div>
                                    <div className="bg-workflowGreen px-3 py-1.5 text-white text-sm font-bold rounded-r-lg">
                                      {wfres.hiringStageDetails[7]
                                        .signoff_duration || 0}
                                    </div>
                                  </div>
                                </td>

                                <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                                  <h3 className="text-left font-bold">
                                    {displayStatus(wfres.task_approval_status)}
                                    {/* API */}
                                    {/* {wfres.status} */}
                                  </h3>
                                </td>
                                {/* <td className="whitespace-nowrap py-2  text-sm text-gray-500 sm:px-2 border-r-2">
                                
                                <h3 className="text-left font-bold">
                                  Robin Williams
                                </h3>
                              </td> */}
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                  <div className="flex justify-center">
                                    <div className="flex self-center">
                                      {/* <a href="" title="View"> */}
                                      {/* <pre>
                                        {JSON.stringify(wfres, undefined, 2)}
                                      </pre> */}
                                      <PencilIcon
                                        className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                        aria-hidden="true"
                                        onClick={() =>
                                          openDrawerWindow('edit', wfres)
                                        }
                                      />
                                      {/* </a> */}
                                    </div>
                                  </div>

                                  {/* <div className="">
                            <a href="#" title="Edit">
                              <PencilIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                            <a href="#" title="View">
                              <PencilIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                            <a href="#" title="Delete">
                              <TrashIcon
                                className="ml-1 mr-2 h-5 w-5 text-blueicon "
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <button
                            type="button"
                            className=" inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Signoffs
                          </button> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        noList()
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Notification
            isShow={showNotification}
            onClose={() => closeNotificationMsg()}
          />
          <Drawer
            isOpen={openDrawer}
            // title={isEdit ? "Edit Location" : "My Task"}
            onClose={() => closeDrawerWindow()}
            onSubmit={() => onSubmit()}
          >
            <SignOffWorkflow
              data={currentValue}
              isEdit={isEdit}
              // isSignoff={ifSignoff}
              onClose={() => closeDrawerWindow()}
            />
          </Drawer>
          <Modal
            isModalOpen={openModal}
            onClose={() => closeModalWindow()}
          // onSubmit={() => onModalSubmit(deptId)}
          ></Modal>
        </div>
      ) : (
        'Loading ...'
      )}
    </div>
  );
}

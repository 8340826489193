import { createSlice } from '@reduxjs/toolkit';
import authSlice from '../auth/authSlice';

const userWSPSlice = createSlice({
  name: 'userprivilege',
  initialState: { userid: null },
  reducers: {
    setUserId: (state, action) => {
      const { userid } = action.payload;
      state.userid = userid;
    },
  },
});

export const { setUserId } = userWSPSlice.actions;

export default userWSPSlice.reducer;

export const selectedUserId = (state) => state.userid;

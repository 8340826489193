import React, { useEffect, useState, Fragment } from 'react';
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import {
  useLocationQuery,
  useDeleteLocationMutation,
} from '../../../features/location/locationApiSlice';
import { Link, useNavigate } from 'react-router-dom';

import Drawer from '../../../components/common/Drawer';
import Modal from '../../../components/common/Modal';
import Notification from '../../../components/common/Notification';
import AddLocation from '../../../features/location/AddLocation';
import { toast } from 'sonner';

// import { useFormik } from "formik";

const Location = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [deleteLocation] = useDeleteLocationMutation();
  const navigate = useNavigate();

  const {
    data: locations,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useLocationQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    return () => {};
  }, []);

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async (deptId) => {
    try {
      const deleteLoc = await deleteLocation(deptId).unwrap();
      closeModalWindow();
      toast.error('Location deleted');
    } catch (error) {}
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    return () => {};
  }, []);

  const noList = () => (
    <div className="flex h-[calc(100vh-114px)]">
      <div className="text-center bg-white w-1/4 m-auto p-10">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Location </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new Location.
        </p>
        <div className="mt-6">
          {/* <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          New Project
        </button> */}
          <div>
            <Link
              type="button"
              className="flex   justify-center rounded-3xl bord1er border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
              onClick={() => openDrawerWindow()}
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Add Location
            </Link>
            <Link
              type="button"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex w-full justify-center rounded-3xl mt-4 text-sm font-medium  shadow-smfocus:outline-none "
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {locations?.data.length > 0 ? (
        <div className="md:flex md:items-baseline w-full md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                Location
              </h2>
              <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
                <Link
                  type="button"
                  to={'..'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex w-full justify-center rounded-3xl mr-6 text-sm font-medium  shadow-smfocus:outline-none "
                >
                  <ArrowLeftIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Back
                </Link>{' '}
                <button
                  type="button"
                  className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                  onClick={() => openDrawerWindow()}
                >
                  <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text"
                    aria-hidden="true"
                  />
                  Add
                </button>
              </div>
            </div>
            {isLoading && <h2>Loading...</h2>}
            {/* {isFetching && <h2>Fetching...</h2>} */}
            {error && <h2>{JSON.stringify(error)}</h2>}
            {isSuccess && (
              <div>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                >
                  {locations.data.map((location) => (
                    <lis
                      key={location.id}
                      className="col-span-1 flex rounded-md shadow-sm relative"
                    >
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md rounded-l-md  border hover:border-blue-600 border-gray-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 text-sm relative">
                          <a
                            // href={project.href}
                            className=" text-gray-900 font-semibold text-md hover:text-gray-600"
                          >
                            {location.name}
                          </a>
                          <p className="text-gray-500">
                            {location.description}
                          </p>
                        </div>
                        <div className="">
                          <div className="flex mr-2">
                            <PencilIcon
                              className="h-5 w-5 mr-2 text-green-700 cursor-pointer"
                              aria-hidden="true"
                              onClick={() =>
                                openDrawerWindow('edit', {
                                  name: location.name,
                                  desc: location.description,
                                  id: location.id,
                                  country_id: location.country_id,
                                })
                              }
                            />
                            <TrashIcon
                              className="h-5 w-5 mr-2 text-red-600 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => openModalWindow(location.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </lis>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : isSuccess ? (
        noList()
      ) : (
        <h2>Loading...</h2>
      )}
      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />
      <Drawer
        isOpen={openDrawer}
        title={isEdit ? 'Edit Location' : 'Add Location'}
        onClose={() => closeDrawerWindow()}
        onSubmit={() => onSubmit()}
      >
        <AddLocation
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(deptId)}
      ></Modal>
    </>
  );
};

export default Location;

import { createSlice } from '@reduxjs/toolkit';
import authSlice from '../auth/authSlice';

const initialState = {
  levelOne: 0,
  levelTwo: 0,
  levelThree: 0,
};

const userWorkspaceSlice = createSlice({
  name: 'userworkspace',
  initialState: initialState,
  reducers: {
    updateLevelOne: (state, action) => {
      state.levelOne = action.payload;
    },
    updateLevelTwo: (state, action) => {
      state.levelTwo = action.payload;
    },
    updateLevelThree: (state, action) => {
      state.levelThree = action.payload;
    },
  },
});

export const { updateLevelOne, updateLevelTwo, updateLevelThree } =
  userWorkspaceSlice.actions;
export default userWorkspaceSlice.reducer;
export const levelOne = (state) => state.userWorkspace.levelOne;
export const levelTwo = (state) => state.userWorkspace.levelTwo;
export const levelThree = (state) => state.userWorkspace.levelThree;

import React from 'react';

export function getStatusCode(status) {
  if (status === 'On Track')
    return (
      <td className="whitespace-nowrap px-2 py-2 text-sm text-white border-r-2 text-center">
        <span className="rounded-md bg-green-600 px-3 py-0.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {status}
        </span>
      </td>
    );
  else if (status === 'At Risk')
    return (
      <td className="whitespace-nowrap px-2 py-2 text-sm text-white border-r-2 text-center">
        <span className="rounded-md bg-red-600 px-3 py-0.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {status}
        </span>
      </td>
    );
  else if (status === 'Overdue')
    return (
      <td className="whitespace-nowrap px-2 py-2 text-sm text-white border-r-2 text-center">
        <span className="rounded-md bg-orange-600 px-3 py-0.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
          {status}
        </span>
      </td>
    );
  else
    return (
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2 text-center">
        {status}
      </td>
    );
}
function UserWSUtils() {
  return <div>UserWSUtils</div>;
}

export default UserWSUtils;

import { apiSlice } from '../../app/api/apiSlice';

export const personalApiSlice = apiSlice.injectEndpoints({
  tagTypes: ['Userprofile'],
  endpoints: (builder) => ({
    personal: builder.query({
      query: () => ({
        url: '/users/profile',
        method: 'GET',
      }),
      providesTags: ['Userprofile'],
      keepUnusedDataFor: 5,
    }),
    personalApi: builder.mutation({
      query: (data) => ({
        url: '/users/profile',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Userprofile'],
    }),
  }),
});

export const { usePersonalApiMutation, usePersonalQuery } = personalApiSlice;

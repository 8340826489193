import React, { useEffect, useState } from 'react';

import {
  useUserWSPQuery,
  useGetuserWSPQuery,
  useAddUserprivMutation,
} from '../../../features/privileges/userWSPApiSlice';
import { toast } from 'sonner';

const UserPrivilegelist = ({ userid }) => {
  const [checkboxState, setCheckboxState] = useState();
  const [addUserpriv, { isLoading: addLoading }] = useAddUserprivMutation();

  const { data: UserWSP } = useUserWSPQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: GetuserWSP, isLoading: getUserSPLoading } = useGetuserWSPQuery(
    userid,
    { refetchOnMountOrArgChange: true }
  );

  if (!getUserSPLoading) {
  }

  const selectedOptions = async (e) => {
    const target = e.target;
    const id = target.id;
    const isChecked = target.checked;

    const updatedCheckboxState = checkboxState.map((checkbox) =>
      checkbox.id === parseInt(id) ? { ...checkbox, isChecked } : checkbox
    );

    setCheckboxState(updatedCheckboxState);

    const selectedValue = {
      user_id: userid,
      workspace_privilege_id: e.target.value,
      status: e.target.checked ? 1 : 0,
    };

    try {
      const accountData = await addUserpriv(selectedValue).unwrap();
      if (e.target.checked == 1) {
        toast.success('Privilege added');
      } else {
        toast.error('Privilege removed');
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (GetuserWSP && UserWSP) {
      const a3 = UserWSP.data.map((t1) => ({
        ...(GetuserWSP?.data[0]?.workspace_privilege?.find(
          (t2) => t2.workspace_privilege_id === t1.id
        )
          ? { ...t1, isChecked: true }
          : { ...t1, isChecked: false }),
      }));
      setCheckboxState(a3);
    }
  }, [UserWSP, GetuserWSP]);

  return (
    <>
      <div>
        {checkboxState
          ? checkboxState.map((d, i) => (
              <div
                key={d.id}
                className="mt-4 divide-y divide-gray-200 pb-3  border-b border-gray-200 pr-2"
              >
                <div>
                  {/* {d[i].name !== d[i + 1].name ? <h1>{d.name}</h1> : ''} */}
                  {i === 0 || i === 3 || i === 6 ? (
                    <div className="flex justify-between">
                      <h1 className="text-md font-medium text-gray-900 pb-3">
                        {d.type}
                      </h1>
                      <div>
                        <h2 className="text-md font-medium text-gray-900">
                          {/* Enable / Disable */}
                        </h2>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className=" flex justify-between text-sm ">
                    <label
                      htmlFor=""
                      className="select-none font-medium text-indigo-700 text-md"
                    >
                      {d.name}
                    </label>

                    <div className="ml-3 flex h-5 items-center">
                      <input
                        id={d.id}
                        name={d.name}
                        value={d.id}
                        type="checkbox"
                        checked={d.isChecked}
                        onChange={(e) => selectedOptions(e)}
                        // onChange={(e) => selectedOptions(e)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : 'Loading...'}
      </div>
    </>
  );
};

export default UserPrivilegelist;

import { useSkillsQuery } from '../features/skillnew/skillnewApiSlice';
const useGetSkills = () => {
  const {
    data: skills,
    error: errSkills,
    isLoading: loadingSkills,
    isFetching: fetchingSkills,
    isSuccess: successSkills,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });
  return {
    skills,
    loadingSkills,
    successSkills,
  };
};

export default useGetSkills;

import React, { useEffect, useState } from 'react';

// import Sourcing from '../components/common/Sourcing';

import { Link, useNavigate } from 'react-router-dom';
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  XCircleIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

import { Dialog, Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import {
  useSourcingQuery,
  useCandlistQuery,
  useDeleteCandidateMutation
} from '../../../../../features/hiringstages/sourcingApiSlice';
import { setCandId } from '../../../../../features/hiringstages/candidateSlice';
import { useDispatch } from 'react-redux';

import GetCountry from '../../../../../components/common/GetCountry';
import GetLocationName from '../../../../../components/common/GetLocationName';

import ModalData from '../../../../../components/common/ModalData';
import { AddPushToScreening } from './AddPushToScreening';
import CandFilter from './CandFilter';
import { stringify } from 'uuid';
import { selectCurrentUserID } from '../../../../../features/auth/authSlice';
import Modal from '../../../../../components/common/Modal'; 

export const AddSourcing = ({ toggleSourcing }) => {
  const [openModal, setOpenModal] = useState(false);
  const [candID, setCandID] = useState(); 
  const [openModalData, setOpenModalData] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [currentModalValue, setCurrentModalValue] = useState(null);

  const navigate = useNavigate();

  const selectedSourcing = useSelector((state) => state.sourcing);
  const selectCurrentUserId = useSelector(selectCurrentUserID);
  const dispatch = useDispatch();
  const [deleteCandidate] = useDeleteCandidateMutation();

  const gotoSourcingList = (value, candid) => {
    if (candid?.id > 0) {
      dispatch(setCandId(candid.id));
    } else {
      dispatch(setCandId(0));
    }
    toggleSourcing(value);
  };

  const {
    data: sourcingL̥ist,
    error: sourcingListError,
    isLoading: sourcingListLoading,
    isFetching: sourcingListFetching,
    isSuccess: sourcingListSuccess,
  } = useSourcingQuery({ refetchOnMountOrArgChange: true });

  const {
    data: candidateList,
    error: candidateListError,
    isLoading: candidateListLoading,
    isFetching: candidateListFetching,
    isSuccess: candidateListSuccess,
  } = useCandlistQuery(selectedSourcing?.job_request_id, {
    refetchOnMountOrArgChange: true,
  });
  console.log('selectedSourcing?.job_request_id ', selectedSourcing?.job_request_id);
  console.log('candidateList?.data ', candidateList?.data[0]);
  console.log('taskUsers ', candidateList?.taskUsers);
  console.log('signoffUsers ', candidateList?.signoffUsers);

  const [filterObj, setFilterObj] = useState({
    search: '',
    checkboxId: [],
    experience: { min: 0, max: 0 },
    // above: 0,
    // below: 0,
  });
  const [filters, setFilters] = useState({ brand: '', color: '', search: '' });
  const [filteredResult, setFilteredResult] = useState();
  console.log('filteredResult -- >', filteredResult);

  // useEffect(() => {
  //   setSearchResults(candidateList?.data);
  // }, [candidateList]);

  useEffect(() => {
    const filteredData = candidateList?.data?.filter((item) => {
      // return (
      // item.fullName.toLowerCase().includes(filterObj.search.toLowerCase()) &&
      // item.experience >= filterObj.experience.min &&
      // item.experience <= filterObj.experience.max
      //     const brandMatch = !filters.brand || product.brand === filters.brand;
      // const colorMatch = !filters.color || product.color === filters.color;
      const nameMatch =
        !filterObj.search ||
        item.fullName.toLowerCase().includes(filterObj.search.toLowerCase());

      const expMatch =
        !filterObj.experience.min ||
        !filterObj.experience.max ||
        (item.experience > filterObj.experience.min &&
          item.experience <= filterObj.experience.max);

      // find the array of checkboxId in the object with the item.key_skills
      // const checkboxMatch =
      //   !filterObj.checkboxId ||
      //   filterObj.checkboxId.some(function (item) {
      //     return item.key_skills.includes(item);
      //   })
      //     ? true
      //     : true;

      // item.key_skills
      //   ? filterObj.checkboxIditem.key_skills.includes()
      //   : [];

      // filterObj.checkboxId;

      // const checkboxMatch =
      //   filterObj.checkboxId.length > 0 ||
      const checkboxMatch =
        !filterObj.checkboxId.length > 0 ||
        // filterObj.checkboxId.some((value) =>
        //   item.technical_skills.map((skill) => skill.skill_id?.includes(value))
        // );

        filterObj.checkboxId?.some((skillId) =>
          item.technical_skills?.some(
            (skill) => skill.skill_id === skillId.toString()
          )
        );

      return nameMatch && expMatch && checkboxMatch;
    });
    console.log("suganthi - filteredData : ", filteredData)

    setFilteredResult(filteredData);
  }, [filterObj, candidateList]);

  const openModalDataWindow = (mode, obj) => {
    // setManpowerId(id);
    // setOpenModalDataValues(sendtoModal);

    setCurrentModalValue(obj);
    setOpenModalData(true);
  };
  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const applyFilter = (e) => {
    const { name, value } = e.target;

    if (name == 'search') setFilterObj({ ...filterObj, [name]: value });
    else if (name == 'min' || name == 'max') {
      setFilterObj({
        ...filterObj,
        experience: { ...filterObj.experience, [name]: parseInt(value) },
      });
    } else {
      if (e.target.checked == true) {
        setFilterObj({
          ...filterObj,
          checkboxId: [...filterObj.checkboxId, parseInt(value)],
        });
      } else {
        setFilterObj({
          ...filterObj,
          checkboxId: filterObj.checkboxId.filter(
            (item) => item !== parseInt(value)
          ),
        });
      }

      // setMyArray(prevArray =>{...prevArray, };
    }
  };

  // added by tist
  const openModalWindow = (id) => {
    setCandID(id);
    setOpenModal(!openModal);
  }

  console.log(candID);

  const closeModalWindow = () => {
    setOpenModal(!openModal);
  }

  const onModalSubmit = async (candID) => {
    try {
      const deleteCand = await deleteCandidate(candID).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {
      console.error('Failed to delete candidate:', error);
    }
  }
  // end here

  return (
    <>
      <div>
        {/* <Sourcing /> */}
        <div className="flex justify-end items-center mb-4">
          <div className="mt-4 flex md:mt-0 md:ml-4 items-center">
            <button
              type="button"
              className="inline-flex rounded-3xl bg-transparent py-3 px-6 text-sm font-medium focus:outline-none "
              onClick={() => gotoSourcingList('sourcinglist')}
            >
              <ArrowLeftIcon
                className="-ml-1 mr-2 h-5 w-5 text"
                aria-hidden="true"
              />
              Back
            </button>
          </div>
        </div>

        <div className=" pt-0 sm:pt-0">
          <div className="mt-0  pb-0 sm:pb-0">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 " />
              <div className="relative  px-6 lg:px-0">
                <div className="">
                  <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4 flex items-center justify-between ">
                    <div className="flex pl-6 justify-start flex-col border-b border-gray-100 py-3 self-center sm:border-0 sm:border-r">
                      <dt className="order-1 mt-2 text-lg font-medium leading-6 text-gray-500">
                        {selectedSourcing?.job_request_title}
                      </dt>
                      <dd className="order-2 text-md font-bold tracking-tight text-indigo-600">
                        <GetLocationName
                          branchId={selectedSourcing?.branch_id}
                        />
                        ,{' '}
                        <GetCountry countryid={selectedSourcing?.country_id} />
                      </dd>
                    </div>
                    <div className="flex flex-col border-b border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l sm:border-r">
                      <dt className="order-1 mt-2 text-lg font-medium leading-6 text-gray-500">
                        Total Position
                      </dt>
                      <dd className="order-2 text-md font-bold tracking-tight text-indigo-600">
                        {selectedSourcing?.no_of_position}
                      </dd>
                    </div>
                    <div className="flex flex-col border-t border-gray-100 py-3 text-center sm:border-0 sm:border-l">
                      <dt className="order-1 mt-2 text-lg font-medium leading-6 text-gray-500">
                        Sourced
                      </dt>
                      <dd className="order-2 text-md font-bold tracking-tight text-indigo-600">
                        {selectedSourcing?.sourced}
                      </dd>
                    </div>

                    <div className=" flex pr-3 items-center justify-end border-b border-t border-gray-100 py-3 text-right sm:border-0 sm:border-l sm:border-r">
                      <button
                        type="button"
                        className="inline-flex rounded-3xl border border-transparent mr-2 bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                        onClick={() => gotoSourcingList('addcandidate', {})}
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5 text"
                          aria-hidden="true"
                        />
                        New Candidate
                      </button>

                      <button
                        type="button"
                        className="inline-flex rounded-3xl border border-gray-400 py-3 px-6 text-sm font-medium shadow-sm focus:outline-none "
                        onClick={() => gotoSourcingList('sourcinglist')}
                      >
                        Cancel
                      </button>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-10">
        <CandFilter applyFilter={applyFilter} />

        <div className="w-full">
          <div className="bg-white shadow-3xl gap-4 rounded-md ml-5 mt-2">
            <div className="bg-white rounded-md shadow-3xl border border-gray-100">
              {/* <div className=" py-6 px-10">
                <span className="inline-flex mr-4 items-left rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black">
                  5 - 10 Years
                  <XCircleIcon
                    className="h-6 w-6 text-gray-900 ml-3"
                    aria-hidden="true"
                  />
                </span>
                <span className="inline-flex items-left mr-4 rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black0">
                  Java
                  <XCircleIcon
                    className="h-6 w-6 text-gray-900 ml-3"
                    aria-hidden="true"
                  />
                </span>
                <span className="inline-flex items-left  mr-4 rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black">
                  Read / Write English
                  <XCircleIcon
                    className="h-6 w-6 text-gray-900 ml-3"
                    aria-hidden="true"
                  />
                </span>
                <span className="inline-flex items-left mr-4 mt-2 rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black">
                  Spring Boot
                  <XCircleIcon
                    className="h-6 w-6 text-gray-900 ml-3"
                    aria-hidden="true"
                  />
                </span>
              </div> */}

              {/* <Sourcing /> */}

              <div className="px-4 sm:px-6 lg:px-8 mt-6">
                <div className="sm:flex sm:items-center"></div>

                <div className=" mb-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full pb-6 align-middle sm:px-0 lg:px-5">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-100">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Candidate Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Exp
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Expectation CTC
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Current company
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Notice Period (in Days)
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Current designation
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Action
                              </th>

                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          {filteredResult?.length > 0 ? (
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {filteredResult
                                .map(
                                  (item) => item
                                  // searchTerm.toLowerCase() === ''
                                  //   ? item
                                  //   : item.fullName
                                  //       .toLowerCase()
                                  //       .includes(searchTerm.toLowerCase())
                                )
                                .map((cand) => (

                                  <tr key={cand.id}>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-10">
                                      {cand.fullName}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                      {cand.experience || 0}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                      {cand.salary_expectation || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {cand.current_company || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {cand.notice_period || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {cand.current_designation || '-'}
                                    </td>
                                    <td className=" flex whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      <a
                                        href={cand.resume}
                                        target="_blank"
                                        title="Edit"
                                      >
                                        <DocumentArrowDownIcon
                                          className="ml-1 mr-2 mt-1 h-7 w-7 text-green-500 "
                                          aria-hidden="true"
                                        // onClick={() => openDrawerWindow()}
                                        />
                                      </a>
                                      <div className=" self-center">
                                        <PencilIcon
                                          className="ml-1 mr-2 h-5 w-5 text-purple-500 cursor-pointer"
                                          aria-hidden="true"
                                          onClick={() =>
                                            gotoSourcingList(
                                              'addcandidate',
                                              cand
                                            )
                                          }
                                        />
                                      </div>
                                      {/* added by tist */}
                                      <div className="self-center">
                                        <TrashIcon
                                          className={`h-5 w-5 mr-2 cursor-pointer ${cand.status === 1 ? "text-red-600" : "text-gray-400 cursor-not-allowed"
                                            }`}
                                          aria-hidden="true"
                                          onClick={cand.status === 1 ? () => openModalWindow(cand.id) : undefined}
                                        />
                                      </div>
                                      {/* end here */}
                                      {cand.status == 1 ? (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            openModalDataWindow('Add', {
                                              id: cand.id,
                                              mandatoryPercentage:
                                                cand.matchedPercentage,
                                            })
                                          }
                                          className="bg-sidebar px-4 py-2 rounded-xl text-white"
                                        >
                                          {' Push to Screening '}
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                      {cand.status == 2 ? (
                                        <label
                                          // onClick={() =>
                                          //   openModalDataWindow('Add', {
                                          //     id: cand.id,
                                          //     mandatoryPercentage:
                                          //       cand.mandatoryPercentage,
                                          //   })
                                          // }
                                          className="bg-red-500 px-4 py-2 rounded-xl text-white"
                                        >
                                          {' Submitted to Screening '}
                                        </label>
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>No Data Found</tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* added by tist */}
      <Modal
        isModalOpen={openModal}
        onClose={() => closeModalWindow()}
        onSubmit={() => onModalSubmit(candID)}
      />
      {/* end here */}
      <ModalData
        isModalDataOpen={openModalData}
        onModalDataClose={closeModalDataWindow}
      // size={'3xl'}
      >
        <AddPushToScreening
          data={currentModalValue}
          showNotification={() => showNotificationMsg()}
          onModalDataClose={() => closeModalDataWindow()}
        />
      </ModalData>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useManpowerByBranchChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';

const ManpowerBarChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  const [selectedDeptId, setSelectedDeptId] = useState(0);

  const [
    manpowerByBranchChart,
    { data: manpowerByBranch, isLoading: manpowerByBranchLoading },
  ] = useManpowerByBranchChartMutation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId, selectedDeptId]);

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
      department_id: parseInt(selectedDeptId),
    };

    const dataObj = cleanObjectData(data);
    manpowerByBranchChart(dataObj);
  };

  const chartData = manpowerByBranch?.data;
  const chartName = 'Man Power';

  const series = ['Manpower', 'Job Request'];

  const getSeriesData = () => {
    return series.map((series, index) => {
      if (index === 0) {
        return {
          name: series,
          data: chartData?.map((item) => item[`manpower`] || 0),
        };
      }
      if (index === 1) {
        return {
          name: series,
          data: chartData?.map((item) => item[`jobrequest`] || 0),
        };
      }
    });
  };

  const options = {
    chart: {
      type: 'bar',
      height: 380,
      fontFamily: 'Inter var', // Set font family for all text elements
      // foreColor: '#FFFFFF', // Set color for all text elements to white
      foreColor: '#000000', //set color for all text elements to black
      toolbar: {
        show: false, //Hide the toolbar (menu bar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: true, // Ensure dataLabels is properly defined with enabled property
          position: 'center',
        },
        barHeight: '15px',
      },
    },
    dataLabels: {
      enabled: true,
      // formatter: function (val, opts) {
      //     return opts.w.config.series[opts.seriesIndex]; //Display value
      // },
      style: {
        fontSize: '10px',
        fontWeight:'',
        fontFamily: 'Inter var',
        // colors: ['#FFFFFF'], //color of the data labels
        colors: ['#fff'], //color of the data labels
        
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        show: false, //Hide the x-axis labels
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        height: 1,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#f3f4f6',
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ['#5EEE6E', '#F92D51'],
    // yaxis: {
    //     labels: {
    //         style: {
    //             fontSize: '12px', // Adjust font size of y-axis labels
    //             fontFamily: 'Poppins',
    //         },
    //     },
    //     grid: {
    //         show: true, // Show the y-axis gridlines
    //         borderColor: '#FFFFFF', // Set the color of y-axis gridlines to white
    //         strokeDashArray: 0, // Optionally, set the strokeDashArray to make gridlines solid
    //         position: 'back', // Set the grid lines behind the data points
    //     },
    // },
  };

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setSelectedLocationId(id);
  };

  const setDepartmentId = (id) => {
    setSelectedDeptId(id);
  }

  return (
    <div>
      <div className="flex justify-between p-3">
        <div>
          <h1 className="text-black text-[16px] font-poppins">{chartName}</h1>
        </div>
        <div>
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            isDaysFilter={true}
            showCountry={true}
            showLocation={true}
            showDepartment={false}
          />
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={getSeriesData()}
        type="bar"
        height={380}
        width={570}
      />
    </div>
  );
};

export default ManpowerBarChart;

import React, { useState } from 'react';
import Notification from '../../components/common/Notification';

import { useUpdateSignoffStatusMutation } from '../../features/workflow/workflowApiSlice';

const SFSignOffWorkflow = ({ data, isEdit, onClose }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [updateSignoffStatus, { isLoading }] = useUpdateSignoffStatusMutation();
  const [message, setMessage] = useState('');

  const updateStatus = async (approveStatus) => {
    const hiringStageid = 'hiring_Stage_id';
    const hiringStateData = data.hiringStageDetails.map((hsd) => ({
      id: hsd.id,
      hiringStageid: hsd.hiringStageId,
      signoffUsers: hsd.signOffUsers.map((value) => ({
        id: value.updateSignoffStatus,
        approval_status: approveStatus,
        comments: message,
      })),
    }));

    try {
      const statusData = {
        workflow_id: data.id,
        hiring_stages: hiringStateData,
      };
      const updateSFStatus = await updateSignoffStatus(statusData).unwrap();

      setMessage('');
      setShowNotification(!showNotification);
      onClose();
    } catch (error) {}
    onClose();
  };
  // Function to split interview stages
  const splitHiringStages = (data) => {
    return data.hiringStageDetails.flatMap((stage) => {
      if (stage.hiringStageTitle === "Interview" && stage.interviewDetails) {
        // Split interview stages
        return stage.interviewDetails.map((interview) => ({
          id: interview.workflow_hiring_stage_id,
          hiringStageId: data.id,
          taskDuration: interview.task_duration ? interview.task_duration.toString() : null,
          signoff_duration: interview.signoff_duration ? interview.signoff_duration.toString() : null,
          hiringStageTitle: `${interview.title} - Interviewer`,
          signOffUsers: interview.signOffUsers.map((user) => ({
            id: user.user_id,
            approval_status: 1,
            comments: null,
            approved_by: interview.createdBy,
            user_id: user.user_id,
            first_name: user.first_name,
            last_name: user.last_name,
          })),
          taskUsers: interview.taskUsers.map((user) => ({
            id: user.user_id,
            approval_status: 1,
            comments: null,
            approved_by: interview.createdBy.toString(),
            user_id: user.user_id,
            first_name: user.first_name,
            last_name: user.last_name,
          })),
        }));
      } else {
        // Keep the stage as is
        return {
          id: stage.id,
          hiringStageId: stage.hiringStageId,
          taskDuration: stage.taskDuration,
          signoff_duration: stage.signoff_duration,
          hiringStageTitle: stage.hiringStageTitle,
          signOffUsers: stage.signOffUsers,
          taskUsers: stage.taskUsers,
        };
      }
    });
  };

  const transformedData = splitHiringStages(data);
  console.log(transformedData);

  
  return (
    <>
      <Notification isShow={showNotification} />
      <div className="flex justify-between  border-gray-200">
        <div className=" items-center">
          <h4 className="text-lg text-sidebar font-semibold">
            {data.designation}
          </h4>
          <h5 className="mb-3 text-sm font-normal tracking-tight text-gray-500">
            {data.branch}
          </h5>
        </div>
        {/* <div>
            <strong className="flex w-full text-2xl font-medium text-purple-700">
              50
            </strong>
          </div> */}
      </div>
      <div className="overflow-y-auto h-">
        {transformedData.map((task) => (
          <div className=" bg-workflow_drawerbg px-4 py-3 rounded-lg mb-3">
            <div className="flex justify-between">
              <div className=" items-center">
                <h4 className="text-md text-black font-semibold">
                  {task.hiringStageTitle}
                </h4>
              </div>
              <div>
                <p className="flex w-full text-sm font-medium text-sidebar">
                  Work Duration:
                  <span className=" text-sm font-medium text-sidebar">
                    {task.signoff_duration} day
                  </span>
                </p>
              </div>
            </div>
            <div className="flex justify-start">
              {task?.signOffUsers?.map((signOffUser) => (
                <div className="bg-white py-1 px-4 my-3  border border-gray-300 rounded-2xl mx-0 shadow-md mr-3 ">
                  <h5 className="text-sm font-medium tracking-tight text-blue-500 truncate whitespace-nowrap overflow-hidden w-28">
                    {signOffUser.first_name} {signOffUser.last_name}
                  </h5>
                  <p className="text-xs text-black tracking-tight ">
                    HR Manager
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SFSignOffWorkflow;

import React from 'react';

import { useGetCandDetailsQuery } from '../features/hiringstages/sourcingApiSlice';

const useCandidate = (id) => {
  const {
    data: canddetails,
    error: canddetailsErr,
    isLoading: canddetailsLoading,
    isFetching: canddetailsFetching,
    isSuccess: canddetailsSuccess,
  } = useGetCandDetailsQuery(id, { refetchOnMountOrArgChange: true });
  return {
    canddetails,
    canddetailsLoading,
    canddetailsSuccess,
  };
};

export default useCandidate;

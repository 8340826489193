//created by lakshmi for job request
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useJobrequestByBranchChartMutation } from '../../features/dashboard/dashboardApiSlice';
import SelectCountryListByLocation from '../../features/utils/SelectCountryListByLocation';
import { cleanObjectData } from '../../features/utils/Utils';

const JobRequestStackedbarChart = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  //   const [selectedDepartment, setSelectedDepartment] = useState('');
  //   const [selectedLast30Days, setSelectedLast30Days] = useState('');

  //   // Define options for countries, branches, departments, and last 30 days
  //   const countryOptions = ['India', 'USA', 'UK'];
  //   const branchOptions = ['Chennai', 'Bangalore', 'Pune'];
  //   const departmentOptions = ['Department1', 'Department2', 'Department3'];
  //   const last30DaysOptions = ['Last 30 days', 'Last month', 'Last Year'];

  // const jobRequest = [
  //     {name:'Admin',value1:10, value2:40, value3:20},
  //     {name:'Sales',value1:50, value2:10, value3:8},
  //     {name:'Manager',value1:50, value2:25, value3:0},
  //     {name:'HR',value1:65, value2:10, value3:6},
  //     {name:'Java',value1:20, value2:25, value3:14},
  //     {name:'Finance',value1:38, value2:14, value3:9},
  // ]
  // const series = [
  //     {
  //         name:"Approved",
  //         data: jobRequest ? jobRequest.map((item)=>item.value1) : [],
  //     },
  //     {
  //         name:"Waiting",
  //         data: jobRequest ? jobRequest.map((item)=>item.value2) : [],
  //     },
  //     {
  //         name:"Rejected",
  //         data: jobRequest ? jobRequest.map((item)=>item.value3) : [],
  //     },
  // ]

  const [
    jobRequestByBranchChart,
    { data: jobRequestByBranch, isLoading: jobRequestByBranchLoading },
  ] = useJobrequestByBranchChartMutation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    callAPI();
  }, [selectedCountryId, selectedLocationId]);

  const callAPI = () => {
    const data = {
      country_id: parseInt(selectedCountryId),
      location_id: parseInt(selectedLocationId),
    };
    const dataObj = cleanObjectData(data);
    jobRequestByBranchChart(dataObj);
  };

  const chartData = jobRequestByBranch?.data;
  const chartName = 'Job Request';

  const series = ['Waiting', 'Approved', 'Rejected'];

  const getSeriesData = () => {
    // return series.map((series, index) => ({
    //   name: series,
    //   data: chartData.map((item) => item[`value${index + 1}`] || 0),
    // }));
    return series.map((series, index) => {
      if (index === 0) {
        return {
          name: series,
          data: chartData?.map((item) => item['Waiting'] || 0),
        };
      }
      if (index === 1) {
        return {
          name: series,
          data: chartData?.map((item) => item['Approved'] || 0),
        };
      }
      if (index === 2) {
        return {
          name: series,
          data: chartData?.map((item) => item['Rejected'] || 0),
        };
      }
    });
  };

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      fontFamily: 'Inter var', //set font family for all text elements
      foreColor: '#000000', //set color for all text element to white
      toolbar: {
        show: false, //Hide the toolbar (menu bar)
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          // total: {
          //   enabled: true,
          //   offsetX: 0,
          //   style: {
          //     fontSize: '13px',
          //     fontWeight: 900,
          //   },
          // },
        },
        // columnWidth: '40%',
        barHeight: '18px',
        borderRadius: 15, //All rounded corners to the bars
        // borderRadiusApplication: 'around',
        // borderRadiusWhenStacked: 'last',
        endingShape: 'rounded', // Rounded ends for all bars
      },
    },
    dataLabels: {
      enabled: true, // Error seems to be here
      // formatter: function (val, opts) {
      //     return opts.w.config.series[opts.seriesIndex]; //Display value
      // },
      style: {
        fontSize: '15px',
        fontFamily: 'Inter var',
        fontWeight:'',  
        colors: ['#fff'], //color of the data labels
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartData ? chartData.map((item) => item.name) : [],
      labels: {
        show: false, //Hide the x-axis labels
      },
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        height: 1,
      },
      axisTicks: {
        show: false,
        // color: '#6234C1', //color of x-axis ticks
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#9ca3af', //color of x-axis line
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#f3f4f6',
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ['#FFC10B','#53BA9E', '#FA2C50'],
  };

  const setCountryId = (id) => {
    setSelectedCountryId(id);
  };

  const setLocationId = (id) => {
    setSelectedLocationId(id);
  };
  return (
    <div>
      <div className="flex justify-between  p-3">
        <div>
          <h1 className="text-black text-[16px] font-sans">{chartName}</h1>
        </div>
        <div>
          {/* Dropdown for country */}
          {/* <select
            className=" bg-[#39216B] text-white text-[13px]"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Country</option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select> */}

          {/* Dropdown for branch */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">Branch</option>
            {branchOptions.map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select> */}

          {/* Dropdown for department */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Department</option>
            {departmentOptions.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select> */}

          {/* Dropdown for last 30 days */}
          {/* <select
            className="bg-[#39216B] text-white text-[13px]"
            value={selectedLast30Days}
            onChange={(e) => setSelectedLast30Days(e.target.value)}
          >
            <option value="">Last 30 Days</option>
            {last30DaysOptions.map((days, index) => (
              <option key={index} value={days}>
                {days}
              </option>
            ))}
          </select> */}
          <SelectCountryListByLocation
            setCountryId={setCountryId}
            setLocationId={setLocationId}
            isDaysFilter={true}
            showCountry={true}
            showLocation={true}
            showDepartment={true}
          />
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={getSeriesData()}
        type="bar"
        height={380}
        width={567}
      />
    </div>
  );
};

export default JobRequestStackedbarChart;

import React from 'react';
import { useCountryQuery } from '../../features/country/countryApiSlice';

const GetCountry = ({ countryid }) => {
  const {
    data: countryList,
    error: countryErr,
    isLoading: countryLoading,
    isFetching: countryFetching,
    isSuccess: countrySuccess,
  } = useCountryQuery({ refetchOnMountOrArgChange: true });

  const getCountryName = (countryid) => {
    if (countrySuccess) {
      const countryName = countryList?.data.filter((country) => {
        return country.id == countryid;
      });
      return countryName[0]?.country_name ? countryName[0]?.country_name : '-';
    }
  };

  return <>{getCountryName(countryid)}</>;
};

export default GetCountry;

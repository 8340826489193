import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const SignoffsWorkflowTask = () => {
  return (
    <div>
      <div className="flex flex-row gap-4">
        <div className="bg-white basis-1/3 p-4 rounded-md shadow-3xl">
          <div className="min-w-0 flex-1">
            <label htmlFor="search" className="sr-only">
              Search by Name
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="block w-full appearance-none rounded-full border border-gray-300 pl-10 pr-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Search by Name"
              />
            </div>
          </div>

          {/* <CardsUser /> */}
        </div>
        {/* <div className="bg-white rounded-md shadow-3xl">
          <MyTask />
          <SignOffs />
        </div> */}
        <div className="bg-white basis-2/3 rounded-md shadow-3xl">
          {/* <DashboardTask />
           */}
          <p className="p-4">Job Recuirement Signoffs</p>
        </div>
      </div>
    </div>
  );
};

export default SignoffsWorkflowTask;

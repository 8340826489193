// Common Import
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

// Hooks Import
import {
  useUpdateCandidateMutation,
  useGetCandDetailsQuery,
} from '../../../../../../features/hiringstages/sourcingApiSlice';
import useGetSkills from '../../../../../../hooks/useGetSkills';
import useCandidate from '../../../../../../hooks/useCandidate';

export const AddTechnicalSkill = ({
  onModalDataClose,
  onModalDataSubmit,
  setShowNotification,
  data,
}) => {
  // From Redux Store
  const profileId = useSelector((state) => state.candidatedetails.id);
  const candiid = useSelector((state) => state.candidatedetails?.id) || [];

  // Local Stae
  const [techskills, setTechskills] = useState([]);

  // REST API
  const { skills, loadingSkills, successSkills } = useGetSkills();
  const { canddetails, canddetailsLoading, canddetailsSuccess } =
    useCandidate(candiid);
  const [updateCandidate, { isCandLoading }] = useUpdateCandidateMutation();

  console.log('canddetails ', canddetails);
  //
  let techSkillsArray = canddetails?.data.technical_skills || [];

  useEffect(() => { }, [canddetails]);

  const initialValues = {
    // id: data.obj?.id || '',
    skill_id: data.obj?.skill_id || '',
    experience: data.obj?.experience || '',
  };
  const validationSchema = yup.object({
    skill_id: yup.string().required('Please select skill'),
    experience: yup
      .number()
      .positive()
      .min(1)
      .required('Please select experience'),
  });

  const onSubmit = async (validationSchema) => {
    console.log(techSkillsArray)
    // Convert the array to a JSON string
    let techSkillsArrayString = JSON.stringify(techSkillsArray);
    // Parse the JSON string to convert it to an array
    let parsedTechSkillsArray;

    try {
      parsedTechSkillsArray = JSON.parse(techSkillsArrayString);    
      if (!Array.isArray(parsedTechSkillsArray)) {
        throw new Error('Parsed value is not an array');
      }
    } catch (error) {
      console.error('Error parsing techSkillsArray:', error);
      parsedTechSkillsArray = [];
    }

    console.log('validationSchema', validationSchema);

    let allSkills = [...parsedTechSkillsArray, validationSchema];
    console.log('...parsedTechSkillsArray =- ', ...parsedTechSkillsArray);
    // allSkills.push(validationSchema);

    console.log('allSkills =- ', allSkills);

    let values = {
      profile_id: profileId,
      technical_skills: allSkills,
    };
    try {
      const updateCandidateData = await updateCandidate(values).unwrap();
      setShowNotification(true);
      onModalDataClose();
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form className="flex flex-col " onSubmit={formik.handleSubmit}>
      <div className="mt-5 sm:mt-6 sm:grid sm:grchid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="skill_id"
            className="block text-sm font-medium text-gray-700 pb-2"
          >
            Skills
          </label>
          <select
            id="skill_id"
            name="skill_id"
            className="bg-inputbg w-full border border-inputBorder mt-1 block rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.skill_id}
          >
            <option value="">-- Select Skills --</option>
            {skills?.data.map((skill) => (
              <option value={skill.id} key={skill.id}>
                {skill.name}
              </option>
            ))}
          </select>
          {formik.touched.skill_id && formik.errors.skill_id ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.skill_id}
            </div>
          ) : null}
        </div>

        <div className="col-span-4 sm:col-span-1">
          <label
            htmlFor="experience"
            className="block text-sm font-medium text-gray-700 pb-2"
          >
            Year of Experience
          </label>
          <input
            type="number"
            id="experience"
            name="experience"
            className="bg-inputbg w-full border border-inputBorder mt-1 block rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.experience}
          >
            {/* <option value="">-- Select Experience --</option>
            <option value="1 Year">1 Year</option>
            <option value="2 Year">2 Year</option>
            <option value="3 Year">3 Year</option>
            <option value="4 Year">4 Year</option>
            <option value="5 Year">5 Year</option>
            <option value="6 Year">6 Year</option>
            <option value="7 Year">7 Year</option>
            <option value="8 Year">8 Year</option>
            <option value="9 Year">9 Year</option>
            <option value="10 Year">10 Year</option>
            <option value="11 Year">11 Year</option>
            <option value="12 Year">12 Year</option>
            <option value="13 Year">13 Year</option>
            <option value="14 Year">14 Year</option>
            <option value="15 Year">15 Year</option>
            <option value="16 Year">16 Year</option>
            <option value="17 Year">17 Year</option>
            <option value="18 Year">18 Year</option>
            <option value="19 Year">19 Year</option>
            <option value="20 Year">20 Year</option>
            <option value="21 Year">21 Year</option>
            <option value="22 Year">22 Year</option>
            <option value="23 Year">23 Year</option>
            <option value="24 Year">24 Year</option>
            <option value="25 Year">25 Year</option> */}
          </input>
          {formik.touched.experience && formik.errors.experience ? (
            <div className="mt-2 text-sm text-red-600">
              {formik.errors.experience}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div>
          <button
            type="button"
            onClick={() => onModalDataClose()}
            className=" rounded-3xl border border-gray-300 bg-white py-3 px-8  text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          // className="inline-flex  justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 mt-4 text-base font-medium text-indigo-700 shadow-sm focus:outline-none  sm:col-start-2 sm:text-sm"
          >
            Cancel
          </button>
        </div>
        <div>
          {/* <button
              type="button"
              className="inline-flex mr-4 mt-4  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Publish
            </button> */}
          <button
            type="submit"
            className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
          // className="inline-flex  justify-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

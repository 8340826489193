import { Outlet } from 'react-router-dom';
import { selectCurrentToken } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';

import React from 'react';
import InternetStatusAlert from '../pages/InternetStatusAlert';

const Layout = () => {
  return (
    <>
      <InternetStatusAlert />
      <Outlet />
    </>
  );
};

export default Layout;

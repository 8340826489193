import { Fragment, useState, useEffect } from 'react';
import { Dialog, Menu, Transition, Tab } from '@headlessui/react';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
// import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import AddMyTaskUserPriv from '../../features/mytaskuserpriv/AddMyTaskUserPriv';

import {
  useAdminMyTaskUserPrevillageQuery,
  useDeleteAdminMyTaskUserPrevillageMutation,
} from '../../features/myadminuserprivillage/AdminMyTaskUserPrevillageApiSlice';
import Drawer from '../../components/common/Drawer';
import { Link, useParams, useNavigate } from 'react-router-dom';
// import MytaskUserPriv from './workflow/MytaskUserPriv';

import {
  ArchiveBoxIcon,
  Bars3Icon,
  BellIcon,
  FlagIcon,
  InboxIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Notification from '../../components/common/Notification';

import { PencilIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import Modal from '../../components/common/Modal';
import AddAdminMyTaskUserPrevillage from '../../features/myadminuserprivillage/AddAdminMyTaskUserPrevillage';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUserID } from '../../features/auth/authSlice';

import { useAdminPrivSetQuery } from '../../features/privileges/adminSettingsApiSlice';
import { useUserPrivSetQuery } from '../../features/privileges/userSettingsApiSlice';

import { useTeamsQuery } from '../../features/teams/teamsApiSlice';

import MTManpower from './mytask/manpower/MTManpower';
import MTJobRequest from './mytask/jobrequirement/MTJobRequest';
import SOManpower from './signoff/manpower/SOManpower';
import SOJobRequest from './signoff/jobrequirement/SOJobRequest';
import MTHiringStage from './mytask/hiringstage/MTHiringStage';
import SOHiringStage from './signoff/hiringstage/SOHiringStage';
import {
  updateLevelOne,
  updateLevelTwo,
  updateLevelThree,
  levelOne,
  levelTwo,
  levelThree,
} from '../../features/privileges/userWorkspaceSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function UserWorkspacePage() {
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectCurrentUserID);

  const levelone = useSelector(levelOne);
  const leveltwo = useSelector(levelTwo);
  const levelthree = useSelector(levelThree);

  const [levelOneState, setLevelOneState] = useState(0);
  const [levelTwoState, setLevelTwoState] = useState(0);

  useEffect(() => {
    setLevelOneState(levelone);
  }, [levelone]);

  useEffect(() => {
    setLevelTwoState(leveltwo);
  }, [leveltwo]);

  const {
    data: adminSettings,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useAdminPrivSetQuery(currentUserId, { refetchOnMountOrArgChange: true });
  const {
    data: userSettings,
    error: userErr,
    isLoading: userloading,
    isSuccess: userSuccess,
  } = useUserPrivSetQuery({ refetchOnMountOrArgChange: true });

  const { data: users, isLoading: userLoading } = useTeamsQuery({
    refetchOnMountOrArgChange: true,
  });

  const displayStatus = (status) => {
    if (status == 0) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md px-3 py- text-center text-sm font-semibold  shadow-sm hover:bg-grey-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approval Pending'}
          </div>
        </div>
      );
    } else if (status == 1) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-green-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Approved'}
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-blue-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Query'}
          </div>
        </div>
      );
    } else if (status == 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-600 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            {'Reject'}
          </div>
        </div>
      );
    }
  };

  const displayUsername = (id) => {
    if (!userLoading) {
      let findUser = users?.data.filter((el) => el.id == id);
      if (findUser[0]) {
        return findUser[0]?.first_name + ' ' + findUser[0]?.last_name;
      }
    }
  };

  let adminpriv;
  let ifMyTask;
  let ifSignoff;
  let ifMYuserpriv;
  let ifMYworkflow;
  let ifMYworkflowsf;
  let ifSFuserpriv;
  let ifSFworkflow;
  let ifSFworkflowsf;
  let userprivApprovalStatus;

  if (!isLoading) {
    adminpriv = adminSettings?.data?.userWorkspacePrivileges;
    if (userSuccess) {
      userprivApprovalStatus =
        userSettings?.data[0]?.workspace_privilege[0].approval_status;
    }

    ifMyTask = adminpriv['My Task'];
    ifSignoff = adminpriv['Signoffs'];

    if (ifMyTask) {
      ifMYuserpriv = ifMyTask.some((el) => el.title == 'Manpower Requirement');
      ifMYworkflow = ifMyTask.some((data) => data.title == 'Job Request');
      ifMYworkflowsf = ifMyTask.some((data) => data.title == 'Hiring Stage');
    }

    if (ifSignoff) {
      ifSFuserpriv = ifSignoff.some(
        (data) => data.title === 'Manpower Requirement'
      );
      ifSFworkflow = ifSignoff.some((data) => data.title === 'Job Request');
      ifSFworkflowsf = ifSignoff.some((data) => data.title === 'Hiring Stage');
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);

  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);
  const [deleteAdminMyTaskUserPrevillage] =
    useDeleteAdminMyTaskUserPrevillageMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(obj);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const onModalSubmit = async (deptId) => {
    try {
      const deleteAdminMytask =
        await deleteAdminMyTaskUserPrevillage(deptId).unwrap();
      closeModalWindow();
      showNotificationMsg();
    } catch (error) {}
  };

  const handleLevelOne = (index) => {
    dispatch(updateLevelOne(index));
  };

  const handleLevelTwo = (index) => {
    dispatch(updateLevelTwo(index));
  };

  const noList = () => (
    <>
      <h1 className="font-extrabold text-red-600 p-4 ">NO DATA</h1>
    </>
  );

  return (
    <>
      {!isLoading ? (
        <div className="">
          {/* <h1>{JSON.stringify(userprivApprovalStatus) || 'Super'}</h1> */}
          {isSuccess && userSuccess ? (
            <section aria-labelledby="features-heading" className="">
              {userprivApprovalStatus != 1 ? (
                <h1 className="text-lg text-red-600">
                  You dont have access to User Workspace Menu
                </h1>
              ) : (
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                  <Tab.Group
                    as="div"
                    className="mt-4"
                    selectedIndex={levelOneState}
                    onChange={handleLevelOne}
                  >
                    <div className="-mx-4 flex overflow-x-auto sm:mx-0 bg-white rounded-t-lg">
                      <div className="flex-auto border-b border-gray-200 px-4 sm:px-0 ">
                        <Tab.List className="-mb-px flex bg-white">
                          {ifMyTask ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                )
                              }
                            >
                              MYTASK
                            </Tab>
                          ) : (
                            ''
                          )}
                          {ifSignoff ? (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                )
                              }
                            >
                              SIGNOFF
                            </Tab>
                          ) : (
                            ''
                          )}
                          {/* ))} */}
                        </Tab.List>
                      </div>
                    </div>

                    <Tab.Panels as={Fragment}>
                      {ifMyTask ? (
                        <Tab.Panel className="space-y-16">
                          <Tab.Group
                            as="div"
                            className=""
                            selectedIndex={levelTwoState}
                            onChange={handleLevelTwo}
                          >
                            <Tab.List className="-mb-px flex  bg-white rounded-b-lg">
                              {ifMYuserpriv ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6 rounded-bl-lg'
                                    )
                                  }
                                >
                                  Manpower Requirement
                                </Tab>
                              ) : (
                                ''
                              )}
                              {ifMYworkflow ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                    )
                                  }
                                >
                                  Job Request
                                </Tab>
                              ) : (
                                ''
                              )}
                              {ifMYworkflowsf ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                    )
                                  }
                                >
                                  Hiring Stage
                                </Tab>
                              ) : (
                                ''
                              )}
                            </Tab.List>
                            <Tab.Panels as={Fragment}>
                              {ifMYuserpriv ? (
                                <Tab.Panel className="space-y-4 py-0">
                                  {/* My Task - content - Manpower Requirment */}
                                  <MTManpower />
                                  {/* <MytaskUserPriv isEditnew={false} /> */}
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                              {ifMYworkflow ? (
                                <Tab.Panel className="space-y-16 py-6">
                                  {/* My Task - content - Workflowffff */}

                                  {/* <div className="lg:pb-4">
                              <div className="flex justify-between ">
                                <div className="flex"></div>
                                <div className="flex">
                                  <Link
                                    type="button"
                                    to={''}
                                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                                    onClick={() => setOpenDrawer(!openDrawer)}
                                    onClick={() => openModalDataWindow()}
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5 text"
                                      aria-hidden="true"
                                    />
                                    Create Jobs
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                                  {/* <div className="px-8">
                              <ul
                                role="list"
                                className="grid grid-cols-1 gap-3 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4"
                              >
                                <li className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg">
                                  <MTJobRequest />
                                </li>
                                <li className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg">
                                  <JobRqmtWaitingforApproval />
                                </li>
                                <li className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg">
                                  <JobRqmtApproved />
                                </li>
                                <li className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg">
                                <ManpowerRqmt />
                              </li>
                              </ul>
                            </div> */}
                                  <MTJobRequest />
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                              {ifMYworkflowsf ? (
                                <Tab.Panel className="space-y-16 py-6">
                                  {/* My Task - content - Workflow Signoff */}
                                  {/* <MytaskWorkflowSignoffs /> */}
                                  <MTHiringStage />
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                            </Tab.Panels>
                          </Tab.Group>
                        </Tab.Panel>
                      ) : (
                        ''
                      )}
                      {ifSignoff ? (
                        <Tab.Panel className="space-y-16">
                          <Tab.Group
                            as="div"
                            className=""
                            selectedIndex={levelTwoState}
                            onChange={handleLevelTwo}
                          >
                            <Tab.List className="-mb-px flex bg-white">
                              {ifSFuserpriv ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                    )
                                  }
                                >
                                  Manpower Requirement
                                </Tab>
                              ) : (
                                ''
                              )}
                              {ifSFworkflow ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                    )
                                  }
                                >
                                  Job Request
                                </Tab>
                              ) : (
                                ''
                              )}
                              {ifSFworkflowsf ? (
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      selected
                                        ? 'border-indigo-500 text-indigo-600 focus:outline-none'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 py-3 text-sm font-bold px-6'
                                    )
                                  }
                                >
                                  Hiring Stage
                                </Tab>
                              ) : (
                                ''
                              )}
                            </Tab.List>
                            <Tab.Panels as={Fragment}>
                              {ifSFuserpriv ? (
                                <Tab.Panel className="space-y-16 py-6">
                                  {/* <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">
                              <div className="inline-block min-w-full py-2 align-middle px-1">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                  {userloading ? (
                                    'Loading ...'
                                  ) : (
                                    <MytaskUserPriv isEditnew={true} />
                                  )}
                                </div>
                              </div>
                            </div> */}
                                  {/* Sign Off - content - Manppwer Req */}
                                  <SOManpower />
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                              {ifSFworkflow ? (
                                <Tab.Panel className="space-y-16 py-6">
                                  {/* Sign Off - content - Workflow */}
                                  <SOJobRequest />
                                  {/* <WorkflowAssign /> */}
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                              {ifSFworkflowsf ? (
                                <Tab.Panel className="space-y-16 py-6">
                                  {/* Sign Off - content - Workflow Signofffff */}
                                  {/* <WorkflowSignoffs /> */}
                                  <SOHiringStage />
                                </Tab.Panel>
                              ) : (
                                ''
                              )}
                            </Tab.Panels>
                          </Tab.Group>
                        </Tab.Panel>
                      ) : (
                        ''
                      )}
                      {/* ))} */}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              )}
            </section>
          ) : (
            ' '
          )}
        </div>
      ) : (
        'Loading...'
      )}

      <Notification
        isShow={showNotification}
        onClose={() => closeNotificationMsg()}
      />

      <Drawer
        isOpen={openDrawer}
        title={isEdit ? 'Edit Interview' : 'Add Interview'}
        onClose={() => closeDrawerWindow()}
        // onSubmit={() => onSubmit()}
      >
        <AddMyTaskUserPriv
          data={currentValue}
          isEdit={isEdit}
          onClose={() => closeDrawerWindow()}
        />
      </Drawer>
    </>
  );
}

import React from 'react';
import { Disclosure } from '@headlessui/react';
import {
  PlusIcon,
  PencilSquareIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { useCandlistQuery } from '../../../../../features/hiringstages/sourcingApiSlice';

const transactions = [
  {
    name: 'Robin Williams',
    exp: '4 Years',
    salary_exp: '10 L',
    notice_prd: '1 Month',
    current_company: 'HCL',
    current_pos: '3D Animator',
  },
  {
    name: 'Theodore',
    exp: '6 Years',
    salary_exp: '12 L',
    notice_prd: '2 Months',
    current_company: 'Capgemini',
    current_pos: '3D Modeler',
  },
  {
    name: 'Dwayne',
    exp: '8 Years',
    salary_exp: '16 L',
    notice_prd: '3 Months',
    current_company: 'TCS',
    current_pos: 'Lead Designer',
  },
  // More transactions...
];

const CandidateList = ({ sourcingId }) => {
  const {
    data: candidateList,
    error: candidateListError,
    isLoading: candidateListLoading,
    isFetching: candidateListFetching,
    isSuccess: candidateListSuccess,
  } = useCandlistQuery(sourcingId, { refetchOnMountOrArgChange: true });

  return (
    <Disclosure.Panel as="dd" className="mt-0 pr-0">
      <p className="text-base leading-7 text-gray-600">
        {/* <h3>Bottom</h3> */}
        <div className="flex">
          <div className=" gap-4 basis-full">
            <div className="bg-white shadow-3xl gap-4 rounded-md ">
              <div className="bg-white rounded-md shadow-3xl border border-gray-100">
                {/* <Sourcing /> */}

                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center"></div>

                  <div className="mt-0 mb-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-100 text-left text-md font-bold text-gray-900">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3  sm:pl-6"
                                >
                                  Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 ">
                                  Experience(Years)
                                </th>
                                <th scope="col" className="px-3 py-3.5 ">
                                  Current Salary
                                </th>
                                <th scope="col" className="px-3 py-3.5 ">
                                  Notice Period(In Days)
                                </th>

                                <th scope="col" className="px-3 py-3.5 ">
                                  Current Company
                                </th>

                                <th scope="col" className="px-3 py-3.5 ">
                                  Current Position
                                </th>

                                <th scope="col" className="px-3 py-3.5 ">
                                  Resume
                                </th>

                                <th scope="col" className="px-3 py-3.5 ">
                                  Profile Match
                                </th>
                              </tr>
                            </thead>
                            {candidateList?.data?.length > 0 ? (
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {candidateList?.data?.map((candidate) => (
                                  <tr key={candidate.id}>
                                    <td className="whitespace-nowrap py-2  pr-3 text-sm text-gray-500 sm:pl-5">
                                      {candidate.fullName}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                      {candidate.experience || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                      {candidate.current_salary || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {candidate.notice_period || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {candidate.current_company || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      {candidate.current_designation || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      <a
                                        href={candidate.resume}
                                        target="_blank"
                                        title="Edit"
                                      >
                                        <DocumentTextIcon
                                          className="ml-1 mr-2 mt-1 h-10 w-10 text-blue-500 "
                                          aria-hidden="true"
                                          // onClick={() => openDrawerWindow()}
                                        />
                                      </a>
                                    </td>
                                    <td className=" whitespace-nowrap px-5 py-2 text-sm text-gray-500">
                                      <div className="flex items-center mt-1">
                                        <div className="w-full">
                                          <div className=" text-gray-700 text-center pb-1">
                                            <span className="text-sm font-semibold">
                                              {candidate.matchedPercentage}
                                            </span>
                                          </div>
                                          <div className="w-full h-2 bg-gray-300 rounded-lg">
                                            <div
                                              style={{
                                                width: `${candidate.matchedPercentage}%`,
                                              }}
                                              className={`h-2 bg-violet-600 rounded-lg`}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <td className="p-3">No Data Found</td>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p>
    </Disclosure.Panel>
  );
};

export default CandidateList;
